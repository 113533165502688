import React, { Component } from 'react';

import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
// import BottomNavigation from '@material-ui/core/BottomNavigation';
// import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import StoreIcon from '@material-ui/icons/Store';
// import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { reduxForm } from 'redux-form';
import * as Rent from '../Rent/Saga/Rent';
import './Css/Table.css';
import './Css/DetailLocation.css';
// import MapDispComponent from '../Map/MapDispComponent';
import DetailMapComponent from './DetailMapComponent';
import { Card, CardContent } from '@material-ui/core';

// swiper bundle styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'

import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Autoplay, Mousewheel, Keyboard } from "swiper";
import SwiperCore, { Navigation } from 'swiper';
import { Loop } from '@material-ui/icons';

// Navigationモジュールを有効化
SwiperCore.use([Navigation]);


class LocationDispComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            parking: false,
            conveni: false,
            store: false,
            bank: false,
            youchien: false,
            school: false,
            ds: false,
            hospital: false,
            spot: 0,
        };
        if (this.props.mypage_local_store.length === 0) {
            this.props.get_mypage_local_spots(1)
            this.props.get_mypage_local_spots(2)
        }
        this.spotDisp = this.spotDisp.bind(this);
    }
    spotDisp(value) {
        if (value === 1) {//parking
            this.setState({ spot: this.state.parking === true ? 0 : 1 });
            this.setState({ parking: !this.state.parking });
            // this.setState({ conveni: false });

        }else if (value === 2) {//コンビニ
            this.setState({ spot: this.state.conveni === true ? 0 : 2 });
            this.setState({ conveni: !this.state.conveni });
            // this.setState({ store: false });
        }else if (value === 3) {//スーパー
            this.setState({ spot: this.state.store === true ? 0 : 3 });
            this.setState({ store: !this.state.store });
            // this.setState({ conveni: false });

        }else if (value === 4) {//銀行
            this.setState({ spot: this.state.bank === true ? 0 : 4 });
            this.setState({ bank: !this.state.bank });
            // this.setState({ store: false });
        }else if (value === 5) {//幼稚園
            this.setState({ spot: this.state.youchien === true ? 0 : 5 });
            this.setState({ youchien: !this.state.youchien });
            // this.setState({ store: false });
        }else if (value === 6) {//小中学校
            this.setState({ spot: this.state.school === true ? 0 : 6 });
            this.setState({ school: !this.state.school });
            // this.setState({ store: false });
        }else if (value === 7) {//ドラッグストア
            this.setState({ spot: this.state.ds === true ? 0 : 7 });
            this.setState({ ds: !this.state.ds });
            // this.setState({ store: false });
        }else if (value === 8) {//病院
            this.setState({ spot: this.state.hospital === true ? 0 : 8 });
            this.setState({ hospital: !this.state.hospital });
            // this.setState({ store: false });
        }
        // console.log(value)
    }
    render() {

        const isMobile = window.innerWidth <= 768;

        return (
            <div className="mb-3" id='Location'>
                    <div className="map_space">
                        {this.props.search_rooms_detail.tatemono_id ? 
                        <DetailMapComponent 
                            parking={this.state.parking}
                            conveni={this.state.conveni}
                            store={this.state.store}
                            bank={this.state.bank}
                            youchien={this.state.youchien}
                            school={this.state.school}
                            ds={this.state.ds}
                            hospital={this.state.hospital}
                        /> : ""}
                    </div>

                    <div className='map_btns'>
                    {isMobile?
                        <Swiper
                            id='MapSwiper'
                            slidesPerView={3}
                            spaceBetween={5}
                            navigation={{ // ナビゲーションオプションを設定
                                nextEl: '.swiper-button-next', // 次に進むボタンのクラス名
                                prevEl: '.swiper-button-prev', // 前に戻るボタンのクラス名
                            }}
                            // modules={[Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <Button id='ParkingiBtn' className='mapbtns'
                                value={this.state.parking ? 1 : 0}
                                onClick={() => this.spotDisp(1)} >
                                    {this.state.parking?<img src={`${process.env.PUBLIC_URL}/img/map/parking_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/parking_off.png`} alt=''/>}
                                </Button>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Button id='ConbiniBtn' className='mapbtns'
                                value={this.state.conveni ? 2 : 0}
                                onClick={() => this.spotDisp(2)} >
                                    {this.state.conveni?<img src={`${process.env.PUBLIC_URL}/img/map/conbini_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/conbini_off.png`} alt=''/>}
                                </Button>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Button id='SuperBtn' className='mapbtns'
                                value={this.state.store ? 3 : 0}
                                onClick={() => this.spotDisp(3)} >
                                    {this.state.store?<img src={`${process.env.PUBLIC_URL}/img/map/super_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/super_off.png`} alt=''/>}
                                </Button>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Button id='BankBtn' className='mapbtns'
                                value={this.state.bank ? 4 : 0}
                                onClick={() => this.spotDisp(4)}>
                                    {this.state.bank?<img src={`${process.env.PUBLIC_URL}/img/map/bank_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/bank_off.png`} alt=''/>}
                                </Button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Button id='YouchienBtn' className='mapbtns'
                                value={this.state.youchien ? 5 : 0}
                                onClick={() => this.spotDisp(5)} >
                                    {this.state.youchien?<img src={`${process.env.PUBLIC_URL}/img/map/youchien_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/youchien_off.png`} alt=''/>}
                                </Button>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Button id='SchoolBtn' className='mapbtns'
                                value={this.state.school ? 6 : 0}
                                onClick={() => this.spotDisp(6)} >
                                    {this.state.school?<img src={`${process.env.PUBLIC_URL}/img/map/school_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/school_off.png`} alt=''/>}
                                </Button>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Button id='DSBtn' className='mapbtns'
                                value={this.state.ds ? 7 : 0}
                                onClick={() => this.spotDisp(7)} >
                                    {this.state.ds?<img src={`${process.env.PUBLIC_URL}/img/map/ds_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/ds_off.png`} alt=''/>}
                                </Button>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Button id='HospitalBtn' className='mapbtns'
                                value={this.state.hospital ? 8 : 0}
                                onClick={() => this.spotDisp(8)} >
                                    {this.state.hospital?<img src={`${process.env.PUBLIC_URL}/img/map/hospital_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/hospital_off.png`} alt=''/>}
                                </Button>
                            </SwiperSlide>
                            <div className="swiper-button-next"><img src={`${process.env.PUBLIC_URL}/img/icon/icon_next.png`} alt=''/></div> 
                            <div className="swiper-button-prev"><img src={`${process.env.PUBLIC_URL}/img/icon/icon_prev.png`} alt=''/></div>
                        </Swiper>
                        :
                        <div id='MapBtns'>
                            <Button id='ParkingiBtn' className='mapbtns'
                            value={this.state.parking ? 1 : 0}
                            onClick={() => this.spotDisp(1)} >
                                {this.state.parking?<img src={`${process.env.PUBLIC_URL}/img/map/parking_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/parking_off.png`} alt=''/>}
                            </Button>

                            <Button id='ConbiniBtn' className='mapbtns'
                            value={this.state.conveni ? 2 : 0}
                            onClick={() => this.spotDisp(2)} >
                                {this.state.conveni?<img src={`${process.env.PUBLIC_URL}/img/map/conbini_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/conbini_off.png`} alt=''/>}
                            </Button>

                            <Button id='SuperBtn' className='mapbtns'
                            value={this.state.store ? 3 : 0}
                            onClick={() => this.spotDisp(3)} >
                                {this.state.store?<img src={`${process.env.PUBLIC_URL}/img/map/super_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/super_off.png`} alt=''/>}
                            </Button>

                            <Button id='BankBtn' className='mapbtns'
                            value={this.state.bank ? 4 : 0}
                            onClick={() => this.spotDisp(4)}>
                                {this.state.bank?<img src={`${process.env.PUBLIC_URL}/img/map/bank_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/bank_off.png`} alt=''/>}
                            </Button>

                            <Button id='YouchienBtn' className='mapbtns'
                            value={this.state.youchien ? 5 : 0}
                            onClick={() => this.spotDisp(5)} >
                                {this.state.youchien?<img src={`${process.env.PUBLIC_URL}/img/map/youchien_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/youchien_off.png`} alt=''/>}
                            </Button>

                            <Button id='SchoolBtn' className='mapbtns'
                            value={this.state.school ? 6 : 0}
                            onClick={() => this.spotDisp(6)} >
                                {this.state.school?<img src={`${process.env.PUBLIC_URL}/img/map/school_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/school_off.png`} alt=''/>}
                            </Button>

                            <Button id='DSBtn' className='mapbtns'
                            value={this.state.ds ? 7 : 0}
                            onClick={() => this.spotDisp(7)} >
                                {this.state.ds?<img src={`${process.env.PUBLIC_URL}/img/map/ds_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/ds_off.png`} alt=''/>}
                            </Button>

                            <Button id='HospitalBtn' className='mapbtns'
                            value={this.state.hospital ? 8 : 0}
                            onClick={() => this.spotDisp(8)} >
                                {this.state.hospital?<img src={`${process.env.PUBLIC_URL}/img/map/hospital_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/hospital_off.png`} alt=''/>}
                            </Button>
                        </div>
                    }
                    <p className='shuhen'>※周辺環境は最新でない場合があります。</p>
                    </div>

                    {/* <BottomNavigation
                        value={this.state.store ? 1 : 0}
                        onChange={this.spotDisp}
                        style={{ display: "block", float: "right", textAlign: "right", paddingTop: "0" }}
                        showLabels
                    >
                        <BottomNavigationAction value={1} onClick={this.spotDisp} label="スーパー" icon={<StoreIcon />} />
                    </BottomNavigation>
                    <BottomNavigation
                        value={this.state.conveni ? 2 : 0}
                        onChange={this.spotDisp}
                        style={{ display: "block", float: "right", textAlign: "right", paddingTop: "0" }}
                        showLabels
                    >
                        <BottomNavigationAction value={2} onClick={this.spotDisp} label="コンビニ" icon={<LocalConvenienceStoreIcon />} />
                    </BottomNavigation> */}
            </div>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        mypage_local_store: state.Rent.mypage_local_store,
        
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_mypage_local_spots(state) {
            dispatch(Rent.get_mypage_local_spots(state))
        },
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

LocationDispComponent = reduxForm({
    form: 'detailform', // a unique identifier for this form
})(LocationDispComponent)

export default connect(mapStateToProps, mapDispatchToProps)(LocationDispComponent);