import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Css/Reserve.css';


class LoadingComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }; 
    }


    render() {
       
    return (
        <div className="height_style">
            <CircularProgress />
        </div> 
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {


    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingComponent);
