import React, { Component } from 'react';
import { connect } from 'react-redux';
import CostDispComponent from './CostDispComponent';
import ExplanationDispComponent from './ExplanationDispComponent';
import FacilityDispComponent from './FacilityDispComponent';
import LocationDispComponent from './LocationDispComponent';
import ImageDispComponent from './ImageDispComponent';
import FloorPlanComponent from './FloorPlanComponent';
// import InquiryButtonComponent from './InquiryButtonComponent';
import ContactBtnComponent from './ContactBtnComponent';
import OsusumeComponent from './OsusumeComponent';
import ContactComponent from './ContactComponent';
import Layout from '../Layout/LayoutDatailComponent';
import RentLoadingComponent from '../Layout/RentLoadingComponent.js';
import { Row, Col } from 'reactstrap';
import * as Details from './Saga/Detail';
import * as Master from '../../Store/Master';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import './Css/Detail.css';
import './Css/DetailImage.css';

const CustomButton = (props) => {
    const customTheme = theme => ({
        root: {
            color: theme.palette.getContrastText(props.themecolor[500]),
            backgroundColor: props.themecolor[500],
            '&:hover': {
                backgroundColor: props.themecolor[700],
            },
        },
    })
    const ComponentName = withStyles(customTheme)(Button)
    return <ComponentName {...props} />
}
class DetailComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: '2',
        };
        this.props.change_cd({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        this.props.get_staff({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        this.props.get_search_rooms_detail({ cd: this.props.match.params.cd, no: this.props.match.params.no, tatemono_id: props.match.params.tid, room_id: props.match.params.rid });
        this.handleClick = this.handleClick.bind(this);
        this.props.get_detail_shuhen()
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleClick(event) {
        if (this.state.mode === 1) {
            this.setState({

                mode: '2',
            });
        } else {
            this.setState({

                mode: '1',
            });
        }
    }



    render() {
        const isMobile = window.innerWidth <= 768;
        return (
            <Layout>
                {/* Object.keys(this.props.search_rooms_detail.rooms).indexOf("tatemono_id") !== -1 && */}
                {!this.props.search_rooms_detail.tatemono_id ?
                    <div className="main_background" style={{ marginTop: "50px" }}>
                        <RentLoadingComponent />
                    </div>
                    :
                    <div className="main_background">
                        <div className="component_style">
                            <Row>
                                {/* 描画データがあるかどうかチェック */}
                                <Col lg="12" className="item-outer">
                                    {this.props.search_rooms_detail.chinryou ? <CostDispComponent search_rooms_detail={this.props.search_rooms_detail} disp_applied={this.props.rent_introduction_headers ? this.props.rent_introduction_headers.disp_applied : 1} /> : ""}
                                </Col>
                                <Col lg="7" className="item-outer">
                                    {this.props.search_rooms_detail.t_room_img ? <ImageDispComponent search_rooms_detail={this.props.search_rooms_detail} /> : ""}
                                </Col>
                                <Col lg="5" className="item-outer">
                                    {this.props.search_rooms_detail.t_room_img ? <FloorPlanComponent staff={this.props.staff} search_rooms_detail={this.props.search_rooms_detail} history={this.props.history} cd={this.props.match.params.cd} no={this.props.match.params.no} disp_applied={this.props.rent_introduction_headers ? this.props.rent_introduction_headers.disp_applied : 1}
                                    /> : ""}
                                </Col>
                                {isMobile ?
                                    <Col lg="12">
                                        <ContactBtnComponent staff={this.props.staff} search_rooms_detail={this.props.search_rooms_detail} history={this.props.history} cd={this.props.match.params.cd} no={this.props.match.params.no} />
                                    </Col>
                                    : ""}
                                <Col lg="12" className="item-outer">
                                    <ExplanationDispComponent no={this.props.match.params.no} search_rooms_detail={this.props.search_rooms_detail} />
                                </Col>
                                <Col lg="12" className="item-outer">
                                    {this.props.search_rooms_detail.tatemono_id ? <LocationDispComponent search_rooms_detail={this.props.search_rooms_detail} /> : ""}
                                    {/* {<FacilityDispComponent search_rooms_detail={this.props.search_rooms_detail}/>} */}
                                    {/* {<InquiryButtonComponent />} */}
                                </Col>
                                <Col lg="12">
                                    <ContactBtnComponent staff={this.props.staff} search_rooms_detail={this.props.search_rooms_detail} history={this.props.history} cd={this.props.match.params.cd} no={this.props.match.params.no} />
                                </Col>
                                <Col lg="12" className="item-outer">
                                    {<FacilityDispComponent search_rooms_detail={this.props.search_rooms_detail} />}
                                </Col>
                                {this.props.search_rooms_detail.pr_comment_web ? <Col lg="12" className="item-outer">
                                    <OsusumeComponent search_rooms_detail={this.props.search_rooms_detail} staff={this.props.staff} />
                                </Col> : ""}
                                <Col lg="12">
                                    <ContactBtnComponent staff={this.props.staff} search_rooms_detail={this.props.search_rooms_detail} history={this.props.history} cd={this.props.match.params.cd} no={this.props.match.params.no} />
                                </Col>
                                {/* <Col lg="6" className="item-outer mb-4">
                                    {<FacilityDispComponent search_rooms_detail={this.props.search_rooms_detail}/>}
                                </Col> */}
                                {/* <Col lg="6" className="item-outer mb-4">
                                    {<InquiryButtonComponent />}
                                </Col> */}
                                <Col lg="12" className="item-outer">
                                    {this.props.search_rooms_detail.youtube_id ? <iframe width="100%" height="500px" src={"https://www.youtube.com/embed/" + this.props.search_rooms_detail.youtube_id + "?rel=0"} frameBorder="0" allowFullScreen title='動画'></iframe> : ""}
                                </Col>
                                <Col lg="12" className="item-outer">
                                    {this.props.search_rooms_detail.vr_code ?
                                        <div>
                                            <iframe src={this.props.search_rooms_detail.vr_code} width="100%" height="500px" border="0" frameBorder="0" allowFullScreen="" title='VR'></iframe>
                                            <CustomButton themecolor={blue} style={{ width: "100%", display: "inline-block" }} onClick={() => { window.location.href = this.props.search_rooms_detail.vr_code }}><span className="btn_style_vr">全画面でVR内見する</span></CustomButton>
                                        </div>
                                        : ""}
                                </Col>

                                <Col lg="12" id='Contact' className="item-outer">
                                    <ContactComponent shop={this.props.staff.tenpo[0]} search_rooms_detail={this.props.search_rooms_detail} cd={this.props.match.params.cd} no={this.props.match.params.no} history={this.props.history} />
                                </Col>
                                {/* <Col lg="12" className="item-outer">
                                    {this.props.search_rooms_detail.vr_code ? <CustomButton themecolor={blue} style={{ width: "100%", display: "inline-block" }} onClick={() => {window.location.href=this.props.search_rooms_detail.vr_code}}><span className="btn_style_vr">全画面でVR内見する</span></CustomButton> : ""}
                                </Col> */}
                                {/* <Col lg="6" className="item-outer">
                                    {<ExplanationDispComponent search_rooms_detail={this.props.search_rooms_detail}/>}
                                </Col> */}
                            </Row>
                            {/* } */}
                        </div>
                    </div>}
            </Layout>
        );
    }
}

// function renderForecastsTable(handleClick, handleFormSubmit, state) {

//     if (state.mode===1) {
//         return (
//             <button className="btn btn-success float-right" onClick={handleClick}>編集</button>
//         );
//     } else {
//         //return (
//         //    <div>
//         //        <button className="btn btn-default float-right" onClick={handleClick}>閉じる</button>

//         //    </div>
//         //);
//     }
// }




const mapStateToProps = (state) => {
    return {
        // customerlist: state.customersReducer.customerlist,
        // responseid: state.customersReducer.responseid,
        // responsedetail: state.customersReducer.responsedetail,
        // customer_list_loading:state.customersReducer.customer_list_loading,

        // search_rooms: state.Rent.search_rooms,
        shuhen: state.Detail.shuhen,
        search_rooms_detail: state.Detail.search_rooms_detail,
        rent_introduction_headers: state.Rent.search_rooms.rent_introduction_headers,
        staff: state.Detail.staff,
        bukken_reserve_loading: state.Detail.bukken_reserve_loading,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_search_rooms(state) {
        //     dispatch(Rent.get_search_rooms(state))
        // },

        get_detail_shuhen(state) {
            dispatch(Details.get_detail_shuhen(state))
        },
        get_staff(state) {
            dispatch(Details.get_staff(state))
        },
        get_search_rooms_detail(state) {
            dispatch(Details.get_search_rooms_detail(state))
        },
        change_cd(state) {
            dispatch(Master.change_cd(state))
        },
        // change_customerloding1(state:number) {
        //     dispatch(Customers.change_customerloding1(state))
        // },
        // change_customerloding2(state:number) {
        //     dispatch(Customers.change_customerloding2(state))
        // },


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailComponent);
// export default connect(
// )(DetailComponent);
