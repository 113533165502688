import React, { Component } from 'react';
//import Select from 'react-select';
import { connect } from 'react-redux';
// import { Button, Col, Row, Container,
//     Card, CardBody, CardImg, CardTitle, CardImgOverlay,
//     CardText, Alert } from 'reactstrap';
//import { actionCreators } from '../../Store/MailData';

// import DatePicker from "react-datepicker";
//import DatePicker from 'react-ja-date-picker'
//import "react-datepicker/dist/react-datepicker.css";
//import { actionCreators } from '../../Store/WeatherForecasts';

import { reduxForm } from 'redux-form';
// import '../../Css/Table.css';
import './Css/Card.css';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// import * as AutoKana from 'vanilla-autokana';
let autokana1;



// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};
    
    if (!values.articleCd) {
        errors.articleCd = "物件番号を入力してください。";
    }
    if (!values.articleName) {
        errors.articleName = "建物名を入力してください。";
    }
    if (!values.articleKana) {
        errors.articleKana = "建物カナを入力してください。";
    }
    if (values.articleCharger === "0") {
        errors.articleCharger = "担当者を入力してください。";
    }
    if (!values.address1) {
        errors.address5 = "住所を入力してください。";
    }
    if (!values.address2) {
        errors.address5 = "住所を入力してください。";
    }
    if (!values.address3) {
        errors.address5 = "住所を入力してください。";
    }
    if (!values.address4 && !values.address5) {
        errors.address5 = "住所を入力してください。";
    }
    if (values.construction === "0") {
        errors.construction = "物件構造を入力してください。";
    }
    
    return errors;
};




class CardCompComponent extends Component {
   
    constructor(props) {
        super(props);

        this.state = {

        };

    }


    render() {
        // const { handleSubmit } = this.props;
       
      return (
        <div class="container shadow my-5 py-5">
            <div class="row justify-content-center">
                <div class="text-center py-3">
                      <CheckCircleIcon />
                </div>
                <div class="h4 col-12 text-center py-3">
                ご入力ありがとうございました。
                </div>
                <div class="h5 col-12 text-center py-3">
                お客様情報が送信されました。
                </div>
            </div>
        </div>

    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        users: state.masterReducer.users_select,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

CardCompComponent = connect(
    mapStateToProps, mapDispatchToProps
)(CardCompComponent);

CardCompComponent = reduxForm({
    form: 'tenantform', // a unique identifier for this form
    validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(CardCompComponent)

export default CardCompComponent