import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import AlarmIcon from '@material-ui/icons/Alarm';
import "react-datepicker/dist/react-datepicker.css";
import { reduxForm } from 'redux-form';
import './Css/Reserve.css';
import * as Master from '../../Store/Master';
import * as Reserve from './Saga/Reserve';


class ReserverInfoComponent extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        //ご希望時間
        // this.reservationsTime = [
        //     {value:"1", label:"10時00分"},
        //     {value:"2", label:"10時30分"},
        //     {value:"3", label:"11時00分"},
        //     {value:"4", label:"11時30分"},
        //     {value:"5", label:"12時00分"},
        //     {value:"6", label:"12時30分"},
        //     {value:"7", label:"13時00分"},
        //     {value:"8", label:"13時30分"},
        //     {value:"9", label:"14時00分"},
        //     {value:"10", label:"14時30分"},
        //     {value:"11", label:"15時00分"},
        //     {value:"12", label:"15時30分"},
        //     {value:"13", label:"16時00分"},
        //     {value:"14", label:"16時30分"},
        //     {value:"15", label:"17時00分"},
        //     {value:"16", label:"17時30分"},
        //     {value:"17", label:"18時00分"},
        // ];
        //ご予約人数
        this.reservationsPeople = [
            { value: "1", label: "1人" },
            { value: "2", label: "2人" },
            { value: "3", label: "3人" },
            { value: "4", label: "4人" },
            { value: "5", label: "5人" },
            { value: "6", label: "5人以上" },
        ];

        this.state = {
            // refresh:false,
            tel1: "",
            requestRemarks: "",
            tel1_flg: 1
        }
        //     reservationsPeople: "1",
        //     reservationsTime: "1",
        //     date: new Date(),
        //     remarks:""
        // }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        // this.onChangeTime = this.onChangeTime.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChangeTel = this.handleChangeTel.bind(this);
        this.handleChangeRequest = this.handleChangeRequest.bind(this);
        this.changeFlgs = this.changeFlgs.bind(this);
        this.checktel = this.checktel.bind(this);


        // this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる

        // this.getTileClass = this.getTileClass.bind(this);
        // this.calendarChange = this.calendarChange.bind(this);

        // this.getTileContent = this.getTileContent.bind(this);
    }

    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    //     onChangeTime(e) {
    //         console.log(e.target.value);
    //         // this.setState({ reservationsTime: e.target.value });
    //         let reserve = this.props.reserve
    //         reserve.reservationsTime= e.target.value
    //         this.props.change_reserve(reserve)
    //     }
    onChange(e) {
        // console.log(e.target.value);
        let reserve = this.props.reserve
        reserve.reservationsPeople = e.target.value
        this.props.change_reserve(reserve)
        // this.setState({ reservationsPeople: e.target.value });
    }

    // componentDidMount() {
    //     console.log(this.props.comingdetail)
    //       this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる

    // // This method is called when the component is first added to the document
    //     //this.ensureDataFetched();

    // }

    handleChangeTel(e) {
        this.setState({ tel1: e.target.value });
        let reserve = this.props.reserve
        reserve.tel1 = e.target.value
        this.props.change_reserve(reserve)
    }
    handleChangeRequest(e) {
        this.setState({ requestRemarks: e.target.value });
        let reserve = this.props.reserve
        reserve.requestRemarks = e.target.value
        this.props.change_reserve(reserve)
    }

    //   handleChange = event => {
    //     let reserve = this.props.reserve

    // reserve[event.target.name]= event.target.value 

    // this.props.change_reserve(reserve)
    //         this.setState({refresh:!this.state.refresh});
    //       };
    //   handleChangeRdio = event => {

    //     this.setState({radio:event.target.value});
    //   };


    //       calendarChange(value, event){
    // let reserve = this.props.reserve
    // reserve.date=value
    // this.props.change_reserve(reserve)

    //       } 
    //       disabled=({activeStartDate, date, view }) => {
    //         if (view !== 'month') {
    //             return '';
    //           }
    //           var hiduke=new Date();
    //          return  date<hiduke
    //       }

    changeFlgs() {
        if (!this.props.reserve.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !this.props.reserve.tel1.match(/^\d{7,13}$/)) {
            this.setState({ tel1_flg: 2 })

        } else {
            this.setState({ tel1_flg: 1 })

            this.props.changeFlg(2)
        }
    }

    checktel(e) {
        if (!e.target.value.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !e.target.value.match(/^\d{7,13}$/)) {
            this.setState({ tel1_flg: 2 })
        } else {
            this.setState({ tel1_flg: 1 })
        }
    }

    render() {
        // const { handleSubmit } = this.props;    

        return (
            <div>
                <div className="reserve_info">
                    {/* <form onSubmit={handleSubmit}> */}
                    <div className="container">
                        <div style={{ margin: "10px 0" }}>
                            <Alert icon={<AlarmIcon fontSize="larger" />} severity="success">
                                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                                    {this.props.reserve_date.date ? this.props.reserve_date.date.getMonth() + 1 + "/" + this.props.reserve_date.date.getDate() : ""}
                                    {this.props.reserve_date.flg && (this.props.reserve_date.flg === "Sun") ? "(日)" : (this.props.reserve_date.flg === "Mon") ? "(月)" : (this.props.reserve_date.flg === "Tue") ? "(火)" : (this.props.reserve_date.flg === "Wed") ? "(水)" : (this.props.reserve_date.flg === "Thu") ? "(木)" : (this.props.reserve_date.flg === "Fri") ? "(金)" : (this.props.reserve_date.flg === "Sat") ? "(土)" : (this.props.reserve_date.flg === "Holi") ? "()" : ""}
                                    {this.props.reserve_date.time && (this.props.reserve_date.time === 1) ? "　10時" : (this.props.reserve_date.time === 2) ? "　13時" : (this.props.reserve_date.time === 3) ? "　15時" : ""}
                                    {/* {this.props.reserve_date.time && (this.props.reserve_date.time === 1) ? "　9:30～" : (this.props.reserve_date.time === 2) ? "　11:00～" : (this.props.reserve_date.time === 3) ? "　12:30～" : (this.props.reserve_date.time === 4) ? "　14:00～" : (this.props.reserve_date.time === 5) ? "　15:30～" : ""} */}
                                </span>
                            </Alert>
                        </div>
                        <div style={{ borderBottom: "2px solid #ff9429", fontWeight: "bold", margin: "10px 0", paddingBottom: "5px", fontFamily:"Noto Sans JP,sans-serif"}}>予約情報入力</div>
                        <div className="row" style={{ marginTop: "20px", fontFamily:"Noto Sans JP,sans-serif" }}>
                            <div className="col-12 mb-1"><i className="fas fa-phone mr-2"></i>電話番号 　<span className="label_info">必須</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-12">
                                <TextField
                                    error={this.state.tel1_flg === 2}
                                    id="tel1"
                                    name="tel1"
                                    margin="dense"
                                    placeholder="例) 090-0900-0900"
                                    defaultValue={this.props.reserve.tel1}
                                    // defaultValue={this.state.tel1}
                                    helperText={this.state.tel1_flg === 2 ? "電話番号の形式が正しくありません。" : ""}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={this.checktel}
                                    onChange={this.handleChangeTel}
                                    style={{ width: "100%", marginTop: 0 }}
                                />
                            </div>
                        </div>
                        <div className="row" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                            <div className="col-12 mb-1"><i className="fas fa-users mr-1"></i>ご予約人数 <span className="label_info">必須</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%", marginTop: 0, marginBottom: "1rem" }}>
                                    <Select
                                        name="ReservationsPeople"
                                        id="ReservationsPeople"
                                        style={{ fontSize: "13px", backgroundColor: "white" }}
                                        defaultValue={this.props.reserve.reservationsPeople}
                                        onChange={this.onChange}
                                    >
                                        {this.reservationsPeople.map(function (d, index) {
                                            return <MenuItem key={index} value={d.value}>{d.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                            <div className="col-12 mb-1"><i className="fas fa-edit mr-1"></i>店舗へのご要望・連絡事項
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <TextField
                                    id="requestRemarks"
                                    name="requestRemarks"
                                    multiline
                                    rows="3"
                                    defaultValue={this.props.reserve.requestRemarks}
                                    // className={classes.textField}
                                    onChange={this.handleChangeRequest}
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="要望等あればご記入ください"
                                    style={{ width: "100%", backgroundColor: "white", marginTop: 0 }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <button className="form-group btn btn-primary mx-auto d-block" style={{marginTop:10}} onClick={()=>this.changeFlg(2)}>予約する</button> */}
                    <button className="form-group btn btn-primary mx-auto d-block" style={{ marginTop: 10, marginBottom: 50, fontFamily:"Noto Sans JP,sans-serif" }} onClick={this.changeFlgs}>予約する</button>
                    {/* </form> */}
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        // users: state.masterReducer.users_select,

        reserve: state.Reserve.reserve,
        reserve_date: state.Reserve.reserve_date,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_reserve(state) {
            dispatch(Reserve.change_reserve(state))
        },
        change_cd(state) {
            dispatch(Master.change_cd(state))
        },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}


ReserverInfoComponent = connect(
    mapStateToProps, mapDispatchToProps
)(ReserverInfoComponent);

ReserverInfoComponent = reduxForm({
    form: 'tenantform', // a unique identifier for this form
})(ReserverInfoComponent)

export default ReserverInfoComponent