import React, { Component } from 'react';

import { connect } from 'react-redux';

// import * as Search from './Saga/Search';

import "react-datepicker/dist/react-datepicker.css";
import DateComponent from './DateComponent';
import * as Reserve from './Saga/Reserve';


import './Css/Table.css';
import TimeStateComponent from './TimeStateComponent';
// import ReserveRequest from './ReserveRequestComponent';

import { Field, reduxForm } from 'redux-form';
import './Css/Reserve.css';
import Button from '@material-ui/core/Button';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import ClearIcon from '@material-ui/icons/Clear';
import ReserverInfo from './ReserverInfoComponent';
import ConfirmationVisit from './ConfirmationVisitComponent';
import ReserveContent from './ReserveContentComponent';
import TatemonoComponent from './TatemonoComponent';
import Completion from './CompletionComponent';



class ReserveVisitComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visit_flg: 1,
        };

        this.props.get_schedule("")
        this.getToday = this.getToday.bind(this);
        this.getLastMonth = this.getLastMonth.bind(this);
        this.getLastWeek = this.getLastWeek.bind(this);
        this.getNextWeek = this.getNextWeek.bind(this);
        this.getNextMonth = this.getNextMonth.bind(this);
        this.changeFlg = this.changeFlg.bind(this);
    }



    componentDidMount() {

    }

    componentDidUpdate() {

    }

    ensureDataFetched() {

    }

    //直近(今日)
    getToday() {
        let dt = new Date()
        this.props.get_schedule(dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate())
    }
    //1か月前
    getLastMonth() {
        let dt = new Date(this.props.schedule.week.date1)
        dt.setMonth(dt.getMonth() - 1)
        this.props.get_schedule(dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate())
    }
    //1週間前
    getLastWeek() {
        let dt = new Date(this.props.schedule.week.date1)
        dt.setDate(dt.getDate() - 7)
        this.props.get_schedule(dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate())
    }
    //1週間後
    getNextWeek() {
        let dt = new Date(this.props.schedule.week.date1)
        dt.setDate(dt.getDate() + 7)
        this.props.get_schedule(dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate())
    }
    //1か月後
    getNextMonth() {
        let dt = new Date(this.props.schedule.week.date1)
        dt.setMonth(dt.getMonth() + 1)
        this.props.get_schedule(dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate())
    }

    changeFlg(num) {

        // this.setState({flg:num})
        // if(num === 4){
        //     this.props.send_reserve()    
        // }
        // if(num === 2){
        //     this.setState({visit_flg: 2})
        // }
        // this.setState({ visit_flg: num })
        this.props.change_reserve_flg(num)
    }

   

    render() {
        // console.log("******" + this.props.schedule.week + "******")
        // console.log("******" + this.props.schedule.times + "******")
        // this.props.schedule.week ? console.log("******" + this.props.schedule.week.date1 + "******") : console.log("++++++++++")

console.log(this.props.list,"aaaa")        
        
        
        return (
            this.props.reserve_flg === 1 ?
                <div>
                    {this.props.tid ?
                    <TatemonoComponent tatemono={this.props.tatemono} tid={this.props.tid}/>
                    :""}
                    
                    <div className="container" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                        
                        <div style={{ borderBottom: "2px solid #ff9429", fontWeight: "bold", marginBottom: "5px", paddingTop: "20px", paddingBottom: "5px" }}>
                            <h3 className='Titles' style={{fontFamily:"Noto Sans JP,sans-serif"}}>来店予約</h3>
                            <span style={{ fontSize: "13px",fontFamily:"Noto Sans JP,sans-serif"}}>ご希望の来店日時を選択してください。<br></br>
                                <RadioButtonUncheckedIcon style={{ color: "#6996e0", fontSize: "13px" }} />,<ChangeHistoryIcon style={{ color: "#ffcc00", fontSize: "13px" }} />の日時を選択することが出来ます。ご希望の来店日時の<RadioButtonUncheckedIcon style={{ color: "#6996e0", fontSize: "13px" }} />,<ChangeHistoryIcon style={{ color: "#ffcc00", fontSize: "13px" }} />を選択してください。</span>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <div style={{ display: "inline-block" }}>
                                <Button variant="outlined" className="today_btn" onClick={this.getToday}>今日</Button>
                            </div>
                            <div style={{ display: "inline-block", float: "right" }}>
                                <Button variant="outlined" className="month_week" onClick={this.getLastMonth}>前の月</Button>
                                <Button variant="outlined" className="month_week" onClick={this.getLastWeek}>前の週</Button>
                                <Button variant="outlined" className="month_week" onClick={this.getNextWeek}>次の週</Button>
                                <Button variant="outlined" className="month_week" onClick={this.getNextMonth}>次の月</Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="table_slide">
                                <table className="reserve-table">
                                    <tr>
                                        <th></th>
                                        <th className="tr-date">{this.props.schedule.week ? <DateComponent date={new Date(this.props.schedule.week.date1)} day={this.props.schedule.week.flg1} /> : ""}</th>
                                        <th className="tr-date">{this.props.schedule.week ? <DateComponent date={new Date(this.props.schedule.week.date2)} day={this.props.schedule.week.flg2} /> : ""}</th>
                                        <th className="tr-date">{this.props.schedule.week ? <DateComponent date={new Date(this.props.schedule.week.date3)} day={this.props.schedule.week.flg3} /> : ""}</th>
                                        <th className="tr-date">{this.props.schedule.week ? <DateComponent date={new Date(this.props.schedule.week.date4)} day={this.props.schedule.week.flg4} /> : ""}</th>
                                        <th className="tr-date">{this.props.schedule.week ? <DateComponent date={new Date(this.props.schedule.week.date5)} day={this.props.schedule.week.flg5} /> : ""}</th>
                                        <th className="tr-date">{this.props.schedule.week ? <DateComponent date={new Date(this.props.schedule.week.date6)} day={this.props.schedule.week.flg6} /> : ""}</th>
                                        <th className="tr-date">{this.props.schedule.week ? <DateComponent date={new Date(this.props.schedule.week.date7)} day={this.props.schedule.week.flg7} /> : ""}</th>
                                    </tr>
                                    <tr className="border1">
                                        <th className="time-cell">10時</th>
                                        {/* <th className="time-cell">09:30<span className="kara">～</span></th> */}
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[0].date1} date={new Date(this.props.schedule.week.date1)} time={1} flg={this.props.schedule.week.flg1} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[0].date2} date={new Date(this.props.schedule.week.date2)} time={1} flg={this.props.schedule.week.flg2} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[0].date3} date={new Date(this.props.schedule.week.date3)} time={1} flg={this.props.schedule.week.flg3} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[0].date4} date={new Date(this.props.schedule.week.date4)} time={1} flg={this.props.schedule.week.flg4} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[0].date5} date={new Date(this.props.schedule.week.date5)} time={1} flg={this.props.schedule.week.flg5} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[0].date6} date={new Date(this.props.schedule.week.date6)} time={1} flg={this.props.schedule.week.flg6} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[0].date7} date={new Date(this.props.schedule.week.date7)} time={1} flg={this.props.schedule.week.flg7} /> : ""}</td>
                                    </tr>
                                    <tr>
                                        <th className="time-cell">13時</th>
                                        {/* <th className="time-cell">11:00<span className="kara">～</span></th> */}
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[1].date1} date={new Date(this.props.schedule.week.date1)} time={2} flg={this.props.schedule.week.flg1} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[1].date2} date={new Date(this.props.schedule.week.date2)} time={2} flg={this.props.schedule.week.flg2} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[1].date3} date={new Date(this.props.schedule.week.date3)} time={2} flg={this.props.schedule.week.flg3} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[1].date4} date={new Date(this.props.schedule.week.date4)} time={2} flg={this.props.schedule.week.flg4} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[1].date5} date={new Date(this.props.schedule.week.date5)} time={2} flg={this.props.schedule.week.flg5} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[1].date6} date={new Date(this.props.schedule.week.date6)} time={2} flg={this.props.schedule.week.flg6} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[1].date7} date={new Date(this.props.schedule.week.date7)} time={2} flg={this.props.schedule.week.flg7} /> : ""}</td>
                                    </tr>
                                    <tr>
                                        <th className="time-cell">15時</th>
                                        {/* <th className="time-cell">12:30<span className="kara">～</span></th> */}
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[2].date1} date={new Date(this.props.schedule.week.date1)} time={3} flg={this.props.schedule.week.flg1} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[2].date2} date={new Date(this.props.schedule.week.date2)} time={3} flg={this.props.schedule.week.flg2} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[2].date3} date={new Date(this.props.schedule.week.date3)} time={3} flg={this.props.schedule.week.flg3} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[2].date4} date={new Date(this.props.schedule.week.date4)} time={3} flg={this.props.schedule.week.flg4} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[2].date5} date={new Date(this.props.schedule.week.date5)} time={3} flg={this.props.schedule.week.flg5} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[2].date6} date={new Date(this.props.schedule.week.date6)} time={3} flg={this.props.schedule.week.flg6} /> : ""}</td>
                                        <td>{this.props.schedule.times ? <TimeStateComponent status={this.props.schedule.times[2].date7} date={new Date(this.props.schedule.week.date7)} time={3} flg={this.props.schedule.week.flg7} /> : ""}</td>
                                    </tr>
                                    {/* <tr>
                                <th className="time-cell">14:00<span className="kara">～</span></th>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[3].date1} date={new Date(this.props.schedule.week.date1)} time={4} flg={this.props.schedule.week.flg1}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[3].date2} date={new Date(this.props.schedule.week.date2)} time={4} flg={this.props.schedule.week.flg2}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[3].date3} date={new Date(this.props.schedule.week.date3)} time={4} flg={this.props.schedule.week.flg3}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[3].date4} date={new Date(this.props.schedule.week.date4)} time={4} flg={this.props.schedule.week.flg4}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[3].date5} date={new Date(this.props.schedule.week.date5)} time={4} flg={this.props.schedule.week.flg5}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[3].date6} date={new Date(this.props.schedule.week.date6)} time={4} flg={this.props.schedule.week.flg6}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[3].date7} date={new Date(this.props.schedule.week.date7)} time={4} flg={this.props.schedule.week.flg7}/>:""}</td>
                            </tr>
                            <tr>
                                <th className="time-cell">15:30<span className="kara">～</span></th>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[4].date1} date={new Date(this.props.schedule.week.date1)} time={5} flg={this.props.schedule.week.flg1}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[4].date2} date={new Date(this.props.schedule.week.date2)} time={5} flg={this.props.schedule.week.flg2}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[4].date3} date={new Date(this.props.schedule.week.date3)} time={5} flg={this.props.schedule.week.flg3}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[4].date4} date={new Date(this.props.schedule.week.date4)} time={5} flg={this.props.schedule.week.flg4}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[4].date5} date={new Date(this.props.schedule.week.date5)} time={5} flg={this.props.schedule.week.flg5}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[4].date6} date={new Date(this.props.schedule.week.date6)} time={5} flg={this.props.schedule.week.flg6}/>:""}</td>
                                <td>{this.props.schedule.times?<TimeStateComponent status={this.props.schedule.times[4].date7} date={new Date(this.props.schedule.week.date7)} time={5} flg={this.props.schedule.week.flg7}/>:""}</td>
                            </tr> */}
                                </table>
                            </div>
                        </div>

                        <div style={{ fontSize: "13px", fontFamily:"Noto Sans JP,sans-serif"}}>
                            <RadioButtonUncheckedIcon style={{ color: "#6996e0", fontSize: "13px" }} />受付中
                            <ChangeHistoryIcon style={{ color: "#ffcc00", fontSize: "13px" }} />残りわずか
                            <ClearIcon style={{ color: "#a9a9a9", fontSize: "13px" }} />受付終了
                        </div>
                        {this.props.reserve_date ? <ReserverInfo changeFlg={this.changeFlg}/> : ""}
                    </div>
                </div>
                : this.props.reserve_flg === 2 ? <ConfirmationVisit changeFlg={this.changeFlg} id={this.props.id} tatemono={this.props.tatemono} tid={this.props.tid} list={this.props.list}/>
                    // : this.state.visit_flg === 6 ? <ReserveContent changeFlg={this.changeFlg} />
                    : this.props.reserve_flg === 6 ? <Completion />
                        : ""

        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        schedule: state.Reserve.schedule,
        reserve_date: state.Reserve.reserve_date,
        reserve_flg: state.Reserve.reserve_flg,
        // chomei_list: state.search.chomei_list,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        get_schedule(state) {
            dispatch(Reserve.get_schedule(state))
        }, 
        change_reserve_date(state) {
            dispatch(Reserve.change_reserve_date(state))
        },
        change_reserve_flg(state) {
            dispatch(Reserve.change_reserve_flg(state))
        },

    };
}



export default connect(mapStateToProps, mapDispatchToProps)(ReserveVisitComponent);