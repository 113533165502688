import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import * as Rent from './Saga/Rent';
// import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { connect } from 'react-redux';
import './Css/RentBukkenBox.css';
import { withRouter } from "react-router";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { BlockMapBuilder } from 'draft-js';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import StarIcon from '@material-ui/icons/Star';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import Alert from '@material-ui/lab/Alert';
import StoreIcon from '@material-ui/icons/Store';
import ApartmentIcon from '@material-ui/icons/Apartment';
import HomeIcon from '@material-ui/icons/Home';
import TrainIcon from '@material-ui/icons/Train';
import Slider from "react-slick";
import Carousel, { Modal, ModalGateway } from 'react-images';
import zIndex from '@material-ui/core/styles/zIndex';

const distance = (lat1, lng1, lat2, lng2) => {
    lat1 *= Math.PI / 180;
    lng1 *= Math.PI / 180;
    lat2 *= Math.PI / 180;
    lng2 *= Math.PI / 180;
    return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}

const link = (str) => {
    const regexp_url = /https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+/g;
    const regexp_makeLink = (url) => {
      return `<a href=${url} target="_blank" rel="noopener noreferrer">${url}</a>`;
    }
    const replacedString = str.replace(/\r?\n/g, '<br />').replace(regexp_url, regexp_makeLink);

    return (
        replacedString
    )
  };

class RentBukkenBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            favorite: 0,    //お気に入りoff
            reflash: false,
            // note: true,
            device: 'pc',

            modalIsOpen : false,
            images : [],
            images_index : 0,
        };
        this.setFavorite = this.setFavorite.bind(this);
        this.toggleModal = this.toggleModal.bind(this);


        // this.setNote = this.setNote.bind(this);
    }
    componentWillUpdate() {
        // alert(this.props.reflash)
        // if (this.props.reflash === true) {

        //     this.setState({ reflash: !this.state.reflash })
        //     this.props.change_reflash(false)
        // }
    }
    // setNote(){
    //     this.setState({note: false});
    // }

    setFavorite() {


        this.props.change_favorite({ is_bookmark: this.props.rooms.is_bookmark ? false : true, tatemono_id: this.props.rooms.tatemono_id, room_id: this.props.rooms.room_id, cd: this.props.cd.cd, no: this.props.cd.no })
        // this.setState({ reflash: !this.state.reflash })
        // this.state.favorite === 0 ?

        //     :
        //     this.setState({ favorite: 0 })

    }

    componentDidMount() {
        const ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
            this.setState({ device: 'sp' });
        } else if (ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0) {
            // iOS12 まで
            this.setState({ device: 'tab' });
        } else if (ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document) {
            // iOS13 以降
            this.setState({ device: 'tab' });
        } else {
            this.setState({ device: 'pc' });
        }
    }

    open_window(url) {

        if(this.state.device == 'pc'){
            window.open(url, '_blank', 'noopener')
        }else{
            this.props.history.push(url)
        }

    }

    toggleModal(e, state){


        let img = []
        let index = 0

        // if(key != null){
            let room_img =  this.props.rooms.room_img
            room_img.forEach( function( img_value, key ) {
                img.push({ src: img_value.original })
                if (img_value.original == e.target.src) {
                    index = key
                }
            });
        // }

        this.setState({
            images:img,
            images_index :index,
            modalIsOpen: state
        });

      }

    render() {
        // const settings = {
        //     dots: true,
        //     infinite: true,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 1
        // };
        // const gaikan_url = "https://www.global-center.co.jp/claso/common/img/building/" + this.props.rooms.tatemonoId + "/1/1";
        // const room_img = this.props.rooms.tRoomImg;
        const madori_img = "https://img.global-center.co.jp/v2/rooms/" + this.props.rooms.tatemono_id + "/" + this.props.rooms.room_id + "/8/1/520/780/0";

        return (
            <div className="BukkenBox" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                <div className="text-right ma-bottom-8">
                    {/* <span class="label label-info">
                        <i class="fa fa-check"></i> 閲覧済み
                    </span> */}
                </div>
                <div className="jumbotron">
                    <div className="row mt-3">
                        <div className="col-12" style={{ marginTop: "20px" }}>
                            <span className="maru" style={{ display: "inline-block", backgroundColor: "black" }}>{this.props.rooms.numbering}</span> <h5 style={{ display: "inline-block",fontFamily:"Noto Sans JP,sans-serif" }}>{this.props.rooms.tatemono_name} {this.props.rooms.room_name}</h5>
                            <Button
                                variant="outlined"
                                value={this.props.rooms.is_bookmark}
                                style={{ display: "block", float: "right", marginBottom: "5px", paddingTop: 0, paddingBottom: 0, height: "42px" }}
                                onClick={this.setFavorite}
                            >
                                {<StarIcon
                                    style={this.props.rooms.is_bookmark ? { color: "ff8c00" } : { color: "#d3d3d3" }}
                                />}
                                <p style={{ fontSize: "10px", color: "#808080", fontWeight: "bold" }}>{this.props.rooms.is_bookmark ? "お気に入り" : "お気に入り登録"}</p>
                            </Button>
                        </div>
                    </div>
                    <div className="room-image-slide"></div>
                    <div className="jumbotron-contents" style={{ borderTop: "1px solid #ddd" }}>
                        {this.props.rooms.remarks ? <Alert severity="error" icon={<ChatBubbleOutlineIcon fontSize="inherit" />} style={{ textAlign: "left" }}><span style={{ fontSize: "16px", fontWeight: "bold" }}>スタッフコメント</span><br></br><span style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: link(this.props.rooms.remarks) }}></span></Alert> : ""}
                        {/* <img src={gaikan_url}  alt="物件画像" /> */}
                        {this.props.rooms.room_img ? <ImageGallery items={this.props.rooms.room_img} 
                            showPlayButton={false}
                            showThumbnails={false}
                            autoPlay={false}
                            lazyLoad={true}
                            showFullscreenButton={false}
                            onClick={(e) => this.toggleModal(e, true)}
                        />
                        : ""}
                        {/* {this.props.rooms.room_img ? <Slider className='imgslick' style={{marginTop:20}}>
                                {this.props.rooms.room_img.map((value, key)=>
                                    <div>
                                        <Button onClick={()=>this.toggleModal(true, key)}><img src={value.original} alt="物件画像" /></Button>
                                    </div>
                                )}  
                            </Slider> : ""} */}
                        <ModalGateway>
                            {this.state.modalIsOpen ? (
                            <Modal onClose={()=>this.setState({modalIsOpen: false})}>
                                <Carousel views={this.state.images} currentIndex={this.state.images_index} />
                            </Modal>
                            ) : null}
                        </ModalGateway>

                        
                        {this.props.rooms.kuushitsu_joukyou_no === 1 || this.props.search_rooms.rent_introduction_headers.disp_applied === 1 ? "" : <p style={{ paddingTop: "10px" }}>成約済み</p>}
                        {/* <Slider {...settings}>
                            {<div class="mt-3">
                                <img src={gaikan_url}  alt="物件画像" />
                            </div>}
                            {room_img.map((value)=>
                                <div>
                                    <img src={"https://www.global-center.co.jp/claso/common/img/room/" + value.tatemonoId + "/" + value.roomId + "/" + value.imgShubetsuNo + "/" + value.imgNo} alt="物件画像" />
                                </div>
                            )}                            
                        </Slider> */}
                    </div>
                    <table className="table_layout">
                        <tbody>
                            <tr>
                                <td rowSpan="5">
                                    <span className="contain" style={{ width: 100, height: 100, backgroundImage: `url(${madori_img})` }}></span><br></br>{this.props.rooms.madori_name}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    家賃/<br></br>共益費
                                </th>
                                {/* カンマ区切りで表示する */}
                                <td className="color-red">
                                    <strong className="lead">{this.props.rooms.chinryou > 0 ? this.props.rooms.chinryou.toLocaleString() : 0}円</strong>/<br></br>{this.props.rooms.kyouekihi ? this.props.rooms.kyouekihi.toLocaleString() + "円" : "込み"}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    敷金/礼金
                                </th>
                                {/* カンマ区切りで表示する */}
                                <td>
                                    <span style={{ fontSize: "14px" }}>敷</span>{this.props.rooms.shikikin_mon >= 1 ? this.props.rooms.shikikin_mon + "ヶ月 / " : this.props.rooms.shikikin > 0 ? this.props.rooms.shikikin.toLocaleString() + "円 / " : "- / "}
                                    <span style={{ fontSize: "14px" }}>礼</span>{this.props.rooms.reikin_mon >= 1 ? this.props.rooms.reikin_mon + "ヶ月" : this.props.rooms.reikin > 0 ? this.props.rooms.reikin.toLocaleString() + "円" : "-"}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    定額クリーニング
                                </th>
                                <td>
                                    {this.props.rooms.teigaku_cleaning?this.props.rooms.teigaku_cleaning:"-"}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    専有面積
                                </th>
                                <td>
                                    {this.props.rooms.senyuu_menseki}㎡
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    築年
                                </th>
                                <td>
                                    {this.props.rooms.shunkou_year}年{this.props.rooms.shunkou_month}月
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3">
                                    <span style={{ fontWeight: "bold" }}>交通　</span>
                                    {this.props.rooms.koutsuu1 === "" ? "" : this.props.rooms.koutsuu1 + "徒歩" + this.props.rooms.koutsuu1_min + "分"}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3">
                                    <span style={{ fontWeight: "bold" }}>所在地　</span>
                                    {this.props.rooms.todoufuken} {this.props.rooms.shikuchoson}{this.props.rooms.choiki}
                                    {/* <a href={"https://www.google.com/maps?q=" + this.props.rooms.ido + "," + this.props.rooms.keido}>[地図]</a> */}
                                </td>
                            </tr>
                            {this.props.houjin === 1?<tr>
                                <td colSpan="3">
                                    <span style={{ fontWeight: "bold" }}>法人契約の場合　</span>
                                    {this.props.rooms.houjin_keiyaku?this.props.rooms.houjin_keiyaku:"-"}
                                </td>
                            </tr>:""}
                            {this.props.search_rooms.rent_introduction_point_headers ? this.props.search_rooms.rent_introduction_point_headers.map((pointheader, index) => {
                                if (pointheader.type === 1 && Object.keys(pointheader).length > 0) {
                                    if(pointheader.rent_introduction_point_details.length > 0) {
                                        if(pointheader.rent_introduction_point_details[0].distance_is_no_disp == 0){
                                    return <tr key={index}>
                                        <td colSpan="3">

                                                <span>
                                                    {/* <LocationOn style={{ color: "red" }} /> */}
                                                    {/* {pointheader.rent_introduction_point_details[0].point_icon == 1 ? <ApartmentIcon fontSize="large" style={{color:pointheader.rent_introduction_point_details[0].point_color == null ? "red" : pointheader.rent_introduction_point_details[0].point_color}} /> : 
                                                    pointheader.rent_introduction_point_details[0].point_icon == 2 ? <HomeIcon fontSize="large" style={{color:pointheader.rent_introduction_point_details[0].point_color == null ? "red" : pointheader.rent_introduction_point_details[0].point_color}} /> : 
                                                    pointheader.rent_introduction_point_details[0].point_icon == 3 ? <TrainIcon fontSize="large" style={{color:pointheader.rent_introduction_point_details[0].point_color == null ? "red" : pointheader.rent_introduction_point_details[0].point_color}} /> : 
                                                    pointheader.rent_introduction_point_details[0].point_icon == 4 ? <StoreIcon fontSize="large" style={{color:pointheader.rent_introduction_point_details[0].point_color == null ? "red" : pointheader.rent_introduction_point_details[0].point_color}} /> : <LocationOnIcon fontSize="large" style={{color:pointheader.rent_introduction_point_details[0].point_color == null ? "red" : pointheader.rent_introduction_point_details[0].point_color}} />
                                                    } */}
                                                    {pointheader.rent_introduction_point_details[0].point_icon === 1 ? <ApartmentIcon style={{color:'#BF4645',}} /> : 
                                                    pointheader.rent_introduction_point_details[0].point_icon === 2 ? <HomeIcon style={{color:'#BF4645'}} /> : 
                                                    pointheader.rent_introduction_point_details[0].point_icon === 3 ? <TrainIcon style={{color:'#BF4645'}} /> : 
                                                    pointheader.rent_introduction_point_details[0].point_icon === 4 ? <StoreIcon style={{color:'#BF4645'}} /> : <LocationOnIcon style={{color:'#BF4645'}} />
                                                    }
                                                     {pointheader.contents}まで
                                                    <p style={{ display: "inline-block",margin:"5px 0",}}><span style={{ fontWeight: "bold", color: "#BF4645",fontSize:"20px", }}>
                                                    {Math.ceil(distance(pointheader.rent_introduction_point_details[0].lat, pointheader.rent_introduction_point_details[0].lng, this.props.rooms.ido, this.props.rooms.keido) * 10) / 10}
                                                </span>km</p></span>
                                        </td>
                                    </tr>
                                }}
                                } else {
                                    // this.setNote()
                                    // this.setState({note: false});
                                    return ""
                                }
                                //全表示
                            }, this) : ""}
                        </tbody>
                    </table>
                    {/* {console.log(this.state.note)} */}
                    {this.props.rooms.rent_introduction_point_headers ? this.props.rooms.rent_introduction_point_headers.filter(a => a.rent_introduction_point_details) > 0 ?
                        <p style={{ textAlign: "right", fontSize: "12px" }}>※目的地までは直線距離です。</p>
                        :
                        <p></p> : <p></p>
                    }
                    <div style={{ textAlign: "center",fontFamily:"Noto Sans JP,sans-serif", }}>
                        <button className="btn btn-primary btn-cliant-blue btn-shadow" style={{width:"200px",backgroundColor:"rgb(74, 192, 240)",borderColor:"rgb(74, 192, 240)",borderRadius:"0",}}
                         onClick={() => this.open_window("/Detail/" + this.props.match.params.cd + "/" + this.props.match.params.no + "/" + this.props.rooms.tatemono_id + "/" + this.props.rooms.room_id)}>
                            <i className="fa fa-chevron-right"></i>　詳細を見る
                        </button>
                    </div>

                </div>
            </div >
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        search_rooms: state.Rent.search_rooms,
        cd: state.masterReducer.cd,
        // reflash: state.Rent.reflash,
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_favorite(state) {
            dispatch(Rent.change_favorite(state))
        },
        // change_reflash(state) {
        //     dispatch(Rent.change_reflash(state))
        // },
        //        responseeditchange(state) {
        //            dispatch(responseeditchange(state))
        //        },
        //        customerdetailData1(state) {
        //            dispatch(customerdetailData1(state))
        //        }, responsedetailData(state) {
        //            dispatch(responsedetailData(state))
        //        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RentBukkenBox));
