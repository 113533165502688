import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import './Css/Line.css';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import addFriend from './Img/image4.png';
import top from './Img/image1.png';
import induction from './Img/image2.png';
import code from './Img/image3.png';
import qrCode from './Img/qr-image.png';
import qr1 from './Img/qr1.png';
import qr2 from './Img/qr2.png';
import qr3 from './Img/qr3.png';
import qr4 from './Img/qr4.png';
import qr6 from './Img/qr6.png';
import qr17 from './Img/qr17.png';
import qr47 from './Img/qr47.png';
import qr10 from './Img/qr10.png';

class LineTextComponent extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
        };
        this.lineopen = this.lineopen.bind(this)

    }

    lineopen() {

        if (this.props.line_section_id === 1) {
            window.open('https://line.me/R/ti/p/@772czlir')
        } else if (this.props.line_section_id === 2) {
            window.open('https://line.me/R/ti/p/@221axikr')
        } else if (this.props.line_section_id === 3) {
            window.open('https://line.me/R/ti/p/@723cihaw')
        } else if (this.props.line_section_id === 4) {
            window.open('https://line.me/R/ti/p/@383gtpzg')
        } else if (this.props.line_section_id === 6) {
            window.open('https://line.me/R/ti/p/@133rokhc')
        } else if (this.props.line_section_id === 17) {
            window.open('https://line.me/R/ti/p/@653lvwms')
        } else if (this.props.line_section_id === 47) {
            window.open('https://line.me/R/ti/p/@707zmmom')
        } else if (this.props.line_section_id === 10) {
            window.open('https://line.me/R/ti/p/@014lkgye')
        } else {
            window.open('https://line.me/R/ti/p/%40bmz6622z')
        }
    }

    render() {

        return (
            <div className="display_style pt-0">
                <div className="container line-info">
                    {/* <div className="row pt-2">
                    <div className="col-xs-12 text_center2">LINE友達登録でお部屋探しをもっと便利に</div>
                </div>
                <div className="row line_middle pt-2 pb-4">
                    <div className="col-md-5 col-5"><img alt="LINEで物件配信" src={addFriend} /></div>
                    <div className="col-md-5 col-7" style={{paddingLeft:0, paddingRight:9, marginTop:"auto"}}>
                    <ul className="pl-0">
                        <li>
                            <div>
                                <div className="text_center3"><CheckCircleIcon /></div>
                                <div className="text_style5">新着物件をいち早くキャッチ！</div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="text_center3 margin_icon"><CheckCircleIcon /></div>
                                <div className="text_style5">ご質問・お問い合わせ・来店予約がLINEトーク上からお手軽に！</div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="text_center3"><CheckCircleIcon /></div>
                                <div className="text_style5">通知offや配信停止(ブロック)はいつでも可能です。</div>
                            </div>
                        </li>
                    </ul>
                    </div>
                </div> */}
                    <div className="row line-step pt-4">
                        <div className="col-md-10 text_center4">
                            <div className="col-md-4 col-xs-4 list1">
                                <dl>
                                    <dd><h5 className="info_style">step1</h5></dd>
                                    <dt>ボタンから友達登録</dt>
                                    <dd className="pct"><img alt="ボタンから友達登録" src={top} width="50%" /></dd>
                                    <dd className="pct"><button className="btn_style1" onClick={this.lineopen}><i className="fab fa-line fa-2x"></i><span style={{ verticalAlign: "super" }}>友達追加</span></button><p>
                                        {this.props.line_section_id === 1 ? <img className="qrCode_none" alt="QRコード" src={qr1} width="25%" />
                                            : this.props.line_section_id === 2 ? <img className="qrCode_none" alt="QRコード" src={qr2} width="25%" />
                                                : this.props.line_section_id === 3 ? <img className="qrCode_none" alt="QRコード" src={qr3} width="25%" />
                                                    : this.props.line_section_id === 4 ? <img className="qrCode_none" alt="QRコード" src={qr4} width="25%" />
                                                        : this.props.line_section_id === 6 ? <img className="qrCode_none" alt="QRコード" src={qr6} width="25%" />
                                                            : this.props.line_section_id === 17 ? <img className="qrCode_none" alt="QRコード" src={qr17} width="25%" />
                                                                : this.props.line_section_id === 47 ? <img className="qrCode_none" alt="QRコード" src={qr47} width="25%" />
                                                                    : this.props.line_section_id === 10 ? <img className="qrCode_none" alt="QRコード" src={qr10} width="25%" />
                                                                        : <img className="qrCode_none" alt="QRコード" src={qrCode} width="25%" />
                                        }</p></dd>

                                    {/* <dd className="pct"><button className="btn_style1" onClick={this.lineopen}><i className="fab fa-line fa-2x"></i><span style={{verticalAlign:"super"}}>友達追加</span></button><p><img className="qrCode_none" alt="QRコード" src={qrCode} width="25%" /></p></dd> */}
                                    {/* <dd className="pct"><button className="btn_style1 mt-4" type="button" onclick="" style={{fontSize:"80%", width:"80%"}}><i className="fab fa-line fa-2x"></i><span style={{verticalAlign:"super"}}>友達追加</span></button></dd> */}
                                    <dd className="text_style4">友達追加ボタンをクリックして登録。<br></br><span className="small">※PCの場合はQRコードから読取をして下さい。</span></dd>
                                </dl>
                            </div>
                            <div className="col-md-4 col-xs-4 list1">
                                <dl>
                                    <dd><h5 className="info_style">step2</h5></dd>
                                    <dt>認証コードを送信</dt>
                                    <dd className="pct"><img alt="認証コード入力" src={induction} width="50%" /></dd>
                                    <dd className="text_style4">LINEトーク上で、「認証コード」の数字を送信。</dd>
                                    <dd className="pct"><img alt="認証コード送信" src={code} width="50%" /></dd>
                                </dl>
                            </div>
                            <div className="col-md-4 col-xs-4 list1">
                                <dl>
                                    <dd><h5 className="info_style">step3</h5></dd>
                                    <dt>認証完了</dt>
                                    <dd className="pct"><img alt="認証完了" src={addFriend} width="50%" /></dd>
                                    <dd className="text_style4 pt-1">友達追加完了です。新着物件が配信されます。</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="frame mb-5">
                        <div className="row pt-2 pb-3">
                            <div className="col-xs-12 text_center">
                                <div className="bottom_style"><button className="btn_style1" onClick={this.lineopen}><i className="fab fa-line fa-2x"></i><span style={{ verticalAlign: "super" }}>友達追加</span></button></div>
                                <div className="bottom_style qrCode_none">
                                    {this.props.line_section_id === 1 ? <img alt="QRコード" src={qr1} width="60px" />
                                        : this.props.line_section_id === 2 ? <img alt="QRコード" src={qr2} width="60px" />
                                            : this.props.line_section_id === 3 ? <img alt="QRコード" src={qr3} width="60px" />
                                                : this.props.line_section_id === 4 ? <img alt="QRコード" src={qr4} width="60px" />
                                                    : this.props.line_section_id === 6 ? <img alt="QRコード" src={qr6} width="60px" />
                                                        : this.props.line_section_id === 17 ? <img alt="QRコード" src={qr17} width="60px" />
                                                            : this.props.line_section_id === 47 ? <img alt="QRコード" src={qr47} width="60px" />
                                                                : <img alt="QRコード" src={qrCode} width="60px" />
                                    }
                                    <br></br><span className="text_style4">友達追加QRコード</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        line_code: state.Line.line_code,
        line_limit: state.Line.line_limit,
        line_section_id: state.Line.line_section_id,
    }
}

LineTextComponent = connect(
    mapStateToProps
)(LineTextComponent);

LineTextComponent = reduxForm({
})(LineTextComponent)

export default LineTextComponent