const authentication = require('react-azure-adb2c').default;



export const shop = (values: any) => {


    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/mypage/rooms/shop/` + values;
    // const url = `http://localhost:5000/v1/mypage/rooms/shop/` + values;

    // alert(url)
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(values))
    return fetch(url, {
            method: 'get',
            // body: JSON.stringify(values),

            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
            .then(payload => ({ payload }))
            .catch(error => ({ error }));

};



export const raiten = (values: any) => {


    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/mypage/rooms/raiten/` + values.cd;
    // const url = `http://localhost:5000/v1/mypage/rooms/raiten/` + values.cd;

    // alert(url)
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(values))
    return fetch(url, {
            method: 'get',
            // body: JSON.stringify(values),

            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
            .then(payload => ({ payload }))
            .catch(error => ({ error }));

};




export const list = (values: any) => {


    let url = process.env.REACT_APP_API_OPENROOM_URL + `/api/reserve?baseDate=` + values;

    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const cal = (values: any) => {


    let url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/reserve/mypagecal`;
    // let url = `http://localhost:5000/v1/reserve/mypagecal`;
    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const Tatemono = (values: any) => {
    const url = process.env.REACT_APP_API_OPENROOM_EX_URL+`/v1/mypage/rooms/contact/tatemono/`+values.tid + '/' + values.rid
    // const url =`http://localhost:5000/v1/mypage/rooms/contact/tatemono/`+values.tid + '/' + values.rid

    // alert(url)

        const token = authentication.getAccessToken();

            return  fetch(url, {
            method: 'get',
            // body: JSON.stringify(search),

            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
        .then(payload => ({ payload }))
            .catch(error => ({ error }));

};






