const authentication = require('react-azure-adb2c').default;

export const insert = (values: any) => {


    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/hearing/sheets/insert/` + values.card_way + `/` + values.section_id;
    // let url = `http://localhost:5000/v1/rent/hearing/sheets/insert/` + values.card_way + `/` + values.section_id;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values.card_details),
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        // headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};