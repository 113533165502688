import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore, { history } from './configureStore'
import { ConnectedRouter} from 'connected-react-router';
import 'bootstrap/dist/css/bootstrap.css';
import { CookiesProvider } from 'react-cookie';
const store = configureStore(/* provide initial state if any */)


ReactDOM.render(
    <Provider store={store}>
    <ConnectedRouter history={history}>
    <CookiesProvider>
<App />
</CookiesProvider>
</ConnectedRouter>
        </Provider>
        , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
