import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as rent_file_headers_api from '../Api/rent_file_headers_api';
import * as rent_file_details_api from '../Api/rent_file_details_api';

export interface MapSearchState {
    fcd: string;
    download_file_list: any;
    download_comp: boolean;
    // search_rooms_detail:any;
}
const initialState: MapSearchState = {

    fcd: "",
    download_file_list: [],
    download_comp: false,
    // search_rooms_detail: [],
}



///action 
const actionCreator = actionCreatorFactory();

export const get_download_file_list = actionCreator<any>('GET_DOWNLOAD_FILE_LIST');
export const change_download_file_list = actionCreator<any>("CHANGE_DOWNLOAD_FILE_LIST");

export const download_file = actionCreator<any>('DOWNLOAD_FILE');
// export const update_mypage_request= actionCreator<any>('UPDATE_MYPAGE_REQUEST');
export const change_download_comp = actionCreator<any>("CHANGE_DOWNLOAD_COMP");
// export const get_search_rooms_detail= actionCreator('GET_SEARCH_ROOMS_DETAIL');
// export const get_search_rooms_detail_success = actionCreator("GET_SEARCH_ROOMS_DETAIL_SUCCESS");


export const downloadReducer = reducerWithInitialState(initialState)
    .case(change_download_file_list, (state, payload) => ({ ...state, download_file_list: payload }))
    .case(change_download_comp, (state, payload) => ({ ...state, download_comp: payload }))

// .case(get_search_rooms_detail_success, (state, payload) => ({ ...state, search_rooms_detal: payload }))
// .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))

// .case(get_response_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

// .case(change_showRight, (state, payload) => ({ ...state, showRight: payload }))
// .case(change_showFlag, (state, payload) => ({ ...state, showFlag: payload }))
// .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))

// .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }));








// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const downloadSaga = [
    // takeEvery('GET_MYPAGE_REQUEST', handle_get_mypage_request),
    takeEvery('GET_DOWNLOAD_FILE_LIST', handle_get_download_file_list),
    takeEvery('DOWNLOAD_FILE', handle_download_file),




    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_download_file_list(action: any) {

    const { payload, error } = yield call(rent_file_headers_api.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        yield put(change_download_file_list(payload));

        // yield put(get_search_rooms_success(payload));


    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_download_file(action: any) {

    const { payload, error } = yield call(rent_file_details_api.download, action.payload.fcd);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.name;
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();

    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

// function* handle_update_mypage_request(action: any) {
//     const state = yield select();

//     const { payload, error } = yield call(MypageRequestApi.update, state.Download.mypage_request);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         console.log(payload)

//         // const setsubis = payload.map((item:any, i:number) => ({
//         //     value: item.setsubiNo,
//         //     label: item.setsubiName,
//         //   }))

//         // 成功時アクション呼び出し


//         yield put(change_download_comp(true));
//         //    yield put(get_search_rooms_detail_success(payload));

//     } else {
//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }