import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApplicationFormComponent from './ApplicationFormComponent';
import CardCompComponent from './CardCompComponent';
import * as Master from '../../Store/Master';
import * as Card from './Saga/Card';
import RentHeaderComponent from '../Layout/RentHeaderComponent';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { TextField, Button } from '@material-ui/core';
import { ThreeSixty } from '@material-ui/icons';
import { array } from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class ApplicationComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comp: false,
            change:false,
        };

        // if (this.props.match) {
        //     this.props.get_rent_contracts_application(this.props.match.params.way)
        // }

        this.props.get_rent_m_job_categorys()
        // this.props.change_card_comp(false)

        // if (this.props.match) {
        //     this.props.change_card_section_id(this.props.match.params.sectionid)
        //     this.props.change_card_way(this.props.match.params.way)//0　来店　1メール　2LINE
        // }
        // this.props.get_card_details(this.props.match.params.cd)
        this.handlesubmit = this.handlesubmit.bind(this)
        // this.onChange = this.onChange.bind(this);
        this.applicationAdd = this.applicationAdd.bind(this);
        this.applicationRemove  = this.applicationRemove.bind(this);
        this.onChange  = this.onChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handlesubmit(state) {
        // console.log(state)
        // alert()
        this.setState({ comp: true })
    }

    // onChange(e) {
    //     let application_form = this.props.application_form
    //     // let application_num = this.props.application_num
    //     if(e.target.name==="tenant_num"){
    //         // application_num = e.target.value
    //         for(let i = 0; i < e.target.value; i++) {
    //             application_form.push({people_num: i + 2, name1:"", name2:"", kana1:"", kana2:"", birthday_year:"", birthday_month:"", birthday_day:"", gender:"", relationship_id:"", relationship_others:"", tel1:"", office_name:""})
    //         }
    //     }
    //     this.props.change_application_form(application_form)
    //     // this.props.change_application_num(application_num)
    //     this.setState({ change: !this.state.change });
    // }

    applicationAdd() {


        let rent_contracts_renter = this.props.rent_contracts_renter

        var disp_cnt = rent_contracts_renter.filter(a => a.is_disp == 1).length

        if(disp_cnt != 6){
            rent_contracts_renter[disp_cnt].is_disp = 1
            rent_contracts_renter[disp_cnt].name1_1 = null
            rent_contracts_renter[disp_cnt].name1_2 = null
            rent_contracts_renter[disp_cnt].kana1_1 = null
            rent_contracts_renter[disp_cnt].kana1_2 = null
            rent_contracts_renter[disp_cnt].birthday_year =null
            rent_contracts_renter[disp_cnt].birthday_month = null
            rent_contracts_renter[disp_cnt].birthday_day = null
            rent_contracts_renter[disp_cnt].sex = null
            rent_contracts_renter[disp_cnt].rent_m_relation_id = null
            rent_contracts_renter[disp_cnt].affiliation = null
            rent_contracts_renter[disp_cnt].tel1 = null
            rent_contracts_renter[disp_cnt].office_name = null
        } 

        this.props.change_rent_contracts_renter(rent_contracts_renter)
        this.props.get_rent_contracts_renter_form_check()
        this.setState({ change: !this.state.change });
    }
    applicationRemove() {
        let rent_contracts_renter = this.props.rent_contracts_renter

        var disp_cnt = rent_contracts_renter.filter(a => a.is_disp == 1).length

        if(disp_cnt != 1){
            rent_contracts_renter[disp_cnt-1].is_disp = 0
            rent_contracts_renter[disp_cnt-1].name1_1 = null
            rent_contracts_renter[disp_cnt-1].name1_2 = null
            rent_contracts_renter[disp_cnt-1].kana1_1 = null
            rent_contracts_renter[disp_cnt-1].kana1_2 = null
            rent_contracts_renter[disp_cnt-1].birthday_year =null
            rent_contracts_renter[disp_cnt-1].birthday_month = null
            rent_contracts_renter[disp_cnt-1].birthday_day = null
            rent_contracts_renter[disp_cnt-1].sex = null
            rent_contracts_renter[disp_cnt-1].rent_m_relation_id = null
            rent_contracts_renter[disp_cnt-1].affiliation = null
            rent_contracts_renter[disp_cnt-1].tel1 = null
            rent_contracts_renter[disp_cnt-1].office_name = null
        }

        this.props.change_rent_contracts_renter(rent_contracts_renter)
        this.props.get_rent_contracts_renter_form_check()
        this.setState({ change: !this.state.change });
    }

    onChange(e) {

        let rent_contracts = this.props.rent_contracts

        rent_contracts[e.target.name] = e.target.value

        this.props.change_rent_contracts(rent_contracts)

        this.setState({ change: !this.state.change });

    }

    render() {

        return (
            <div style={{ backgroundColor: "whitesmoke" }}>
                <RentHeaderComponent root="newcard" />

                
                <div className="container">

                    
                    {/* {this.props.card_comp === false ? */}
                    <div style={{}}>
                        {/* <TextField
                            id="tenant_num"
                            name="tenant_num"
                            label="入居人数"
                            type="number"
                            onChange={this.onChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        /> */}
                    <div className="display_style">
                        <div class="confirm_h1" style={{backgroundColor:"white"}}>お申込みフォーム</div>

                            <table style={{marginBottom:20}}>
                                <colgroup width="40%" />
                                <colgroup width="60%" />
                                <tbody>
                                    <tr>
                                        <th>物件名</th>
                                        <td style={{backgroundColor:"white"}}>
                                            <div>{this.props.rent_contracts.building_name}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>部屋名</th>
                                        <td style={{backgroundColor:"white"}}>
                                            <div class="fn-middle">{this.props.rent_contracts.room_name}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            <div className="conditions_style_application">
                                <div className="container">
                                    <div className="row pt-2">
                                        <h6>【駐車場】</h6>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">台数</div>
                                        <div className="col-md-9 form-group">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <FormControl variant="outlined" margin="dense" style={{ width: "40%" }}>
                                                        <InputLabel>台数</InputLabel>
                                                        <Select
                                                            name="number_of_cars"
                                                            id="number_of_cars"
                                                            value={parseInt(this.props.rent_contracts.number_of_cars)}
                                                            onChange={this.onChange}
                                                            label="台数"
                                                        >
                                                            <MenuItem value={0}>{"-"}</MenuItem>
                                                            <MenuItem value={1}>{"1台"}</MenuItem>
                                                            <MenuItem value={2}>{"2台"}</MenuItem>
                                                            <MenuItem value={3}>{"3台"}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {parseInt(this.props.rent_contracts.number_of_cars) > 0 ? <div className="row">
                                        <div className="col-md-3">車両①</div>
                                        <div className="col-md-9 form-group">
                                            <div className="row">
                                                <div className="col-md-10">
                                                <TextField
                                                        id="car_name1"
                                                        name="car_name1"
                                                        label="メーカー名/車種"
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        autoComplete='off'
                                                        value={this.props.rent_contracts.car_name1}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                <TextField
                                                        id="car_color1"
                                                        name="car_color1"
                                                        label="車色"
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        autoComplete='off'
                                                        value={this.props.rent_contracts.car_color1}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="col-md-7">
                                                <TextField
                                                        id="car_no1"
                                                        name="car_no1"
                                                        label="車両番号"
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        autoComplete='off'
                                                        value={this.props.rent_contracts.car_no1}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>:""}

                                    {parseInt(this.props.rent_contracts.number_of_cars) > 1 ? <div className="row">
                                        <div className="col-md-3">車両②</div>
                                        <div className="col-md-9 form-group">
                                            <div className="row">
                                                <div className="col-md-10">
                                                <TextField
                                                        id="car_name2"
                                                        name="car_name2"
                                                        label="メーカー名/車種"
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        autoComplete='off'
                                                        value={this.props.rent_contracts.car_name2}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                <TextField
                                                        id="car_color2"
                                                        name="car_color2"
                                                        label="車色"
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        autoComplete='off'
                                                        value={this.props.rent_contracts.car_color2}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="col-md-7">
                                                <TextField
                                                        id="car_no2"
                                                        name="car_no2"
                                                        label="車両番号"
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        autoComplete='off'
                                                        value={this.props.rent_contracts.car_no2}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>:""}

                                    {parseInt(this.props.rent_contracts.number_of_cars) > 2 ? <div className="row">
                                        <div className="col-md-3">車両③</div>
                                        <div className="col-md-9 form-group">
                                            <div className="row">
                                                <div className="col-md-10">
                                                <TextField
                                                        id="car_name3"
                                                        name="car_name3"
                                                        label="メーカー名/車種"
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        autoComplete='off'
                                                        value={this.props.rent_contracts.car_name3}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                <TextField
                                                        id="car_color3"
                                                        name="car_color3"
                                                        label="車色"
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        autoComplete='off'
                                                        value={this.props.rent_contracts.car_color3}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className="col-md-7">
                                                <TextField
                                                        id="car_no3"
                                                        name="car_no3"
                                                        label="車両番号"
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        autoComplete='off'
                                                        value={this.props.rent_contracts.car_no3}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>:""}
                                </div>
                            </div>
 

                            <div className="conditions_style_application">
                                <div className="container">
                                    <div className="row pt-2">
                                        <h6>【バイク】</h6>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">種類</div>
                                        <div className="col-md-9 form-group">
                                            <div className="row">
                                                <div className="col-md-10">
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="bike" name="bike" value={String(this.props.rent_contracts.bike)} onChange={this.onChange} row>
                                                        <FormControlLabel control={<Radio color="primary" value={"0"} />} label="なし" labelPlacement="end" />
                                                        <FormControlLabel control={<Radio color="primary" value={"1"} />} label="原付" labelPlacement="end" />
                                                        <FormControlLabel control={<Radio color="primary" value={"2"} />} label="中型バイク" labelPlacement="end" />
                                                        <FormControlLabel control={<Radio color="primary" value={"3"} />} label="大型バイク" labelPlacement="end" />
                                                    </RadioGroup>
                                                </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>





                            {this.props.rent_contracts_renter.length > 0 ?
                            this.props.rent_contracts_renter.filter(a => a.is_disp == 1).map(function(value, key){
                                return <div>
                                <div className="conditions_style_application">
                                    <div className="container">
                                        <div className="row pt-2">
                                            <h6>【入居者 {key + 1} 】</h6>
                                        </div>
                                        <ApplicationFormComponent renter={value} renter_key={key}/>
                                    </div>
                                </div>
                            </div>
                            }) : ""}
                            <div style={{marginBottom: "100px", textAlign: "right"}}>
                                {this.props.rent_contracts_renter.length > 0 ? this.props.rent_contracts_renter.filter(a=>a.is_disp==1).length <= 5 ? <Button variant="contained" onClick={this.applicationAdd}>入居者追加</Button> : "" : ""}
                                {this.props.rent_contracts_renter.length > 0 ? this.props.rent_contracts_renter.filter(a=>a.is_disp==1).length > 1 ? <Button style={{marginLeft:10}} variant="contained" onClick={this.applicationRemove}>入居者削除</Button> : "" : ""}
                                
                            </div>


                        </div>
                    </div>
                    {/* : <CardCompComponent />} */}
                </div>

                {this.props.rent_contracts_renter.length == 6 ?

                this.props.rent_contracts_renter_form_check == true ? <div id="footer-submit_button" className="pt-3 pb-3">
                    <button className="form-group btn btn-primary mx-auto d-block" onClick={()=>this.props.update_rent_contracts(0)}>登録する</button>
                </div> : <div id="footer-submit_button" className="pt-3 pb-3">
                    <Alert severity="error" 
                    // action={<button className="form-group btn btn-primary mx-auto d-block" style={{marginBottom:0}} onClick={()=>{this.props.update_rent_contracts(1);this.setState({ "modal_draft": true })}}>下書き保存</button>}
                    >未入力の項目があります。</Alert>
                </div>: ""}

                <Modal isOpen={this.state.modal_draft} fade={false} className="modal-dialog-centered">
                    <ModalBody>
                        <div>下書き保存しました。</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "modal_draft": false }) }}>閉じる</Button>
                    </ModalFooter>

                </Modal>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,

        rent_contracts: state.Card.rent_contracts,
        rent_contracts_renter: state.Card.rent_contracts_renter,
        rent_contracts_renter_form_check: state.Card.rent_contracts_renter_form_check

    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_cd(state) {
        //     dispatch(Master.change_cd(state))
        // },

        // change_card_comp(state) {
        //     dispatch(Card.change_card_comp(state))
        // },

        // change_card_section_id(state) {
        //     dispatch(Card.change_card_section_id(state))
        // },
        // change_card_way(state) {
        //     dispatch(Card.change_card_way(state))
        // },
        get_rent_m_job_categorys(state) {
            dispatch(Card.get_rent_m_job_categorys(state))
        },
        get_rent_contracts_application(state) {
            dispatch(Card.get_rent_contracts_application(state))
        },
        change_rent_contracts_renter(state) {
            dispatch(Card.change_rent_contracts_renter(state))
        },
        update_rent_contracts(state) {
            dispatch(Card.update_rent_contracts(state))
        },
        get_rent_contracts_renter_form_check(state) {
            dispatch(Card.get_rent_contracts_renter_form_check(state))
        },
        change_rent_contracts(state) {
            dispatch(Card.change_rent_contracts(state))
        },
        // change_application_num(state) {
        //     dispatch(Card.change_application_num(state))
        // },
 
        //customeredit2change(state) {
        //        dispatch(customeredit2change(state))
        //    },
        //        responseeditchange(state) {
        //            dispatch(responseeditchange(state))
        //        },
        //        customerdetailData1(state) {
        //            dispatch(customerdetailData1(state))
        //        }, responsedetailData(state) {
        //            dispatch(responsedetailData(state))
        //        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationComponent);
