import React, { Component }  from 'react';
import { connect } from 'react-redux';
import './Css/RentFooterComponent.css';
import { withRouter } from "react-router";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HouseIcon from '@material-ui/icons/House';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import RoomIcon from '@material-ui/icons/Room';
// import ChatIcon from '@material-ui/icons/Chat';
import DateRangeIcon from '@material-ui/icons/DateRange';
// import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@mui/material/IconButton';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

import Button from '@mui/material/Button';
import SvgIcon from "@material-ui/core/SvgIcon";


const LineIcon = () => {
    return (
        <SvgIcon
            className="icon_line"
            component="span"
            viewBox=""
            style={{fontSize:window.innerWidth <= 768 ? "25px" : "32px"}}
        >
            <svg style={{verticalAlign:'super'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.37 43.23"><g><path style={{fill: "#fff"}} d="m45.37,18.41C45.37,8.26,35.19,0,22.69,0S0,8.26,0,18.41c0,9.1,8.07,16.72,18.97,18.16.74.16,1.74.49,2,1.12.23.57.15,1.47.07,2.05,0,0-.27,1.6-.32,1.94-.1.57-.46,2.24,1.97,1.22,2.42-1.02,13.06-7.69,17.82-13.17h0c3.29-3.61,4.86-7.26,4.86-11.33Zm-30.69,5.43c0,.24-.19.43-.43.43h-6.37c-.24,0-.43-.19-.43-.43h0v-9.9c0-.24.19-.43.43-.43h1.61c.24,0,.43.19.43.43v7.86h4.33c.24,0,.43.19.43.43v1.61Zm3.84,0c0,.24-.19.43-.43.43h-1.61c-.24,0-.43-.19-.43-.43v-9.9c0-.24.19-.43.43-.43h1.61c.24,0,.43.19.43.43v9.9Zm10.95,0c0,.24-.19.43-.43.43h-1.6s-.08,0-.11-.02c0,0,0,0,0,0-.01,0-.02,0-.03,0,0,0,0,0-.01,0h-.02s-.01-.02-.02-.02c0,0,0,0-.01,0,0,0-.02-.01-.03-.02,0,0,0,0,0,0-.04-.03-.08-.06-.11-.11l-4.54-6.12v5.88c0,.24-.19.43-.43.43h-1.61c-.24,0-.43-.19-.43-.43v-9.9c0-.24.19-.43.43-.43h1.6s0,0,.01,0c0,0,.02,0,.02,0,0,0,.02,0,.02,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,0,0,0,0,.01,0,0,0,.02,0,.03.01,0,0,0,0,.01,0,0,0,.02,0,.02.01,0,0,0,0,.01,0,0,0,.02.01.02.02,0,0,0,0,.01,0,0,0,.02.01.02.02h0s.02.02.03.03c0,0,0,0,0,0,.01.01.02.03.03.04l4.53,6.12v-5.88c0-.24.19-.43.43-.43h1.61c.24,0,.43.19.43.43v9.9Zm8.79-8.29c0,.24-.19.43-.43.43h-4.33v1.67h4.33c.24,0,.43.19.43.43v1.61c0,.24-.19.43-.43.43h-4.33v1.67h4.33c.24,0,.43.19.43.43v1.61c0,.24-.19.43-.43.43h-6.37c-.24,0-.43-.19-.43-.43h0v-9.89h0c0-.25.19-.44.43-.44h6.37c.24,0,.43.19.43.43v1.61Z"/></g></svg>
        </SvgIcon>
    );
};

class RentFooterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spot:"",
            open:false,
        };
        this.spotDisp = this.spotDisp.bind(this);
        this.LineLink = this.LineLink.bind(this);
        // this.TelLink = this.TelLink.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.eigyouCall = this.eigyouCall.bind(this);
    }
    spotDisp(e, value){       
        this.setState({spot: value});
        // this.eigyouCall();
    }
    handleClickOpen(){
        this.setState({open: true});
    };
    
    handleClose(){
        this.setState({open: false});
    };

    // TelLink(){
    //     alert()
    //     if(this.props.staff.length || Object.keys(this.props.staff).indexOf("staff")!== -1){
    //         window.location.href = "tel:"+ this.props.staff.tenpo[0].Tel;
    //     }
    // }
    LineLink(){  
        if(this.props.staff.length || Object.keys(this.props.staff).indexOf("staff")!== -1){
            if(this.props.staff.tenpo[0].Tenpo_No === 17){
                window.location.href = "https://page.line.me/653lvwms?openQrModal=true";
            }
            else if(this.props.staff.tenpo[0].Tenpo_No === 47){
                window.location.href = "https://page.line.me/707zmmom?openQrModal=true";
            }
            else if(this.props.staff.tenpo[0].Tenpo_No === 1){
                window.location.href = "https://page.line.me/772czlir?openQrModal=true";
            }
            else if(this.props.staff.tenpo[0].Tenpo_No === 2){
                window.location.href = "https://page.line.me/221axikr?openQrModal=true";
            }
            else if(this.props.staff.tenpo[0].Tenpo_No === 3){
                window.location.href = "https://page.line.me/723cihaw?openQrModal=true";
            }
            else if(this.props.staff.tenpo[0].Tenpo_No === 4){
                window.location.href = "https://page.line.me/383gtpzg?openQrModal=true";
            }
            else if(this.props.staff.tenpo[0].Tenpo_No === 6){
                window.location.href = "https://page.line.me/133rokhc?openQrModal=true";
            }else{
                window.location.href = "https://page.line.me/653lvwms?openQrModal=true";
            }
        } 
    }

    eigyouCall() {
        if(this.props.staff.length || Object.keys(this.props.staff).indexOf("staff")!== -1 ){
            // console.log(this.props.staff.tenpo,"店舗")
            //現在の日付と曜日取得
            const now = new Date();
            const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
            const currentDayIndex = now.getDay();
            const currentDayOfWeek = daysOfWeek[currentDayIndex];//その日の曜日を取得

            const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.props.staff.tenpo[0].start_h, this.props.staff.tenpo[0].start_m);
            const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.props.staff.tenpo[0].end_h, this.props.staff.tenpo[0].end_m);

            // const youbi =this.props.staff.tenpo[0].Teikyuubi.charAt(0) //Teikyuubiの一文字目を取得
            const youbi = this.props.staff.tenpo[0].Tenpo_No === 10 ? "木":this.props.staff.tenpo[0].Teikyuubi.charAt(0);

            // 定休日でなく、かつ指定の時間帯内にいる場合に電話がかけられる
            // console.log( youbi!==currentDayOfWeek,"曜日")
            // console.log( now >= startTime && now <= endTime,"now")
            // console.log(this.props.staff.tenpo[0].Teikyuubi,startTime,now,endTime)

            if(youbi !== currentDayOfWeek && now >= startTime && now <= endTime){
                return true;
            }else{
                return false;
            }
            
        }
    }

    

    render() {
        // if(this.props.staff.length || Object.keys(this.props.staff).indexOf("staff")!== -1){
        //     this.setState({line_link: this.props.staff.tenpo[0].Tenpo_No});
        // }

        // console.log(this.props.staff,"header")

        this.eigyouCall();
        // console.log(this.eigyouCall(),"営業")
   


        return (
            
            <footer id="footer-menublock">
                
            {/* <BottomNavigation
                value={this.state.spot}
                onChange={this.spotDisp}
                showLabels           
            >
                {this.props.cd.no === "contact" || this.props.cd.no === '0' ? "" :
                <BottomNavigationAction value={0} onClick={()=>this.props.history.push("/Rent/"+this.props.cd.cd+"/"+this.props.cd.no)} label="お部屋一覧" icon={<HouseIcon />} />
                }
                 <BottomNavigationAction value={1} onClick={()=>this.props.history.push("/MapSearch")}  label="地図検索" icon={<RoomIcon />}  />
                <BottomNavigationAction value={2} onClick={()=>this.props.history.push("/Chat")}  label="メッセージ" icon={<ChatIcon />}  /> 
                <BottomNavigationAction value={3} onClick={()=>this.props.history.push("/Reserve/"+this.props.cd.cd+"/"+this.props.cd.no)}  label="来店予約" icon={<DateRangeIcon />}  />
                <BottomNavigationAction value={4} onClick={()=>this.props.history.push("/Coming/"+this.props.cd.cd+"/"+this.props.cd.no)}  label="条件設定" icon={<AssignmentIcon />}  />
            </BottomNavigation> */}
           
            <div className='footer_btns' value={this.state.spot} onChange={this.spotDisp}>
                {this.props.staff.length || Object.keys(this.props.staff).indexOf("staff")!== -1 ?
                // <a href={"tel:"+ this.props.staff.tenpo[0].Tel} id='TelBtn' style={{color: "rgb(128, 128, 128)"}}>
                //     <LocalPhoneIcon style={{marginBottom: 0}}/>
                // </a>
                <div onClick={this.handleClickOpen} id='TelBtn' style={{color: "rgb(128, 128, 128)"}}>
                    <LocalPhoneIcon style={{marginBottom: 0}}/>
                </div>
                :""}
                <Button onClick={()=> this.LineLink()} id='LineBtn' variant="contained" size="large" style={{backgroundColor: "#06c755"}} startIcon={<LineIcon style={{ fontSize:window.innerWidth <= 768 ? "25px" : "32px" }}/>}>
                    LINEで<br className='for-small'/>問合せ
                </Button>
                <Button id='RaitenBtn' variant="contained" size="large" style={{backgroundColor: "#4ac0f0"}}  startIcon={<DateRangeIcon style={{ fontSize:window.innerWidth <= 768 ? "25px" : "32px" }}/>}
                value={3} onClick={()=>this.props.history.push("/Reserve/"+this.props.cd.cd+"/"+this.props.cd.no)}
                >
                    来店予約<br className='for-small'/>する
                </Button>

            </div>
            
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                id='popupTel'
                style={{fontFamily:"Noto Sans JP,sans-serif"}}
            >
                <DialogTitle style={{fontWeight:"bold",fontFamily:"Noto Sans JP,sans-serif"}}>
                {"電話でお問い合わせ"}
                </DialogTitle>
                <DialogContent>
                    {this.props.staff.length || Object.keys(this.props.staff).indexOf("staff")!== -1 ?
                    <DialogContentText style={{fontFamily:"Noto Sans JP,sans-serif",color:"#000"}}>
                        <p className='shopName'>グローバルセンター{this.props.staff.tenpo[0].Tenpo_Name}</p>
                        <dl className={!this.eigyouCall()?'infos gai':"infos"}>
                            <dt>営業時間</dt>
                            <dd>{this.props.staff.tenpo[0].Eigyou_Jikan}</dd>
                        </dl>
                        {!this.eigyouCall()?<p className='eigyougai'>※現在営業時間外です。</p>:""}
                        <dl className={!this.eigyouCall()?'infos gai':"infos"}>
                            <dt>定休日</dt>
                            <dd>{this.props.staff.tenpo[0].Teikyuubi}</dd>
                        </dl>
                        <div className='popupBtns'>
                            {this.eigyouCall()?<a className='pb_Tel' href={"tel:"+ this.props.staff.tenpo[0].Tel}>
                                <div style={{ backgroundColor:"#4AC0F0"}}>
                                    <LocalPhoneIcon style={{marginBottom: 0,fontSize:"35px"}}/>
                                    <span className='text'>電話でお問い合わせ</span>
                                </div>
                            </a>
                            :
                            <div className='pb_Tel'>
                                <div style={{ backgroundColor:"#A0A0A0" }}>
                                    <LocalPhoneIcon style={{marginBottom: 0,fontSize:"35px"}}/>
                                    <span className='text'>電話でお問い合わせ</span>
                                </div>
                            </div>
                            }
                            <div className='pb_Cancel' onClick={this.handleClose}>
                                キャンセル
                            </div>
                        </div>
                    </DialogContentText>
                    :""}
                </DialogContent>
            </Dialog>
                
                {/* <ul className="footer-menu_list pa-left-0">
                {this.props.cd.no === "contact" ? "" :
                    <li>
                        <span className="current" href="" onClick={()=>this.props.history.push("/Rent/"+this.props.cd.cd+"/"+this.props.cd.no)}>
                            <i className="fa fa-home"></i>
                            お部屋一覧
                        </span>
                    </li>
                }
                        <li>
                        <span class="" href="" onClick={()=>this.props.history.push("/MapSearch")}>
                            <i class="fas fa-map-marker-alt"></i>
                            地図検索
                        </span>
                    </li>
                    <li>
                        <span class="" href="" id="chat-link" onClick={()=>this.props.history.push("/Chat")}>
                            <i class="far fa-comment-dots"></i>
                            メッセージ
                        </span>
                    </li>
                    <li>
                        <span className="" href="" id="chat-link" onClick={()=>this.props.history.push("/Reserve/"+this.props.cd.cd+"/"+this.props.cd.no)}>
                            <i className="far fa-calendar-alt"></i>
                            来店予約
                        </span>
                    </li>
                    <li>
                        <span className="" href="" id="chat-link" onClick={()=>this.props.history.push("/Coming/"+this.props.cd.cd+"/"+this.props.cd.no)}>
                            <i className="fas fa-clipboard-list"></i>
                            条件設定
                        </span>
                    </li>                    
                </ul> */}
            </footer>

        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        cd: state.masterReducer.cd,
        staff:state.Rent.staff,
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
        
//        customeredit1change(state) {
//            dispatch(customeredit1change(state))
//        },
//customeredit2change(state) {
//        dispatch(customeredit2change(state))
//    },
//        responseeditchange(state) {
//            dispatch(responseeditchange(state))
//        },
//        customerdetailData1(state) {
//            dispatch(customerdetailData1(state))
//        }, responsedetailData(state) {
//            dispatch(responsedetailData(state))
//        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RentFooterComponent));
