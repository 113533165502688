const authentication = require('react-azure-adb2c').default;


export const send = (values: any) => {


    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/mypage/reserve/bukken/` + values.cd;
    // const url = `http://localhost:5000/v1/mypage/reserve/bukken/` + values.cd;

    // alert(url)
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        // .then(payload => ({ payload }))
        // .catch(error => ({ error }));
        .then(payload => { return { payload } })
        .catch(error => { alert("送信に失敗しました。店舗にお問い合わせください。" + error); return { error } });

};


export const Shuhen = (values: any) => {

    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/mypage/rooms/shuhen`;
    // const url = "http://localhost:5000/v1/mypage/rooms/shuhen"

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        // .then(payload => {console.log(payload,"shuhen");return { payload }})
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const list = (values: any) => {


    const url = process.env.REACT_APP_API_OPENROOM_URL + `/api/MypageDetail/`;




    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};





export const detail = (values: any) => {
    // const url =`https://openrooms.azurewebsites.net/v1/mypage/rooms/detail/` + values.cd + '/' + values.no + '/' +values.tatemono_id + '/' + values.room_id

    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/mypage/rooms/detail/` + values.cd + '/' + values.no + '/' + values.tatemono_id + '/' + values.room_id
    // const url =`http://localhost:5000/v1/mypage/rooms/detail/` + values.cd + '/' + values.no + '/' +values.tatemono_id + '/' + values.room_id


    // alert(url)

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
