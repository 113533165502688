import React, { Component } from 'react';
import { connect } from 'react-redux';
import DownloadCompComponent from './DownloadCompComponent';
import * as Master from '../../Store/Master';
import * as Download from './Saga/Download';
import RentHeaderComponent from '../Layout/RentHeaderComponent';
class DownloadComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comp: false
        };

        this.props.change_download_comp(false)


        // this.props.change_cd({cd:this.props.match.params.cd,no:this.props.match.params.no})


        this.props.get_download_file_list(this.props.match.params.fcd)
        this.handlesubmit = this.handlesubmit.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handlesubmit(state) {
        // console.log(state)
        // alert()
        this.setState({ comp: true })
    }

    render() {

        return (
            <div style={{ backgroundColor: "whitesmoke" }}>
                <RentHeaderComponent />
                <div className="container">
                    <DownloadCompComponent />
                </div>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,

        download_comp: state.Download.download_comp,
        download_file_list: state.Download.download_file_list,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_cd(state) {
        //     dispatch(Master.change_cd(state))
        // },
        get_download_file_list(state) {
            dispatch(Download.get_download_file_list(state))
        },
        change_download_comp(state) {
            dispatch(Download.change_download_comp(state))
        },

        //        customeredit1change(state) {
        //            dispatch(customeredit1change(state))
        //        },
        //customeredit2change(state) {
        //        dispatch(customeredit2change(state))
        //    },
        //        responseeditchange(state) {
        //            dispatch(responseeditchange(state))
        //        },
        //        customerdetailData1(state) {
        //            dispatch(customerdetailData1(state))
        //        }, responsedetailData(state) {
        //            dispatch(responsedetailData(state))
        //        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadComponent);
