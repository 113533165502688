import React, { useState } from 'react';
import axios from 'axios';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';



function ApplyFormComponent(props) {

  const year = [{ value: "", label: "選択してください" }];
  const month = [{ value: "", label: "選択してください" }];
  const day = [{ value: "", label: "選択してください" }];
  var hiduke = new Date();
  // 昭和
  for (let i = 1960; i <= 1988; i++) {
      let showa = i - 1925
      let yearset = []
      yearset.value = i
      yearset.label = i + "年/昭和" + showa + "年"
      year.push(yearset);
  }
  // 平成
  for (let i = 1989; i <= 2018; i++) {
      let heisei = i - 1988
      let yearset = []
      yearset.value = i
      yearset.label = i + "年/平成" + heisei + "年"
      year.push(yearset);
  }
  // 令和
  for (let i = 2019; i <= hiduke.getFullYear(); i++) {
      let heisei = i - 2018
      let yearset = []
      yearset.value = i
      yearset.label = i + "年/令和" + heisei + "年"
      year.push(yearset);
  }
  for (let i = 1; i <= 12; i++) {
      let monthset = []
      monthset.value = i
      monthset.label = i + "月"
      month.push(monthset);
  }
  for (let i = 1; i <= 31; i++) {
      let dayset = []
      dayset.value = i
      dayset.label = i + "日"
      day.push(dayset);
  }


    //続柄
    const relationship = [
        { value: null, label: "選択してください" },
        { value: 1, label: "本人" },
        { value: 2, label: "父" },
        { value: 3, label: "母" },
        { value: 4, label: "子" },
        { value: 5, label: "妻" },
        { value: 6, label: "夫" },
        { value: 7, label: "祖父" },
        { value: 8, label: "祖母" },
        { value: 9, label: "義父" },
        { value: 10, label: "義母" },
        { value: 11, label: "兄" },
        { value: 12, label: "姉" },
        { value: 13, label: "弟" },
        { value: 14, label: "妹" },
        { value: 20, label: "叔父" },
        { value: 21, label: "叔母" },
        { value: 22, label: "婚約者" },
    ]

    //業種
    const jobCategory = [
        { value: null, label: "選択してください" },
        { value: 1, label: "農業" },
        { value: 2, label: "林業" },
        { value: 3, label: "漁業" },
        { value: 4, label: "鉱業" },
        { value: 5, label: "建設業" },
        { value: 6, label: "製造業" },
        { value: 7, label: "電気・ガス・熱供給・水道業" },
        { value: 8, label: "情報通信業" },
        { value: 9, label: "運輸業" },
        { value: 10, label: "卸売業・小売業" },
        { value: 11, label: "金融業・保険業" },
        { value: 12, label: "不動産業" },
        { value: 13, label: "学術研究" },
        { value: 14, label: "宿泊業，飲食サービス業" },
        { value: 15, label: "生活関連サービス業，娯楽業" },
        { value: 16, label: "教育，学習支援業" },
        { value: 17, label: "医療，福祉" },
        { value: 18, label: "その他" },
    ]

    //雇用形態
    const jobClass = [
        { value: null, label: "選択してください" },
        { value: 2, label: "公務員" },
        { value: 1, label: "会社員" },
        { value: 10, label: "会社経営者" },
        { value: 6, label: "契約社員" },
        { value: 11, label: "派遣社員" },
        { value: 3, label: "自営業" },
        { value: 12, label: "個人事業勤務" },
        { value: 4, label: "アルバイト" },
        { value: 5, label: "パート" },
        { value: 13, label: "学生" },
        { value: 7, label: "年金" },
        { value: 8, label: "生活保護受給" },
        { value: 9, label: "無職" },
        { value: 14, label: "その他" },
    ]
    //住居
    const myHome = [
        { value: null, label: "選択してください" },
        { value: 1, label: "持家" },
        { value: 2, label: "借家" },
        { value: 3, label: "親と同居" },
    ]
    //引越し理由
    const moveReasonData = [
        { value: null, label: "選択してください" },
        { value: 8, label: "結婚" },
        { value: 7, label: "転勤" },
        { value: 6, label: "入社" },
        { value: 4, label: "入学" },
        { value: 2, label: "独立" },
        { value: 11, label: "家賃を抑えたい" },
        { value: 12, label: "手狭になった" },
        { value: 3, label: "その他" },
    ]


    //  エラーメッセージ
    const [errorText, setErrorText] = useState({});
    //  エラーの有無
    const [errorFlg, setErrorFlg] = useState(false);




//   const [formItems, setFormItems] = useState({"number_of_cars": 0, "sex": "1", "number_of_renters": 1, "pets": "0"});
  const [formItems, setFormItems] = useState({
    "number_of_cars": 0,
    "car_name1": "",
    "car_color1": "",
    "car_no1": "",
    "car_name2": "",
    "car_color2": "",
    "car_no2": "",
    "car_name3": "",
    "car_color3": "",
    "car_no3": "",
    "name11": "",
    "name12": "",
    "kana11": "",
    "kana12": "",
    "sex": "1",
    "birthday_year": "",
    "birthday_month": "",
    "birthday_day": "",
    "zip": "",
    "address1": "",
    "tel1": "",
    "email": "",
    "office_name": "",
    "job_class": null,
    "my_home": null,
    "reason": null,
    "number_of_renters": 1,
    "e_name11": "",
    "e_name12": "",
    "e_kana11": "",
    "e_kana12": "",
    "e_birthday_year": "",
    "e_birthday_month": "",
    "e_birthday_day": "",
    "e_sex": "1",
    "e_zip": "",
    "e_address": "",
    "e_tel": "",
    "e_relationship": null,
    "e_myhome": null,
    "e_job_class": null,
    "h_name11": "",
    "h_name12": "",
    "h_kana11": "",
    "h_kana12": "",
    "h_birthday_year1": "",
    "h_birthday_month1": "",
    "h_birthday_day1": "",
    "h_sex1": "1",
    "h_relationship1": null,
    "h_tel1": "",
    "h_work1": "",
    "h_name21": "",
    "h_name22": "",
    "h_kana21": "",
    "h_kana22": "",
    "h_birthday_year2": "",
    "h_birthday_month2": "",
    "h_birthday_day2": "",
    "h_sex2": "",
    "h_relationship2": null,
    "h_tel2": "",
    "h_work2": "",
    "h_name31": "",
    "h_name32": "",
    "h_kana31": "",
    "h_kana32": "",
    "h_birthday_year3": "",
    "h_birthday_month3": "",
    "h_birthday_day3": "",
    "h_sex3": "",
    "h_relationship3": null,
    "h_tel3": "",
    "h_work3": "",
    "h_name41": "",
    "h_name42": "",
    "h_kana41": "",
    "h_kana42": "",
    "h_birthday_year4": "",
    "h_birthday_month4": "",
    "h_birthday_day4": "",
    "h_sex4": "",
    "h_relationship4": null,
    "h_tel4": "",
    "h_work4": "",
    "h_name51": "",
    "h_name52": "",
    "h_kana51": "",
    "h_kana52": "",
    "h_birthday_year5": "",
    "h_birthday_month5": "",
    "h_birthday_day5": "",
    "h_sex5": "",
    "h_relationship5": null,
    "h_tel5": "",
    "h_work5": "",
    "h_name61": "",
    "h_name62": "",
    "h_kana61": "",
    "h_kana62": "",
    "h_birthday_year6": "",
    "h_birthday_month6": "",
    "h_birthday_day6": "",
    "h_sex6": "",
    "h_relationship6": null,
    "h_tel6": "",
    "h_work6": "",
    "pets": "0"
  });

  const [cars, setCars] = useState(0);
  const [renters, setRenters] = useState(1);
  const [pets, setPets] = useState(0);
  const [dogs, setDogs] = useState(false);
  const [cats, setCats] = useState(false);

  const [first, setFirst] = useState(true);     // 戻った直後かどうかの判断用

// 戻った時の値セット
  if(props.items && first) {
    // alert("戻った時の値セット");
    setFormItems(props.items);
    setCars(props.items["number_of_cars"]);
    setRenters(props.items["number_of_renters"]);
    setPets(props.items["pets"]);
    setDogs(props.items["dog"]);
    setCats(props.items["cat"]);
    setFirst(false);
  }


  const validator = (name, value) => {
    switch(name) {
        case 'email':
            return emailValidation(value);
        case 'tel1':
            return telValidation(value);
        // case 'office_tel':
        //     return telValidation(value);
        case 'e_tel':
            return telValidation(value);
        // case 'e_office_tel':
        //     return telValidation(value);
        case 'h_tel1':
            return telValidation(value);
        case 'h_tel2':
            return telValidation(value);
        case 'h_tel3':
            return telValidation(value);
        case 'h_tel4':
            return telValidation(value);
        case 'h_tel5':
            return telValidation(value);
        case 'h_tel6':
            return telValidation(value);                    
        }
  }

  const emailValidation = (value) => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (regex.test(value)) {
        return '';
    } else {
        return '正しい形式でメールアドレスを入力してください。';
    }
  }

  const telValidation = (value) => {
    // 電話番号の正規表現（固定電話、携帯、フリーダイヤル、IP電話を考慮）
    const regex = /^(0[5-9]0[-(]?[0-9]{4}[-)]?[0-9]{4}|0120[-]?\d{1,3}[-]?\d{4}|050[-]?\d{4}[-]?\d{4}|0[1-9][-]?\d{1,4}[-]?\d{1,4}[-]?\d{4})*$/;
    if (regex.test(value)) {
        return '';
    } else {
        return '有効な電話番号を入力してください。';
    }
  }

  const onChange = (e) => {
    // console.log("***onChange!!!***");
    setErrorFlg(false);     //  確認画面へ進むボタンのところのエラーメッセージ消す。エラー無しにする。

    const name = e.target.name;
    const value = e.target.value;
    // console.log(name);
    // console.log(value);


    // 入力チェック
    if(value === "" || value === null) {
        setErrorText({...errorText, [name]: "入力してください。"});
    } else {
        setErrorText({...errorText, [name]: validator(name, value)});
    }

    // 郵便番号の場合、住所リセット
    if(name === "zip") {
        formItems["address1"] = "";
    } else if(name === "office_zip") {
        formItems["office_address"] = "";
    } else if(name === "e_zip") {
        formItems["e_address"] = "";
    }


    setFormItems({...formItems, [name]: value});
    // console.log(formItems);
}


  const onChangeCars = (e) => {
    setErrorFlg(false);     //  確認画面へ進むボタンのところのエラーメッセージ消す。エラー無しにする。
    const name = e.target.name;
    const value = e.target.value;

    // 車台数少なく変更された場合、入力値削除
    if(formItems[name] > value) {
        if(value == 2) {
            // 車両3情報削除
            deleteCar3();
        } else if(value == 1) {
            if(formItems[name] == 3) {
                // 車両2,3情報削除
                deleteCar2();
                deleteCar3();
            } else if(formItems[name] == 2) {
                // 車両2情報削除
                deleteCar2();
            }
        } else if(value == 0) {
            if(formItems[name] == 3) {
                // 車両1,2,3情報削除
                deleteCar1();
                deleteCar2();
                deleteCar3();
            } else if(formItems[name] == 2) {
                // 車両1,2情報削除
                deleteCar1();
                deleteCar2();
            } else if(formItems[name] == 1) {
                // 車両1情報削除
                deleteCar1();
            }
        }
    }

    setCars(value);
    setFormItems({...formItems, [name]: value});
    // console.log(formItems);
  }

  // 駐車場台数変更時の情報削除
  // 車両1情報削除
  const deleteCar1 = () => {
    formItems["car_name1"] = "";
    formItems["car_color1"] = "";
    formItems["car_no1"] = "";
  }
  // 車両2情報削除
  const deleteCar2 = () => {
    formItems["car_name2"] = "";
    formItems["car_color2"] = "";
    formItems["car_no2"] = "";
  }
  // 車両3情報削除
  const deleteCar3 = () => {
    formItems["car_name3"] = "";
    formItems["car_color3"] = "";
    formItems["car_no3"] = "";
  }

  const onChangeRenters = (e) => {
    setErrorFlg(false);     //  確認画面へ進むボタンのところのエラーメッセージ消す。エラー無しにする。
    const name = e.target.name;
    const value = e.target.value;

    // 入居者少なく変更された場合、入力値削除
    if(formItems[name] > value) {
        if(value == 5) {
            // 入居者6情報削除
            deleteResident6();
        } else if(value == 4) {
            if(formItems[name] == 6) {
                // 入居者5,6情報削除
                deleteResident5();
                deleteResident6();
            } else {
                // 入居者5情報削除
                deleteResident5();
            }
        } else if(value == 3) {
            if(formItems[name] == 6) {
                // 入居者4,5,6情報削除
                deleteResident4();
                deleteResident5();
                deleteResident6();
            } else if(formItems[name] == 5) {
                // 入居者4,5情報削除
                deleteResident4();
                deleteResident5();
            } else {
                // 入居者4情報削除
                deleteResident4();
            }
        } else if(value == 2) {
            if(formItems[name] == 6) {
                // 入居者3,4,5,6情報削除
                deleteResident3();
                deleteResident4();
                deleteResident5();
                deleteResident6();
            } else if(formItems[name] == 5) {
                // 入居者3,4,5情報削除
                deleteResident3();
                deleteResident4();
                deleteResident5();
            } else if(formItems[name] == 4) {
                // 入居者3,4情報削除
                deleteResident3();
                deleteResident4();
            } else {
                // 入居者3情報削除
                deleteResident3();
            }
        } else if(value == 1) {
            if(formItems[name] == 6) {
                // 入居者2,3,4,5,6情報削除
                deleteResident2();
                deleteResident3();
                deleteResident4();
                deleteResident5();
                deleteResident6();
            } else if(formItems[name] == 5) {
                // 入居者2,3,4,5情報削除
                deleteResident2();
                deleteResident3();
                deleteResident4();
                deleteResident5();
            } else if(formItems[name] == 4) {
                // 入居者2,3,4情報削除
                deleteResident2();
                deleteResident3();
                deleteResident4();
            } else if(formItems[name] == 3) {
                // 入居者2,3情報削除
                deleteResident2();
                deleteResident3();
            } else {
                // 入居者2情報削除
                deleteResident2();
            }
        }

    }
    setRenters(value);
    setFormItems({...formItems, [name]: value});
    // console.log(formItems);
  }

// 入居者人数変更時の情報削除
// 入居者2情報削除
const deleteResident2 = () => {
    formItems["h_name21"] = "";
    formItems["h_name22"] = "";
    formItems["h_kana21"] = "";
    formItems["h_kana22"] = "";
    formItems["h_birthday_year2"] = "";
    formItems["h_birthday_month2"] = "";
    formItems["h_birthday_day2"] = "";
    formItems["h_sex2"] = "";
    formItems["h_relationship2"] = null;
    formItems["h_tel2"] = "";
    formItems["h_work2"] = "";
  }
// 入居者3情報削除
const deleteResident3 = () => {
    formItems["h_name31"] = "";
    formItems["h_name32"] = "";
    formItems["h_kana31"] = "";
    formItems["h_kana32"] = "";
    formItems["h_birthday_year3"] = "";
    formItems["h_birthday_month3"] = "";
    formItems["h_birthday_day3"] = "";
    formItems["h_sex3"] = "";
    formItems["h_relationship3"] = null;
    formItems["h_tel3"] = "";
    formItems["h_work3"] = "";
  }
// 入居者4情報削除
const deleteResident4 = () => {
    formItems["h_name41"] = "";
    formItems["h_name42"] = "";
    formItems["h_kana41"] = "";
    formItems["h_kana42"] = "";
    formItems["h_birthday_year4"] = "";
    formItems["h_birthday_month4"] = "";
    formItems["h_birthday_day4"] = "";
    formItems["h_sex4"] = "";
    formItems["h_relationship4"] = null;
    formItems["h_tel4"] = "";
    formItems["h_work4"] = "";
  }
// 入居者5情報削除
const deleteResident5 = () => {
    formItems["h_name51"] = "";
    formItems["h_name52"] = "";
    formItems["h_kana51"] = "";
    formItems["h_kana52"] = "";
    formItems["h_birthday_year5"] = "";
    formItems["h_birthday_month5"] = "";
    formItems["h_birthday_day5"] = "";
    formItems["h_sex5"] = "";
    formItems["h_relationship5"] = null;
    formItems["h_tel5"] = "";
    formItems["h_work5"] = "";
  }
// 入居者6情報削除
  const deleteResident6 = () => {
    formItems["h_name61"] = "";
    formItems["h_name62"] = "";
    formItems["h_kana61"] = "";
    formItems["h_kana62"] = "";
    formItems["h_birthday_year6"] = "";
    formItems["h_birthday_month6"] = "";
    formItems["h_birthday_day6"] = "";
    formItems["h_sex6"] = "";
    formItems["h_relationship6"] = null;
    formItems["h_tel6"] = "";
    formItems["h_work6"] = "";
  }

  const onChangePets = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPets(value);
    if(value == 0) {
        setDogs(false);
        setCats(false);
        formItems["dog"] = "";
        formItems["cat"] = "";
        formItems["dog_count"] = "";
        formItems["dog_remarks"] = "";
        formItems["cat_count"] = "";
        formItems["cat_remarks"] = "";
    }
    setFormItems({...formItems, [name]: value});
    // console.log(formItems);
  }

  const onChangeDogs = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    setDogs(value);
    if(!dogs) {
        formItems["dog_count"] = "";
        formItems["dog_remarks"] = "";
    }
    setFormItems({...formItems, [name]: value});
    // console.log(formItems);
  }
  const onChangeCats = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    setCats(value);
    if(!cats) {
        formItems["cat_count"] = "";
        formItems["cat_remarks"] = "";
    }
    setFormItems({...formItems, [name]: value});
    // console.log(formItems);
  }

//   住所自動入力
  const [autoAddress, setAutoAddress] = useState("");

  const handleSetAddress = async (zip) => {
    // alert(zip);
      const res = await axios.get('https://api.zipaddress.net/?zipcode=' + formItems[zip]);

      if(res.data.code === 200) {
        var address = res.data.data.pref + res.data.data.city + res.data.data.town;
        // console.log(address);
        if(zip === "zip") {
            formItems["address1"] = address;
            if(errorText["address1"]) {
                errorText["address1"] = "";
            }
        } else if(zip === "office_zip") {
            formItems["office_address"] = address;
            if(errorText["office_address"]) {
                errorText["office_address"] = "";
            }
        } else if(zip === "e_zip") {
            formItems["e_address"] = address;
            if(errorText["e_address"]) {
                errorText["e_address"] = "";
            }            
        } else if(zip === "e_office_zip") {
            formItems["e_office_address"] = address;
            if(errorText["e_office_address"]) {
                errorText["e_office_address"] = "";
            }           
        }
        setFormItems({...formItems});

    }
  }

    // 駐車場台数の入力チェック(エラーメッセージerrorTextに空文字設定=>必須項目解除)
    const parkingErrorText = (num) => {
        if(num < 3) {
            // alert("parkingErrorText: 0")
            errorText["car_name3"] = "";
            errorText["car_color3"] = "";
            errorText["car_no3"] = "";
        }
        if(num < 2) {
            errorText["car_name2"] = "";
            errorText["car_color2"] = "";
            errorText["car_no2"] = "";
        }
        if(num < 1) {
            errorText["car_name1"] = "";
            errorText["car_color1"] = "";
            errorText["car_no1"] = "";
        }
    }
    
    // 入居人数の入力チェック(エラーメッセージerrorTextに空文字設定=>必須項目解除)
    const rentersErrorText = (num) => {
        if(num < 6) {
            errorText["h_name61"] = "";
            errorText["h_name62"] = "";
            errorText["h_kana61"] = "";
            errorText["h_kana62"] = "";
            errorText["h_birthday_year6"] = "";
            errorText["h_birthday_month6"] = "";
            errorText["h_birthday_day6"] = "";
            errorText["h_sex6"] = "";
            errorText["h_relationship6"] = "";
            errorText["h_tel6"] = "";
            errorText["h_work6"] = "";
        }
        if(num < 5) {
            errorText["h_name51"] = "";
            errorText["h_name52"] = "";
            errorText["h_kana51"] = "";
            errorText["h_kana52"] = "";
            errorText["h_birthday_year5"] = "";
            errorText["h_birthday_month5"] = "";
            errorText["h_birthday_day5"] = "";
            errorText["h_sex5"] = "";
            errorText["h_relationship5"] = "";
            errorText["h_tel5"] = "";
            errorText["h_work5"] = "";
        }
        if(num < 4) {
            errorText["h_name41"] = "";
            errorText["h_name42"] = "";
            errorText["h_kana41"] = "";
            errorText["h_kana42"] = "";
            errorText["h_birthday_year4"] = "";
            errorText["h_birthday_month4"] = "";
            errorText["h_birthday_day4"] = "";
            errorText["h_sex4"] = "";
            errorText["h_relationship4"] = "";
            errorText["h_tel4"] = "";
            errorText["h_work4"] = "";
        }
        if(num < 3) {
            errorText["h_name31"] = "";
            errorText["h_name32"] = "";
            errorText["h_kana31"] = "";
            errorText["h_kana32"] = "";
            errorText["h_birthday_year3"] = "";
            errorText["h_birthday_month3"] = "";
            errorText["h_birthday_day3"] = "";
            errorText["h_sex3"] = "";
            errorText["h_relationship3"] = "";
            errorText["h_tel3"] = "";
            errorText["h_work3"] = "";
        }
        if(num < 2) {
            errorText["h_name21"] = "";
            errorText["h_name22"] = "";
            errorText["h_kana21"] = "";
            errorText["h_kana22"] = "";
            errorText["h_birthday_year2"] = "";
            errorText["h_birthday_month2"] = "";
            errorText["h_birthday_day2"] = "";
            errorText["h_sex2"] = "";
            errorText["h_relationship2"] = "";
            errorText["h_tel2"] = "";
            errorText["h_work2"] = "";
        }
    }

  // 入力チェック
  const errorCheck = () => {
    Object.keys(formItems).map((key) => {
        // console.log("key: " + key + "\nvalue: " + formItems[key]);
        
        // 必須項目ではないもの
        if(key == "office_tel" || key == "office_zip" || key == "office_address" || key == "job_category" || key == "work_years" || key == "income" ||
            key == "e_work_years" || key == "e_income" || key == "e_office" || key == "e_office_zip" || key == "e_office_address" || key == "e_office_tel" || key == "e_job_category" ||
            key == "dog" || key == "cat" || key == "dog_count" || key == "cat_count" || key == "dog_remarks" || key == "cat_remarks")
        {
            errorText[key] = "";
        }

        // 駐車場の数によって必須項目変える
        if(key == "number_of_cars") {
            errorText[key] = "";
            // alert(formItems[key]);
            parkingErrorText(formItems[key]);
        }

        // 入居者の数によって必須項目変える
        if(key == "number_of_renters") {
            errorText[key] = "";
            // alert(formItems[key]);
            rentersErrorText(formItems[key]);
        }


        if(errorText[key] != "") {
            if(formItems[key] == null || formItems[key] == "") {
                errorText[key] = '入力してください。';
                // setErrorText({...errorText, [key]: '入力してください。'})
                setErrorFlg(true);  // エラー有
            } else {
                errorText[key] = "";
                // setErrorText({...errorText, [key]: ''})
            }
        }
        setErrorText({...errorText});

    })
  }


  const onClick = () => {
    // 必須項目チェック
    errorCheck();

    let errorMsgFlg = false;  // エラーメッセージ無
    Object.keys(errorText).map((key) => {
        if(errorText[key]) {
            errorMsgFlg = true; // エラーメッセージ有
        }
    })

    // エラーが無い場合
    if(!errorMsgFlg) {
        // ApplyComponent(親)へ値を渡す
        props.handlePageChange(2);   // ページ切り替え
        props.handleInputDatas(formItems);  // 入力データ
    }
    // // ApplyComponent(親)へ値を渡す
    // props.handlePageChange(2);   // ページ切り替え
    // props.handleInputDatas(formItems);  // 入力データ
  }


  return (
    <div>
      <div className="row pt-2">
        <h6>【駐車場】</h6>
        {/* {formItems.length > 0 ?
        formItems.map((data, index) => <li key={index}>{data}</li>) : ""
        } */}
      </div>
      <div className="row">
        <div className="col-md-3">台数</div>
        <div className="col-md-9 form-group">
            <div className="row">
                <div className="col-md-10">
                    <FormControl variant="outlined" margin="dense" style={{ width: "40%" }} required={true}>
                        <InputLabel>台数</InputLabel>
                        <Select
                            name="number_of_cars"
                            id="number_of_cars"
                            value={formItems["number_of_cars"]}
                            onChange={(e) => onChangeCars(e)}
                            label="台数"
                        >
                            <MenuItem value={0}>{"不要"}</MenuItem>
                            <MenuItem value={1}>{"1台"}</MenuItem>
                            <MenuItem value={2}>{"2台"}</MenuItem>
                            <MenuItem value={3}>{"3台"}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
      </div>
        {cars > 0 ? <div className="row">
        {/* {parseInt(this.props.rent_contracts.number_of_cars) > 0 ? <div className="row"> */}
        <div className="col-md-3">車両①</div>
        <div className="col-md-9 form-group">
            <div className="row">
                <div className="col-md-10">
                <TextField
                    id="car_name1"
                    name="car_name1"
                    required={true}
                    label="メーカー名/車種"
                    value={formItems["car_name1"]}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: formItems["car_name1"] ? '' : '#FFE6EA' }}
                    autoComplete='off'
                    onChange={(e) => onChange(e)}
                    error={errorText["car_name1"]}
                    // helperText={errorText["car_name1"]}
                />
                <Typography style={{color: 'red', fontSize: 12}}>{errorText["car_name1"]}</Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                <TextField
                    id="car_color1"
                    name="car_color1"
                    required={true}
                    label="車色"
                    value={formItems["car_color1"]}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: formItems["car_color1"] ? '' : '#FFE6EA' }}
                    autoComplete='off'
                    // value={this.props.rent_contracts.car_color1}
                    onChange={(e) => onChange(e)}
                    error={errorText["car_color1"]}
                    // helperText={errorText["car_color1"]}
                />
                <Typography style={{color: 'red', fontSize: 12}}>{errorText["car_color1"]}</Typography>
                </div>
                <div className="col-md-7">
                <TextField
                    id="car_no1"
                    name="car_no1"
                    required={true}
                    label="車両番号"
                    value={formItems["car_no1"]}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: formItems["car_no1"] ? '' : '#FFE6EA' }}
                    autoComplete='off'
                    // value={this.props.rent_contracts.car_no1}
                    onChange={(e) => onChange(e)}
                    error={errorText["car_no1"]}
                    // helperText={errorText["car_no1"]}
                />
                <Typography style={{color: 'red', fontSize: 12}}>{errorText["car_no1"]}</Typography>
                </div>
            </div>
        </div>
      </div>:""}

      {cars > 1 ? <div className="row">
      {/* {parseInt(this.props.rent_contracts.number_of_cars) > 1 ? <div className="row"> */}
        <div className="col-md-3">車両②</div>
        <div className="col-md-9 form-group">
            <div className="row">
                <div className="col-md-10">
                <TextField
                    id="car_name2"
                    name="car_name2"
                    required={true}
                    label="メーカー名/車種"
                    value={formItems["car_name2"]}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: formItems["car_name2"] ? '' : '#FFE6EA' }}
                    autoComplete='off'
                    // value={this.props.rent_contracts.car_name2}
                    onChange={(e) => onChange(e)}
                    error={errorText["car_name2"]}
                    // helperText={errorText["car_name2"]}
                />
                <Typography style={{color: 'red', fontSize: 12}}>{errorText["car_name2"]}</Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                <TextField
                    id="car_color2"
                    name="car_color2"
                    required={true}
                    label="車色"
                    value={formItems["car_color2"]}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: formItems["car_color2"] ? '' : '#FFE6EA' }}
                    autoComplete='off'
                    // value={this.props.rent_contracts.car_color2}
                    onChange={(e) => onChange(e)}
                    error={errorText["car_color2"]}
                    // helperText={errorText["car_color2"]}
                />
                <Typography style={{color: 'red', fontSize: 12}}>{errorText["car_color2"]}</Typography>
                </div>
                <div className="col-md-7">
                <TextField
                    id="car_no2"
                    name="car_no2"
                    required={true}
                    label="車両番号"
                    value={formItems["car_no2"]}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: formItems["car_no2"] ? '' : '#FFE6EA' }}
                    autoComplete='off'
                    // value={this.props.rent_contracts.car_no2}
                    onChange={(e) => onChange(e)}
                    error={errorText["car_no2"]}
                    // helperText={errorText["car_no2"]}
                />
                <Typography style={{color: 'red', fontSize: 12}}>{errorText["car_no2"]}</Typography>
                </div>
            </div>
        </div>
      </div>:""}

      {cars > 2 ? <div className="row">
      {/* {parseInt(this.props.rent_contracts.number_of_cars) > 2 ? <div className="row"> */}
        <div className="col-md-3">車両③</div>
        <div className="col-md-9 form-group">
            <div className="row">
                <div className="col-md-10">
                <TextField
                    id="car_name3"
                    name="car_name3"
                    required={true}
                    label="メーカー名/車種"
                    value={formItems["car_name3"]}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: formItems["car_name3"] ? '' : '#FFE6EA' }}
                    autoComplete='off'
                    // value={this.props.rent_contracts.car_name3}
                    onChange={(e) => onChange(e)}
                    error={errorText["car_name3"]}
                    // helperText={errorText["car_name3"]}
                />
                <Typography style={{color: 'red', fontSize: 12}}>{errorText["car_name3"]}</Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                <TextField
                    id="car_color3"
                    name="car_color3"
                    required={true}
                    label="車色"
                    value={formItems["car_color3"]}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: formItems["car_color3"] ? '' : '#FFE6EA' }}
                    autoComplete='off'
                    // value={this.props.rent_contracts.car_color3}
                    onChange={(e) => onChange(e)}
                    error={errorText["car_color3"]}
                    // helperText={errorText["car_color3"]}
                />
                <Typography style={{color: 'red', fontSize: 12}}>{errorText["car_color3"]}</Typography>
                </div>
                <div className="col-md-7">
                <TextField
                    id="car_no3"
                    name="car_no3"
                    required={true}
                    label="車両番号"
                    value={formItems["car_no3"]}
                    margin="dense"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: formItems["car_no3"] ? '' : '#FFE6EA' }}
                    autoComplete='off'
                    // value={this.props.rent_contracts.car_no3}
                    onChange={(e) => onChange(e)}
                    error={errorText["car_no3"]}
                    // helperText={errorText["car_no3"]}
                />
                <Typography style={{color: 'red', fontSize: 12}}>{errorText["car_no3"]}</Typography>
                </div>
            </div>
        </div>
      </div>:""}
      <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>

      <div className="row pt-2">
        <h6>【賃借人】</h6>
      </div>
      <div className="row">
          <div className="col-md-3">氏名
          </div>
          <div className="col-md-9">
              <div className="row">
                  <div className="col-md-4 col-5 form-group">
                      <TextField
                          id="name11"
                          name="name11"
                          label="姓（漢字）"
                          value={formItems["name11"]}
                          margin="dense"
                          variant="outlined"
                          autoComplete='off'
                          onChange={(e) => onChange(e)}
                          required={true}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{backgroundColor: formItems["name11"] ? '' : '#FFE6EA'}}
                          error={errorText["name11"]}
                        //   helperText={errorText["name11"]}
                        //   {...register("name11", {
                        //     required: "姓を入力して下さい"
                        //   })}
                      />
                      <Typography style={{color: 'red', fontSize: 12}}>{errorText["name11"]}</Typography>
                      {/* {errors.name11?.message && (
                        <p className='error-message'>{errors.name11?.message}</p>
                      )} */}
                  </div>
                  <div className="col-md-4 col-5 form-group">
                      <TextField
                          id="name12"
                          name="name12"
                          label="名（漢字）"
                          value={formItems["name12"]}
                          margin="dense"
                          variant="outlined"
                          autoComplete='off'
                          onChange={(e) => onChange(e)}
                          required={true}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{backgroundColor: formItems["name12"] ? '' : '#FFE6EA'}}
                          error={errorText["name12"]}
                        //   helperText={errorText["name12"]}
                      />
                      <Typography style={{color: 'red', fontSize: 12}}>{errorText["name12"]}</Typography>
                  </div>
              </div>
          </div>
      </div>
      <div className="row">
          <div className="col-md-3">フリガナ
          </div>
          <div className="col-md-9">
              <div className="row">
                  <div className="col-md-4 col-5 form-group">
                      <TextField
                          id="kana11"
                          name="kana11"
                          label="姓（カナ）"
                          value={formItems["kana11"]}
                          margin="dense"
                          variant="outlined"
                          autoComplete='off'
                          onChange={(e) => onChange(e)}
                          required={true}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{backgroundColor: formItems["kana11"] ? '' : '#FFE6EA'}}
                          error={errorText["kana11"]}
                        //   helperText={errorText["kana11"]}
                      />
                      <Typography style={{color: 'red', fontSize: 12}}>{errorText["kana11"]}</Typography>
                  </div>
                  <div className="col-md-4 col-5 form-group">
                      <TextField
                          id="kana12"
                          name="kana12"
                          label="名（カナ）"
                          value={formItems["kana12"]}
                          margin="dense"
                          variant="outlined"
                          autoComplete='off'
                          onChange={(e) => onChange(e)}
                          required={true}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{backgroundColor: formItems["kana12"] ? '' : '#FFE6EA'}}
                          error={errorText["kana12"]}
                        //   helperText={errorText["kana12"]}
                      />
                      <Typography style={{color: 'red', fontSize: 12}}>{errorText["kana12"]}</Typography>
                  </div>
              </div>
          </div>
      </div>
      <div className="row">
            <div className="col-md-3">性別</div>
            <div className="form-group col-md-9">
                <FormControl component="fieldset">
                <FormLabel component="legend" required={true} style={{fontSize: 14}}>性別</FormLabel>
                    <RadioGroup aria-label="gender" name="sex" value={formItems["sex"]} onChange={(e) => onChange(e)} row>
                    {/* <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row> */}
                        <FormControlLabel control={<Radio color="primary" value={"1"} />} label="男性" labelPlacement="end" />
                        <FormControlLabel control={<Radio color="primary" value={"0"} />} label="女性" labelPlacement="end" />
                    </RadioGroup>
                </FormControl>
            </div>
      </div>
      <div className="row">
        <div className="col-md-3">生年月日</div>
        <div className="form-group col-md-9">
          <div className="row">
              <div className="col-md-4 col-9 pr-0">
                  <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                      <InputLabel>年</InputLabel>
                      <Select
                          name="birthday_year"
                          id="birthday_year"
                          value={formItems["birthday_year"]}
                          onChange={(e) => onChange(e)}
                          label="年"
                          style={{backgroundColor: formItems["birthday_year"] ? '' : '#FFE6EA'}}
                          error={errorText["birthday_year"]}
                      >
                          {year.map(d =>
                              <MenuItem value={d.value}>{d.label}</MenuItem>
                          )}
                      </Select>
                      <Typography style={{color: 'red', fontSize: 12}}>{errorText["birthday_year"]}</Typography>
                  </FormControl>
              </div>
              <div className="col-md-2 col-4 pr-0">
                  <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                      <InputLabel>月</InputLabel>
                      <Select
                          name="birthday_month"
                          id=""
                          value={formItems["birthday_month"]}
                          // value={this.props.renter.birthday_month}
                          onChange={(e) => onChange(e)}
                          label="月"
                          style={{backgroundColor: formItems["birthday_month"] ? '' : '#FFE6EA'}}
                          error={errorText["birthday_month"]}
                      >
                          {month.map(d =>
                              <MenuItem value={d.value}>{d.label}</MenuItem>
                          )}
                      </Select>
                      <Typography style={{color: 'red', fontSize: 12}}>{errorText["birthday_month"]}</Typography>
                  </FormControl>
                  {/* <span style={{verticalAlign:"bottom"}}>月</span> */}
              </div>
              <div className="col-md-2 col-4 pr-0">
                  <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                      <InputLabel>日</InputLabel>
                      <Select
                          name="birthday_day"
                          id="birthday_day"
                          value={formItems["birthday_day"]}
                          // value={this.props.renter.birthday_day}
                          onChange={(e) => onChange(e)}
                          label="日"
                          style={{backgroundColor: formItems["birthday_day"] ? '' : '#FFE6EA'}}
                          error={errorText["birthday_day"]}
                      >
                          {day.map(d =>
                              <MenuItem value={d.value}>{d.label}</MenuItem>
                          )}
                      </Select>
                      <Typography style={{color: 'red', fontSize: 12}}>{errorText["birthday_day"]}</Typography>
                  </FormControl>
              </div>
          </div>
        </div>
        </div>
        <div className="row">
            <div className="col-md-3">住所</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="zip"
                            name="zip"
                            label={"郵便番号"}
                            value={formItems["zip"]}
                            margin="dense"
                            variant="outlined"
                            required={true}
                            // value={this.state.post}
                            // value={this.props.renter.post}
                            style={{ width: "50%", backgroundColor: formItems["zip"] ? '' : '#FFE6EA' }}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                            placeholder="例）7610000"
                            error={errorText["zip"]}
                            // helperText={errorText["zip"]}
                        />
                        <button style={{ marginLeft: "10px" }} className="btn btn-sm btn-success mt-3" type="button" onClick={() => handleSetAddress("zip")}>住所検索</button>
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["zip"]}</Typography>                   
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="address1"
                            name="address1"
                            label={"住所"}
                            value={formItems["address1"]}
                            margin="dense"
                            variant="outlined"
                            required={true}
                            // value={add1}
                            // value={this.props.renter.add1}
                            style={{ width: "100%", backgroundColor: formItems["address1"] ? '' : '#FFE6EA' }}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                            // helperText={this.props.renter_key == 0 ? "お引越し前のご住所をご入力ください。" : ""}
                            error={errorText["address1"]}
                            // helperText={errorText["address1"]}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["address1"]}</Typography>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">TEL（携帯）</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="tel1"
                            name="tel1"
                            label="TEL"
                            value={formItems["tel1"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%", backgroundColor: formItems["tel1"] ? '' : '#FFE6EA' }}
                            autoComplete='off'
                            // error={!this.props.renter.tel1}
                            // value={this.props.renter.tel1}
                            onChange={(e) => onChange(e)}
                            required={true}
                            placeholder="例）090-1234-5678"
                            helperText="半角数字のみで入力してください。"
                            error={errorText["tel1"]}
                            // helperText={errorText["tel1"]}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["tel1"]}</Typography>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">メールアドレス</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="email"
                            name="email"
                            label="ﾒｰﾙｱﾄﾞﾚｽ"
                            value={formItems["email"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%", backgroundColor: formItems["email"] ? '' : '#FFE6EA' }}
                            autoComplete='off'
                            // error={!this.props.renter.}
                            // value={this.props.renter.}
                            onChange={(e) => onChange(e)}
                            required={true}
                            error={errorText["email"]}
                            // helperText={errorText["email"]}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["email"]}</Typography>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">勤務先名</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="office_name"
                            name="office_name"
                            label={"勤務先名"}
                            value={formItems["office_name"]}
                            margin="dense"
                            variant="outlined"
                            required={true}
                            // value={this.props.renter.office_name}
                            style={{ width: "100%", backgroundColor: formItems["office_name"] ? '' : '#FFE6EA' }}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                            placeholder="正式名称を記入してください"
                            error={errorText["office_name"]}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["office_name"]}</Typography>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">勤務先TEL</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="office_tel"
                            name="office_tel"
                            label="勤務先TEL"
                            value={formItems["office_tel"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%"}}
                            autoComplete='off'
                            // error={!this.props.renter.tel1}
                            // value={this.props.renter.office_tel}
                            onChange={(e) => onChange(e)}
                            placeholder="例）090-1234-5678"
                            helperText="半角数字のみで入力してください。"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">勤務先住所</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="office_zip"
                            name="office_zip"
                            label={"郵便番号"}
                            value={formItems["office_zip"]}
                            margin="dense"
                            variant="outlined"
                            // value={this.props.renter.post}
                            style={{ width: "50%"}}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                            placeholder="例）7610000"
                        />
                        <button style={{ marginLeft: "10px" }} className="btn btn-sm btn-success mt-3" type="button" onClick={() => handleSetAddress("office_zip")}>住所検索</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="office_address"
                            name="office_address"
                            label="勤務先住所"
                            value={formItems["office_address"] ? formItems["office_address"] : ''}
                            margin="dense"
                            variant="outlined"
                            // value={this.props.renter.}
                            style={{ width: "100%"}}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">業種</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-sm-5">
                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} >
                            <InputLabel>業種</InputLabel>
                            <Select
                                name="job_category"
                                id="job_category"
                                value={formItems["job_category"]}
                                // value={parseInt(this.props.renter.rent_m_job_category_id)}
                                onChange={(e) => onChange(e)}
                                label="業種"
                            >
                                {jobCategory.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">雇用形態</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-sm-5">
                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true} >
                            <InputLabel>雇用形態</InputLabel>
                            <Select
                                name="job_class"
                                id="job_class"
                                value={formItems["job_class"]}
                                // value={parseInt(this.props.renter.rent_m_job_category_id)}
                                onChange={(e) => onChange(e)}
                                label="雇用形態"
                                style={{backgroundColor: formItems["job_class"] ? '' : '#FFE6EA'}}
                                error={errorText["job_class"]}
                            >
                                {jobClass.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["job_class"]}</Typography>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">住居</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-sm-5">
                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                            <InputLabel>住居</InputLabel>
                            <Select
                                name="my_home"
                                id="my_home"
                                value={formItems["my_home"]}
                                // value={parseInt(this.props.renter.rent_m_job_category_id)}
                                onChange={(e) => onChange(e)}
                                label="住居"
                                style={{backgroundColor: formItems["my_home"] ? '' : '#FFE6EA'}}
                                error={errorText["my_home"]}
                            >
                                {myHome.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["my_home"]}</Typography>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">引越し理由</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-sm-5">
                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                            <InputLabel>引越し理由</InputLabel>
                            <Select
                                name="reason"
                                id="reason"
                                value={formItems["reason"]}
                                // value={parseInt(this.props.renter.rent_m_job_category_id)}
                                onChange={(e) => onChange(e)}
                                label="引越し理由"
                                style={{backgroundColor: formItems["reason"] ? '' : '#FFE6EA'}}
                                error={errorText["reason"]}
                            >
                                {moveReasonData.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["reason"]}</Typography>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">勤続年数</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-2">
                        <TextField
                            id="work_years"
                            name="work_years"
                            label="勤務年数"
                            value={formItems["work_years"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%"}}
                            autoComplete='off'
                            // error={!this.props.renter.work_years}
                            // value={this.props.renter.work_years}
                            onChange={(e) => onChange(e)}
                            required={true}
                        />
                    </div>
                    <span style={{alignSelf: 'end'}}>年</span>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">年収</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-2" style={{display: 'flex'}}>
                        <TextField
                            id="income"
                            name="income"
                            label="年収"
                            value={formItems["income"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%"}}
                            autoComplete='off'
                            // error={!this.props.renter.}
                            // value={this.props.renter.}
                            onChange={(e) => onChange(e)}
                            required={true}
                        />
                    </div>
                    <span style={{alignSelf: 'end'}}>万円</span>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">入居人数</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <FormControl variant="outlined" margin="dense" style={{ width: "40%" }} required={true}>
                            <InputLabel>入居人数</InputLabel>
                            <Select
                                name="number_of_renters"
                                id="number_of_renters"
                                value={formItems["number_of_renters"]}
                                // value={this.renters}
                                // value={parseInt(this.props.rent_contracts.number_of_renters)}
                                onChange={(e) => onChangeRenters(e)}
                                label="入居人数"
                            >
                                <MenuItem value={1}>{"1人"}</MenuItem>
                                <MenuItem value={2}>{"2人"}</MenuItem>
                                <MenuItem value={3}>{"3人"}</MenuItem>
                                <MenuItem value={4}>{"4人"}</MenuItem>
                                <MenuItem value={5}>{"5人"}</MenuItem>
                                <MenuItem value={6}>{"6人"}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>


        <div className="row pt-2">
            <h6>【緊急連絡先】</h6>
        </div>
        <div style={{color: 'red'}}>※ご契約者様とは別世帯のご連絡先を入力してください。</div>
        <div className="row">
            <div className="col-md-3">氏名
            </div>
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-4 col-5 form-group">
                        <TextField
                            id="e_name11"
                            name="e_name11"
                            label="姓（漢字）"
                            value={formItems["e_name11"]}
                            margin="dense"
                            variant="outlined"
                            autoComplete='off'
                            // value={props.renter.name1_1}
                            // error={!props.renter.name1_1}
                            // onChange={(e) => change_name(e, props)}
                            onChange={(e) => onChange(e)}
                            required={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{backgroundColor: formItems["e_name11"] ? '' : '#FFE6EA'}}
                            error={errorText["e_name11"]}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_name11"]}</Typography>
                    </div>
                    <div className="col-md-4 col-5 form-group">
                        <TextField
                            id="e_name12"
                            name="e_name12"
                            label="名（漢字）"
                            value={formItems["e_name12"]}
                            margin="dense"
                            variant="outlined"
                            autoComplete='off'
                            // value={props.renter.name1_2}
                            // error={!props.renter.name1_2}
                            // onChange={(e) => change_name(e, props)}
                            onChange={(e) => onChange(e)}
                            required={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{backgroundColor: formItems["e_name12"] ? '' : '#FFE6EA'}}
                            error={errorText["e_name12"]}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_name12"]}</Typography>
                    </div>
                </div>
            </div>
        </div>



        <div className="row">
            <div className="col-md-3">フリガナ
            </div>
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-4 col-5 form-group">
                        <TextField
                            id="e_kana11"
                            name="e_kana11"
                            label="姓（カナ）"
                            value={formItems["e_kana11"]}
                            margin="dense"
                            variant="outlined"
                            autoComplete='off'
                            // value={props.renter.kana11}
                            // error={!props.renter.kana11}
                            // onChange={(e) => change_name(e, props)}
                            onChange={(e) => onChange(e)}
                            required={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{backgroundColor: formItems["e_kana11"] ? '' : '#FFE6EA'}}
                            error={errorText["e_kana11"]}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_kana11"]}</Typography>
                    </div>
                    <div className="col-md-4 col-5 form-group">
                        <TextField
                            id="e_kana12"
                            name="e_kana12"
                            label="名（カナ）"
                            value={formItems["e_kana12"]}
                            margin="dense"
                            variant="outlined"
                            autoComplete='off'
                            // value={props.renter.kana1_2}
                            // error={!props.renter.kana1_2}
                            // onChange={(e) => change_name(e, props)}
                            onChange={(e) => onChange(e)}
                            required={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{backgroundColor: formItems["e_kana12"] ? '' : '#FFE6EA'}}
                            error={errorText["e_kana12"]}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_kana12"]}</Typography>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">生年月日</div>
            <div className="form-group col-md-9">
                <div className="row">
                    <div className="col-md-4 col-9 pr-0">
                        <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                            <InputLabel>年</InputLabel>
                            <Select
                                name="e_birthday_year"
                                id="e_birthday_year"
                                value={formItems["e_birthday_year"]}
                                // value={this.props.renter.birthday_year}
                                onChange={(e) => onChange(e)}
                                label="年"
                                style={{backgroundColor: formItems["e_birthday_year"] ? '' : '#FFE6EA'}}
                                error={errorText["e_birthday_year"]}
                            >
                                {year.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_birthday_year"]}</Typography>
                        </FormControl>
                    </div>
                    <div className="col-md-2 col-4 pr-0">
                        <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                            <InputLabel>月</InputLabel>
                            <Select
                                name="e_birthday_month"
                                id="e_birthday_month"
                                value={formItems["e_birthday_month"]}
                                // value={this.props.renter.birthday_month}
                                onChange={(e) => onChange(e)}
                                label="月"
                                style={{backgroundColor: formItems["e_birthday_month"] ? '' : '#FFE6EA'}}
                                error={errorText["e_birthday_month"]}
                            >
                                {month.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_birthday_month"]}</Typography>
                        </FormControl>
                        {/* <span style={{verticalAlign:"bottom"}}>月</span> */}
                    </div>
                    <div className="col-md-2 col-4 pr-0">
                        <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                            <InputLabel>日</InputLabel>
                            <Select
                                name="e_birthday_day"
                                id="e_birthday_day"
                                value={formItems["e_birthday_day"]}
                                // value={this.props.renter.birthday_day}
                                onChange={(e) => onChange(e)}
                                label="日"
                                style={{backgroundColor: formItems["e_birthday_day"] ? '' : '#FFE6EA'}}
                                error={errorText["e_birthday_day"]}
                            >
                                {day.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_birthday_day"]}</Typography>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">性別</div>
            <div className="form-group col-md-9">
                <FormControl component="fieldset">
                <FormLabel component="legend" required={true} style={{fontSize: 14}}>性別</FormLabel>
                    <RadioGroup aria-label="gender" name="e_sex" value={formItems["e_sex"]} onChange={(e) => onChange(e)} row>
                    {/* <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row> */}
                        <FormControlLabel control={<Radio color="primary" value={"1"} />} label="男性" labelPlacement="end" />
                        <FormControlLabel control={<Radio color="primary" value={"0"} />} label="女性" labelPlacement="end" />
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">住所</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="e_zip"
                            name="e_zip"
                            label={"郵便番号"}
                            value={formItems["e_zip"]}
                            margin="dense"
                            variant="outlined"
                            required={true}
                            // value={this.props.renter.post}
                            style={{ width: "50%", backgroundColor: formItems["e_zip"] ? '' : '#FFE6EA' }}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                            placeholder="例）7610000"
                            error={errorText["e_zip"]}
                        />
                        <button style={{ marginLeft: "10px" }} className="btn btn-sm btn-success mt-3" type="button" onClick={() => handleSetAddress("e_zip")}>住所検索</button>
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_zip"]}</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="e_address"
                            name="e_address"
                            label={"住所"}
                            value={formItems["e_address"]}
                            margin="dense"
                            variant="outlined"
                            required={true}
                            // value={this.props.renter.add1}
                            style={{ width: "100%", backgroundColor: formItems["e_address"] ? '' : '#FFE6EA' }}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                            error={errorText["e_address"]}
                            // helperText={this.props.renter_key == 0 ? "お引越し前のご住所をご入力ください。" : ""}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_address"]}</Typography>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">TEL（携帯）</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="e_tel"
                            name="e_tel"
                            label="TEL"
                            value={formItems["e_tel"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%", backgroundColor: formItems["e_tel"] ? '' : '#FFE6EA'  }}
                            autoComplete='off'
                            // error={!this.props.renter.tel1}
                            // value={this.props.renter.tel1}
                            onChange={(e) => onChange(e)}
                            required={true}
                            placeholder="例）090-1234-5678"
                            helperText="半角数字のみで入力してください。"
                            error={errorText["e_tel"]}
                        />
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_tel"]}</Typography>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">契約者との続柄</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-sm-5">
                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                        {/* <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} error={!this.props.renter.rent_m_relation_id} required={true}> */}
                            <InputLabel>続柄</InputLabel>
                            <Select
                                name="e_relationship"
                                id="e_relationship"
                                value={formItems["e_relationship"]}
                                // value={parseInt(this.props.renter.rent_m_relation_id)}
                                onChange={(e) => onChange(e)}
                                label="続柄"
                                style={{backgroundColor: formItems["e_relationship"] ? '' : '#FFE6EA' }}
                                error={errorText["e_relationship"]}
                            >
                                {relationship.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_relationship"]}</Typography>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">住居</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-sm-5">
                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                            <InputLabel>住居</InputLabel>
                            <Select
                                name="e_myhome"
                                id="e_myhome"
                                value={formItems["e_myhome"]}
                                // value={parseInt(this.props.renter.rent_m_job_category_id)}
                                onChange={(e) => onChange(e)}
                                label="住居"
                                style={{backgroundColor: formItems["e_myhome"] ? '' : '#FFE6EA' }}
                                error={errorText["e_myhome"]}
                            >
                                {myHome.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_myhome"]}</Typography>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">勤続年数</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-2">
                        <TextField
                            id="e_work_years"
                            name="e_work_years"
                            label="勤務年数"
                            value={formItems["e_work_years"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%" }}
                            autoComplete='off'
                            // error={!this.props.renter.work_years}
                            // value={this.props.renter.work_years}
                            onChange={(e) => onChange(e)}
                            // required={true}
                        />
                    </div>
                    <span style={{alignSelf: 'end'}}>年</span>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">年収</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-2" style={{display: 'flex'}}>
                        <TextField
                            id="e_income"
                            name="e_income"
                            label="年収"
                            value={formItems["e_income"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%" }}
                            autoComplete='off'
                            // error={!this.props.renter.}
                            // value={this.props.renter.}
                            onChange={(e) => onChange(e)}
                            // required={true}
                        />
                    </div>
                    <span style={{alignSelf: 'end'}}>万円</span>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">勤務先名</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="e_office"
                            name="e_office"
                            label={"勤務先名"}
                            value={formItems["e_office"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%" }}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                            placeholder="正式名称を記入してください"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">勤務先住所</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="e_office_zip"
                            name="e_office_zip"
                            label={"郵便番号"}
                            value={formItems["e_office_zip"]}
                            margin="dense"
                            variant="outlined"
                            // value={this.props.renter.post}
                            style={{ width: "50%" }}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                            placeholder="例）7610000"
                        />
                        <button style={{ marginLeft: "10px" }} className="btn btn-sm btn-success mt-3" type="button" onClick={() => handleSetAddress("e_office_zip")}>住所検索</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="e_office_address"
                            name="e_office_address"
                            label="勤務先住所"
                            value={formItems["e_office_address"] ? formItems["e_office_address"] : ''}
                            margin="dense"
                            variant="outlined"
                            // value={this.props.renter.}
                            style={{width: "100%"}}
                            autoComplete='off'
                            onChange={(e) => onChange(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">勤務先TEL</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-md-10">
                        <TextField
                            id="e_office_tel"
                            name="e_office_tel"
                            label="勤務先TEL"
                            value={formItems["e_office_tel"]}
                            margin="dense"
                            variant="outlined"
                            style={{ width: "100%" }}
                            autoComplete='off'
                            // error={!this.props.renter.tel1}
                            // value={this.props.renter.office_tel}
                            onChange={(e) => onChange(e)}
                            // required={true}
                            placeholder="例）090-1234-5678"
                            helperText="半角数字のみで入力してください。"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">業種</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-sm-5">
                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} >
                            <InputLabel>業種</InputLabel>
                            <Select
                                name="e_job_category"
                                id="e_job_category"
                                value={formItems["e_job_category"]}
                                // value={parseInt(this.props.renter.rent_m_job_category_id)}
                                onChange={(e) => onChange(e)}
                                label="業種"
                            >
                                {jobCategory.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">雇用形態</div>
            <div className="col-md-9 form-group">
                <div className="row">
                    <div className="col-sm-5">
                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                            <InputLabel>雇用形態</InputLabel>
                            <Select
                                name="e_job_class"
                                id="e_job_class"
                                value={formItems["e_job_class"]}
                                // value={parseInt(this.props.renter.rent_m_job_category_id)}
                                onChange={(e) => onChange(e)}
                                label="雇用形態"
                                style={{backgroundColor: formItems["e_job_class"] ? '' : '#FFE6EA'}}
                                error={errorText["e_job_class"]}
                            >
                                {jobClass.map(d =>
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["e_job_class"]}</Typography>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>

        {renters >= 1 ? <div className="row">
            <div className="col-md-12"><h6>【入居者①】</h6></div>
            <div className="col-md-12 form-group">
                <div className="row">
                    <div className="col-md-3">氏名</div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name11"
                                    name="h_name11"
                                    label="姓（漢字）"
                                    value={formItems["h_name11"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name11}
                                    // error={!props.renter.h_name11}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name11"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name11"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name11"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name12"
                                    name="h_name12"
                                    label="名（漢字）"
                                    value={formItems["h_name12"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name12}
                                    // error={!props.renter.h_name12}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name12"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name12"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name12"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">フリガナ
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana11"
                                    name="h_kana11"
                                    label="姓（カナ）"
                                    value={formItems["h_kana11"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana11}
                                    // error={!props.renter.h_kana11}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana11"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana11"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana11"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana12"
                                    name="h_kana12"
                                    label="名（カナ）"
                                    value={formItems["h_kana12"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana12}
                                    // error={!props.renter.h_kana12}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana12"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana12"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana12"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Name props={this.props} handleChange={this.handleChange} /> */}
                <div className="row">
                    <div className="col-md-3">生年月日</div>
                    <div className="form-group col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-9 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>年</InputLabel>
                                    <Select
                                        name="h_birthday_year1"
                                        id="h_birthday_year1"
                                        value={formItems["h_birthday_year1"]}
                                        // value={this.props.renter.birthday_year}
                                        onChange={(e) => onChange(e)}
                                        label="年"
                                        style={{backgroundColor: formItems["h_birthday_year1"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_year1"]}
                                    >
                                        {year.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_year1"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>月</InputLabel>
                                    <Select
                                        name="h_birthday_month1"
                                        id="h_birthday_month1"
                                        value={formItems["h_birthday_month1"]}
                                        // value={this.props.renter.birthday_month}
                                        onChange={(e) => onChange(e)}
                                        label="月"
                                        style={{backgroundColor: formItems["h_birthday_month1"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_month1"]}
                                    >
                                        {month.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_month1"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>日</InputLabel>
                                    <Select
                                        name="h_birthday_day1"
                                        id="h_birthday_day1"
                                        value={formItems["h_birthday_day1"]}
                                        // value={this.props.renter.birthday_day}
                                        onChange={(e) => onChange(e)}
                                        label="日"
                                        style={{backgroundColor: formItems["h_birthday_day1"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_day1"]}
                                    >
                                        {day.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_day1"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">性別</div>
                    <div className="form-group col-md-9">
                        <FormControl component="fieldset">
                        <FormLabel component="legend" required={true} style={{fontSize: 14}}>性別</FormLabel>
                            <RadioGroup aria-label="gender" name="h_sex1" value={formItems["h_sex1"]} onChange={(e) => onChange(e)} style={{backgroundColor: formItems["h_sex1"] ? '' : '#FFE6EA'}} row>
                            {/* <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row> */}
                                <FormControlLabel control={<Radio color="primary" value={"1"} />} label="男性" labelPlacement="end" />
                                <FormControlLabel control={<Radio color="primary" value={"0"} />} label="女性" labelPlacement="end" />
                            </RadioGroup>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_sex1"]}</Typography>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">続柄</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-sm-5">
                                <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                                {/* <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} error={!this.props.renter.rent_m_relation_id} required={true}> */}
                                    <InputLabel>続柄</InputLabel>
                                    <Select
                                        name="h_relationship1"
                                        id="h_relationship1"
                                        value={formItems["h_relationship1"]}
                                        // value={parseInt(this.props.renter.rent_m_relation_id)}
                                        onChange={(e) => onChange(e)}
                                        label="続柄"
                                        style={{backgroundColor: formItems["h_relationship1"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_relationship1"]}
                                    >
                                        {relationship.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_relationship1"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">TEL（携帯）</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_tel1"
                                    name="h_tel1"
                                    label="TEL"
                                    value={formItems["h_tel1"]}
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%", backgroundColor: formItems["h_tel1"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    // error={!this.props.renter.tel1}
                                    // value={this.props.renter.tel1}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="例）090-1234-5678"
                                    helperText="半角数字のみで入力してください。"
                                    error={errorText["h_tel1"]}
                                />
                            </div>
                        </div>
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_tel1"]}</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">勤務先/学校名</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_work1"
                                    name="h_work1"
                                    label={"勤務先/学校名"}
                                    value={formItems["h_work1"]}
                                    margin="dense"
                                    variant="outlined"
                                    // value={this.props.renter.office_name}
                                    style={{ width: "100%", backgroundColor: formItems["h_work1"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="正式名称を記入してください"
                                    error={errorText["h_work1"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_work1"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ml-3'>
                <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>
            </div>
        </div>:""}
        {renters >= 2 ? <div className="row">
            <div className="col-md-12"><h6>【入居者②】</h6></div>
            <div className="col-md-12 form-group">
                <div className="row">
                    <div className="col-md-3">氏名</div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name21"
                                    name="h_name21"
                                    label="姓（漢字）"
                                    value={formItems["h_name21"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name21}
                                    // error={!props.renter.h_name21}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name21"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name21"]}
                                    />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name21"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name22"
                                    name="h_name22"
                                    label="名（漢字）"
                                    value={formItems["h_name22"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name22}
                                    // error={!props.renter.h_name22}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name22"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name22"]}
                                    />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name22"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">フリガナ
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana21"
                                    name="h_kana21"
                                    label="姓（カナ）"
                                    value={formItems["h_kana21"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana21}
                                    // error={!props.renter.h_kana21}
                                    // onChange={(e) => change_name(e, props)}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana21"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana21"]}
                                    />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana21"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana22"
                                    name="h_kana22"
                                    label="名（カナ）"
                                    value={formItems["h_kana22"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana22}
                                    // error={!props.renter.h_kana22}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana22"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana22"]}
                                    />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana22"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Name props={this.props} handleChange={this.handleChange} /> */}
                <div className="row">
                    <div className="col-md-3">生年月日</div>
                    <div className="form-group col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-9 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>年</InputLabel>
                                    <Select
                                        name="h_birthday_year2"
                                        id="h_birthday_year2"
                                        value={formItems["h_birthday_year2"]}
                                        // value={this.props.renter.birthday_year}
                                        onChange={(e) => onChange(e)}
                                        label="年"
                                        style={{backgroundColor: formItems["h_birthday_year2"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_year2"]}
                                    >
                                        {year.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_year2"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>月</InputLabel>
                                    <Select
                                        name="h_birthday_month2"
                                        id="h_birthday_month2"
                                        value={formItems["h_birthday_month2"]}
                                        // value={this.props.renter.birthday_month}
                                        onChange={(e) => onChange(e)}
                                        label="月"
                                        style={{backgroundColor: formItems["h_birthday_month2"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_month2"]}
                                    >
                                        {month.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_month2"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>日</InputLabel>
                                    <Select
                                        name="h_birthday_day2"
                                        id="h_birthday_day2"
                                        value={formItems["h_birthday_day2"]}
                                        // value={this.props.renter.birthday_day}
                                        onChange={(e) => onChange(e)}
                                        label="日"
                                        style={{backgroundColor: formItems["h_birthday_day2"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_day2"]}
                                    >
                                        {day.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_day2"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">性別</div>
                    <div className="form-group col-md-9">
                        <FormControl component="fieldset">
                        <FormLabel component="legend" required={true} style={{fontSize: 14}}>性別</FormLabel>
                            <RadioGroup aria-label="gender" name="h_sex2" value={formItems["h_sex2"]} onChange={(e) => onChange(e)} style={{backgroundColor: formItems["h_sex2"] ? '' : '#FFE6EA'}} row>
                            {/* <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row> */}
                                <FormControlLabel control={<Radio color="primary" value={"1"} />} label="男性" labelPlacement="end" />
                                <FormControlLabel control={<Radio color="primary" value={"0"} />} label="女性" labelPlacement="end" />
                            </RadioGroup>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_sex2"]}</Typography>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">続柄</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-sm-5">
                                <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                                {/* <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} error={!this.props.renter.rent_m_relation_id} required={true}> */}
                                    <InputLabel>続柄</InputLabel>
                                    <Select
                                        name="h_relationship2"
                                        id="h_relationship2"
                                        value={formItems["h_relationship2"]}
                                        // value={parseInt(this.props.renter.rent_m_relation_id)}
                                        onChange={(e) => onChange(e)}
                                        label="続柄"
                                        style={{backgroundColor: formItems["h_relationship2"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_relationship2"]}
                                    >
                                        {relationship.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_relationship2"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">TEL（携帯）</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_tel2"
                                    name="h_tel2"
                                    label="TEL"
                                    value={formItems["h_tel2"]}
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%", backgroundColor: formItems["h_tel2"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    // error={!this.props.renter.tel1}
                                    // value={this.props.renter.tel1}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="例）090-1234-5678"
                                    helperText="半角数字のみで入力してください。"
                                    error={errorText["h_tel2"]}
                                />
                            </div>
                        </div>
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_tel2"]}</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">勤務先/学校名</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_work2"
                                    name="h_work2"
                                    label={"勤務先/学校名"}
                                    value={formItems["h_work2"]}
                                    margin="dense"
                                    variant="outlined"
                                    // value={this.props.renter.office_name}
                                    style={{ width: "100%", backgroundColor: formItems["h_work2"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="正式名称を記入してください"
                                    error={errorText["h_work2"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_work2"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ml-3'>
                <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>
            </div>
        </div>:""}
        {renters >= 3 ? <div className="row">
            <div className="col-md-12"><h6>【入居者③】</h6></div>
            <div className="col-md-12 form-group">
                <div className="row">
                    <div className="col-md-3">氏名</div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name31"
                                    name="h_name31"
                                    label="姓（漢字）"
                                    value={formItems["h_name31"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name31}
                                    // error={!props.renter.h_name31}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name31"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name31"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name31"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name32"
                                    name="h_name32"
                                    label="名（漢字）"
                                    value={formItems["h_name32"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name32}
                                    // error={!props.renter.h_name32}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name32"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name32"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name32"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">フリガナ
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana31"
                                    name="h_kana31"
                                    label="姓（カナ）"
                                    value={formItems["h_kana31"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana31}
                                    // error={!props.renter.h_kana31}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana31"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana31"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana31"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana32"
                                    name="h_kana32"
                                    label="名（カナ）"
                                    value={formItems["h_kana32"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana32}
                                    // error={!props.renter.h_kana32}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana32"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana32"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana32"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Name props={this.props} handleChange={this.handleChange} /> */}
                <div className="row">
                    <div className="col-md-3">生年月日</div>
                    <div className="form-group col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-9 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>年</InputLabel>
                                    <Select
                                        name="h_birthday_year3"
                                        id="h_birthday_year3"
                                        value={formItems["h_birthday_year3"]}
                                        // value={this.props.renter.birthday_year}
                                        onChange={(e) => onChange(e)}
                                        label="年"
                                        style={{backgroundColor: formItems["h_birthday_year3"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_year3"]}
                                    >
                                        {year.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_year3"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>月</InputLabel>
                                    <Select
                                        name="h_birthday_month3"
                                        id="h_birthday_month3"
                                        value={formItems["h_birthday_month3"]}
                                        // value={this.props.renter.birthday_month}
                                        onChange={(e) => onChange(e)}
                                        label="月"
                                        style={{backgroundColor: formItems["h_birthday_month3"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_month3"]}
                                    >
                                        {month.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_month3"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>日</InputLabel>
                                    <Select
                                        name="h_birthday_day3"
                                        id="h_birthday_day3"
                                        value={formItems["h_birthday_day3"]}
                                        // value={this.props.renter.birthday_day}
                                        onChange={(e) => onChange(e)}
                                        label="日"
                                        style={{backgroundColor: formItems["h_birthday_day3"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_day3"]}
                                    >
                                        {day.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_day3"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">性別</div>
                    <div className="form-group col-md-9">
                        <FormControl component="fieldset">
                        <FormLabel component="legend" required={true} style={{fontSize: 14}}>性別</FormLabel>
                            <RadioGroup aria-label="gender" name="h_sex3" value={formItems["h_sex3"]} onChange={(e) => onChange(e)} style={{backgroundColor: formItems["h_sex3"] ? '' : '#FFE6EA'}} row>
                            {/* <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row> */}
                                <FormControlLabel control={<Radio color="primary" value={"1"} />} label="男性" labelPlacement="end" />
                                <FormControlLabel control={<Radio color="primary" value={"0"} />} label="女性" labelPlacement="end" />
                            </RadioGroup>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_sex3"]}</Typography>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">続柄</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-sm-5">
                                <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                                {/* <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} error={!this.props.renter.rent_m_relation_id} required={true}> */}
                                    <InputLabel>続柄</InputLabel>
                                    <Select
                                        name="h_relationship3"
                                        id="h_relationship3"
                                        value={formItems["h_relationship3"]}
                                        // value={parseInt(this.props.renter.rent_m_relation_id)}
                                        onChange={(e) => onChange(e)}
                                        label="続柄"
                                        style={{backgroundColor: formItems["h_relationship3"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_relationship3"]}
                                    >
                                        {relationship.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_relationship3"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">TEL（携帯）</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_tel3"
                                    name="h_tel3"
                                    label="TEL"
                                    value={formItems["h_tel3"]}
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%", backgroundColor: formItems["h_tel3"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    // error={!this.props.renter.tel1}
                                    // value={this.props.renter.tel1}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="例）090-1234-5678"
                                    helperText="半角数字のみで入力してください。"
                                    error={errorText["h_tel3"]}
                                />
                            </div>
                        </div>
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_tel3"]}</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">勤務先/学校名</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_work3"
                                    name="h_work3"
                                    label={"勤務先/学校名"}
                                    value={formItems["h_work3"]}
                                    margin="dense"
                                    variant="outlined"
                                    // value={this.props.renter.office_name}
                                    style={{ width: "100%", backgroundColor: formItems["h_work3"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="正式名称を記入してください"
                                    error={errorText["h_work3"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_work3"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ml-3'>
                <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>
            </div>
        </div>:""}
        {renters >= 4 ? <div className="row">
        <div className="col-md-12"><h6>【入居者④】</h6></div>
            <div className="col-md-12 form-group">
                <div className="row">
                    <div className="col-md-3">氏名</div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name41"
                                    name="h_name41"
                                    label="姓（漢字）"
                                    value={formItems["h_name41"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name41}
                                    // error={!props.renter.h_name41}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name41"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name41"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name41"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name42"
                                    name="h_name42"
                                    label="名（漢字）"
                                    value={formItems["h_name42"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name42}
                                    // error={!props.renter.h_name42}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name42"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name42"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name42"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">フリガナ
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana41"
                                    name="h_kana41"
                                    label="姓（カナ）"
                                    value={formItems["h_kana41"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana41}
                                    // error={!props.renter.h_kana41}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana41"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana41"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana41"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana42"
                                    name="h_kana42"
                                    label="名（カナ）"
                                    value={formItems["h_kana42"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana42}
                                    // error={!props.renter.h_kana42}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana42"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana42"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana42"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Name props={this.props} handleChange={this.handleChange} /> */}
                <div className="row">
                    <div className="col-md-3">生年月日</div>
                    <div className="form-group col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-9 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>年</InputLabel>
                                    <Select
                                        name="h_birthday_year4"
                                        id="h_birthday_year4"
                                        value={formItems["h_birthday_year4"]}
                                        // value={this.props.renter.birthday_year}
                                        onChange={(e) => onChange(e)}
                                        label="年"
                                        style={{backgroundColor: formItems["h_birthday_year4"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_year4"]}
                                    >
                                        {year.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_year4"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>月</InputLabel>
                                    <Select
                                        name="h_birthday_month4"
                                        id="h_birthday_month4"
                                        value={formItems["h_birthday_month4"]}
                                        // value={this.props.renter.birthday_month}
                                        onChange={(e) => onChange(e)}
                                        label="月"
                                        style={{backgroundColor: formItems["h_birthday_month4"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_month4"]}
                                    >
                                        {month.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_month4"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>日</InputLabel>
                                    <Select
                                        name="h_birthday_day4"
                                        id="h_birthday_day4"
                                        value={formItems["h_birthday_day4"]}
                                        // value={this.props.renter.birthday_day}
                                        onChange={(e) => onChange(e)}
                                        label="日"
                                        style={{backgroundColor: formItems["h_birthday_day4"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_day4"]}
                                    >
                                        {day.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_day4"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">性別</div>
                    <div className="form-group col-md-9">
                        <FormControl component="fieldset">
                        <FormLabel component="legend" required={true} style={{fontSize: 14}}>性別</FormLabel>
                            <RadioGroup aria-label="gender" name="h_sex4" value={formItems["h_sex4"]} onChange={(e) => onChange(e)} style={{backgroundColor: formItems["h_sex4"] ? '' : '#FFE6EA'}} row>
                            {/* <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row> */}
                                <FormControlLabel control={<Radio color="primary" value={"1"} />} label="男性" labelPlacement="end" />
                                <FormControlLabel control={<Radio color="primary" value={"0"} />} label="女性" labelPlacement="end" />
                            </RadioGroup>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_sex4"]}</Typography>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">続柄</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-sm-5">
                                <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                                {/* <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} error={!this.props.renter.rent_m_relation_id} required={true}> */}
                                    <InputLabel>続柄</InputLabel>
                                    <Select
                                        name="h_relationship4"
                                        id="h_relationship4"
                                        value={formItems["h_relationship4"]}
                                        // value={parseInt(this.props.renter.rent_m_relation_id)}
                                        onChange={(e) => onChange(e)}
                                        label="続柄"
                                        style={{backgroundColor: formItems["h_relationship4"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_relationship4"]}
                                    >
                                        {relationship.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_relationship4"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">TEL（携帯）</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_tel4"
                                    name="h_tel4"
                                    label="TEL"
                                    value={formItems["h_tel4"]}
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%", backgroundColor: formItems["h_tel4"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    // error={!this.props.renter.tel4}
                                    // value={this.props.renter.tel4}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="例）090-1234-5678"
                                    helperText="半角数字のみで入力してください。"
                                    error={errorText["h_tel4"]}
                                />
                            </div>
                        </div>
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_tel4"]}</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">勤務先/学校名</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_work4"
                                    name="h_work4"
                                    label={"勤務先/学校名"}
                                    value={formItems["h_work4"]}
                                    margin="dense"
                                    variant="outlined"
                                    // value={this.props.renter.office_name}
                                    style={{ width: "100%", backgroundColor: formItems["h_work4"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="正式名称を記入してください"
                                    error={errorText["h_work4"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_work4"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ml-3'>
                <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>
            </div>
        </div>:""}
        {renters >= 5 ? <div className="row">
        <div className="col-md-12"><h6>【入居者⑤】</h6></div>
            <div className="col-md-12 form-group">
                <div className="row">
                    <div className="col-md-3">氏名</div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name51"
                                    name="h_name51"
                                    label="姓（漢字）"
                                    value={formItems["h_name51"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name51}
                                    // error={!props.renter.h_name51}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name51"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name51"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name51"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name52"
                                    name="h_name52"
                                    label="名（漢字）"
                                    value={formItems["h_name52"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name52}
                                    // error={!props.renter.h_name52}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name52"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name52"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name52"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">フリガナ
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana51"
                                    name="h_kana51"
                                    label="姓（カナ）"
                                    value={formItems["h_kana51"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana51}
                                    // error={!props.renter.h_kana51}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana51"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana51"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana51"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana52"
                                    name="h_kana52"
                                    label="名（カナ）"
                                    value={formItems["h_kana52"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana52}
                                    // error={!props.renter.h_kana52}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana52"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana52"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana52"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Name props={this.props} handleChange={this.handleChange} /> */}
                <div className="row">
                    <div className="col-md-3">生年月日</div>
                    <div className="form-group col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-9 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>年</InputLabel>
                                    <Select
                                        name="h_birthday_year5"
                                        id="h_birthday_year5"
                                        value={formItems["h_birthday_year5"]}
                                        // value={this.props.renter.birthday_year}
                                        onChange={(e) => onChange(e)}
                                        label="年"
                                        style={{backgroundColor: formItems["h_birthday_year5"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_year5"]}
                                    >
                                        {year.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_year5"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>月</InputLabel>
                                    <Select
                                        name="h_birthday_month5"
                                        id="h_birthday_month5"
                                        value={formItems["h_birthday_month5"]}
                                        // value={this.props.renter.birthday_month}
                                        onChange={(e) => onChange(e)}
                                        label="月"
                                        style={{backgroundColor: formItems["h_birthday_month5"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_month5"]}
                                    >
                                        {month.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_month5"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>日</InputLabel>
                                    <Select
                                        name="h_birthday_day5"
                                        id="h_birthday_day5"
                                        value={formItems["h_birthday_day5"]}
                                        // value={this.props.renter.birthday_day}
                                        onChange={(e) => onChange(e)}
                                        label="日"
                                        style={{backgroundColor: formItems["h_birthday_day5"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_day5"]}
                                    >
                                        {day.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_day5"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">性別</div>
                    <div className="form-group col-md-9">
                        <FormControl component="fieldset">
                        <FormLabel component="legend" required={true} style={{fontSize: 14}}>性別</FormLabel>
                            <RadioGroup aria-label="gender" name="h_sex5" value={formItems["h_sex5"]} onChange={(e) => onChange(e)} style={{backgroundColor: formItems["h_sex5"] ? '' : '#FFE6EA'}} row>
                            {/* <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row> */}
                                <FormControlLabel control={<Radio color="primary" value={"1"} />} label="男性" labelPlacement="end" />
                                <FormControlLabel control={<Radio color="primary" value={"0"} />} label="女性" labelPlacement="end" />
                            </RadioGroup>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_sex5"]}</Typography>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">続柄</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-sm-5">
                                <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                                {/* <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} error={!this.props.renter.rent_m_relation_id} required={true}> */}
                                    <InputLabel>続柄</InputLabel>
                                    <Select
                                        name="h_relationship5"
                                        id="h_relationship5"
                                        value={formItems["h_relationship5"]}
                                        // value={parseInt(this.props.renter.rent_m_relation_id)}
                                        onChange={(e) => onChange(e)}
                                        label="続柄"
                                        style={{backgroundColor: formItems["h_relationship5"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_relationship5"]}
                                    >
                                        {relationship.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_relationship5"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">TEL（携帯）</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_tel5"
                                    name="h_tel5"
                                    label="TEL"
                                    value={formItems["h_tel5"]}
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%", backgroundColor: formItems["h_tel5"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    // error={!this.props.renter.tel1}
                                    // value={this.props.renter.tel1}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="例）090-1234-5678"
                                    helperText="半角数字のみで入力してください。"
                                    error={errorText["h_tel5"]}
                                />
                            </div>
                        </div>
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_tel5"]}</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">勤務先/学校名</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_work5"
                                    name="h_work5"
                                    label={"勤務先/学校名"}
                                    value={formItems["h_work5"]}
                                    margin="dense"
                                    variant="outlined"
                                    // value={this.props.renter.office_name}
                                    style={{ width: "100%", backgroundColor: formItems["h_work5"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="正式名称を記入してください"
                                    error={errorText["h_work5"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_work5"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ml-3'>
                <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>
            </div>
        </div>:""}
        {renters >= 6 ? <div className="row">
        <div className="col-md-12"><h6>【入居者⑥】</h6></div>
            <div className="col-md-12 form-group">
                <div className="row">
                    <div className="col-md-3">氏名</div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name61"
                                    name="h_name61"
                                    label="姓（漢字）"
                                    value={formItems["h_name61"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name61}
                                    // error={!props.renter.h_name61}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name61"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name61"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name61"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_name62"
                                    name="h_name62"
                                    label="名（漢字）"
                                    value={formItems["h_name62"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_name62}
                                    // error={!props.renter.h_name62}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_name62"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_name62"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_name62"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">フリガナ
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana61"
                                    name="h_kana61"
                                    label="姓（カナ）"
                                    value={formItems["h_kana61"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana61}
                                    // error={!props.renter.h_kana61}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana61"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana61"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana61"]}</Typography>
                            </div>
                            <div className="col-md-4 col-5 form-group">
                                <TextField
                                    id="h_kana62"
                                    name="h_kana62"
                                    label="名（カナ）"
                                    value={formItems["h_kana62"]}
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete='off'
                                    // value={props.renter.h_kana62}
                                    // error={!props.renter.h_kana62}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{backgroundColor: formItems["h_kana62"] ? '' : '#FFE6EA'}}
                                    error={errorText["h_kana62"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_kana62"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Name props={this.props} handleChange={this.handleChange} /> */}
                <div className="row">
                    <div className="col-md-3">生年月日</div>
                    <div className="form-group col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-9 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>年</InputLabel>
                                    <Select
                                        name="h_birthday_year6"
                                        id="h_birthday_year6"
                                        value={formItems["h_birthday_year6"]}
                                        // value={this.props.renter.birthday_year}
                                        onChange={(e) => onChange(e)}
                                        label="年"
                                        style={{backgroundColor: formItems["h_birthday_year6"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_year6"]}
                                    >
                                        {year.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_year6"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>月</InputLabel>
                                    <Select
                                        name="h_birthday_month6"
                                        id="h_birthday_month6"
                                        value={formItems["h_birthday_month6"]}
                                        // value={this.props.renter.birthday_month}
                                        onChange={(e) => onChange(e)}
                                        label="月"
                                        style={{backgroundColor: formItems["h_birthday_month6"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_month6"]}
                                    >
                                        {month.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_month6"]}</Typography>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }} required={true}>
                                    <InputLabel>日</InputLabel>
                                    <Select
                                        name="h_birthday_day6"
                                        id="h_birthday_day6"
                                        value={formItems["h_birthday_day6"]}
                                        // value={this.props.renter.birthday_day}
                                        onChange={(e) => onChange(e)}
                                        label="日"
                                        style={{backgroundColor: formItems["h_birthday_day6"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_birthday_day6"]}
                                    >
                                        {day.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_birthday_day6"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">性別</div>
                    <div className="form-group col-md-9">
                        <FormControl component="fieldset">
                        <FormLabel component="legend" required={true} style={{fontSize: 14}}>性別</FormLabel>
                            <RadioGroup aria-label="gender" name="h_sex6" value={formItems["h_sex6"]} onChange={(e) => onChange(e)} style={{backgroundColor: formItems["h_sex6"] ? '' : '#FFE6EA'}} row>
                            {/* <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row> */}
                                <FormControlLabel control={<Radio color="primary" value={"1"} />} label="男性" labelPlacement="end" />
                                <FormControlLabel control={<Radio color="primary" value={"0"} />} label="女性" labelPlacement="end" />
                            </RadioGroup>
                            <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_sex6"]}</Typography>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">続柄</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-sm-5">
                                <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} required={true}>
                                {/* <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} error={!this.props.renter.rent_m_relation_id} required={true}> */}
                                    <InputLabel>続柄</InputLabel>
                                    <Select
                                        name="h_relationship6"
                                        id="h_relationship6"
                                        value={formItems["h_relationship6"]}
                                        // value={parseInt(this.props.renter.rent_m_relation_id)}
                                        onChange={(e) => onChange(e)}
                                        label="続柄"
                                        style={{backgroundColor: formItems["h_relationship6"] ? '' : '#FFE6EA'}}
                                        error={errorText["h_relationship6"]}
                                    >
                                        {relationship.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_relationship6"]}</Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">TEL（携帯）</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_tel6"
                                    name="h_tel6"
                                    label="TEL"
                                    value={formItems["h_tel6"]}
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%", backgroundColor: formItems["h_tel6"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    // error={!this.props.renter.tel6}
                                    // value={this.props.renter.tel6}
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="例）090-1234-5678"
                                    helperText="半角数字のみで入力してください。"
                                    error={errorText["h_tel6"]}
                                />
                            </div>
                        </div>
                        <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_tel6"]}</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">勤務先/学校名</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="h_work6"
                                    name="h_work6"
                                    label={"勤務先/学校名"}
                                    value={formItems["h_work6"]}
                                    margin="dense"
                                    variant="outlined"
                                    // value={this.props.renter.office_name}
                                    style={{ width: "100%", backgroundColor: formItems["h_work6"] ? '' : '#FFE6EA' }}
                                    autoComplete='off'
                                    onChange={(e) => onChange(e)}
                                    required={true}
                                    placeholder="正式名称を記入してください"
                                    error={errorText["h_work6"]}
                                />
                                <Typography style={{color: 'red', fontSize: 12}}>{errorText["h_work6"]}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ml-3'>
                <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>
            </div>
        </div>:""}

        <div className="row">
            <div className="col-md-12"><h6>【ペット】</h6></div>
            <div className="col-md-12 form-group">
                <div className="row">
                    <div className="col-md-3">ペット</div>
                    <div className="form-group col-md-9">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="pets" name="pets" onChange={(e) => onChangePets(e)} value={formItems["pets"]} row>
                            {/* <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row> */}
                                <FormControlLabel control={<Radio color="primary" value={"0"} />} label="なし" labelPlacement="end" />
                                <FormControlLabel control={<Radio color="primary" value={"1"} />} label="あり" labelPlacement="end" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                {pets == 1 ? <div>
                    <div className="row">
                        <div className="col-md-3">犬飼育</div>
                        <div className="col-md-9 form-group">
                            <div className="row">
                                <div className="col-sm-5">
                                    <FormControlLabel control={<Checkbox name="dog" checked={formItems["dog"]} onChange={(e) => onChangeDogs(e)} style={{transform: "scale(1.0)"}} color="primary" />} label="犬"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {dogs ? <div> 
                    <div className="row">
                        <div className="col-md-3">犬飼育匹数</div>
                        <div className="col-md-9 form-group">
                            <div className="row">
                                <div className="col-md-3">
                                    <TextField
                                        id="dog_count"
                                        name="dog_count"
                                        label="犬飼育匹数"
                                        margin="dense"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        autoComplete='off'
                                        value={formItems["dog_count"]}
                                        // error={!this.props.renter.dog_count}
                                        // value={this.props.renter.dog_count}
                                        onChange={(e) => onChange(e)}
                                        // required={true}
                                    />
                                </div>
                                <span style={{alignSelf: 'end'}}>匹</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">犬備考(種類、大きさ)</div>
                        <div className="col-md-9 form-group">
                            <div className="row">
                                <div className="col-md-10">
                                    <TextField
                                        id="dog_remarks"
                                        name="dog_remarks"
                                        label="犬備考(種類、大きさ)"
                                        margin="dense"
                                        variant="outlined"
                                        value={formItems["dog_remarks"]}
                                        // value={this.props.renter.dog_remarks}
                                        style={{ width: "100%" }}
                                        autoComplete='off'
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>:""}
                    <div className="row">
                        <div className="col-md-3">猫飼育</div>
                        <div className="col-md-9 form-group">
                            <div className="row">
                                <div className="col-sm-5">
                                    <FormControlLabel control={<Checkbox name="cat" checked={formItems["cat"]} onChange={(e) => onChangeCats(e)} style={{transform: "scale(1.0)"}} color="primary" />} label="猫"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {cats ? <div>
                    <div className="row">
                        <div className="col-md-3">猫飼育匹数</div>
                        <div className="col-md-9 form-group">
                            <div className="row">
                                <div className="col-md-3">
                                    <TextField
                                        id="cat_count"
                                        name="cat_count"
                                        label="猫飼育匹数"
                                        margin="dense"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        autoComplete='off'
                                        value={formItems["cat_count"]}
                                        // error={!this.props.renter.cat_count}
                                        // value={this.props.renter.cat_count}
                                        onChange={(e) => onChange(e)}
                                        // required={true}
                                    />
                                </div>
                                <span style={{alignSelf: 'end'}}>匹</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">猫備考(種類、大きさ)</div>
                        <div className="col-md-9 form-group">
                            <div className="row">
                                <div className="col-md-10">
                                    <TextField
                                        id="cat_remarks"
                                        name="cat_remarks"
                                        label="猫備考(種類、大きさ)"
                                        margin="dense"
                                        variant="outlined"
                                        value={formItems["cat_remarks"]}
                                        // value={this.props.renter.cat_remarks}
                                        style={{ width: "100%" }}
                                        autoComplete='off'
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>:""}
                </div>:""}
                <button className="btn btn-sm mb-3 border" type="button">下書き保存</button>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12"><h6>【必要書類】</h6></div>
        </div>
        <div>※ご契約者様の免許証の両面、保険証の両面、その他の画像またはファイルをアップしてください。</div>
        <div className="row">
            <button type='button' id="upload_chat_button" className="btn btn-small btn-inverse btn-success mt-3 mb-5" style={{marginLeft: 12}}>
                画像またはファイルを選択してください
            </button>
        </div>

        <div className="row" style={{justifyContent: 'center'}}>
            <button type='button' className="border border-dark" style={{padding:20, paddingLeft: 50, paddingRight: 50}} onClick={() => onClick()}>
                この内容で確認画面へ進む
            </button>
        </div>
        <div className="row pb-5" style={{justifyContent: 'center'}}>
            <Typography style={{color: 'red'}}>{errorFlg ? "※必須入力項目で入力されていない箇所があります。入力内容を確認してください。" : ""}</Typography>
        </div>



      {/* <ul>
        {list.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
      <button onClick={addToList}>項目を追加</button> */}
    </div>
  );
}



export default ApplyFormComponent;