/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
//import Select from 'react-select';
import { connect } from 'react-redux';
// import { Button, Col, Row, Container,
//     Card, CardBody, CardImg, CardTitle, CardImgOverlay,
//     CardText, Alert } from 'reactstrap';
import * as Coming from './Saga/Coming';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import { actionCreators } from '../../Store/MailData';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
//import DatePicker from "react-datepicker";
//import DatePicker from 'react-ja-date-picker'
import "react-datepicker/dist/react-datepicker.css";
import Select from '@material-ui/core/Select';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
//import { actionCreators } from '../../Store/WeatherForecasts';
import { } from 'redux-form';

// import { Field, reduxForm } from 'redux-form';
// import '../../Css/Table.css';
import './Css/Coming.css';
// import { blue } from '@material-ui/core/colors';
// import { thisExpression } from '@babel/types';
//import title from '../../Images/bg-slash-green.png';
import stationLine from './Img/stationLine.jpg';

// import * as AutoKana from 'vanilla-autokana';
let autokana1;


// const useStyles = makeStyles(theme => ({
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 120,
//     },
// }));
// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
// const validate = (values) => {

//     // 検証に失敗したら values と同じキーで
//     // エラーメッセージをセットする。
//     const errors = {};
    
//     if (!values.name1) {
//         errors.name1 = "姓を入力してください。";
//     }
//     if (!values.name2) {
//         errors.name2 = "名を入力してください。";
//     }
//     if (!values.kana1) {
//         errors.kana1 = "フリガナ(姓)を入力してください。";
//     }
//     if (!values.kana2) {
//         errors.kana2 = "フリガナ(名)を入力してください。";
//     }
//     if (!values.address1) {
//         errors.address1 = "住所を入力してください。";
//     }
//     if (!values.tel2) {
//         errors.tel2 = "携帯番号を入力してください。";
//     }

//     if (values.post) {
//         if (!values.zip1.match(/^\d{3}$/)) {
//             errors.zip1 = "郵便番号の形式が正しくありません。";
//         }
//     }
//     if (values.post) {
//         if (!values.zip2.match(/^\d{4}$/)) {
//             errors.zip2 = "郵便番号の形式が正しくありません。";
//         }
//     }

//     if (values.tel1) {
//         if (!values.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel1.match(/^\d{7,13}$/)) {
//             errors.tel1 = "電話番号の形式が正しくありません。";
//         }
//     }
//     if (values.tel2) {
//         if (!values.tel2.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel2.match(/^\d{7,13}$/)) {
//             errors.tel2 = "携帯番号の形式が正しくありません。";
//         }
//     }
//     if (values.fax) {
//         if (!values.fax.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.fax.match(/^\d{7,13}$/)) {
//             errors.fax = "FAXの形式が正しくありません。";
//         }
//     }
//     if (values.email) {
//         if (!values.email.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
//             errors.email = "メールアドレスの形式が正しくありません。";
//         }
//     }

//     return errors;
// };

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, onKeyDown, style,meta: { touched, error } }) => (
//     <div>
//         <input {...input} type={type} id={id} onKeyDown={onKeyDown} placeholder={placeholder} style={style} className={(touched && error) || ((input.name==="name1"||input.name==="name2"||input.name==="kana1"||input.name==="kana2"||input.name==="address1"||input.name==="tel2") && !touched) ? 'alert-danger ' + className : className}/>
//         {touched && error && <div className="text-danger">{error}</div>}
//     </div>
// );



export class RenderField extends React.Component {
    render() {
        const { className, type, style, input, disabled, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};




class ComingFormComponent extends Component {
   
    constructor(props) {
        super(props);
      console.log(props);

        //お引越し時期
        this.movingTimeData = [
            {value:"", label:"選択してください"},
            {value:"1", label:"今月中"},
            {value:"2", label:"来月中"},
            {value:"3", label:"2ヶ月後"},
            {value:"4", label:"3ヶ月後"},
            {value:"5", label:"4ヶ月後"},
            {value:"6", label:"5ヶ月後"},
            {value:"7", label:"半年後"},
            {value:"8", label:"1年以内"},
        ];

        //学校名
        this.schoolData = [
            {value:"", label:"選択してください"},
            {value:"1", label:"香川大学　その他"},
            {value:"2", label:"その他"},
            {value:"3", label:"香川大学　経済学部"},
            {value:"4", label:"香川大学　教育学部"},
            {value:"5", label:"香川大学　工学部"},
            {value:"6", label:"香川大学　医学部"},
            {value:"7", label:"香川大学　農学部"},
            {value:"8", label:"高松大学"},
            {value:"9", label:"穴吹カレッジ"},
            {value:"10", label:"徳島文理大学"},
            {value:"11", label:"保健医療大学"},
            {value:"12", label:"香川大学　法学部　"},
            {value:"13", label:"四国医療福祉専門学校"},
        ]

        //学年
        this.gakunenData = [
            {value:"", label:"選択してください"},
            {value:"7", label:"新入学(予約)"},
            {value:"1", label:"新入学(合格済)"},
            {value:"2", label:"１回生"},
            {value:"3", label:"２回生"},
            {value:"4", label:"３回生"},
            {value:"5", label:"４回生"},            
            {value:"6", label:"院生"},            
        ]

        //お引越し理由
        this.moveReasonData = [
            {value:"", label:"選択してください"},
            {value:"8", label:"結婚"},
            {value:"7", label:"転勤"},
            {value:"6", label:"入社"},
            {value:"4", label:"入学"},
            {value:"2", label:"独立"},
            {value:"11", label:"家賃を抑えたい"},
            {value:"12", label:"手狭になった"},
            {value:"3", label:"その他"},
        ]

        //駅・沿線
        this.stationData = [
            {value:"", label:"選択してください"},
            {value:"1", label:"JR：予讃線"},
            {value:"2", label:"JR：高徳線"},
            {value:"3", label:"琴平電鉄：琴平線"},
            {value:"4", label:"琴平電鉄：長尾線"},
            {value:"5", label:"琴平電鉄：志度線"},
        ]

        //築年
        this.buildYearData = [
            {value:0, label:"選択してください"},
            {value:1, label:"1年以内"},
            {value:5, label:"5年以内"},
            {value:10, label:"10年以内"},
            {value:15, label:"15年以内"},
            {value:20, label:"20年以内"},
        ]

        //家賃
        this.rentData = [
            {value:"20000", label:"2万"},
            {value:"25000", label:"2.5万"},
            {value:"30000", label:"3万"},
            {value:"35000", label:"3.5万"},
            {value:"40000", label:"4万"},
            {value:"45000", label:"4.5万"},
            {value:"50000", label:"5万"},
            {value:"55000", label:"5.5万"},
            {value:"60000", label:"6万"},
            {value:"65000", label:"6.5万"},
            {value:"70000", label:"7万"},
            {value:"75000", label:"7.5万"},
            {value:"80000", label:"8万"},
            {value:"85000", label:"8.5万"},
            {value:"90000", label:"9万"},
            {value:"95000", label:"9.5万"},
            {value:"100000", label:"10万"},
            {value:"110000", label:"11万"},
            {value:"120000", label:"12万"},
            {value:"130000", label:"13万"},
            {value:"140000", label:"14万"},
            {value:"150000", label:"15万"},
            {value:"200000", label:"20万"},
            {value:"300000", label:"30万"},
            {value:"500000", label:"50万"},
            {value:"1000000", label:"100万"},
        ]

        //小学校区
        this.shougakkouData = [
            {value:"", label:"選択してください"},
            {value:"1", label:"日新小学校"},
            {value:"2", label:"二番丁小学校"},
            {value:"3", label:"亀阜小学校"},
            {value:"4", label:"四番丁小学校"},
            {value:"5", label:"新塩屋町小学校"},
            {value:"6", label:"三木小学校"},
            {value:"7", label:"築地小学校"},
            {value:"8", label:"松島小学校"},
            {value:"9", label:"花園小学校"},
            {value:"10", label:"木太北部小学校"},
            {value:"11", label:"木太小学校"},
            {value:"12", label:"木太南小学校"},
            {value:"13", label:"中央小学校"},
            {value:"14", label:"栗林小学校"},
            {value:"15", label:"太田小学校"},
            {value:"16", label:"太田南小学校"},
            {value:"17", label:"屋島小学校"},
            {value:"18", label:"屋島東小学校"},
            {value:"19", label:"屋島西小学校"},
            {value:"20", label:"古高松小学校"},
            {value:"22", label:"下笠居小学校"},
            {value:"23", label:"弦打小学校"},
            {value:"24", label:"鬼無小学校"},
            {value:"25", label:"香西小学校"},
            {value:"26", label:"鶴尾小学校"},
            {value:"27", label:"前田小学校"},
            {value:"28", label:"川添小学校"},
            {value:"29", label:"林小学校"},
            {value:"30", label:"川岡小学校"},
            {value:"31", label:"円座小学校"},
            {value:"32", label:"檀紙小学校"},
            {value:"33", label:"一宮小学校"},
            {value:"34", label:"多肥小学校"},
            {value:"35", label:"三渓小学校"},
            {value:"36", label:"仏生山小学校"},
            {value:"37", label:"川島小学校"},
            {value:"38", label:"十河小学校"},
            {value:"39", label:"平井中学校"},
            {value:"40", label:"東植田小学校"},
            {value:"41", label:"植田小学校"},
            {value:"42", label:"城東小学校"},
            {value:"43", label:"浅野小学校"},
            {value:"44", label:"大野小学校"},
            {value:"45", label:"平井小学校"},
            {value:"46", label:"下高岡小学校"},
            {value:"47", label:"白山小学校"},
            {value:"48", label:"氷上小学校"},
            {value:"49", label:"田中小学校"},
            {value:"50", label:"川東小学校"},
            {value:"51", label:"牟礼南小学校"},
            {value:"52", label:"国分寺北部小学校"},
            {value:"53", label:"国分寺南部小学校"},
            {value:"54", label:"香南小学校"},
            {value:"55", label:"牟礼小学校"},
            {value:"56", label:"女木小学校"},
            {value:"57", label:"富熊小学校"},
            {value:"74", label:"昭和小学校"},
            {value:"75", label:"陶小学校"},
            {value:"76", label:"滝宮小学校"},
            {value:"77", label:"羽床小学校"},
            {value:"78", label:"志度小学校"},
            {value:"79", label:"牟礼北小学校"},
            {value:"81", label:"志度中央小学校"},
            {value:"83", label:"長尾小学校"},
            {value:"84", label:"庵治小学校"},
        ]

        //中学校区
        this.chuugakkouData = [
            {value:"", label:"選択してください"},
            {value:"1", label:"紫雲中学校"},
            {value:"2", label:"城内中学校"},
            {value:"3", label:"光洋中学校"},
            {value:"4", label:"玉藻中学校"},
            {value:"5", label:"木太中学校"},
            {value:"6", label:"桜町中学校"},
            {value:"7", label:"太田中学校"},
            {value:"8", label:"屋島中学校"},
            {value:"9", label:"古高松中学校"},
            {value:"10", label:"下笠居中学校"},
            {value:"11", label:"鶴尾中学校"},
            {value:"12", label:"協和中学校"},
            {value:"13", label:"香東中学校"},
            {value:"14", label:"一宮中学校"},
            {value:"15", label:"龍雲中学校"},
            {value:"16", label:"山田中学校"},
            {value:"17", label:"勝賀中学校"},
            {value:"18", label:"国分寺中学校"},
            {value:"19", label:"綾南中学校"},
            {value:"20", label:"綾歌中学校"},
            {value:"21", label:"香川第一中学校"},
            {value:"22", label:"香南中学校"},
            {value:"23", label:"三木中学校"},
            {value:"24", label:"牟礼中学校"},
            {value:"25", label:"白峰中学校"},
            {value:"26", label:"東中学校"},
            {value:"27", label:"志度中学校"},
            {value:"28", label:"志度東中学校"},
            {value:"29", label:"西中学校"},
            {value:"31", label:"高松第一中学校"},
            {value:"32", label:"長尾中学校"},
            {value:"33", label:"国分寺南部中学校"},
            {value:"34", label:"平井中学校"},
            {value:"35", label:"庵治中学校"},
        ]

        this.state = {
            customerinfo:this.props.forecasts,
            startDate: new Date(),
            checkedA: true,
            checkedB: true,
            checkedF: true,
            radio:{},
            setDog:false,
            setCat:false,
            stationData: "",
            change:false,
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRdio = this.handleChangeRdio.bind(this);
        this.onChangeSelectBox = this.onChangeSelectBox.bind(this);

        // this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる
    }
    
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        
        let mypage_request = this.props.mypage_request
        if(e.target.name==="mypageRequestContractForm"){
            if(e.target.checked){
                mypage_request[e.target.name]=1
             }else{
                mypage_request[e.target.name]=0
             }

        }else if(e.target.name==="mypageRequestAreas"){
            
            if(e.target.checked){
           
            
              let set = mypage_request.mypageRequestAreas?mypage_request.mypageRequestAreas.split(','):[]
            //   console.log(this.props.mypageRequestAreas)
              console.log(mypage_request.mypageRequestAreas?mypage_request.mypageRequestAreas.split(','):"ddd")
                mypage_request.mypageRequestAreas=set.filter(a=>a!==e.target.value).concat(e.target.value).join(',')
             }else{
                mypage_request.mypageRequestAreas= mypage_request.mypageRequestAreas.split(',').filter(a=>a!==e.target.value).join(',')
             }

            }else if(e.target.name==="mypageRequestTatemonoTypes"){
            
                if(e.target.checked){
               
                
                  let set = mypage_request.mypageRequestTatemonoTypes?mypage_request.mypageRequestTatemonoTypes.split(','):[]
                //   console.log(this.props.mypageRequestAreas)
                  console.log(mypage_request.mypageRequestTatemonoTypes?mypage_request.mypageRequestTatemonoTypes.split(','):"ddd")
                    mypage_request.mypageRequestTatemonoTypes=set.filter(a=>a!==e.target.value).concat(e.target.value).join(',')
                 }else{
                    mypage_request.mypageRequestTatemonoTypes= mypage_request.mypageRequestTatemonoTypes.split(',').filter(a=>a!==e.target.value).join(',')
                 }
                }else if(e.target.name==="mypageRequestPlans"){
            
                    if(e.target.checked){
                   
                    
                      let set = mypage_request.mypageRequestPlans?mypage_request.mypageRequestPlans.split(','):[]
                    //   console.log(this.props.mypageRequestAreas)
                      console.log(mypage_request.mypageRequestPlans?mypage_request.mypageRequestPlans.split(','):"ddd")
                        mypage_request.mypageRequestPlans=set.filter(a=>a!==e.target.value).concat(e.target.value).join(',')
                     }else{
                        mypage_request.mypageRequestPlans= mypage_request.mypageRequestPlans.split(',').filter(a=>a!==e.target.value).join(',')
                     }
                  
                    }else if(e.target.name==="mypageStations"){
            
                        if(e.target.checked){
                       
                        
                          let set = mypage_request.mypageStations?mypage_request.mypageStations.split(','):[]
                        //   console.log(this.props.mypageRequestAreas)
                          console.log(mypage_request.mypageStations?mypage_request.mypageStations.split(','):"ddd")
                            mypage_request.mypageStations=set.filter(a=>a!==e.target.value).concat(e.target.value).join(',')
                         }else{
                            mypage_request.mypageStations= mypage_request.mypageStations.split(',').filter(a=>a!==e.target.value).join(',')
                         }
                     

                    }else if(e.target.name==="mypageResponseRequests"){
            
                        if(e.target.checked){
                       
                        
                          let set = mypage_request.mypageResponseRequests?mypage_request.mypageResponseRequests.split(','):[]
                        //   console.log(this.props.mypageRequestAreas)
                          console.log(mypage_request.mypageResponseRequests?mypage_request.mypageResponseRequests.split(','):"ddd")
                            mypage_request.mypageResponseRequests=set.filter(a=>a!==e.target.value).concat(e.target.value).join(',')
                         }else{
                            mypage_request.mypageResponseRequests= mypage_request.mypageResponseRequests.split(',').filter(a=>a!==e.target.value).join(',')
                         }
                        }else if(e.target.name==="mypageDog"){
            
                            if(e.target.checked){
                           
                             mypage_request.mypageDog=true
                             }else{
                                mypage_request.mypageDog=false
                             }
                        }else if(e.target.name==="mypageCat"){
            
                            if(e.target.checked){
                           
                             mypage_request.mypageCat=true
                             }else{
                                mypage_request.mypageCat=false
                             }
                
                            }else if(e.target.name==="mypageRequestMeansTrain"){
            
                                if(e.target.checked){
                               
                                 mypage_request.mypageRequestMeansTrain=true
                                 }else{
                                    mypage_request.mypageRequestMeansTrain=false
                                 }   
                                }else if(e.target.name==="mypageRequestMeansBus"){
            
                                    if(e.target.checked){
                                   
                                     mypage_request.mypageRequestMeansBus=true
                                     }else{
                                        mypage_request.mypageRequestMeansBus=false
                                     }   
                                    }else if(e.target.name==="mypageRequestMeansCar"){
            
                                        if(e.target.checked){
                                       
                                         mypage_request.mypageRequestMeansCar=true
                                         }else{
                                            mypage_request.mypageRequestMeansCar=false
                                         }   

                                        }else if(e.target.name==="mypageRequestMeansBicycle"){
            
                                            if(e.target.checked){
                                           
                                             mypage_request.mypageRequestMeansBicycle=true
                                             }else{
                                                mypage_request.mypageRequestMeansBicycle=false
                                             } 

                                            }else if(e.target.name==="mypageRequestMeansWalk"){
            
                                                if(e.target.checked){
                                               
                                                 mypage_request.mypageRequestMeansWalk=true
                                                 }else{
                                                    mypage_request.mypageRequestMeansWalk=false
                                                 } 
                                             

        }else{
            mypage_request[e.target.name]=e.target.value

        }
        console.log(this.props.mypage_request.mypageRequestContractForm)
        this.props.change_mypage_request(mypage_request)
        // console.log(e.target.value);
        this.setState({ change: !this.state.change });
    }
    
    componentDidMount() {
        // console.log(this.props.comingdetail)
        //   this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる
        
    // This method is called when the component is first added to the document
        //this.ensureDataFetched();
       
    }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.ensureDataFetched();
  }

  ensureDataFetched() {
      //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
      //console.log(startDateIndex)

      //this.props.requestWeatherForecasts(0);

    }


    onChangeName(property, event) {

        if (property === "tatemonoKana") {
            this.props.change(property, autokana1.getFurigana())
        } else if (property === "kana2") {
            
        }


    }


    handleChange = name => event => {

        this.setState({ ...this.state, [name]: event.target.checked });
    };
    handleChangeRdio = event => {
     
        this.setState({radio:event.target.value});
    };

    onChangeSelectBox(e) {
        console.log("***************************"+e.target.name)
        this.setState({[e.target.name]:e.target.value});
    }


    render() {
        const year = ["選択してください"];
        const day = ["選択してください"];

        var hiduke = new Date();
        for (var i = 1926; i <= hiduke.getFullYear(); i++) {
            year.push(i);
        }
        for (var i2 = 1; i2 <= 31; i2++) {
            day.push(i2);
        }

        // const { handleSubmit } = this.props;
    

        return (

        // <form onSubmit={handleSubmit}>
        <div className="display_style">     
        <div class="title_style">条件設定/変更</div>
        <div className="conditions_style" style={{marginBottom:"95px"}}>
        <div class="container">
            <div class="row pt-2">
                <h6>【ご希望のお部屋探し条件】</h6>
            </div>
            <div class="row">
                <div class="col-md-3">ご契約形態
                </div>
                <div class="col-md-9 form-group">
                    <FormControlLabel
                        control={
                            <Checkbox
                                /*************/
                                name="mypageRequestContractForm"
                                value={true}
                                checked={this.props.mypage_request.mypageRequestContractForm?true:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="法人契約希望"
                    />

                        {/* <div class="form-check form-check-inline">
                            <label class="form-check-label"><input class="form-check-input" type="checkbox" name="contractForm" id ="contractForm" value="true"></input> 法人契約希望</label>
                        </div> */}
                        {/* <Field name="contractForm" component="input" type="checkbox" value="false" checked={this.state.setContractForm === "true"} onChange={()=>this.setState({"setContractForm" : "true"})} /> 法人契約希望 */}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">地域
                </div>
                <div class="col-md-9 form-group">
                    <FormControlLabel
                        control={
                            <Checkbox
                                /***********/
                                name="mypageRequestAreas"
                                value="1"
                                checked={this.props.mypage_request.mypageRequestAreas?this.props.mypage_request.mypageRequestAreas.split(',').indexOf("1") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="高松市中心部"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestAreas"
                                value="2"
                                checked={this.props.mypage_request.mypageRequestAreas?this.props.mypage_request.mypageRequestAreas.split(',').indexOf("2") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="レインボー・サンフラワー周辺"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestAreas"
                                value="3"
                                checked={this.props.mypage_request.mypageRequestAreas?this.props.mypage_request.mypageRequestAreas.split(',').indexOf("3") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="高松市南部"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestAreas"
                                value="4"
                                checked={this.props.mypage_request.mypageRequestAreas?this.props.mypage_request.mypageRequestAreas.split(',').indexOf("4") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="高松市東部"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestAreas"
                                value="5"
                                checked={this.props.mypage_request.mypageRequestAreas?this.props.mypage_request.mypageRequestAreas.split(',').indexOf("5") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="高松市西部"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestAreas"
                                value="8"
                                checked={this.props.mypage_request.mypageRequestAreas?this.props.mypage_request.mypageRequestAreas.split(',').indexOf("8") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="高松市全域"
                    />
                    <div class="row">
                        <div class="col-md-12">その他、具体的なエリア
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-10">
                            <TextField
                                /***********/
                                id="mypageAreaRemarks"
                                name="mypageAreaRemarks"
                                margin="dense"
                                // defaultValue={this.props.mypage_request.mypageAreaRemarks}
                                value={this.props.mypage_request.mypageAreaRemarks}
                                
                                onChange={this.onChange}
                                variant="outlined"
                                style={{width:"100%"}}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">駅・沿線
                </div>
                <div className="form-group col-md-9">
                    <div class="row mb-2">
                        <div class="col-md-6">
                            <FormControl variant="outlined" margin="dense">
                                <Select
                                    name="mypageStationLines"
                                    id="station"
                                    style={{fontSize:13, width:150}}
                                    value={parseInt(this.props.mypage_request.mypageStationLines)}
                                    onChange={this.onChange}
                                >
                                    {this.stationData.map(d => 
                                        <MenuItem value={d.value}>{d.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-12 ml-3">
                            <div className="row mr-0" style={{background:`url(${stationLine})`,display: (this.props.mypage_request.mypageStationLines === "1") ? '' : 'none'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="822"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("822") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="高松"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1513"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1513") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="香西"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1514"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1514") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="鬼無"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1515"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1515") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="端岡"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1516"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1516") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="国分"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                            </div>
                            <div className="row mr-0" style={{background:`url(${stationLine})`, display: (this.props.mypage_request.mypageStationLines === "2") ? '' : 'none'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="822"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("822") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="高松"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="69"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("69") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="昭和町"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="792"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("792") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="栗林公園北口"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1517"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1517") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="栗林"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="709"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("709") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="木太町"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1519"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1519") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="屋島"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1520"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1520") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="古高松南"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1521"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1521") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="八栗口"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1522"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1522") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="讃岐牟礼"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="847"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("847") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="志度"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                            </div>
                            <div className="row mr-0" style={{background:`url(${stationLine})`, display: (this.props.mypage_request.mypageStationLines === "3") ? '' : 'none'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="829"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("829") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="高松築港"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1503"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1503") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="片原町"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="25"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("25") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="瓦町"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1504"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1504") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="栗林公園"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="630"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("630") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="三条"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="135"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("135") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="太田"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="95"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("95") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="仏生山"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1505"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1505") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="空港通り"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="646"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("646") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="一宮"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="155"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("155") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="円座"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="635"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("635") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="岡本"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                            </div>
                            <div className="row mr-0" style={{background:`url(${stationLine})`, display: (this.props.mypage_request.mypageStationLines === "4") ? '' : 'none'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="829"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("829") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="高松築港"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1503"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1503") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="片原町"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="25"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("25") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="瓦町"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="94"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("94") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="花園"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="715"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("715") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="林道"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="716"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("716") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="木太東口"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="735"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("735") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="元山"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="740"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("740") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="水田"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1506"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1506") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="西前田"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="753"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("753") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="高田"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1507"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1507") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="池戸"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1508"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1508") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="農学部前"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="846"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("846") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="平木"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="4209"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("4209") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="学園通り"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="4211"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("4211") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="白山"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="4212"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("4212") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="井戸"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="4196"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("4196") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="公文明"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="4195"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("4195") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="長尾"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                            </div>
                            <div className="row mr-0" style={{background:`url(${stationLine})`, display: (this.props.mypage_request.mypageStationLines === "5") ? '' : 'none'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="25"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("25") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="瓦町"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="817"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("817") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="今橋"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1509"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1509") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="松島二丁目"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="92"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("92") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="沖松島"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="710"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("710") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="春日川"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1510"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1510") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="潟元"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="702"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("702") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="琴電屋島"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1511"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1511") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="古高松"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="1512"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("1512") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="八栗"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="3638"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("3638") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="六万寺"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="3639"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("3639") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="大町"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="3640"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("3640") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="八栗新道"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="3641"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("3641") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="塩屋"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="3642"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("3642") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="房前"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="3643"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("3643") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="原"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="mypageStations"
                                            value="4210"
                                            checked={this.props.mypage_request.mypageStations?this.props.mypage_request.mypageStations.split(',').indexOf("4210") >= 0?true:false:false} 
                                            onChange={this.onChange}
                                            style={{transform: "scale(1.0)"}}
                                            color="primary"
                                        />
                                    }
                                    label="琴電志度"
                                    className="station_style"
                                    style={{marginLeft:1, marginRight:10}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">建物種別
                </div>
                <div class="form-group col-md-9">
                    <FormControlLabel
                        control={
                            <Checkbox
                                /**********/
                                name="mypageRequestTatemonoTypes"
                                value="2"
                                // checked={this.props.mypage_request.mypageRequestTatemonoTypes} 
                                checked={this.props.mypage_request.mypageRequestTatemonoTypes?this.props.mypage_request.mypageRequestTatemonoTypes.split(',').indexOf("2") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="マンション"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                /**********/
                                name="mypageRequestTatemonoTypes"
                                value="1"
                                checked={this.props.mypage_request.mypageRequestTatemonoTypes?this.props.mypage_request.mypageRequestTatemonoTypes.split(',').indexOf("1") >= 0?true:false:false} 
                                
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="アパート"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                /***********/
                                name="mypageRequestTatemonoTypes"
                                value="7"
                                checked={this.props.mypage_request.mypageRequestTatemonoTypes?this.props.mypage_request.mypageRequestTatemonoTypes.split(',').indexOf("7") >= 0?true:false:false} 
                                
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="一戸建て"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">築年
                </div>
                <div class="form-group col-md-9">
                    <div class="row">
                        <div class="col-6">
                            <FormControl variant="outlined" margin="dense">
                                <Select
                                    /*********/
                                    name="mypageRequestYear"
                                    id="mypageRequestYear"
                                    style={{fontSize:13, width:150}}
                                    value={parseInt(this.props.mypage_request.mypageRequestYear)}
                                    onChange={this.onChange}
                                >
                                    {this.buildYearData.map(d => 
                                        <MenuItem value={d.value}>{d.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">間取り
                </div>
                <div class="form-group col-md-9">
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="1"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("1") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="ワンルーム"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="mypageRequestPlans"
                                // value="checked1K"
                                value="2"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("2") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="1K"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="mypageRequestPlans"
                                value="3"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("3") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="1DK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="5"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("5") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="1LDK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="8"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("8") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="2K"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="9"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("9") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="2DK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="11"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("11") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="2LDK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="14"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("14") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="3K"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="15"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("15") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="3DK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="17"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("17") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="3LDK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="20"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("20") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="4K"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="21"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("21") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="4DK"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageRequestPlans"
                                value="23"
                                checked={this.props.mypage_request.mypageRequestPlans?this.props.mypage_request.mypageRequestPlans.split(',').indexOf("23") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="4LDK以上"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">家賃
                </div>
                <div class="form-group col-md-9">
                    <div class="row">
                        <div class="col-md-6">
                            <FormControl variant="outlined" margin="dense">
                                <Select
                                    /************/
                                    name="mypageRequestFromCost"
                                    id="mypageRequestFromCost"
                                    style={{fontSize:13, width:150}}
                                    value={parseInt(this.props.mypage_request.mypageRequestFromCost)}
                                    onChange={this.onChange}
                                >
                                <option selected="selected" value="0">下限なし</option>
                                {this.rentData.map(d => 
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                                </Select>
                            </FormControl><div style={{display:"inline-block", marginTop:"15px", paddingLeft:"5px", paddingRight:"5px"}}> ～ </div>
                            <FormControl variant="outlined" margin="dense">
                                <Select
                                    /************/
                                    name="mypageRequestToCost"
                                    id="mypageRequestToCost"
                                    style={{fontSize:13, width:150}}
                                    value={parseInt(this.props.mypage_request.mypageRequestToCost)}
                                    onChange={this.onChange}
                                >
                                <option selected="selected" value="0">上限なし</option>
                                {this.rentData.map(d => 
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={{fontSize:13}}>現在の家賃
                        </div>
                        <div class="col-2">
                            <TextField
                                /***********/
                                id="mypageRentCurrent"
                                name="mypageRentCurrent"
                                type="number"
                                value={this.props.mypage_request.mypageRentCurrent}
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end" style={{width:"80px"}}>万円</InputAdornment>,
                                }}
                                margin="dense"
                                onChange={this.onChange}
                                variant="outlined"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">初期費用
                </div>
                <div class="form-group col-md-9">
                    <div class="row">
                        <div class="col-md-3 col-5">
                            <TextField
                                /***********/
                                id="mypageInitialCost"
                                name="mypageInitialCost"
                                onChange={this.onChange}
                                value={this.props.mypage_request.mypageInitialCost}
                                type="number"
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end" style={{width:"75px"}}>万円</InputAdornment>,
                                }}
                                margin="dense"
                                variant="outlined"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">駐車場
                </div>
                <div class="col-md-9 form-group">
                    <div class="row">
                    <div class="col-md-3 col-5">
                            <TextField
                                /************/
                                id="mypageRequestCar"
                                name="mypageRequestCar"
                                onChange={this.onChange}
                                value={this.props.mypage_request.mypageRequestCar}
                                type="number"
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end" style={{width:"30px"}}>台</InputAdornment>,
                                }}
                                margin="dense"
                                variant="outlined"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">通勤手段
                </div>
                <div class="form-group col-md-9">
                    <FormControlLabel
                        control={
                            <Checkbox 
                                /*************/
                                name="mypageRequestMeansTrain"
                                value={true}
                                checked={this.props.mypage_request.mypageRequestMeansTrain?true:false}
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="電車"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                /*************/
                                name="mypageRequestMeansBus"
                                value={true}
                                checked={this.props.mypage_request.mypageRequestMeansBus?true:false}
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="バス"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                /*************/
                                name="mypageRequestMeansCar"
                                value={true}
                               
                                checked={this.props.mypage_request.mypageRequestMeansCar?true:false}
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="車"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                /*************/
                                name="mypageRequestMeansBicycle"
                                value={true}
                                checked={this.props.mypage_request.mypageRequestMeansBicycle?true:false}
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="自転車"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                /*************/
                                // name="requestMeansWalk"
                                name="mypageRequestMeansWalk"
                                value={true}
                                checked={this.props.mypage_request.mypageRequestMeansWalk?true:false}
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="徒歩"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">小学校区
                </div>
                <div class="form-group col-md-9">
                    <div class="row">
                        <div class="col-md-3">
                            <FormControl variant="outlined" margin="dense">
                                <Select
                                    name="mypageRequestShougakkou"
                                    id="mypageRequestShougakkou"
                                    style={{fontSize:13, width:150}}
                                    value={parseInt(this.props.mypage_request.mypageRequestShougakkou)}
                                    onChange={this.onChange}
                                >
                                <option selected="selected" value=""></option>
                                {this.shougakkouData.map(d => 
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">中学校区
                </div>
                <div class="form-group col-md-9">
                    <div class="row">
                        <div class="col-md-3">
                            <FormControl variant="outlined" margin="dense">
                                <Select
                                    name="mypageRequestChuugakkou"
                                    id="mypageRequestChuugakkou"
                                    style={{fontSize:13, width:150}}
                                    value={parseInt(this.props.mypage_request.mypageRequestChuugakkou)}
                                    onChange={this.onChange}
                                >
                                <option selected="selected" value=""></option>
                                {this.chuugakkouData.map(d => 
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </div> 
                   </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">設備
                </div>
                <div class="form-group col-md-9">
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageResponseRequests"

                                value="20"
                                checked={this.props.mypage_request.mypageResponseRequests?this.props.mypage_request.mypageResponseRequests.split(',').indexOf("20") >= 0?true:false:false} 
                                onChange={this.onChange}

                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="バストイレ別"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageResponseRequests"
                                value="37"
                                checked={this.props.mypage_request.mypageResponseRequests?this.props.mypage_request.mypageResponseRequests.split(',').indexOf("37") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="オートロック"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageResponseRequests"
                                value="58"
                                checked={this.props.mypage_request.mypageResponseRequests?this.props.mypage_request.mypageResponseRequests.split(',').indexOf("58") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="エアコン"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageResponseRequests"
                                value="1"
                                checked={this.props.mypage_request.mypageResponseRequests?this.props.mypage_request.mypageResponseRequests.split(',').indexOf("1") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="温水洗浄暖房便座"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageResponseRequests"
                                value="3"
                                checked={this.props.mypage_request.mypageResponseRequests?this.props.mypage_request.mypageResponseRequests.split(',').indexOf("3") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="追焚き"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageResponseRequests"
                                value="91"
                                checked={this.props.mypage_request.mypageResponseRequests?this.props.mypage_request.mypageResponseRequests.split(',').indexOf("91") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="対面キッチン"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageResponseRequests"
                                value="77"
                                checked={this.props.mypage_request.mypageResponseRequests?this.props.mypage_request.mypageResponseRequests.split(',').indexOf("77") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="独立洗面台"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageResponseRequests"
                                value="153"
                                checked={this.props.mypage_request.mypageResponseRequests?this.props.mypage_request.mypageResponseRequests.split(',').indexOf("153") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="2F以上"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageResponseRequests"
                                value="16"
                                checked={this.props.mypage_request.mypageResponseRequests?this.props.mypage_request.mypageResponseRequests.split(',').indexOf("16") >= 0?true:false:false} 
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="インターネット使い放題"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">その他の希望条件
                </div>
                <div class="col-md-9 form-group">
                    <div class="row">
                        <div class="col-md-10">
                            <TextField
                                id="mypageRequestRemarks"
                                name="mypageRequestRemarks"
                                onChange={this.onChange}
                                multiline
                                rows="3"
                                value={this.props.mypage_request.mypageRequestRemarks}
                                // className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                style={{width:"100%"}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="container">
            <div class="row pt-2">
                <h6>【ご来店のきっかけ】</h6>
            </div>
            <div class="row">
                <div class="col-md-3">何を見てご来店頂きましたか
                </div>
                <div class="col-md-9 form-group">
                    <div className="row">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="mainMedia" name="mainMedia" value={this.state.radio} onChange={this.handleChangeRdio} row>
                                <div className="col-sm-3"><FormControlLabel value="2" control={<Radio color="primary" />} label="当社ＨＰ" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="1" control={<Radio color="primary" />} label="アパマンＨＰ" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="14" control={<Radio color="primary" />} label="ＳＵＵＭＯ" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="16" control={<Radio color="primary" />} label="ＨＯＭＥＳ" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="17" control={<Radio color="primary" />} label="いい部屋ネット" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="21" control={<Radio color="primary" />} label="アットホーム" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="11" control={<Radio color="primary" />} label="通りがかり" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="8" control={<Radio color="primary" />} label="リピート" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="9" control={<Radio color="primary" />} label="紹介" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="23" control={<Radio color="primary" />} label="ラジオ" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="22" control={<Radio color="primary" />} label="ＴＶＣＭ" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="28" control={<Radio color="primary" />} label="こまち" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="5" control={<Radio color="primary" />} label="カサブランカ" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="29" control={<Radio color="primary" />} label="すまいず" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="6" control={<Radio color="primary" />} label="当社情報誌" labelPlacement="end" /></div>
                                <div className="col-sm-3"><FormControlLabel value="4" control={<Radio color="primary" />} label="その他" labelPlacement="end" /></div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">他に行かれた不動産屋
                </div>
                <div class="col-md-9 form-group">
                    <div class="row">
                        <div class="col-md-10">
                            <TextField
                                id="otherTrader"
                                margin="dense"
                                variant="outlined"
                                style={{width:"100%"}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <div class="container">
            <div class="row pt-2">
                <h6>【お引越し理由】</h6>
            </div>
            <div class="row">
                <div class="col-md-3">お引越し時期
                </div>
                <div class="col-md-9 form-group">
                    <div class="row">
                        <div className="col-sm-3">
                            <FormControl variant="outlined" margin="dense">
                                <Select
                                    name="requestMovingTime"
                                    id="requestMovingTime"
                                    style={{fontSize:13, width:200}}
                                    value={this.state.movingTimeData}
                                    onChange={this.onChangeSelectBox}
                                >
                                {this.movingTimeData.map(d => 
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">社会人・学生
                </div>
                <div class="col-md-9 form-group">
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="studentIndependent" name="studentIndependent" value={this.state.radio} onChange={this.handleChangeRdio} row>
                            <FormControlLabel value="1" control={<Radio color="primary" />} checked={this.state.setStudentIndependent === "1"} onChange={()=>this.setState({"setStudentIndependent" : "1"})} label="社会人" labelPlacement="end" />
                            <FormControlLabel value="2" control={<Radio color="primary" />} checked={this.state.setStudentIndependent === "2"} onChange={()=>this.setState({"setStudentIndependent" : "2"})} label="学生" labelPlacement="end" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div class="row" style={{ display: (this.state.setStudentIndependent === "1") ? '' : 'none'}}>
                <div class="col-md-3">単身・ファミリー
                </div>
                <div class="col-md-9 form-group">
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="aloneFamily" name="aloneFamily" value={this.state.radio} onChange={this.handleChangeRdio} row>
                            <FormControlLabel value="1" control={<Radio color="primary" />} checked={this.state.setAloneFamily === "1"} onChange={()=>this.setState({"setAloneFamily" : "1"})} label="単身" labelPlacement="end" />
                            <FormControlLabel value="2" control={<Radio color="primary" />} checked={this.state.setAloneFamily === "2"} onChange={()=>this.setState({"setAloneFamily" : "2"})} label="ファミリー" labelPlacement="end" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div style={{ display: (this.state.setStudentIndependent === "2") ? '' : 'none'}}>
                <div className="row">
                    <div class="col-md-3">学校名
                    </div>
                    <div class="col-md-9 form-group">
                        <div class="row">
                            <div className="col-sm-3">
                                <FormControl variant="outlined" margin="dense">
                                    <Select
                                        name="rentMSchoolId"
                                        id="rentMSchoolId"
                                        style={{fontSize:13, width:200}}
                                        value={this.state.schoolData}
                                        onChange={this.onChangeSelectBox}
                                    >
                                    {this.schoolData.map(d => 
                                        <MenuItem value={d.value}>{d.label}</MenuItem>
                                    )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div class="col-md-3">学年
                    </div>
                    <div class="col-md-9 form-group">
                        <div class="row">
                            <div className="col-md-3">
                                <FormControl variant="outlined" margin="dense">
                                    <Select
                                        name="gakunen"
                                        id="gakunen"
                                        style={{fontSize:13, width:200}}
                                        value={this.state.gakunenData}
                                        onChange={this.onChangeSelectBox}
                                    >
                                    {this.gakunenData.map(d => 
                                        <MenuItem value={d.value}>{d.label}</MenuItem>
                                    )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">お引越し理由
                </div>
                <div class="col-md-9 form-group">
                    <div class="row">
                        <div className="col-sm-3">
                            <FormControl variant="outlined" margin="dense">
                                <Select
                                    name="rentMReasonId"
                                    id="rentMReasonId"
                                    style={{fontSize:13, width:200}}
                                    value={this.state.moveReasonData}
                                    onChange={this.onChangeSelectBox}
                                >
                                {this.moveReasonData.map(d => 
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div class="container">
            <div class="row pt-2">
                <h6>【入居予定】</h6>
            </div>
            <div class="row">
                <div class="col-md-3">お引越し時期
                </div>
                <div class="col-md-9 form-group">
                    <div class="row">
                        <div className="col-sm-3">
                            <FormControl variant="outlined" margin="dense">
                                <Select
                                    name="mypageRequestMovingTime"
                                    id="mypageRequestMovingTime"
                                    style={{fontSize:13, width:200}}
                                    value={parseInt(this.props.mypage_request.mypageRequestMovingTime)}
                                    onChange={this.onChange}
                                >
                                {this.movingTimeData.map(d => 
                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">社会人・学生
                </div>
                <div class="col-md-9 form-group">
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="mypageStudentIndependent" 
                        name="mypageStudentIndependent" 
                        // value={this.state.radio} 
                        // onChange={this.handleChangeRdio} 
                        row>
                            <FormControlLabel value="1" control={<Radio color="primary" />} 
                            checked={parseInt(this.props.mypage_request.mypageStudentIndependent) === 1} 
                            onChange={this.onChange} 
                            label="社会人" 
                            labelPlacement="end" />
                            <FormControlLabel 
                            value="2" 
                            control={<Radio color="primary" />} 
                            checked={parseInt(this.props.mypage_request.mypageStudentIndependent) ===2} 
                            onChange={this.onChange} 
                            label="学生" 
                            labelPlacement="end" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div class="row" style={{ display: (parseInt(this.props.mypage_request.mypageStudentIndependent) === 1) ? '' : 'none'}}>
                <div class="col-md-3">単身・ファミリー
                </div>
                <div class="col-md-9 form-group">
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="mypageAloneFamily" name="mypageAloneFamily" 
                        // value={this.state.radio} 
                        // onChange={this.handleChangeRdio} 
                        row>
                            <FormControlLabel value="1" 
                            control={<Radio color="primary" />} 
                            checked={parseInt(this.props.mypage_request.mypageAloneFamily) === 1} 
                            onChange={this.onChange} 
                            label="単身" 
                            labelPlacement="end" />
                            <FormControlLabel 
                            value="2" 
                            control={<Radio color="primary" />} 
                            checked={parseInt(this.props.mypage_request.mypageAloneFamily) === 2}
                            onChange={this.onChange} 
                             label="ファミリー" labelPlacement="end" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            {/* <div style={{ display: (this.state.setStudentIndependent === "2") ? '' : 'none'}}>
                <div className="row">
                    <div class="col-md-3">学校名
                    </div>
                    <div class="col-md-9 form-group">
                        <div class="row">
                            <div className="col-sm-3">
                                <FormControl variant="outlined" margin="dense">
                                    <Select
                                        name="mypageRentMSchoolId"
                                        id="mypageRentMSchoolId"
                                        style={{fontSize:13, width:200}}
                                        value={this.state.schoolData}
                                        onChange={this.onChangeSelectBox}
                                    >
                                    {this.schoolData.map(d => 
                                        <MenuItem value={d.value}>{d.label}</MenuItem>
                                    )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div class="col-md-3">学年
                    </div>
                    <div class="col-md-9 form-group">
                        <div class="row">
                            <div className="col-md-3">
                                <FormControl variant="outlined" margin="dense">
                                    <Select
                                        name="mypageGakunen"
                                        id="mypageGakunen"
                                        style={{fontSize:13, width:200}}
                                        value={this.state.gakunenData}
                                        onChange={this.onChangeSelectBox}
                                    >
                                    {this.gakunenData.map(d => 
                                        <MenuItem value={d.value}>{d.label}</MenuItem>
                                    )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div class="row">
                <div class="col-md-3">入居人数
                </div>
                <div class="col-md-9 form-group">
                    <div class="row">
                        <div class="col-md-3 col-5">
                            <TextField
                                /************/
                                id="mypageRequestNumberOfPeople"
                                name="mypageRequestNumberOfPeople"
                                type="number"
                                value={this.props.mypage_request.mypageRequestNumberOfPeople}
                                onChange={this.onChange}
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end" style={{width:"30px"}}>人</InputAdornment>,
                                }}
                                margin="dense"
                                variant="outlined"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">犬飼育
                </div>
                <div class="col-md-9 form-group">
                    <FormControlLabel
                        control={
                            <Checkbox
                                /*********/
                                name="mypageDog"
                                value={true}
                                checked={this.props.mypage_request.mypageDog?true:false}
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="犬"
                    />
                </div>
            </div>
            <div style={{ display: (this.props.mypage_request.mypageDog === true) ? '' : 'none'}}>
                <div class="row">
                    <div class="col-md-3">犬飼育匹数
                    </div>
                    <div class="col-md-9 form-group">
                        <div class="row">
                            <div class="col-md-3 col-5">
                                <TextField
                                    /***********/
                                    id="mypageDogCount"
                                    name="mypageDogCount"
                                    type="number"
                                    value={this.props.mypage_request.mypageDogCount}
                                    onChange={this.onChange}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end" style={{width:"30px"}}>匹</InputAdornment>,
                                    }}
                                    margin="dense"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">犬備考(種類、大きさ)
                    </div>
                    <div class="col-md-9 form-group">
                        <div class="row">
                            <div class="col-md-10">
                                <TextField
                                /***********/
                                id="mypageDogRemarks"
                                name="mypageDogRemarks"
                                    margin="dense"
                                    variant="outlined"
                                    value={this.props.mypage_request.mypageDogRemarks}
                                    onChange={this.onChange}
                                    style={{width:"100%"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">猫飼育
                </div>
                <div class="col-md-9 form-group">
                    <FormControlLabel
                        control={
                            <Checkbox
                                /**********/
                                name="mypageCat"
                                value={true}
                                checked={this.props.mypage_request.mypageCat?true:false}
                                onChange={this.onChange}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="猫"
                    />
                    {/* <div class="form-check form-check-inline">
                        <label class="form-check-label"><input class="form-check-input" type="checkbox" name="cat" id ="cat" value=""></input> 猫</label>
                    </div> */}
                </div>
            </div>
            <div style={{ display: (this.props.mypage_request.mypageCat === true) ? '' : 'none'}}>
                <div class="row">
                    <div class="col-md-3">猫飼育匹数
                    </div>
                    <div class="col-md-9 form-group">
                        <div class="row">
                            <div class="col-md-3 col-5">
                                <TextField
                                    /*************/
                                    id="mypageCatCount"
                                    name="mypageCatCount"
                                   value={this.props.mypage_request.mypageCatCount}
                                    onChange={this.onChange}
                                    type="number"
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end" style={{width:"30px"}}>匹</InputAdornment>,
                                    }}
                                    margin="dense"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">猫備考(種類、大きさ)
                    </div>
                    <div class="col-md-9 form-group">
                        <div class="row">
                            <div class="col-md-10">
                                <TextField
                                    /*************/
                                    id="mypageCatRemarks"
                                    name="mypageCatRemarks"
                                    value={this.props.mypage_request.mypageCatRemarks}
                                    onChange={this.onChange}
                                    margin="dense"
                                    variant="outlined"
                                    style={{width:"100%"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="row">
                <div class="col-md-3">連帯保証人の予定
                </div>
                <div class="col-md-9 form-group">
                    <FormControlLabel
                        control={
                            <Checkbox 
                                name="mypageConsigner"
                                value="checked_c"
                                checked={this.state.checkedo_c} 
                                onChange={this.handleChange('checked_c')}
                                style={{transform: "scale(1.0)"}}
                                color="primary"
                            />
                        }
                        label="あり"
                    />
                    {/* <div class="form-check form-check-inline">
                        <label class="form-check-label"><input class="form-check-input" type="checkbox" name="consigner" id ="consigner" value=""></input> あり</label>
                    </div> 
                </div>
            </div> */}
        </div>
        </div>
        {/* <button type="submit" className="form-group btn btn-primary mx-auto d-block" onClick={this.handleFormSubmit}>この内容で確認画面へ進む</button> */}
        <footer id="footer-submit_button">
            <button type="submit" className="form-group btn btn-primary mx-auto d-block" onClick={this.props.update_mypage_request}>登録する</button>
        </footer>
    </div>
// </form>

    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        mypage_request: state.Coming.mypage_request,
  
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_mypage_request(state) {
           dispatch(Coming.change_mypage_request(state))
        },
        update_mypage_request(state) {
            dispatch(Coming.update_mypage_request(state))
         },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}


export default ComingFormComponent = connect(
    mapStateToProps, mapDispatchToProps
)(ComingFormComponent);

// ComingFormComponent = reduxForm({
//     form: 'tenantform', // a unique identifier for this form
//     validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ComingFormComponent)

// export default ComingFormComponent