import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import './Css/Reserve.css';
import moment from "moment";
import Button from '@material-ui/core/Button';
import ReserveVisit from './ReserveVisitComponent';
import './Css/TatemonoTable.css';




class ReserveContentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // cancelReason: "",
            // error_flg: 0,
            // disp: false,
        };

        
    }

    



    render() {
    if(this.props.tid){
        var gaikan_img  = "https://img.global-center.co.jp/v2/tatemono/" + this.props.tid + "/1/1/520/780/0";
    }

    return (
        <div id='Tatemono' className="reserve_height">
            {this.props.tatemono || Object.keys(this.props.tatemono).indexOf("staff")!== -1?
                <div className="mt-3 mb-5" style={{paddingRight:'20px',paddingLeft:'20px',fontFamily:"Noto Sans JP,sans-serif"}}>
                    <h3 className='Titles' style={{fontFamily:"Noto Sans JP,sans-serif"}}>お問い合わせの物件情報</h3>
                    <div className='TatemonoDetail' style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                        <img className='gaikan' src={gaikan_img} alt="外観"/>
                        {this.props.tatemono.map(t =><div className='tables'>
                            <div className="tab tab1" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                                <div className='th for-large'>物件名</div>
                                <div className='td'>{t.tatemono_name}</div>
                            </div>
                            <div className="tab tab2" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                                <div className='th for-large'>家賃［共益費］</div>
                                <div className='th for-small'>家賃</div>
                                <div className='td'>{t.chinryou}円 [{t.kyouekihi}円]</div>
                            </div>
                            <div className="tab tab3" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                                <div className='th for-large'>敷金/礼金</div>
                                <div className='th for-small'>敷/礼</div>
                                <div className='td'>{t.shikikin ? t.shikikin + "円" :(t.shikikin === 0 ? "0円":"-")} / {t.reikin ? t.reikin + "円" :(t.reikin === 0 ? "0円":"-")}</div>
                            </div>
                            <div className="tab tab3" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                                <div className='th for-large'>定額クリーニング</div>
                                <div className='th for-small'>定額クリーニング</div>
                                <div className='td'>{t.teigaku_cleaning ? t.teigaku_cleaning :"-"} </div>
                            </div>
                            <div className="tab tab4" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                                <div className='th'>階層</div>
                                <div className='td'>{t.kai}階 {t.room_name}</div>
                            </div>
                            <div className="tab tab5" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                                <div className='th for-large'>間取り［専有面積］</div>
                                <div className='th for-small'>間取り</div>
                                <div className='td'>{t.madori_name} [{t.senyuu_menseki}㎡]</div>
                            </div>
                        </div>
                        )}  
                    </div>
                </div>
            :""}
        </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
    }
}
function mapDispatchToProps(dispatch) {
    return {
    

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveContentComponent);
