import React, { Component }  from 'react';
import { connect } from 'react-redux';
import './Css/RentStaffComponent.css';
import PersonIcon from '@material-ui/icons/Person';

class RentStaffComponent extends Component {
    constructor(props) {
        super(props);
        // console.log(props.staff,"staff")
        this.state = {

        };
    }

    render() {


            // console.log(this.props.staff.staff,this.props.staff.staff_name,"スタッフ")
        return (

           <div id='staffbox'> 
                {this.props.comment && this.props.comment.length > 0?
                <div className='staff_coment'>
                    <div className='coment' dangerouslySetInnerHTML={{ __html: this.props.comment.replace(/\r?\n/g, '<br />') }} />
                </div>: ""}
                {this.props.staff.staff_name && this.props.staff.staff_name.length > 0? 
                <div className='staff_profile'>
                    <div className='titleboxs'>
                        <img className='profile' src={`${process.env.PUBLIC_URL}/img/profile.png`} alt='profile'/>
                        <div className='tantou'>
                            <PersonIcon/>
                            <span style={{fontFamily:"Noto Sans JP,sans-serif",fontWeight:"bold"}}>担当者紹介</span>
                        </div>
                    </div>
                    <div className='profile_box'>
                        <div className='profiles'>
                            <div className='profile_inner'>
                                <div className='face'>
                                    {this.props.staff && this.props.staff.staff.length > 0 && this.props.staff.staff[0].image_ext? 
                                    <img src={"https://img.global-center.co.jp/v1/staff/introduction/" + this.props.staff.staff[0].id + "/150/150/" + this.props.staff.staff[0].image_ext}  alt='スタッフ写真'/>
                                    :<img src={`https://www.homestation.jp/manage/master/images/index/`+this.props.staff.staff_name[0].id}  alt='スタッフ写真'/>
                                    }
                                </div>
                                <div className='profile_text'>
                                    <div className='names'>
                                        {/* <p className='tenpo'>{this.props.staff.staff_name[0].am_section_id === 26 ? this.props.staff.tenpo[0].Tenpo_Name + "お部屋探しサポートチーム" :this.props.staff.tenpo[0].Tenpo_Name}</p> */}
                                        {this.props.staff.staff_name[0].am_section_id === 26 ?
                                            <p className='tenpo'> 
                                            {this.props.staff.tenpo[0].Tenpo_Name}<br/>お部屋探しサポートチーム
                                            </p>:
                                            <p className='tenpo'> 
                                            {this.props.staff.tenpo[0].Tenpo_Name}
                                        </p>
                                        }
                                        <p className='name'>{
                                        this.props.staff.staff_name[0].id === 1023 ? "白井 麻莉奈" 
                                        :this.props.staff.staff_name[0].id === 1296 ? "宮井 七実"
                                        : this.props.staff.staff_name[0].user_name_all
                                        }</p>
                                        <p className='kana'>{
                                        this.props.staff.staff_name[0].id === 1023 ? "シライ マリナ" 
                                        :this.props.staff.staff_name[0].id === 1296 ? "ミヤイ ナナミ"
                                        :this.props.staff.staff_name[0].user_name_kana
                                        }</p>
                                    </div>
                                    {this.props.staff.staff && this.props.staff.staff.length > 0?<div>
                                        {this.props.staff.staff[0].pushing_point?
                                        <div className='tokuthou'>
                                            {this.props.staff.staff[0].pushing_point.includes(1)?<p className='t1'>宅建資格</p>:""}
                                            {this.props.staff.staff[0].pushing_point.includes(2)?<p className='t3'>地元密着</p>:""}
                                            {this.props.staff.staff[0].pushing_point.includes(3)?<p className='t4'>単身応援</p>:""}
                                            {this.props.staff.staff[0].pushing_point.includes(4)?<p className='t2'>経験豊富</p>:""}
                                            {this.props.staff.staff[0].pushing_point.includes(5)?<p className='t5'>女性目線</p>:""}
                                            {this.props.staff.staff[0].pushing_point.includes(6)?<p className='t6'>家族目線</p>:""}
                                        </div>:""}
                                    </div>:""}
                                </div>
                            </div>
                        
                            
                        </div>

                        {this.props.staff.staff && this.props.staff.staff.length > 0 ?
                        <div className='profiles2'>
                            <div className='tokugishumi'>
                                {this.props.staff.staff[0].special_skill?
                                <dl>
                                    <dt>特技</dt>
                                    <dd>{this.props.staff.staff[0].special_skill}</dd>
                                </dl>
                                :""}
                                {this.props.staff.staff[0].hobby?
                                <dl>
                                    <dt>趣味</dt>
                                    <dd>{this.props.staff.staff[0].hobby}</dd>
                                </dl>
                                :""}
                            </div>
                            {this.props.staff.staff[0].comment?
                            <div className='profile_comment'>
                                {this.props.staff.staff[0].comment}
                            </div>
                            :""}
                        </div>
                        :""}
                    </div>
                </div>:
                ""}

           </div>

        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        cd: state.masterReducer.cd,
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
//        customeredit1change(state) {
//            dispatch(customeredit1change(state))
//        },
//customeredit2change(state) {
//        dispatch(customeredit2change(state))
//    },
//        responseeditchange(state) {
//            dispatch(responseeditchange(state))
//        },
//        customerdetailData1(state) {
//            dispatch(customerdetailData1(state))
//        }, responsedetailData(state) {
//            dispatch(responsedetailData(state))
//        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RentStaffComponent);
