import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Css/Reserve.css';
import moment from "moment";



class CompletionComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }; 
    }

    formatDate(date){
        moment.locale("ja", {
            weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
            weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
        });
        return moment(date).utc().format('YYYY年MM月DD日(ddd) HH時')
    }


    render() {
        console.log(this.props.raiten_date,this.props.cd,"return")
        return (
            <div className="done_style container" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                <h3 className='Titles' style={{textAlign:"left",marginBottom:"20px",fontFamily:"Noto Sans JP,sans-serif"}}>ご予約を受け付けました。</h3>

                <div style={{fontSize:"15px"}}>
                    <div style={{textAlign:"left",lineHeight:'1.5em',fontFamily:"Noto Sans JP,sans-serif",paddingBottom:"50px",fontWeight:"bold"}}>
                        {this.formatDate(this.props.raiten_date.start_at)} に来店予約を受け付けました。<br/>
                        {this.props.raiten_date.tatemono_name?<span>物件名：{this.props.raiten_date.tatemono_name}　{this.props.raiten_date.room_name}<br/></span>:""}
                        ご予約人数：{this.props.raiten_date.reservations_people}人<br/>
                        {this.props.raiten_date.reservations_remarks ? <span>店舗へのご要望・連絡事項：{this.props.raiten_date.reservations_remarks}<br/></span>:""}<br/>
                        スタッフ一同ご来店を心よりお待ちしております！
                    </div>
                    <a href={"/Rent/" + this.props.cd.cd + "/" + this.props.cd.no + "/"}
                    style={{borderRadius: "5px",textAlign:"center", padding:'10px',color:"#fff",background:' rgb(74, 192, 240)', fontFamily:"Noto Sans JP,sans-serif"}}>
                        トップページに戻る
                    </a>
                </div>


            </div>
        );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        //tatemonoedit: state.tatemonoReducer.tatemonoedit,
        cd: state.masterReducer.cd,
        raiten_date: state.Reserve.raiten_date,
    }
}

function mapDispatchToProps(dispatch) {
    return {
    
        tatemonochange() {
            //dispatch(Responses.responseeditchange())
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletionComponent);
