// import { MasterState } from './Types';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';



import { call, put, takeEvery } from 'redux-saga/effects';
// import * as RentMMedeasApi from './Api/RentMMedeasApi';
// import * as UsersApi from './Api/UsersApi';
// import * as RentAgentsApi from './Api/RentAgentsApi';
// import * as RentMReasonsApi from './Api/RentMReasonsApi';
// import * as TmTenpoesApi from './Api/TmTenpoesApi';
// import * as TmSetsubiApi from './Api/TmSetsubiApi';

///types


//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface MasterState {
 
    rentMMedeas: any;
    users: any;
    rentAgents: any;
    rentMReasons: any;
    user_details: any;
    shops:any;
    users_select:any;
    window_height:number;
    setsubi_master:any
    cd:any,

}
const initialState: MasterState = {

  
    rentMMedeas: [],
    users: [],
    rentAgents: [],
    rentMReasons: [],
    user_details:[],
    shops:[],
    users_select:[],
    window_height:0,
    setsubi_master:[],
    cd:{cd:"",no:""},

}



///action 
const actionCreator = actionCreatorFactory();

export const getRentMMedeas = actionCreator('RENT_M_MEDEAS_REQUEST');
export const getRentMMedeasSuccess = actionCreator("RENT_M_MEDEAS_SUCCESS");
export const getRentMMedeasFailure = actionCreator("RENT_M_MEDEAS_FAILURE");

export const getUsers = actionCreator('USERS_REQUEST');
export const getUsersSuccess = actionCreator("USERS_SUCCESS");
export const getUsersFailure = actionCreator("USERS_FAILURE");

export const get_users_select = actionCreator('GET_USERS_SELECT');


export const get_user_details = actionCreator('GET_USER_DETAILS');
export const get_user_details_success = actionCreator("GET_USER_DETAILS_SUCCESS");
export const get_user_details_failure = actionCreator("GET_USER_DETAILS_FAILURE");

export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");

export const get_shops = actionCreator('GET_SHOPS');
export const get_shops_success = actionCreator("GET_SHOPS_SUCCESS");
export const get_shops_failure = actionCreator("GET_SHOPS_FAILURE");


export const change_window_height = actionCreator<number>('CHANGE_WINDOW_HEIGHT');

export const get_setsubi_master = actionCreator('GET_SETSUBI_MASTER');
export const get_setsubi_master_success = actionCreator("GET_SETSUBI_MASTER_SUCCESS");

export const change_cd = actionCreator<any>("CHANGE_CD");


export const masterReducer = reducerWithInitialState(initialState)
    .case(getRentMMedeasSuccess, (state, payload) => ({ ...state, rentMMedeas: payload }))
    //.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
    .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
    .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
    .case(get_user_details_success, (state, payload) => ({ ...state, user_details: payload }))
    .case(get_users_select, (state, payload) => ({ ...state, users_select: payload }))
    // .case(get_user_details_failure, (state, payload) => ({ ...state, user_details: payload }))
    .case(get_shops_success, (state, payload) => ({ ...state, shops: payload }))
    .case(get_shops_failure, (state, payload) => ({ ...state, shops: payload }))
    .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
    .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
    .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))

    .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))


    .case(change_window_height, (state, payload) => ({ ...state, window_height: payload }))

    .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }))

    .case(change_cd, (state, payload) => ({ ...state, cd: payload }))


    



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const masterSaga = [
//     takeEvery('RENT_M_MEDEAS_REQUEST', handleRequest),
// takeEvery('GET_SETSUBI_MASTER', handleRequestSetsubiMaster),
//     takeEvery('USERS_REQUEST', handleUsersRequest),
//     takeEvery('GET_USER_DETAILS', handleUserdetailsRequest),
//     takeEvery('GET_SHOPS', handleshopsRequest),
//     takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
//     takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;


// function* handleRequest(action:any) {
   
//     let { payload } = action;
//     payload = yield call(RentMMedeasApi.rentMMedeasList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//         // 成功時アクション呼び出し
//     console.log(payload)
//     yield put(getRentMMedeasSuccess(payload));

//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }



// function* handleUsersRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(UsersApi.UsersLIst, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getUsersSuccess(payload));

// // let userselect:any =[];

// const userselection = payload.map((item:any, i:number) => ({
//     value: item.id,
//     label: item.userNameAll
//   }))

//   yield put(get_users_select(userselection));


// // let userselectss  = payload.map(function (item:any, i:number) {
// //         let userselects:any =[];
// //         userselects.value=item.id;
// //         userselects.label=item.userNameAll
// //         userselect.concat(userselects)
// // })
// // console.log(persons)
// // yield put(get_users_select(userselectss));


//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }


// function* handleUserdetailsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(UsersApi.UsersDetails, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(get_user_details_success(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }


// function* handleshopsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(TmTenpoesApi.get_shops, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(get_shops_success(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }


// function* handleRentAgentsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentAgentsApi.RentAgentsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentAgentsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentMReasonsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentMReasonsApi.RentMReasonsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentMReasonsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRequestSetsubiMaster(action:any) {
  
//     const { payload, error }= yield call(TmSetsubiApi.get_setsubi, action.payload);
   
//        // サーバーからのレスポンスデータによる分岐処理
//        if (payload && !error) {
//            console.log(payload)
          
       
//            //yield put(get_setsubi_master_success(setsubis));
//            yield put(get_setsubi_master_success(payload));
   
//        } else {
//        //    // 失敗時アクション呼び出し
//        //    yield put(loginFailure(payload));
//        }
//    }