import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Css/Reserve.css';
import * as Reserve from './Saga/Reserve';
// import * as Master from '../../Store/Master';
import moment from 'moment';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import TatemonoComponent from './TatemonoComponent';


class ConfirmationVisitComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.send = this.send.bind(this);
        this.updateSend = this.updateSend.bind(this);
        // this.props.change_cd({cd:this.props.match.params.cd,no:this.props.match.params.no})
    }

    // onClickToConfirm = () => {
    // };


    formatdata(date) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();

        return (year + '年' + month + '月' + day + '日')
    }

    send() {
        console.log("send")
        if(this.props.tatemono){
            this.props.send_reserve_coming(this.props.tatemono)
        }
        else{
            this.props.send_reserve()
        }
        // this.props.changeFlg(6)
    }
    updateSend(){
        console.log("updateSend")
        this.props.update_send_reserve(this.props.list)
        // this.props.update_send_reserve(this.props.id)
    }

    render() {
        return (
            <div className="mb-3" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                {this.props.tid ?
                <TatemonoComponent tatemono={this.props.tatemono} tid={this.props.tid}/>
                :""}
                <div className="pl-3 pr-3" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                    <h3 className='Titles' style={{fontFamily:"Noto Sans JP,sans-serif"}}>入力内容の確認</h3>
                    <p style={{fontFamily:"Noto Sans JP,sans-serif"}}>まだ予約は完了していません。</p>
                    <p className="subtitle-2" style={{fontFamily:"Noto Sans JP,sans-serif"}}>内容をご確認のうえ、ページ下部にある「この内容で予約する」ボタンを押してください。</p>
                    <h4 className='Titles' style={{fontFamily:"Noto Sans JP,sans-serif"}}>予約内容</h4>
                    <table className="table_style">
                        <tbody>
                            <tr>
                                <th className="yoyaku_title"><i className="fas fa-clock mr-2"></i>ご希望日時</th>
                                <td>
                                    {this.props.reserve_date.date ? this.props.reserve_date.date.getMonth() + 1 + "/" + this.props.reserve_date.date.getDate() : ""}
                                    {this.props.reserve_date.date && moment(this.props.reserve_date.date).format("ddd") === "Sun" ? "(日)" : (moment(this.props.reserve_date.date).format("ddd") === "Mon") ? "(月)" : (moment(this.props.reserve_date.date).format("ddd") === "Tue") ? "(火)" : (moment(this.props.reserve_date.date).format("ddd") === "Wed") ? "(水)" : (moment(this.props.reserve_date.date).format("ddd") === "Thu") ? "(木)" : (moment(this.props.reserve_date.date).format("ddd") === "Fri") ? "(金)" : (moment(this.props.reserve_date.date).format("ddd") === "Sat") ? "(土)" : (moment(this.props.reserve_date.date).format("ddd") === "Holi") ? "()" : ""}
                                    {this.props.reserve_date.time && (this.props.reserve_date.time === 1) ? "　10時" : (this.props.reserve_date.time === 2) ? "　13時" : (this.props.reserve_date.time === 3) ? "　15時" : ""}
                                    {/* {this.props.reserve_date.time && (this.props.reserve_date.time === 1) ? "　9:30～" : (this.props.reserve_date.time === 2) ? "　11:00～" : (this.props.reserve_date.time === 3) ? "　12:30～" : (this.props.reserve_date.time === 4) ? "　14:00～" : (this.props.reserve_date.time === 5) ? "　15:30～" : ""} */}

                                </td>
                                {/* <td>{this.formatdata(this.props.reserve.date)} {reservationsTime.filter(a=>a.value===this.props.reserve.reservationsTime)[0].label}</td> */}
                            </tr>
                            <tr>
                                <th className="yoyaku_title"><i className="fas fa-phone mr-2"></i>電話番号</th>
                                <td>{this.props.reserve.tel1}</td>
                            </tr>
                            <tr>
                                <th className="yoyaku_title"><i className="fas fa-users mr-1"></i>ご予約人数</th>
                                <td>{this.props.reserve.reservationsPeople}人</td>
                            </tr>
                            <tr>
                                <th className="yoyaku_title"><i className="fas fa-edit mr-1"></i>店舗へのご要望・連絡事項</th>
                                <td>{this.props.reserve.requestRemarks}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="confirm_btn_style">
                    {this.props.reserve_send_loading ? <Button variant="outlined" disabled><i className="fas fa-pen"></i> 入力画面に戻る</Button> : <Button variant="outlined" onClick={() => this.props.changeFlg(1)} ><i className="fas fa-pen"></i> 入力画面に戻る</Button>}

                    {this.props.reserve_send_loading ? <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SendIcon />}
                        variant="outlined"
                        style={{ marginLeft: 10 }}
                    >
                        送信中
                    </LoadingButton> : <Button style={{ marginLeft: 10 }} variant="contained" onClick={this.props.id? () => this.updateSend():() => this.send()} endIcon={<SendIcon />}>この内容で予約する</Button>}
                    {/* <button className="btn btn-secondary btn-shadow mr-3" style={{ fontSize: 12 }} onClick={() => this.props.changeFlg(1)}><i className="fas fa-pen"></i> 入力画面に戻る</button>
                    <button className="btn btn-primary btn-shadow" onClick={this.send}><i className="far fa-paper-plane"></i> この内容で予約する</button> */}
                </div>
                <div>
                    {/* <button className="btn btn-secondary btn-shadow mr-3" onClick={()=>this.props.InfoMethod(3)}> Loding画面</button>
                <button className="btn btn-secondary btn-shadow mr-3" onClick={()=>this.props.InfoMethod(5)}> 失敗画面</button>
                <button className="btn btn-secondary btn-shadow mr-3" onClick={()=>this.props.InfoMethod(6)}> 予約内容/キャンセル画面</button>
                <button className="btn btn-secondary btn-shadow" onClick={()=>this.props.InfoMethod(7)}> ページ無し画面</button> */}
                    {/* <button className="btn btn-secondary btn-shadow mr-3" onClick={()=>this.props.changeFlg(1)}> 来店予約画面</button> */}
                </div>
            </div>
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {
        reserve: state.Reserve.reserve,
        cd: state.masterReducer.cd,
        reserve_date: state.Reserve.reserve_date,
        reserve_send_loading: state.Reserve.reserve_send_loading,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        send_reserve(state) {
            dispatch(Reserve.send_reserve(state))
        },

        send_reserve_coming(state){
            dispatch(Reserve.send_reserve_coming(state))
        },

        update_send_reserve(state) {
            dispatch(Reserve.update_send_reserve(state))
        },

        tatemonochange() {
            //dispatch(Responses.responseeditchange())
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        },
        change_reserve(state) {
            dispatch(Reserve.change_reserve(state))
        },
        change_reserve_flg(state) {
            dispatch(Reserve.change_reserve_flg(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationVisitComponent);
