import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import { actionCreators } from '../../Store/MailData';

// import DatePicker from "react-datepicker";
//import DatePicker from 'react-ja-date-picker'
import "react-datepicker/dist/react-datepicker.css";
//import { actionCreators } from '../../Store/WeatherForecasts';

import {reduxForm } from 'redux-form';
import './Css/Table.css';
// import '../../Css/DetailImage.css';
import './Css/DetailImage.css';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Card, CardContent } from '@material-ui/core';
import Slider from "react-slick";
import Button from '@material-ui/core/Button';
import Carousel, { Modal, ModalGateway } from 'react-images';

// const images = [
//     {
//       original: 'https://picsum.photos/id/1018/1000/600/',
//       thumbnail: 'https://picsum.photos/id/1018/250/150/',
//     },
//     {
//       original: 'https://picsum.photos/id/1015/1000/600/',
//       thumbnail: 'https://picsum.photos/id/1015/250/150/',
//     },
//     {
//       original: 'https://picsum.photos/id/1019/1000/600/',
//       thumbnail: 'https://picsum.photos/id/1019/250/150/',
//     },
//   ];
class ImageDispComponent extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen : false,
            images : [],
            images_index : 0,
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(e, state){


        let img = []
        let index = 0
        // if(key != null){
            let room_img =  this.props.search_rooms_detail.img_list
            room_img.forEach( function( img_value, key ) {
                if (img_value.original == e.target.src) {
                    index = key
                }
                img.push({ src: img_value.original })
            });
        // }

        

        this.setState({
            images:img,
            images_index :index,
            modalIsOpen: state
        });

      }


    render() {        
        // const settings = {
        //     dots: true,
        //     infinite: true,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        // };

        // const gaikan_url = "https://www.global-center.co.jp/claso/common/img/building/" + this.props.search_rooms_detail.tatemonoId + "/1/1"
        // // 最後は間取り図なので、最後から2番目までの画像を表示する。
        // const roomImg_num = this.props.search_rooms_detail.tRoomImg.length
        // const room_img = this.props.search_rooms_detail.tRoomImg.slice(0, roomImg_num - 2)
        
        return (
        <Card id='DatailImages' className="mb-3">
            <CardContent className="image_space">
            {/* <Slider {...settings}>
                {<div>
                    <img src={gaikan_url}  alt="物件画像" />
                </div>}
                {room_img.map((value,index)=>
                    <div key={index}>
                        <img  src={"https://www.global-center.co.jp/claso/common/img/room/" + value.tatemonoId + "/" + value.roomId + "/" + value.imgShubetsuNo + "/" + value.imgNo} alt="物件画像" />
                    </div>
                )}   
            </Slider> */}

                        <ModalGateway>
                            {this.state.modalIsOpen ? (
                            <Modal onClose={()=>this.setState({modalIsOpen: false})}>
                                <Carousel views={this.state.images} currentIndex={this.state.images_index} />
                            </Modal>
                            ) : null}
                        </ModalGateway>

              {this.props.search_rooms_detail.img_list? <ImageGallery items={this.props.search_rooms_detail.img_list} 
            showPlayButton={false}
            lazyLoad={true}
            autoPlay={false}
            showFullscreenButton={false}
            onClick={(e) => this.toggleModal(e, true)}
            />:""}
            </CardContent>
        </Card>
        );
    }
}


//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        
        search_rooms_detail:state.Detail.search_rooms_detail,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

ImageDispComponent = connect(
    mapStateToProps, mapDispatchToProps
)(ImageDispComponent);

ImageDispComponent = reduxForm({
    form: 'detailform', // a unique identifier for this form
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(ImageDispComponent)

export default ImageDispComponent