const authentication = require('react-azure-adb2c').default;








export const detail = (values: any) => {


    // const url = process.env.REACT_APP_API_OPENROOM_URL + `/api/MypageRequest/` + values;
    const url = `http://localhost:5000/v1/rent/responses/card/` + values;

  

    const token = authentication.getAccessToken();
    // alert(values.token)
    return fetch(url, {
        method: 'put',
        // body: JSON.stringify({ token: values.token }),


        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const insert = (values: any) => {


    let url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/responses/cardinsert/` + values.card_way + `/` + values.section_id;
    // let url = `http://localhost:5000/v1/rent/responses/cardinsert/` + values.card_way + `/` + values.section_id;

    // 更新の場合
    if(values.response_code != ""){
        // url = `http://localhost:5000/v1/rent/responses/cardupdate/` + values.card_way + `/` + values.section_id + `/` + values.response_code;
        url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/responses/cardupdate/` + values.card_way + `/` + values.section_id + `/` + values.response_code;
    }
    // const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/responses/cardinsert/` + values.card_way + `/` + values.section_id;
    // const url = `http://localhost:5000/v1/rent/responses/cardinsert/` + values.card_way + `/` + values.section_id;

    // alert(url)
    // alert(JSON.stringify(values.card_details))
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values.card_details),
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        // headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};