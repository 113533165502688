import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import './Css/Reserve.css';
import moment from "moment";
import Button from '@material-ui/core/Button';
import ReserveVisit from './ReserveVisitComponent';
import * as Reserve from './Saga/Reserve';
import LoadingButton from '@mui/lab/LoadingButton';


const reservationsTime = [
    {value:"1", label:"10時00分"},
    {value:"2", label:"10時30分"},
    {value:"3", label:"11時00分"},
    {value:"4", label:"11時30分"},
    {value:"5", label:"12時00分"},
    {value:"6", label:"12時30分"},
    {value:"7", label:"13時00分"},
    {value:"8", label:"13時30分"},
    {value:"9", label:"14時00分"},
    {value:"10", label:"14時30分"},
    {value:"11", label:"15時00分"},
    {value:"12", label:"15時30分"},
    {value:"13", label:"16時00分"},
    {value:"14", label:"16時30分"},
    {value:"15", label:"17時00分"},
    {value:"16", label:"17時30分"},
    {value:"17", label:"18時00分"},
];


class ReserveContentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelReason: "",
            error_flg: 0,
            disp: false,
        };
        this.checkText = this.checkText.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeBtn = this.changeBtn.bind(this)
    }


    // onClickToConfirm = () => {
    // };  
    
    // formatdata(date){
    // var year = date.getFullYear();
    // var month = date.getMonth() + 1;
    // var day = date.getDate();
     
    // return (year + '年' + month + '月' + day + '日')
    // }
    
    formatdata(date){
        moment.locale("ja", {
            weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
            weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
        });
           
        // 現在日付と曜日
        return(moment(date).utc().format('YYYY年MM月DD日(ddd) HH時'))
    }

    handleChange(e){
        this.setState({cancelReason: e.target.value});
        // console.log(e.target)
    }

    checkText(){
        if(this.state.cancelReason === ""){
            this.setState({error_flg:1})
        }else{
            this.setState({error_flg:2})
            this.props.cancel_send_reserve({
                id:this.props.list.id,
                cancelReason:this.state.cancelReason,
                start_at:this.props.list.start_at,
            })
        }

        

            
    }

    changeBtn(){
        this.setState({ disp: true })
        console.log(this.state.disp)
    }


    render() {
        console.log(this.props.reserve_flg,"reserve")
        console.log(this.props.cansel_loading,"ロード")
       
    return (
        <div id='Change' className="reserve_height">
            {this.props.num === 1 ?<div className="mt-3 mb-5" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                {!this.state.disp ?<div className="pl-3 pr-3" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                    <h3 className='Titles' style={{fontFamily:"Noto Sans JP,sans-serif"}}>ご予約内容変更</h3>
                    <table className="table_style" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                        <tbody>
                            {this.props.list.tatemono_name?<tr>
                            <th className="yoyaku_title" style={{fontFamily:"Noto Sans JP,sans-serif"}}><i className="fa fa-building mr-2"></i>物件名</th>
                                <td style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                                {this.props.list.tatemono_name}　{this.props.list.room_name}
                                </td>
                            </tr>:""}
                            <tr>
                                <th className="yoyaku_title" style={{fontFamily:"Noto Sans JP,sans-serif"}}><i className="fas fa-clock mr-2"></i>ご希望日時</th>
                                <td style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                                {this.formatdata(this.props.list.start_at)}
                                </td>
                            </tr>
                            {this.props.reserve.tel1 ? <tr>
                                <th className="yoyaku_title" style={{fontFamily:"Noto Sans JP,sans-serif"}}><i className="fas fa-phone mr-2"></i>電話番号</th>
                                <td style={{fontFamily:"Noto Sans JP,sans-serif"}}>{this.props.reserve.tel1}</td>
                            </tr>:""}
                            <tr>
                                <th className="yoyaku_title" style={{fontFamily:"Noto Sans JP,sans-serif"}}><i className="fas fa-users mr-1"></i>ご予約人数</th>
                                <td style={{fontFamily:"Noto Sans JP,sans-serif"}}>{this.props.list.reservations_people}人</td>
                            </tr>
                            <tr>
                                <th className="yoyaku_title" style={{fontFamily:"Noto Sans JP,sans-serif"}}><i className="fas fa-edit mr-1"></i>店舗へのご要望・連絡事項</th>
                                <td style={{fontFamily:"Noto Sans JP,sans-serif"}}>{this.props.list.reservations_remarks}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="confirm_btn_style" style={{marginTop:20,fontFamily:"Noto Sans JP,sans-serif"}}>
                        <Button variant="outlined" onClick={this.changeBtn}>変更する</Button>
                    </div>                    
                </div>:""}
            </div>
            :""}

            {this.state.disp ? <ReserveVisit id={this.props.list.id} list={this.props.list}/>:""}

            {this.props.num === 2 && this.props.reserve_flg !== 6 ?<div className="mt-3 mb-3" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                <div className="pl-3 pr-3" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                    <h3 className='Titles' style={{fontFamily:"Noto Sans JP,sans-serif"}}>ご予約のキャンセル</h3>
                    <div className="row">
                        <div className="col-12 mb-1" style={{fontFamily:"Noto Sans JP,sans-serif"}}><i className="fas fa-edit mr-1"></i>キャンセル理由</div>
                        <div className="col-12 mb-1" style={{fontFamily:"Noto Sans JP,sans-serif"}}>キャンセル理由をお聞かせください。</div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TextField
                                error={this.state.error_flg === 1}
                                id="cancelReason"
                                name="cancelReason"
                                multiline
                                rows="3"
                                defaultValue={this.state.cancelReason}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                helperText={this.state.error_flg === 1 ? "入力してください。" : ""}
                                placeholder="例) 引っ越し時期が延期になったため"
                                style={{width:"100%", backgroundColor:"white", marginTop:0,fontFamily:"Noto Sans JP,sans-serif"}}
                            />
                        </div>
                    </div>
                    <div className="confirm_btn_style" style={{marginTop:0}}>
                    {/* {this.props.cansel_loading ? <LoadingButton
                            loading
                            loadingPosition="start"
                            variant="outlined"
                            style={{ marginLeft: 10 }}
                            className='LoadingButton'
                        >
                            送信中
                        </LoadingButton> :
                        <button className="btn btn-primary btn-shadow" style={{background:"rgb(233, 87, 63)",borderColor:"rgb(233, 87, 63)"}} onClick={this.checkText}> キャンセル</button>
                    } */}
                    <button className="btn btn-primary btn-shadow" style={{background:"rgb(233, 87, 63)",borderColor:"rgb(233, 87, 63)",fontFamily:"Noto Sans JP,sans-serif"}} onClick={this.checkText}> キャンセル</button>
                    </div>
                </div>
            
            </div>
            :""}

            {this.props.num === 2 && this.props.reserve_flg === 6 ? 
            <div className="done_style container" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                <h3 className='Titles' style={{textAlign:"left",paddingBottom:"50px",fontFamily:"Noto Sans JP,sans-serif"}}>送信完了しました。</h3>

                <a href={"/Rent/" + this.props.cd.cd + "/" + this.props.cd.no + "/"}
                style={{borderRadius: "5px",textAlign:"center", padding:'10px',color:"#fff",background:' rgb(74, 192, 240)', fontFamily:"Noto Sans JP,sans-serif"}}>
                    トップページに戻る
                </a>
            </div>
            :""}
    </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        cd: state.masterReducer.cd,
        cansel_loading: state.Reserve.cansel_loading,
        reserve: state.Reserve.reserve,
        reserve_date: state.Reserve.reserve_date,
        reserve_flg: state.Reserve.reserve_flg,
    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        tatemonochange() {
            //dispatch(Responses.responseeditchange())
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        },
        cancel_send_reserve(state) {
            dispatch(Reserve.cancel_send_reserve(state))
        },  

        change_cancel_loading(state) {
            dispatch(Reserve.change_cancel_loading(state))
        },  
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveContentComponent);
