import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Master from '../../Store/Master';
import * as Credit from './Saga/Credit';
import Layout from '../Layout/LayoutComponent';
import './Css/Credit.css';
import visa from './Img/visa.jpg';
import jcb from './Img/jcb.jpg';
import master from './Img/mastercard.png';
import american from './Img/american.jpg';
import diners from './Img/dinersclub.jpg';
import RentHeaderComponent from '../Layout/RentHeaderComponent';

var moment = require("moment");
class CreditConfirmComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // comp: false
        };
        // this.props.change_line_comp(false)
        // this.props.change_cd({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        // this.props.get_line_code(this.props.match.params.cd)
        // this.handlesubmit = this.handlesubmit.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    // handlesubmit(state) {
    //     // console.log(state)
    //     // alert()
    //     this.setState({ comp: true })
    // }

    render() {

        return (
            <div className="app" style={{ height: "100%" }}>
                <RentHeaderComponent />
                <div style={{ minHeight: "100%", backgroundColor: "whitesmoke" }}>
                    <div style={{ backgroundColor: "#f5f5f5" }}>
                        <div className="display_style">
                            <div className="container">
                                <div className="frame">
                                    <div class="confirm_h1">契約内容確認</div>
                                    <table>
                                        <colgroup width="20%" />
                                        <colgroup width="30%" />
                                        <colgroup width="20%" />
                                        <colgroup width="30%" />
                                        <tbody>
                                            <tr>
                                                <th>物件名</th>
                                                <td>
                                                    <div>{this.props.credit_detail.building_name}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>部屋名</th>
                                                <td>
                                                    <div class="fn-middle">{this.props.credit_detail.room_name}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>契約日</th>
                                                <td colSpan="3">
                                                    <span>{moment(this.props.credit_detail.contract_date).format('YYYY年MM月DD日')}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{ marginTop: 5 }}>
                                        <colgroup width="40%" />
                                        <colgroup width="60%" />
                                        <tbody>
                                            <tr>
                                                <th colSpan="2" style={{ textAlign: "center" }}>条件</th>
                                            </tr>
                                            {this.props.credit_detail.monies ? this.props.credit_detail.monies.map(function (value) {
                                                return <tr>
                                                    <th>{value.name}</th>
                                                    <td><span>{value.amount > 0 ? (value.amount + value.tax_amount).toLocaleString() + "円" : value.rent_m_etc_id > 0 ? value.etc_name : "-"}</span></td>
                                                </tr>
                                            }) : ""}


                                        </tbody>
                                    </table>
                                    <div style={{ fontSize: "15px", marginTop: "5px" }}>下記の「カード決済へ進む」ボタンからカード情報の入力をお願いします。</div>
                                </div>
                                <div style={{ border: "1px solid #CCCCCC", padding: "5px", marginBottom: "10px", float: "right" }}>
                                    <img src={visa} style={{ height: 40, paddingRight: 5 }} />
                                    <img src={jcb} style={{ height: 40, paddingRight: 5 }} />
                                    <img src={master} style={{ height: 40, paddingRight: 5 }} />
                                    <img src={american} style={{ height: 40, paddingRight: 5 }} />
                                    <img src={diners} style={{ height: 40 }} />
                                </div>
                            </div>
                            <div className="container" style={{ marginTop: 80, marginBottom: 50, textAlign: "center" }}>
                                <form method="post" action="https://www.paydesign.jp/settle/settle3/bp3.dll" accept-charset="Shift_JIS">
                                    <input name="IP" type="hidden" value="HB78665000" />
                                    <input name="SID" type="hidden" value={(Math.floor(Math.random() * (999 + 1 - 100)) + 100).toString() + this.props.credit_detail.id.toString()} />
                                    <input name="K1" type="hidden" value={this.props.credit_detail.moneysum} />
                                    <input name="STORE" type="hidden" value="51" />

                                    <input name="IP_USER_ID" type="hidden" value={this.props.credit_detail.id} />
                                    <input name="KAKUTEI" type="hidden" value="2" />
                                    <input name="N1" type="hidden" value={this.props.credit_detail.building_name + ' ' + this.props.credit_detail.room_name} />

                                    <button type="submit" className="btn_style"><span style={{ verticalAlign: "super" }}>カード登録へ進む</span></button>
                                </form>
                                {/* <button className="btn_style" onClick={this.lineopen}><span style={{ verticalAlign: "super" }}>カード決済へ進む</span></button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,

        credit_detail: state.Credit.credit_detail,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_cd(state) {
        //     dispatch(Master.change_cd(state))
        // },
        // // get_mypage_request(state) {
        // //     dispatch(Line.get_mypage_request(state))
        // // },
        // change_line_comp(state) {
        //     dispatch(Line.change_line_comp(state))
        // },
        // get_line_code(state) {
        //     dispatch(Line.get_line_code(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditConfirmComponent);
