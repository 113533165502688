import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GetAppIcon from '@material-ui/icons/GetApp';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import * as Download from './Saga/Download';
import './Css/Download.css';
var moment = require('moment-timezone');

class DownloadCompComponent extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            selectedIndex: 0
        };
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.setSelectedIndex = this.setSelectedIndex.bind(this);
    }

    handleListItemClick = (event, index) => {
        this.setSelectedIndex(index);
    }

    setSelectedIndex(i) {
        this.setState.selectedIndex = i
    }
    byte(size) {
        const kb = 1024
        const mb = Math.pow(kb, 2)
        const gb = Math.pow(kb, 3)
        const tb = Math.pow(kb, 4)


        if (size >= tb) return Math.floor((size / tb) * Math.pow(10, 1)) / Math.pow(10, 1) + 'TB'
        if (size >= gb) return Math.floor((size / gb) * Math.pow(10, 1)) / Math.pow(10, 1) + 'GB'
        if (size >= mb) return Math.floor((size / mb) * Math.pow(10, 1)) / Math.pow(10, 1) + 'MB'
        if (size >= kb) return Math.floor((size / kb) * Math.pow(10, 1)) / Math.pow(10, 1) + 'KB'

    }
    render() {

        return (
            <div className="container my-5 py-5" style={{ textAlign: "-webkit-center" }}>
                <p className="title_bold">株式会社グローバルセンターより</p>
                <p className="title_bold">ファイル{this.props.download_file_list.details ? this.props.download_file_list.details.length : 0}件</p>
                <p>{moment(this.props.download_file_list.limited_at).format('YYYY年M月DD日')}までダウンロードできます。</p>
                <p>下記をクリックしてください。</p>
                <div className="frame_style">
                    <List component="nav" aria-label="download">
                        {this.props.download_file_list.details ? this.props.download_file_list.details.map(function (value) {


                            return <ListItem
                                key={value.code}
                                button
                                // selected={this.selectedIndex === 0}
                                onClick={() => this.props.download_file({ fcd: value.code, name: value.disp_name, })}
                            >
                                <ListItemIcon>
                                    <GetAppIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={value.disp_name} />
                                <ListItemText secondary={

                                    value.size > 0 ? this.byte(value.size) : ""} />

                            </ListItem>

                        }, this) : ""}


                    </List>
                </div>
            </div >
        );
    }
}


const mapStateToProps = state => {
    return {
        // users: state.masterReducer.users_select,
        download_file_list: state.Download.download_file_list,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        download_file(state) {
            dispatch(Download.download_file(state))
        },

    };
}

DownloadCompComponent = connect(
    mapStateToProps, mapDispatchToProps
)(DownloadCompComponent);

// DownloadCompComponent = reduxForm({
//     form: 'tenantform', // a unique identifier for this form
// })(DownloadCompComponent)

export default DownloadCompComponent