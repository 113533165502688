const authentication = require('react-azure-adb2c').default;


export const download = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/limited/public/docs/download/` + values;

    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/limited/public/docs/download/` + values;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(response => response.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
