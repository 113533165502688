import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { eventChannel } from 'redux-saga';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as rent_responses_api from '../Api/rent_responses_api';
import * as rent_hearing_sheets_api from '../Api/rent_hearing_sheets_api';
import * as tm_zip_address_api from '../Api/tm_zip_address_api';
import * as rent_contracts_api from '../Api/rent_contracts_api';
import * as rent_m_job_categorys_api from '../Api/rent_m_job_categorys_api';
import * as rent_m_medea_api from '../Api/rent_m_medea_api';
import * as rent_m_reasons_api from '../Api/rent_m_reasons_api';
import * as rent_m_schools_api from '../Api/rent_m_schools_api';
import * as rent_m_jobs_api from '../Api/rent_m_jobs_api';

import firebase from '../../../firebase';
import { PublicTwoTone } from '@material-ui/icons';
import { array } from 'prop-types';
import moment from 'moment';
export interface MapSearchState {
    card_details: any;
    card_comp: boolean;
    card_section_id: number;
    card_way: number;
    card_response_code: string;
    card_sign_in_state: string;

    rent_contracts: any;
    rent_contracts_renter: any;
    rent_contracts_renter_form_check: boolean;
    rent_contracts_renter_pass: string;
    rent_contracts_renter_cd: string;
    application_comp:boolean;
    
    rent_m_job_categorys: any;
    rent_m_medea: any;
    rent_m_reasons: any;
    rent_m_schools: any;
    rent_m_jobs: any;

    // application_num: number;
    // search_rooms_detail:any;
}
const initialState: MapSearchState = {

    card_details: {},
    card_comp: false,
    card_section_id: 0,
    card_way: 0,
    card_response_code: "",
    card_sign_in_state: "",

    rent_contracts_renter:[],
    rent_contracts:[],
    rent_contracts_renter_form_check:false,
    rent_contracts_renter_pass: "",
    rent_contracts_renter_cd: "",
    application_comp:false,
    rent_m_job_categorys:[],
    rent_m_medea:[],
    rent_m_reasons:[],
    rent_m_schools:[],
    rent_m_jobs:[],
    // application_num: 0,
    // search_rooms_detail: [],
}



///action 
const actionCreator = actionCreatorFactory();
export const get_card_details = actionCreator<any>("GET_CARD_DETAILS");
export const change_card_details = actionCreator<any>("CHANGE_CARD_DETAILS");
export const update_card_details = actionCreator<any>('UPDATE_CARD_DETAILS');
export const change_card_comp = actionCreator<any>("CHANGE_CARD_COMP");
export const get_zip_address = actionCreator<any>("GET_ZIP_ADDRESS");
export const change_card_section_id = actionCreator<any>("CHANGE_CARD_SECTION_ID");
export const change_card_way = actionCreator<any>("CHANGE_CARD_WAY");
export const change_card_response_code = actionCreator<any>("CHANGE_CARD_RESPONSE_CODE");
export const card_sign_in = actionCreator<any>("CARD_SIGN_IN");
export const check_card_sign_in = actionCreator<any>("CHECK_CARD_SIGN_IN");
export const change_card_sign_in_state = actionCreator<any>("CHANGE_CARD_SIGN_IN_STATE");


export const get_rent_contracts_application = actionCreator('GET_RENT_CONTRACTS_APPLICATION');
export const change_rent_contracts = actionCreator<any>('CHANGE_RENT_CONTRACTS');
export const update_rent_contracts = actionCreator('UPDATE_RENT_CONTRACTS');
export const change_rent_contracts_renter = actionCreator<any>('CHANGE_RENT_CONTRACTS_RENTER');
export const get_rent_contracts_renter_form_check = actionCreator<any>('GET_RENT_CONTRACTS_RENTER_FORM_CHECK');
export const change_rent_contracts_renter_form_check = actionCreator<boolean>('CHANGE_RENT_CONTRACTS_RENTER_FORM_CHECK');

export const change_rent_contracts_renter_cd = actionCreator<string>('CHANGE_RENT_CONTRACTS_RENTER_CD');
export const change_rent_contracts_renter_pass = actionCreator<string>('CHANGE_RENT_CONTRACTS_RENTER_PASS');
export const get_zip_address_renter = actionCreator<any>("GET_ZIP_ADDRESS_RENTER");
export const change_application_comp = actionCreator<any>("CHANGE_APPLICATION_COMP");
export const get_rent_m_job_categorys = actionCreator<any>("GET_RENT_M_JOB_CATEGORYS");
export const change_rent_m_job_categorys = actionCreator<any>("CHANGE_RENT_M_JOB_CATEGORYS");
export const get_rent_m_medea = actionCreator<any>("GET_RENT_M_MEDEA");
export const change_rent_m_medea = actionCreator<any>("CHANGE_RENT_M_MEDEA");
export const get_rent_m_reasons = actionCreator<any>("GET_RENT_M_REASONS");
export const change_rent_m_reasons = actionCreator<any>("CHANGE_RENT_M_REASONS");
export const get_rent_m_schools = actionCreator<any>("GET_RENT_M_SCHOOLS");
export const change_rent_m_schools = actionCreator<any>("CHANGE_RENT_M_SCHOOLS");
export const get_rent_m_jobs = actionCreator<any>("GET_RENT_M_JOBS");
export const change_rent_m_jobs = actionCreator<any>("CHANGE_RENT_M_JOBS");


export const cardReducer = reducerWithInitialState(initialState)
    .case(change_card_details, (state, payload) => ({ ...state, card_details: payload }))
    .case(change_card_comp, (state, payload) => ({ ...state, card_comp: payload }))
    .case(change_card_section_id, (state, payload) => ({ ...state, card_section_id: payload }))
    .case(change_card_way, (state, payload) => ({ ...state, card_way: payload }))
    .case(change_card_response_code, (state, payload) => ({ ...state, card_response_code: payload }))
    .case(change_card_sign_in_state, (state, payload) => ({ ...state, card_sign_in_state: payload }))

    // .case(change_application_form, (state, payload) => ({ ...state, application_form: payload }))
    .case(change_rent_contracts, (state, payload) => ({ ...state, rent_contracts: payload }))
    .case(change_rent_contracts_renter, (state, payload) => ({ ...state, rent_contracts_renter: payload }))
    .case(change_rent_contracts_renter_form_check, (state, payload) => ({ ...state, rent_contracts_renter_form_check: payload }))

    .case(change_rent_contracts_renter_cd, (state, payload) => ({ ...state, rent_contracts_renter_cd: payload }))
    .case(change_rent_contracts_renter_pass, (state, payload) => ({ ...state, rent_contracts_renter_pass: payload }))
    .case(change_application_comp, (state, payload) => ({ ...state, application_comp: payload }))
    .case(change_rent_m_job_categorys, (state, payload) => ({ ...state, rent_m_job_categorys: payload }))
    .case(change_rent_m_medea, (state, payload) => ({ ...state, rent_m_medea: payload }))
    .case(change_rent_m_reasons, (state, payload) => ({ ...state, rent_m_reasons: payload }))
    .case(change_rent_m_schools, (state, payload) => ({ ...state, rent_m_schools: payload }))
    .case(change_rent_m_jobs, (state, payload) => ({ ...state, rent_m_jobs: payload }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const cardSaga = [
takeEvery('GET_CARD_DETAILS', handle_get_card_details),
takeEvery('UPDATE_CARD_DETAILS', handle_update_card_details),
takeEvery('GET_ZIP_ADDRESS', handle_get_zip_address),

takeEvery('CARD_SIGN_IN', handle_card_sign_in),
takeEvery('CHECK_CARD_SIGN_IN', handle_check_card_sign_in),

takeEvery('GET_RENT_CONTRACTS_APPLICATION', handle_get_rent_contracts_application),
takeEvery('UPDATE_RENT_CONTRACTS', handle_update_rent_contracts),
takeEvery('GET_RENT_CONTRACTS_RENTER_FORM_CHECK', handle_get_rent_contracts_renter_form_check),
takeEvery('GET_ZIP_ADDRESS_RENTER', handle_get_zip_address_renter),
takeEvery('GET_RENT_M_JOB_CATEGORYS', handle_get_rent_m_job_categorys),
takeEvery('GET_RENT_M_MEDEA', handle_get_rent_m_medea),
takeEvery('GET_RENT_M_REASONS', handle_get_rent_m_reasons),
takeEvery('GET_RENT_M_SCHOOLS', handle_get_rent_m_schools),
takeEvery('GET_RENT_M_JOBS', handle_get_rent_m_jobs),

    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_card_details(action: any) {

    const { payload, error } = yield call(rent_responses_api.detail, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        yield put(change_card_details(payload));

        // yield put(get_search_rooms_success(payload));


    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_update_card_details(action: any): any {
    const state = yield select();

    // const { payload, error } = yield call(rent_responses_api.insert, { card_details: state.Card.card_details, card_way: state.Card.card_way, section_id: state.Card.card_section_id, response_code: state.Card.card_response_code });

    const { payload, error } = yield call(rent_hearing_sheets_api.insert, { card_details: state.Card.card_details, card_way: state.Card.card_way, section_id: state.Card.card_section_id, response_code: state.Card.card_response_code });


    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        yield put(change_card_comp(true));
        //    yield put(get_search_rooms_detail_success(payload));

    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_zip_address(action: any): any {
    const state = yield select();

    const { payload, error } = yield call(tm_zip_address_api.get_address, String(state.Card.card_details.post.replace(/[^0-9]/g, '')));

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し

        let card_details = state.Card.card_details
        card_details.add1 = payload.todoufuken_name
        card_details.add2 = payload.shikuchouson_name
        card_details.add3 = payload.chouiki_name

        yield put(change_card_details({}));
        yield put(change_card_details(card_details));
        //    yield put(get_search_rooms_detail_success(payload));

    } else {
        // alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_card_sign_in(action: any): any {
    const state = yield select();

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
        .then(() => {

            firebase.auth().signInAnonymously()
                .then((value: any) => {


                    alert(value.user.l)
                    console.log(value)
                    // yield put(change_card_sign_in_state(users.l))
                    // Signed in..
                })
                .catch((error: any) => {
                    alert(error.message)
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // ...
                });

        })
        .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
        });
}

function* handle_check_card_sign_in(action: any) {
alert("aaaaaaaaaaaaaaaaa")
    const idtoken: string = yield call(onAuthStateChanged);

    // alert(user)
    if (idtoken) {
        console.log(idtoken)


        const { payload, error } = yield call(rent_responses_api.detail, { cd: "B798dRaQ", token: idtoken });

        // サーバーからのレスポンスデータによる分岐処理
        if (payload && !error) {
            console.log(payload)

            yield put(change_card_details({ name1: payload.name1 }));
            // yield put(change_card_comp(payload));


        } else {
            alert(error)
            //    // 失敗時アクション呼び出し
            //    yield put(loginFailure(payload));
        }
        yield put(change_card_sign_in_state(idtoken));
    }
}

function onAuthStateChanged() {
    return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {
                const jwtToken = user.getIdToken();

                resolve(jwtToken);
            } else {

                // reject(' ');

            }
        });
    });
}

// メディアリスト
function* handle_get_rent_m_medea(action: any) {

    const { payload, error } = yield call(rent_m_medea_api.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
// console.log(payload)
// console.log("aaaaaa")
        // 成功時アクション呼び出し
        yield put(change_rent_m_medea(payload))
    } else {

    }

}

// 理由
function* handle_get_rent_m_reasons(action: any) {

    const { payload, error } = yield call(rent_m_reasons_api.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_m_reasons(payload))
    } else {

    }
}

// 学校
function* handle_get_rent_m_schools(action: any) {

    const { payload, error } = yield call(rent_m_schools_api.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_m_schools(payload))
    } else {

    }
}

// 職業
function* handle_get_rent_m_jobs(action: any) {

    const { payload, error } = yield call(rent_m_jobs_api.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_m_jobs(payload))
    } else {

    }
}

// 入居者リスト
function* handle_get_rent_contracts_application(action: any): any {

    const state = yield select();

    const { payload, error } = yield call(rent_contracts_api.detail, { cd: state.Card.rent_contracts_renter_cd, pass: state.Card.rent_contracts_renter_pass });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        if(payload.length > 0){

            const data = payload[0]
        // 成功時アクション呼び出し
// alert(payload.renter1_name1)
        var list = []

            list.push({
                is_disp : 1,
                name1_1 : data.renter1_name1 ? data.renter1_name1.split(" ")[0] : "", 
                name1_2 : data.renter1_name1 ? data.renter1_name1.split(" ")[1] : "", 
                kana1_1 : data.renter1_kana1 ? data.renter1_kana1.split(" ")[0] : "", 
                kana1_2 : data.renter1_kana1 ? data.renter1_kana1.split(" ")[1] : "", 
                birthday_year : data.renter1_birthday ? parseInt(moment(data.renter1_birthday).format("YYYY")) : "", 
                birthday_month : data.renter1_birthday ? parseInt(moment(data.renter1_birthday).format("MM")) : "",
                birthday_day : data.renter1_birthday ? parseInt(moment(data.renter1_birthday).format("DD")) : "",
                sex : data.renter1_sex, 
                rent_m_relation_id : data.renter1_rent_m_relation_id, 
                affiliation : data.renter1_affiliation, 
                tel1 : data.renter1_tel1, 
                office_name : data.renter1_office_name,
                office_tel : data.renter1_office_tel,
                add1 : data.renter1_add1,
                post : data.renter1_post,
                rent_m_job_category_id : data.renter1_rent_m_job_category_id,
            })

        
            list.push({
                is_disp : (data.renter2_name1 || data.renter2_kana1 || data.renter2_birthday || data.renter2_sex || data.renter2_rent_m_relation_id || data.renter2_affiliation || data.renter2_tel1 || data.renter2_office_name) ? 1 : 0,
                name1_1 : data.renter2_name1 ? data.renter2_name1.split(" ")[0] : "", 
                name1_2 : data.renter2_name1 ? data.renter2_name1.split(" ")[1] : "", 
                kana1_1 : data.renter2_kana1 ? data.renter2_kana1.split(" ")[0] : "", 
                kana1_2 : data.renter2_kana1 ? data.renter2_kana1.split(" ")[1] : "", 
                birthday_year : data.renter2_birthday ? parseInt(moment(data.renter2_birthday).format("YYYY")) : "", 
                birthday_month : data.renter2_birthday ? parseInt(moment(data.renter2_birthday).format("MM")) : "",
                birthday_day : data.renter2_birthday ? parseInt(moment(data.renter2_birthday).format("DD")) : "",
                sex : data.renter2_sex, 
                rent_m_relation_id : data.renter2_rent_m_relation_id, 
                affiliation : data.renter2_affiliation, 
                tel1 : data.renter2_tel1, 
                office_name : data.renter2_office_name,
            })
        
            list.push({
                is_disp : (data.renter3_name1 || data.renter3_kana1 || data.renter3_birthday || data.renter3_sex || data.renter3_rent_m_relation_id || data.renter3_affiliation || data.renter3_tel1 || data.renter3_office_name) ? 1 : 0,
                name1_1 : data.renter3_name1 ? data.renter3_name1.split(" ")[0] : "", 
                name1_2 : data.renter3_name1 ? data.renter3_name1.split(" ")[1] : "", 
                kana1_1 : data.renter3_kana1 ? data.renter3_kana1.split(" ")[0] : "", 
                kana1_2 : data.renter3_kana1 ? data.renter3_kana1.split(" ")[1] : "", 
                birthday_year : data.renter3_birthday ? parseInt(moment(data.renter3_birthday).format("YYYY")) : "", 
                birthday_month : data.renter3_birthday ? parseInt(moment(data.renter3_birthday).format("MM")) : "",
                birthday_day : data.renter3_birthday ? parseInt(moment(data.renter3_birthday).format("DD")) : "",
                sex : data.renter3_sex, 
                rent_m_relation_id : data.renter3_rent_m_relation_id, 
                affiliation : data.renter3_affiliation, 
                tel1 : data.renter3_tel1, 
                office_name : data.renter3_office_name,
            })
   
            list.push({
                is_disp : (data.renter4_name1 || data.renter4_kana1 || data.renter4_birthday || data.renter4_sex || data.renter4_rent_m_relation_id || data.renter4_affiliation || data.renter4_tel1 || data.renter4_office_name) ? 1 : 0,
                name1_1 : data.renter4_name1 ? data.renter4_name1.split(" ")[0] : "", 
                name1_2 : data.renter4_name1 ? data.renter4_name1.split(" ")[1] : "", 
                kana1_1 : data.renter4_kana1 ? data.renter4_kana1.split(" ")[0] : "", 
                kana1_2 : data.renter4_kana1 ? data.renter4_kana1.split(" ")[1] : "", 
                birthday_year : data.renter4_birthday ? parseInt(moment(data.renter4_birthday).format("YYYY")) : "", 
                birthday_month : data.renter4_birthday ? parseInt(moment(data.renter4_birthday).format("MM")) : "",
                birthday_day : data.renter4_birthday ? parseInt(moment(data.renter4_birthday).format("DD")) : "",
                sex : data.renter4_sex, 
                rent_m_relation_id : data.renter4_rent_m_relation_id, 
                affiliation : data.renter4_affiliation, 
                tel1 : data.renter4_tel1, 
                office_name : data.renter4_office_name,
            })


            list.push({
                is_disp : (data.renter5_name1 || data.renter5_kana1 || data.renter5_birthday || data.renter5_sex || data.renter5_rent_m_relation_id || data.renter5_affiliation || data.renter5_tel1 || data.renter5_office_name) ? 1 : 0,
                name1_1 : data.renter5_name1 ? data.renter5_name1.split(" ")[0] : "", 
                name1_2 : data.renter5_name1 ? data.renter5_name1.split(" ")[1] : "", 
                kana1_1 : data.renter5_kana1 ? data.renter5_kana1.split(" ")[0] : "", 
                kana1_2 : data.renter5_kana1 ? data.renter5_kana1.split(" ")[1] : "", 
                birthday_year : data.renter5_birthday ? parseInt(moment(data.renter5_birthday).format("YYYY")) : "", 
                birthday_month : data.renter5_birthday ? parseInt(moment(data.renter5_birthday).format("MM")) : "",
                birthday_day : data.renter5_birthday ? parseInt(moment(data.renter5_birthday).format("DD")) : "",
                sex : data.renter5_sex, 
                rent_m_relation_id : data.renter5_rent_m_relation_id, 
                affiliation : data.renter5_affiliation, 
                tel1 : data.renter5_tel1, 
                office_name : data.renter5_office_name,
            })


        
            list.push({
                is_disp : (data.renter6_name1 || data.renter6_kana1 || data.renter6_birthday || data.renter6_sex || data.renter6_rent_m_relation_id || data.renter6_affiliation || data.renter6_tel1 || data.renter6_office_name) ? 1 : 0,
                name1_1 : data.renter6_name1 ? data.renter6_name1.split(" ")[0] : "", 
                name1_2 : data.renter6_name1 ? data.renter6_name1.split(" ")[1] : "", 
                kana1_1 : data.renter6_kana1 ? data.renter6_kana1.split(" ")[0] : "", 
                kana1_2 : data.renter6_kana1 ? data.renter6_kana1.split(" ")[1] : "", 
                birthday_year : data.renter6_birthday ? parseInt(moment(data.renter6_birthday).format("YYYY")) : "", 
                birthday_month : data.renter6_birthday ? parseInt(moment(data.renter6_birthday).format("MM")) : "",
                birthday_day : data.renter6_birthday ? parseInt(moment(data.renter6_birthday).format("DD")) : "",
                sex : data.renter6_sex, 
                rent_m_relation_id : data.renter6_rent_m_relation_id, 
                affiliation : data.renter6_affiliation, 
                tel1 : data.renter6_tel1, 
                office_name : data.renter6_office_name,
            })
            
        if(list[1].is_disp == 1) list[0].is_disp = 1
        if(list[2].is_disp == 1) list[1].is_disp = 1
        if(list[3].is_disp == 1) list[2].is_disp = 1
        if(list[4].is_disp == 1) list[3].is_disp = 1
        if(list[5].is_disp == 1) list[4].is_disp = 1
        
        // console.log("aaaaaaaaaaa")
        // console.log(list)
        // console.log(data)
// change_rent_contracts_renter_form_check
        
// console.log(data)
        yield put(change_rent_contracts(data))

        yield put(change_rent_contracts_renter(list))
        yield put(get_rent_contracts_renter_form_check(list))


        }
    } else {

    }
}

// 入居者リスト更新
function* handle_update_rent_contracts(action: any): any {

    // alert(action.payload)

    const state = yield select();

    let rent_contracts =  state.Card.rent_contracts
    let rent_contracts_renter =  state.Card.rent_contracts_renter

    const { payload, error } = yield call(rent_contracts_api.update, {rent_contracts : rent_contracts, renter : rent_contracts_renter});

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // let rent_corporate_contacts_list= state.corporation.rent_corporate_contacts_list

        // // 一覧の情報更新
        // rent_corporate_contacts_list= rent_corporate_contacts_list.map(function(value:any){
        //     if(value.id === payload.id){
        //         // alert(payload.title)
        //         value.rent_m_corporate_id = payload.rent_m_corporate_id
        //         value.user_id = payload.user_id
        //         value.date = payload.date
        //         value.rent_m_corporate_way_id = payload.rent_m_corporate_way_id
        //         value.remarks = payload.remarks
        //         value.is_deleted = payload.is_deleted
        //     }
        //     return value
        // })


        // 成功時アクション呼び出し
        // yield put(change_rent_corporate_contacts_list(rent_corporate_contacts_list))

        if(action.payload == 0){
            yield put(change_application_comp(true));
        }
        

    } else {
        
    }
}

// 入居者リスト入力チェック
function* handle_get_rent_contracts_renter_form_check(action: any): any {
    // alert(action.payload)

    const state = yield select();

    let rent_contracts_renter =  state.Card.rent_contracts_renter

    let form_check = true

    if(rent_contracts_renter[0].is_disp == 1){
        form_check = (rent_contracts_renter[0].is_disp == 1 ? rent_contracts_renter[0].name1_1 && rent_contracts_renter[0].name1_2 && rent_contracts_renter[0].kana1_1 && rent_contracts_renter[0].kana1_2 && rent_contracts_renter[0].rent_m_relation_id && rent_contracts_renter[0].tel1 ? true : false : true)
    }
    if(rent_contracts_renter[1].is_disp == 1 && form_check == true){
        form_check = (rent_contracts_renter[1].is_disp == 1 ? rent_contracts_renter[1].name1_1 && rent_contracts_renter[1].name1_2 && rent_contracts_renter[1].kana1_1 && rent_contracts_renter[1].kana1_2 && rent_contracts_renter[1].rent_m_relation_id && rent_contracts_renter[1].tel1 ? true : false : true)
    }
    if(rent_contracts_renter[2].is_disp == 1 && form_check == true){
        form_check = (rent_contracts_renter[2].is_disp == 1 ? rent_contracts_renter[2].name1_1 && rent_contracts_renter[2].name1_2 && rent_contracts_renter[2].kana1_1 && rent_contracts_renter[2].kana1_2 && rent_contracts_renter[2].rent_m_relation_id && rent_contracts_renter[2].tel1 ? true : false : true)
    }
    if(rent_contracts_renter[3].is_disp == 1 && form_check == true){
        form_check = (rent_contracts_renter[3].is_disp == 1 ? rent_contracts_renter[0].name1_1 && rent_contracts_renter[3].name1_2 && rent_contracts_renter[3].kana1_1 && rent_contracts_renter[3].kana1_2 && rent_contracts_renter[3].rent_m_relation_id && rent_contracts_renter[3].tel1 ? true : false : true)
    }
    if(rent_contracts_renter[4].is_disp == 1 && form_check == true){
        form_check = (rent_contracts_renter[4].is_disp == 1 ? rent_contracts_renter[4].name1_1 && rent_contracts_renter[4].name1_2 && rent_contracts_renter[4].kana1_1 && rent_contracts_renter[4].kana1_2 && rent_contracts_renter[4].rent_m_relation_id && rent_contracts_renter[4].tel1 ? true : false : true)
    }
    if(rent_contracts_renter[5].is_disp == 1 && form_check == true){
        form_check = (rent_contracts_renter[5].is_disp == 1 ? rent_contracts_renter[5].name1_1 && rent_contracts_renter[5].name1_2 && rent_contracts_renter[5].kana1_1 && rent_contracts_renter[5].kana1_2 && rent_contracts_renter[5].rent_m_relation_id && rent_contracts_renter[5].tel1 ? true : false : true)
    }

// alert(form_check)
        // 成功時アクション呼び出し
        yield put(change_rent_contracts_renter_form_check(form_check))


}

// 入居者1住所
function* handle_get_zip_address_renter(action: any): any {
    const state = yield select();

    let rent_contracts_renter =  state.Card.rent_contracts_renter
    const { payload, error } = yield call(tm_zip_address_api.get_address, String(rent_contracts_renter[0].post.replace(/[^0-9]/g, '')));

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し
        rent_contracts_renter[0].add1 = payload.todoufuken_name + payload.shikuchouson_name + payload.chouiki_name

        yield put(change_rent_contracts_renter({}));
        yield put(change_rent_contracts_renter(rent_contracts_renter));
        //    yield put(get_search_rooms_detail_success(payload));

    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

// 業種
// 代行会社リスト
function* handle_get_rent_m_job_categorys(action: any) {

    const { payload, error } = yield call(rent_m_job_categorys_api.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
// console.log(payload)
// console.log("aaaaaa")
        // 成功時アクション呼び出し
        yield put(change_rent_m_job_categorys(payload))
    } else {

    }

}