import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import './Css/RentHeaderComponent.css';
import { withRouter } from "react-router";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
// const location = useLocation();
class RentHeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spot:"",
        };
        this.spotDisp = this.spotDisp.bind(this);
    }

    spotDisp(e, value){       
        this.setState({spot: value});
    }


    render() {

        return (

            <header id="header-menublock">
                {(this.props.cd.no === "contact" || this.props.cd.no === '0' || this.props.root === 'newcard') ?
                    <div className="header-menu_list">
                        <img src="https://img.global-center.co.jp/v1/logo/global" alt="global"></img>
                    </div>
                    :
                    <div className="header-menu_list" onClick={() => this.props.history.push("/Rent/" + this.props.cd.cd + "/" + this.props.cd.no + "/")}>
                        <img src="https://img.global-center.co.jp/v1/logo/global" alt="global"></img>
                    </div>
                }
                <BottomNavigation
                    className='headbtns'
                    value={this.state.spot}
                    onChange={this.spotDisp}
                    showLabels           
                >
                    <BottomNavigationAction id='Btns' value={0} onClick={()=>this.props.history.push("/Rent/"+this.props.cd.cd+"/"+this.props.cd.no)} label="お部屋一覧" icon={<HouseOutlinedIcon className='icons'/>} />
                    <BottomNavigationAction id='Btns' value={4} onClick={()=>this.props.history.push("/Coming/"+this.props.cd.cd+"/"+this.props.cd.no)}  label="条件設定" icon={<AssignmentOutlinedIcon  className='icons'/>}  />
                </BottomNavigation>
            </header>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        cd: state.masterReducer.cd,
        // no: ownProps.match.params.no,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
        //        customeredit1change(state) {
        //            dispatch(customeredit1change(state))
        //        },
        //customeredit2change(state) {
        //        dispatch(customeredit2change(state))
        //    },
        //        responseeditchange(state) {
        //            dispatch(responseeditchange(state))
        //        },
        //        customerdetailData1(state) {
        //            dispatch(customerdetailData1(state))
        //        }, 
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RentHeaderComponent));
