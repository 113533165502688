import React from 'react';
// import { compose, withStateHandlers } from "recompose";
import { connect } from 'react-redux';
import styled from 'styled-components'
// import ie from './Img/Map/icon_ie01.png'
import GoogleMapReact from 'google-map-react'
// import GoogleMapReact, { MapOptions, Maps } from 'google-map-react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import './Css/Pin.css'
import StoreIcon from '@material-ui/icons/Store';
import ApartmentIcon from '@material-ui/icons/Apartment';
import HomeIcon from '@material-ui/icons/Home';
import TrainIcon from '@material-ui/icons/Train';
// import StoreIcon from '@material-ui/icons/StoreMallDirectory';
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import RentBukkenBoxPopup from './RentBukkenBoxPopup';

import * as Rent from './Saga/Rent';

// 参考サイト
// https://stackblitz.com/edit/react-umnzy4
// https://tomchentw.github.io/react-google-maps/


const R = Math.PI / 180;
 
function distance(lat1, lng1, lat2, lng2) {
  lat1 *= R;
  lng1 *= R;
  lat2 *= R;
  lng2 *= R;
  // alert(6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2)))
  return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}

const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: true,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: true,
    fullscreenControl: true,


  }
}

const kaigyo = (str) => {
  return str.split("\n").map((m,i) => <span key={i}>{m}<br/></span>)
};


const onBoundsChange = (center, zoom, bounds, marginBounds) => {
  // console.log(bounds)
  // this.props.get_mypage_local_spots
  // state.max_lat=bounds[0];
  // state.min_lat=bounds[2];
  // state.max_lng=bounds[3];
  // state.min_lng=bounds[1];
  // state.rentResponseId=1;


}
// const bnCoord = {
//   lat: 44.597923,
//   lng: 0.873799,
// }


const App = (props) => {
  
  // const pins= [
  //   {
  //     lat: 34.355185 ,
  //     lng: 134.046989,
  //     // name: ''
  //   },

  //   {
  //     lat: 34.339829, 
  //     lng: 134.035230, 
  //     // name: ''
  //   },
  //   {
  //     lat: 34.336144, 
  //     lng: 134.053831, 
  //     name: '高松市中心部'
  //   },
  //   {
  //     lat: 34.351265, 
  //     lng: 134.057135, 
  //     name: ''
  //   }


  // ]


  // const rooms= [
  //   {
  //     lat: 34.355185 ,
  //     lng: 134.046989,
  //     tatemonoName: 'Aマンション',
  //     tatemonoId:1
  //   },

  //   {
  //     lat: 34.349829, 
  //     lng: 134.045230, 
  //     tatemonoName: 'Bマンション',
  //     tatemonoId:2
  //   },
  //   {
  //     lat: 34.346144, 
  //     lng: 134.053831, 
  //     tatemonoName: 'Cマンション',
  //     tatemonoId:3
  //   },
  //   {
  //     lat: 34.351265, 
  //     lng: 134.057135, 
  //     tatemonoName: 'Dマンション',
  //     tatemonoId:4
  //   }


// props.get_mypage_local_shuhen()


  var setParking = []
  var setConveni = []
  var setSuper = []
  var setBankPost = []
  var setNursery = []
  var setSchool = []
  var setDrug = []
  var setHospital = []

  if(props.shuhen){
    const shuhen = props.shuhen;
    setParking =  shuhen.filter(x => x.category === 'parking')
    setConveni = shuhen.filter(x => x.category === 2)
    setSuper = shuhen.filter(x => x.category === 1)
    setBankPost = shuhen.filter(x => x.category === 18 || x.category === 5)
    setNursery = shuhen.filter(x => x.category === 13 || x.category === 14)
    setSchool = shuhen.filter(x => x.category === 19 || x.category === 6)
    setDrug = shuhen.filter(x => x.category === 17)
    setHospital = shuhen.filter(x => x.category === 3 || x.category === 106)
  }

  
  // console.log(shuhenConvenienceStore,"コンビニ")
  // console.log(shuhenSuperMarket,"スーパー")
  // console.log(shuhenBankPost,"銀行")
  // console.log(shuhenNursery,"？")
  // console.log(shuhenSchool,"小中学校")
  // console.log(shuhenDrug,"ドラッグストア")
  // console.log(shuhenHospital,"病院")

  

  const [roomdetail, setroomdetail] = React.useState([]);
  const detail = (room) => {
    // console.log(e)
    // alert(e.tatemonoId)
    setroomdetail(room);
  }

  const apiLoaded = (map, maps, search_rooms) => {
    if (search_rooms.rent_introduction_point_headers) {
      search_rooms.rent_introduction_point_headers.map((pinsheader) => {
        if (pinsheader) {
          // pinsheader.rentIntroductionPointDetails.map((pins) => {
          if (pinsheader.type === 2) {

            const path = new maps.Polygon({
              path: pinsheader.rent_introduction_point_details.map((p) => ({
                lat: parseFloat(p.lat),
                lng: parseFloat(p.lng)
              })),
              geodesic: true,
              strokeColor: pinsheader.rent_introduction_point_details.length > 0 ? pinsheader.rent_introduction_point_details[0].point_color == null ? '#ff69b4' : pinsheader.rent_introduction_point_details[0].point_color :'#ff69b4',
              strokeOpacity: 1,
              strokeWeight: 2,
            })

            path.setMap(map)
          } else if (pinsheader.type === 4) {

            const path = new maps.Polyline({
              path: pinsheader.rent_introduction_point_details.map((p) => ({
                lat: parseFloat(p.lat),
                lng: parseFloat(p.lng)
              })),
              geodesic: true,
              strokeColor: pinsheader.rent_introduction_point_details.length > 0 ? pinsheader.rent_introduction_point_details[0].point_color == null ? '#ff4500' : pinsheader.rent_introduction_point_details[0].point_color :'#ff4500',
              strokeOpacity: 1,
              strokeWeight: 5,
            })
            path.setMap(map)
          }else if (pinsheader.type === 5) {
 
            const path = new maps.Circle({
              center: {
                lat: pinsheader.rent_introduction_point_details.length === 2 ? parseFloat(pinsheader.rent_introduction_point_details[0].lat) :  0,
                lng: pinsheader.rent_introduction_point_details.length === 2 ? parseFloat(pinsheader.rent_introduction_point_details[0].lng) : 0,
              },
              // radius: Math.sqrt(distance(parseFloat(pinsheader.rent_introduction_point_details[0].lat), parseFloat(pinsheader.rent_introduction_point_details[0].lng),parseFloat(pinsheader.rent_introduction_point_details[1].lat),parseFloat(pinsheader.rent_introduction_point_details[1].lng))) * 100,
              radius: pinsheader.rent_introduction_point_details.length === 2 ? distance(parseFloat(pinsheader.rent_introduction_point_details[0].lat), parseFloat(pinsheader.rent_introduction_point_details[0].lng),parseFloat(pinsheader.rent_introduction_point_details[1].lat),parseFloat(pinsheader.rent_introduction_point_details[1].lng)) * 1000 : 0,
              
              geodesic: true,
              strokeColor: pinsheader.rent_introduction_point_details.length > 0 ? pinsheader.rent_introduction_point_details[0].point_color == null ? '#ff4500' : pinsheader.rent_introduction_point_details[0].point_color :'#ff4500',
              strokeOpacity: 1,
              strokeWeight: 2,
            })

            path.setMap(map)
          }
          // })
        }
        return ""

      })
    }





    // const path = new maps.Polygon({
    //   path: pins.map((p) => ({
    //     lat: p.lat,
    //     lng: p.lng
    //   })),
    //   geodesic: true,
    //   strokeColor: '#ff69b4',
    //   strokeOpacity: 1,
    //   strokeWeight: 2,
    // })

    // path.setMap(map)
  }
  var point = props.search_rooms.mypage_rooms ? props.search_rooms.mypage_rooms.filter(function (x, i, self) {
    return (self.findIndex(function (v2) {
      return (parseInt(x.tatemono_id) === parseInt(v2.tatemono_id))
    }) === i);
  }) : null;
  return (
    <GoogleMapReact

      bootstrapURLKeys={{
        key: 'AIzaSyClDVE129NsjckVjDJpMUpqBUuqMTg5YUo'
      }}
      defaultCenter={{
        lat: parseFloat(props.search_rooms.rent_introduction_headers.lat),
        lng: parseFloat(props.search_rooms.rent_introduction_headers.lng)
      }}
      defaultZoom={props.search_rooms.rent_introduction_headers.zoom}

      options={createMapOptions}
      // draggable={props.mapsearch.draggable}
      onBoundsChange={onBoundsChange}

      onGoogleApiLoaded={({ map, maps }) => apiLoaded(map, maps, props.search_rooms)}
    // onChildClick={props.get_room_details}
    // onChildMouseDown={this.onChildMouseDown.bind(this)}
    // onChildMouseUp={this.onChildMouseUp.bind(this)}
    // onChildMouseMove={this.onCircleInteraction.bind(this)}
    >


      {/* {
        props.conveni ? props.mypage_local_conveni ? props.mypage_local_conveni.map((pin) => (

          <Pin

            key={pin.id}
            flg={1}
            style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

            lat={pin.lat}
            lng={pin.lng}
          >

            <div className="wrapper">
              <div className="spot_convenience">
                <LocalConvenienceStoreIcon fontSize="small" />
              </div>
            </div>

          </Pin>
        )) : "" : ""
      }
      {
        props.store ? props.mypage_local_store ? props.mypage_local_store.map((pin) => (

          <Pin
            key={pin.id}
            flg={1}
            style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

            lat={pin.lat}
            lng={pin.lng}
          >

            <div className="wrapper">
              <div className="spot_supermarket">
                <StoreIcon fontSize="small" />
              </div>
            </div>

          </Pin>
        )) : "" : ""
      } */}

      {
        props.search_rooms.mypage_rooms ? point.map((pin) => (

          <Pin

            key={pin.tatemono_id}
            flg={1}
            style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, zIndex:10 , cursor: 'pointer', borderRadius: 30 }}

            lat={pin.ido}
            lng={pin.keido}
          >

            <div style={{ position: 'absolute', left: -10, top: 40, whiteSpace: "nowrap", opacity: "0.8", padding: 1, paddingLeft: 10, paddingRight: 10, zIndex:10 , borderRadius: 10, backgroundColor: "#f5f5f5", color: "#000", fontWeight: "bold", fontSize: 9, filter: "drop-shadow(1px 1px 1px rgba(0,0,0,0.8))" }}>{pin.tatemono_name}</div>

          </Pin>
        )) : ""
      }


      {
        props.search_rooms.mypage_rooms ? point.map((pin) => (

          <Pin
            key={pin.tatemonoId}
            flg={1}
            style={{ position: 'absolute', zIndex:10 , width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
            onClick={() => detail(pin)}
            lat={pin.ido}
            lng={pin.keido}
          >

            <div className="wrapper">
              <div className="tatemono"
                style={{ backgroundColor: pin.is_bookmark ? "#ff8c00" : "black" }}
                // style={{ background: roomdetail.tatemono_id === pin.tatemono_id ? "#ff4500" : "", backgroundColor: pin.is_bookmark ? "#ff8c00" : "black" }}
              >
                {/* <img src={pin.kanriCorpNo===1?(pin.selected===true?iekanriselected:iekanri):(pin.kanriCorpNo===8 || pin.kanriCorpNo===82?(pin.selected===true?ieippanselected:ieippan):(pin.selected===true?iegyouselected:iegyou))} style={{"width":30}} /> */}
                {pin.numbering ? pin.numbering : "★"}
              </div>
            </div>

          </Pin>
        )) : ""
      }


      {/* パーキング */}
      {
        props.parking ? setParking.map((pin) => (
        <Pin
            flg={1}
            style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

            lat={pin.lat}
            lng={pin.lng}
          >
            <img width={40}  src={`${process.env.PUBLIC_URL}/img/icon/icon_parking.png`} alt={pin.name}/>
        </Pin>
      )) : ""
      }

      {/* コンビニ */}
      {
        props.conveni ? setConveni.map((pin) => (
        <Pin
            flg={1}
            style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

            lat={pin.lat}
            lng={pin.lng}
          >
            <img width={40}  src={`${process.env.PUBLIC_URL}/img/icon/icon_convini.png`} alt={pin.name}/>
        </Pin>
       )) : ""
      }


      {/* スーパー */}
      {
        props.store ? setSuper.map((pin) => (
        <Pin
            flg={1}
            style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

            lat={pin.lat}
            lng={pin.lng}
          >
            <img width={40}  src={`${process.env.PUBLIC_URL}/img/icon/icon_super.png`} alt={pin.name}/>
        </Pin>
        )) : ""
      }

      

      {/* 銀行 */}
      {
        props.bank ? setBankPost.map((pin) => (
        <Pin
            flg={1}
            style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
            lat={pin.lat}
            lng={pin.lng}
          >
            <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_bank.png`} alt={pin.name}/>
        </Pin>
        )) : ""
      }     
      
      
      {/* 幼稚園 */}
      {
        props.youchien ? setNursery.map((pin) => (
        <Pin
            flg={1}
            style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
            lat={pin.lat}
            lng={pin.lng}
          >
            <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_youtien.png`} alt={pin.name}/>
        </Pin>
        )) : ""
      }     

      {/*　小中学校 */}
      {
        props.school ? setSchool.map((pin) => (
        <Pin
            flg={1}
            style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
            lat={pin.lat}
            lng={pin.lng}
          >
            <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_school.png`} alt={pin.name}/>
        </Pin>
        )) : ""
      }     

      {/*　ドラッグストア */}
      {
        props.ds ? setDrug.map((pin) => (
        <Pin
            flg={1}
            style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
            lat={pin.lat}
            lng={pin.lng}
          >
            <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_ds.png`} alt={pin.name}/>
        </Pin>
        )) : ""
      }   

      {/*　病院 */}
      {
        props.hospital ? setHospital.map((pin) => (
        <Pin
            flg={1}
            style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
            lat={pin.lat}
            lng={pin.lng}
          >
            <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_hospital.png`} alt={pin.name}/>
        </Pin>
        )) : ""
      }  


      {/* {
   props.search_rooms.mypageRooms?props.search_rooms.mypageRooms.map((pin) => (
     
      <Pin

      key={pin.tatemonoId}
      
      style={{position: 'absolute',width: 40,height: 40,left: -20,top: -20,cursor: 'pointer',borderRadius: 40}}

        lat={pin.ido}
        lng={pin.keido}
      >
        <img src={ie} style={{"width":30}} />
        <div style={{position: 'absolute',left: -30,whiteSpace: "nowrap",padding:2,paddingLeft:10,paddingRight:10,borderRadius: 10,backgroundColor:"#333333",color:"#FFF",fontWeight:"bold"}}>{pin.tatemonoName}</div> 
      
      </Pin>
    )):""
  } */}


      {props.search_rooms.rent_introduction_point_headers ? props.search_rooms.rent_introduction_point_headers.map((pointheader, index) => {
        //全表示

        return pointheader.rent_introduction_point_details ? pointheader.rent_introduction_point_details.map((pin) => (


          pointheader.type === 3 ? <Pin
            style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
            id={pin.id}
            lat={pin.lat}
            lng={pin.lng}
            flg={2}
          >
            <div className="wrapper">

              <div style={{ position: 'absolute', whiteSpace: "nowrap", opacity: "0.8", padding: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: "#32cd32", color: "#FFF", fontWeight: "bold", fontSize: 12 }}>
                {/* {pointheader.contents} */}
                {pin.link_url ? <span className='link' onClick={()=>window.open(pin.link_url, '_blank', 'noopener')} >{kaigyo(pointheader.contents)}</span> : <span>{kaigyo(pointheader.contents)}</span>}
              </div>
            </div>
            {/* <PointEdit id={pin.id} contents={pin.contents} setDraggable={this.setDraggable}/> */}
          </Pin> 
          : pointheader.type === 1 ? <Pin
            style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
            id={pin.id}
            lat={pin.lat}
            lng={pin.lng}
            flg={2}
          >
            <div className="wrapper">
              {/* <div class="pin2">
                <HomeWorkIcon />
              </div> */}
              <div className="PinDetail">
                {/* <LocationOnIcon fontSize="large" /> */}
                {pin.point_icon === 1 ? <ApartmentIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : 
                pin.point_icon === 2 ? <HomeIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : 
                pin.point_icon === 3 ? <TrainIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : 
                pin.point_icon === 4 ? <StoreIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} /> : <LocationOnIcon fontSize="large" style={{color:pin.point_color == null ? "red" : pin.point_color}} />
                }
              </div>
            </div>

            {/* <PointEdit id={pin.id} contents={pin.contents} setDraggable={this.setDraggable}/> */}
            <div style={{ position: 'absolute', whiteSpace: "nowrap", opacity: "0.8", padding: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: pin.point_color == null ? "red" : pin.point_color, color: "#FFF", fontWeight: "bold", fontSize: 12 }}>
              {/* {pointheader.contents} */}
              {pin.link_url ? <span className='link' onClick={()=>window.open(pin.link_url, '_blank', 'noopener')} >{kaigyo(pointheader.contents)}</span> : <span>{kaigyo(pointheader.contents)}</span>}
            </div>

          </Pin> : ""
        )) : ""
      }, this) : ""}

      {roomdetail.tatemono_id ? <Pin
        style={{ position: 'absolute', width: 30, height: 30, left: -150, top: -200, cursor: 'pointer', borderRadius: 30 }}
        lat={parseFloat(roomdetail.ido)}
        lng={parseFloat(roomdetail.keido)}
        flg={2}
      >
        <div style={{ width: "300px" }} ><RentBukkenBoxPopup setroomdetail={setroomdetail} rooms={roomdetail} dispApplied={props.search_rooms.rent_introduction_headers.disp_applied} /></div>
      </Pin> : ""}






    </GoogleMapReact>)
}


const Pin = styled.div``

//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
  return {
    search_rooms: state.Rent.search_rooms,
    mypage_local_store: state.Rent.mypage_local_store,
    mypage_local_conveni: state.Rent.mypage_local_conveni,
    // shuhen: state.Rent.mypage_local_shuhen,

    //customerdetail: state.customerReducer.detail,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    //firebasedata: state.firebaseReducer.firebasedata,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    // get_mypage_local_shuhen(state) {
    //   dispatch(Rent.get_mypage_local_shuhen(state))
    // },

    //firebaseSet(state) {
    //    dispatch(firebaseSet(state))
    //},
    //loadTodos(state) {
    //    dispatch(loadTodos(state))
    //}
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(App);