import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Css/Reserve.css';
// import * as Master from '../../Store/Master';


const reservationsTime = [
    { value: "1", label: "10時00分" },
    { value: "2", label: "10時30分" },
    { value: "3", label: "11時00分" },
    { value: "4", label: "11時30分" },
    { value: "5", label: "12時00分" },
    { value: "6", label: "12時30分" },
    { value: "7", label: "13時00分" },
    { value: "8", label: "13時30分" },
    { value: "9", label: "14時00分" },
    { value: "10", label: "14時30分" },
    { value: "11", label: "15時00分" },
    { value: "12", label: "15時30分" },
    { value: "13", label: "16時00分" },
    { value: "14", label: "16時30分" },
    { value: "15", label: "17時00分" },
    { value: "16", label: "17時30分" },
    { value: "17", label: "18時00分" },
];

class ConfirmationComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        // this.props.change_cd({cd:this.props.match.params.cd,no:this.props.match.params.no})
    }

    // onClickToConfirm = () => {
    // };  
    formatdata(date) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();

        return (year + '年' + month + '月' + day + '日')
    }

    render() {

        return (
            <div className="mt-3 mb-3" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                <div className="pl-3 pr-3">
                    <h3 style={{fontFamily:"Noto Sans JP,sans-serif"}}>入力内容の確認</h3>
                    <p style={{fontFamily:"Noto Sans JP,sans-serif"}}>まだ予約は完了していません。</p>
                    <p className="subtitle-2" style={{fontFamily:"Noto Sans JP,sans-serif"}}>内容をご確認のうえ、ページ下部にある「この内容で予約する」ボタンを押してください。</p>
                    <h4 style={{fontFamily:"Noto Sans JP,sans-serif"}}>予約内容</h4>
                    <table className="table_style" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                        <tbody>
                            <tr>
                                <th className="yoyaku_title"><i className="fas fa-clock mr-2"></i>ご希望日時</th>
                                <td>{this.formatdata(this.props.reserve.date)} {reservationsTime.filter(a => a.value === this.props.reserve.reservationsTime)[0].label}</td>
                            </tr>
                            <tr>
                                <th className="yoyaku_title"><i className="fas fa-phone mr-2"></i>電話番号</th>
                                <td>{this.props.reserve.tel1}</td>
                            </tr>
                            <tr>
                                <th className="yoyaku_title"><i className="fas fa-users mr-1"></i>ご予約人数</th>
                                <td>{this.props.reserve.reservationsPeople}人</td>
                            </tr>
                            <tr>
                                <th className="yoyaku_title"><i className="fas fa-edit mr-1"></i>店舗へのご要望・連絡事項</th>
                                <td>{this.props.reserve.requestRemarks}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="btn_style">
                    <button className="btn btn-secondary btn-shadow mr-3" style={{ fontSize: 12 }} onClick={() => this.props.InfoMethod(1)}><i className="fas fa-pen"></i> 入力画面に戻る</button>
                    <button className="btn btn-primary btn-shadow" onClick={() => this.props.InfoMethod(4)}><i className="far fa-paper-plane"></i> この内容で予約する</button>
                </div>
                <div>
                    {/* <button className="btn btn-secondary btn-shadow mr-3" onClick={()=>this.props.InfoMethod(3)}> Loding画面</button>
                <button className="btn btn-secondary btn-shadow mr-3" onClick={()=>this.props.InfoMethod(5)}> 失敗画面</button>
                <button className="btn btn-secondary btn-shadow mr-3" onClick={()=>this.props.InfoMethod(6)}> 予約内容/キャンセル画面</button>
                <button className="btn btn-secondary btn-shadow" onClick={()=>this.props.InfoMethod(7)}> ページ無し画面</button> */}
                    {/* <button className="btn btn-secondary btn-shadow mr-3" onClick={()=>this.props.InfoMethod(8)}> 来店予約画面</button> */}
                </div>
            </div>
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {
        reserve: state.Reserve.reserve,
        cd: state.masterReducer.cd,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_cd(state) {
        //     dispatch(Master.change_cd(state))
        // },

        tatemonochange() {
            //dispatch(Responses.responseeditchange())
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationComponent);
