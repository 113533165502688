const authentication = require('react-azure-adb2c').default;





export const get_address = (values: any) => {



    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/tm/zip/address/` + values;


    // const url = `http://localhost:5000/v1/tm/zip/address/` + values;
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const detail = (values: any) => {


    const url = process.env.REACT_APP_API_OPENROOM_URL + `/api/SearchRooms/` + values.tatemono_id + '/' + values.room_id;




    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};