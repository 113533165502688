import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as MypageRequestApi from '../Api/MypageRequestApi';

import * as rent_customer_contacts_api from '../Api/rent_customer_contacts_api';
export interface MapSearchState {
    mypage_request: any;
    coming_comp: boolean;
    // search_rooms_detail:any;
}
const initialState: MapSearchState = {

    mypage_request: {},
    coming_comp: false,
    // search_rooms_detail: [],
}



///action 
const actionCreator = actionCreatorFactory();

export const get_mypage_request = actionCreator('GET_MYPAGE_REQUEST');
export const change_mypage_request = actionCreator<any>("CHANGE_MYPAGE_REQUEST");
export const update_mypage_request = actionCreator<any>('UPDATE_MYPAGE_REQUEST');
export const change_coming_comp = actionCreator<any>("CHANGE_COMING_COMP");


// export const get_search_rooms_detail= actionCreator('GET_SEARCH_ROOMS_DETAIL');
// export const get_search_rooms_detail_success = actionCreator("GET_SEARCH_ROOMS_DETAIL_SUCCESS");


export const comingReducer = reducerWithInitialState(initialState)
    .case(change_mypage_request, (state, payload) => ({ ...state, mypage_request: payload }))
    .case(change_coming_comp, (state, payload) => ({ ...state, coming_comp: payload }))

// .case(get_search_rooms_detail_success, (state, payload) => ({ ...state, search_rooms_detal: payload }))
// .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))

// .case(get_response_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

// .case(change_showRight, (state, payload) => ({ ...state, showRight: payload }))
// .case(change_showFlag, (state, payload) => ({ ...state, showFlag: payload }))
// .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))

// .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }));








// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const comingSaga = [takeEvery('GET_MYPAGE_REQUEST', handle_get_mypage_request),
takeEvery('UPDATE_MYPAGE_REQUEST', handle_update_mypage_request),


    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_mypage_request(action: any) {

    const { payload, error } = yield call(MypageRequestApi.detail, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        yield put(change_mypage_request(payload));

        // yield put(get_search_rooms_success(payload));


    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_update_mypage_request(action: any): any {
    const state = yield select();

    // alert(state.masterReducer.cd.cd)
    // return

    const { payload, error } = yield call(MypageRequestApi.update, {request:state.Coming.mypage_request, cd:state.masterReducer.cd.cd});

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        yield put(change_coming_comp(true));


        yield call(rent_customer_contacts_api.autodelete, state.Coming.mypage_request.responseCode);
        //    yield put(get_search_rooms_detail_success(payload));

    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}