import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as rent_limited_public_pages_api from '../Api/rent_limited_public_pages_api';
import * as rent_limited_public_docs_api from '../Api/rent_limited_public_docs_api';

export interface MapSearchState {
    fcd: string;
    rent_limited_public_docs_list: any;
    download_comp: boolean;
    rent_limited_public_pages: any;
    rent_limited_public_pages_cd: any;
    rent_limited_public_pages_pass: any;
    rent_limited_public_pages_err: boolean;
}
const initialState: MapSearchState = {

    fcd: "",
    rent_limited_public_docs_list: [],
    download_comp: false,
    
    rent_limited_public_pages: [],
    rent_limited_public_pages_cd : "",
    rent_limited_public_pages_pass: "",
    rent_limited_public_pages_err: false,
}



///action 
const actionCreator = actionCreatorFactory();

export const get_rent_limited_public_docs_list = actionCreator<any>('GET_RENT_LIMITED_PUBLIC_DOCS_LIST');
export const change_rent_limited_public_docs_list = actionCreator<any>("CHANGE_RENT_LIMITED_PUBLIC_DOCS_LIST");

export const download_rent_limited_public_docs = actionCreator<any>('DOWNLOAD_RENT_LIMITED_PUBLIC_DOCS');
// export const change_download_comp = actionCreator<any>("CHANGE_DOWNLOAD_COMP");

export const get_rent_contracts = actionCreator('GET_RENT_CONTRACTS');
export const change_rent_limited_public_pages = actionCreator<any>("CHANGE_RENT_LIMITED_PUBLIC_PAGES");

export const change_rent_limited_public_pages_cd = actionCreator<any>("CHANGE_RENT_LIMITED_PUBLIC_PAGES_CD");
export const change_rent_limited_public_pages_pass = actionCreator<any>("CHANGE_RENT_LIMITED_PUBLIC_PAGES_PASS");
export const change_rent_limited_public_pages_err = actionCreator<any>("CHANGE_RENT_LIMITED_PUBLIC_PAGES_ERR");


export const publicdocumentReducer = reducerWithInitialState(initialState)
    .case(change_rent_limited_public_docs_list, (state, payload) => ({ ...state, rent_limited_public_docs_list: payload }))
    // .case(change_download_comp, (state, payload) => ({ ...state, download_comp: payload }))

    .case(change_rent_limited_public_pages, (state, payload) => ({ ...state, rent_limited_public_pages: payload }))

    .case(change_rent_limited_public_pages_cd, (state, payload) => ({ ...state, rent_limited_public_pages_cd: payload }))
    .case(change_rent_limited_public_pages_pass, (state, payload) => ({ ...state, rent_limited_public_pages_pass: payload }))
    .case(change_rent_limited_public_pages_err, (state, payload) => ({ ...state, rent_limited_public_pages_err: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const publicdocumentSaga = [
    // takeEvery('GET_MYPAGE_REQUEST', handle_get_mypage_request),
    takeEvery('GET_RENT_LIMITED_PUBLIC_DOCS_LIST', handle_get_rent_limited_public_docs_list),
    takeEvery('DOWNLOAD_RENT_LIMITED_PUBLIC_DOCS', handle_download_rent_limited_public_docs),

    takeEvery('GET_RENT_CONTRACTS', handle_get_rent_contracts),

];
// export default masterSaga;



function* handle_get_rent_limited_public_docs_list(action: any) {
// alert("1")
    const { payload, error } = yield call(rent_limited_public_pages_api.list, action.payload);
// alert("2")
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert("3")
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        yield put(change_rent_limited_public_docs_list(payload));
        // alert("4")
        // yield put(get_search_rooms_success(payload));


    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_download_rent_limited_public_docs(action: any) {

    const { payload, error } = yield call(rent_limited_public_docs_api.download, action.payload.fcd);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.name;
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();

    } else {
        // alert(error)
        // alert(1)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

// 
function* handle_get_rent_contracts(action: any) {

    const state = yield select();

    yield put(change_rent_limited_public_pages([]))
    yield put(change_rent_limited_public_pages_err(false))

    const { payload, error } = yield call(rent_limited_public_pages_api.detail, { cd: state.PublicDocument.rent_limited_public_pages_cd, pass: state.PublicDocument.rent_limited_public_pages_pass });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
// alert(payload.renter1_name1)

console.log(payload)
// alert(payload.length)
        if(payload.id !== 0){
            yield put(change_rent_limited_public_pages(payload))
        } else {
            yield put(change_rent_limited_public_pages_err(true))
        }

    } else {

    }
}