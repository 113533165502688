/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
//import Select from 'react-select';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import { makeStyles } from '@material-ui/core/styles';
import Calendar from 'react-calendar';

import "react-datepicker/dist/react-datepicker.css";

import { reduxForm } from 'redux-form';
import './Css/Reserve.css';
import * as Master from '../../Store/Master';
import * as Reserve from './Saga/Reserve';


  


class ReserveRequestComponent extends Component {
   
    constructor(props) {
        super(props);
      console.log(props);

        //ご希望時間
        this.reservationsTime = [
            {value:"1", label:"10時00分"},
            {value:"2", label:"10時30分"},
            {value:"3", label:"11時00分"},
            {value:"4", label:"11時30分"},
            {value:"5", label:"12時00分"},
            {value:"6", label:"12時30分"},
            {value:"7", label:"13時00分"},
            {value:"8", label:"13時30分"},
            {value:"9", label:"14時00分"},
            {value:"10", label:"14時30分"},
            {value:"11", label:"15時00分"},
            {value:"12", label:"15時30分"},
            {value:"13", label:"16時00分"},
            {value:"14", label:"16時30分"},
            {value:"15", label:"17時00分"},
            {value:"16", label:"17時30分"},
            {value:"17", label:"18時00分"},
        ];
        //ご予約人数
        this.reservationsPeople = [
            {value:"1", label:"1人"},
            {value:"2", label:"2人"},
            {value:"3", label:"3人"},
            {value:"4", label:"4人"},
            {value:"5", label:"5人"},
            {value:"6", label:"5人以上"},
        ];

        this.state = {
            refresh:false}
        //     reservationsPeople: "1",
        //     reservationsTime: "1",
        //     date: new Date(),
        //     remarks:""
        // }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        
        // this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる

        this.getTileClass = this.getTileClass.bind(this);
        this.calendarChange = this.calendarChange.bind(this);
        
        // this.getTileContent = this.getTileContent.bind(this);
    }
    
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChangeTime(e) {
        console.log(e.target.value);
        // this.setState({ reservationsTime: e.target.value });
        let reserve = this.props.reserve
        reserve.reservationsTime= e.target.value
        this.props.change_reserve(reserve)
    }
    onChange(e) {
        console.log(e.target.value);
        let reserve = this.props.reserve
reserve.reservationsPeople= e.target.value 
this.props.change_reserve(reserve)
        // this.setState({ reservationsPeople: e.target.value });
    }
    
    componentDidMount() {
        console.log(this.props.comingdetail)
          this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる
        
    // This method is called when the component is first added to the document
        //this.ensureDataFetched();
       
    }

  handleChange = event => {
    let reserve = this.props.reserve

reserve[event.target.name]= event.target.value 

this.props.change_reserve(reserve)
        this.setState({refresh:!this.state.refresh});
      };
      handleChangeRdio = event => {
     
        this.setState({radio:event.target.value});
      };


      getTileClass({ date, view }) {
        // 月表示のときのみ
        if (view !== 'month') {
          return '';
        }
        var dayOfWeek = date.getDay() ;	// 曜日(数値)
        // const day = this.getFormatDate(date);
        return dayOfWeek===0 ?'aka' :  dayOfWeek===6?'ao' :'';
        // return (this.state.month_days[day] && this.state.month_days[day].is_holiday) ?
          
      }

      calendarChange(value, event){
let reserve = this.props.reserve
reserve.date=value
this.props.change_reserve(reserve)

      } 
      disabled=({activeStartDate, date, view }) => {
        if (view !== 'month') {
            return '';
          }
          var hiduke=new Date();
         return  date<hiduke
      }
    render() {
        const { handleSubmit } = this.props;    

        return (
        <div>
            <div className="container pt-3">
                <div className="row">
                    <div className="col-12"><i className="far fa-calendar-alt mr-2"></i>ご希望日 　<span className="label_info">必須</span>
                    </div>
                </div>
                {/* <DateComponent date={new Date()} width={100} height={100} fontSize={30}/> */}
                {/* <p>今月の日数：{thisMonth}</p>
                <p>来月の日数：{nextMonth}</p> */}
                <div className="row" style={{marginRight:"auto", marginLeft:"auto"}}>
                    {/* <div className="col table_slide"> */}
                    <Calendar
                        locale="ja-JP"
                        calendarType="US"
                        minDetail="month"
                        tileDisabled={this.disabled}
                        value={this.props.reserve.date}
                        tileClassName={this.getTileClass}
                        tileContent={this.getTileContent}
                        next2Label={ null }
                      
                        prev2Label={ null }
                        onChange={this.calendarChange}
                    />
                    {/* </div> */}
                </div>
            </div>
            <div className="reserve_info">
                <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-1"><i className="fas fa-clock mr-2"></i>ご希望時間 <span className="label_info">必須</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormControl variant="outlined" margin="dense" style={{width:"100%", marginTop:0, marginBottom:"1rem"}}>
                                <Select
                                    name="ReservationsTime"
                                    id="ReservationsTime"
                                    style={{fontSize:"13px", backgroundColor:"white"}}
                                    defaultValue={this.props.reserve.reservationsTime}
                                    onChange={this.onChangeTime}
                                >
                                {this.reservationsTime.map(function(d,index) {
                                   return <MenuItem key={index} value={d.value}>{d.label}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-1"><i className="fas fa-phone mr-2"></i>電話番号 　<span className="label_info">必須</span>
                        </div>
                    </div>
                    <div className="row">
                    <div className="form-group col-12">
            <TextField
            error={this.props.tel1_flg===2}
                id="tel1"
                name="tel1"
                // label="Error"
                margin="dense"
                placeholder="例) 090-0900-0900"
                defaultValue={this.props.reserve.tel1}
                // defaultValue={this.props.reserve.tel1}
                helperText={this.props.tel1_flg===2?"電話番号の形式が正しくありません。":""}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                  }}
                  
                  onBlur={this.props.checktel}
                  onChange={this.handleChange}
                style={{width:"100%", marginTop:0}}
            />
        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-1"><i className="fas fa-users mr-1"></i>ご予約人数 <span className="label_info">必須</span>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-12">
                            <Field
                                name="ReservationsPeople"
                                id="ReservationsPeople"
                                type="text"
                                placeholder=""
                                className="form-control"
                                component="select"
                                style={{fontSize:13}}
                            >
                            <option selected="selected" value="1"></option>
                            { this.reservationsPeople.map( d => <option value={d.value}>{d.label}</option>) }
                            </Field>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-12">
                            <FormControl variant="outlined" margin="dense" style={{width:"100%", marginTop:0, marginBottom:"1rem"}}>
                                <Select
                                    name="ReservationsPeople"
                                    id="ReservationsPeople"
                                    style={{fontSize:"13px", backgroundColor:"white"}}
                                    defaultValue={this.props.reserve.reservationsPeople}
                                    onChange={this.onChange}
                                >
                                {this.reservationsPeople.map(function(d,index) {
                                    return <MenuItem key={index} value={d.value}>{d.label}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-1"><i className="fas fa-edit mr-1"></i>店舗へのご要望・連絡事項
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TextField
                                id="requestRemarks"
                                name="requestRemarks"
                                multiline
                                rows="3"
                                defaultValue=""
                                // className={classes.textField}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                placeholder="要望等あればご記入ください"
                                style={{width:"100%", backgroundColor:"white", marginTop:0}}
                            />
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    );
  }
} 


const mapStateToProps = state => {
    return {
        // users: state.masterReducer.users_select,

        reserve: state.Reserve.reserve,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_reserve(state) {
           dispatch(Reserve.change_reserve(state))
        },
        change_cd(state) {
            dispatch(Master.change_cd(state))
        },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}


ReserveRequestComponent = connect(
    mapStateToProps, mapDispatchToProps
)(ReserveRequestComponent);

ReserveRequestComponent = reduxForm({
    form: 'tenantform', // a unique identifier for this form
})(ReserveRequestComponent)

export default ReserveRequestComponent