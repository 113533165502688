import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';


class ConfirmationDocumentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comp: false
        };
    
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleClick() {
        this.setState({ comp: true })
    }

    render() {
        return (
            <div>
                <h1 className='h1_style'>個人情報および法人情報の取得・管理・利用に関する同意書および賃貸借保証委託契約に関する重要事項説明書</h1>
                <h2 className='h2_style'>個人情報および法人情報の取得・管理・利用に関する同意事項</h2>
                <div className='sentence_container'>
                    <p>賃貸借保証委託契約（以下「委託契約」といい、当該委託契約に係る賃貸借保証契約を「保証契約」といいます。）の申込者（委託契約に係る連帯保証人予定者を含みます。また、委託契約への申込後、委託契約締結に至った者及びその連帯保証人も含みます。以下、これらの者を併せて「申込者」といいます。）は、全保連株式会社（以下「当社」といいます。）が、本条項に従い、申込者の個人情報及び法人情報を取り扱うことに同意します。</p>

                    <h3 className='h3_style'>第 1 条（個人情報）</h3>
                    <p>個人情報とは、以下の個人に関する情報等をいい、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの等をいいます。また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、それにより特定の個人を識別することができるもの等も個人情報に含まれます。</p>
                    <p className='indent2'>①氏名、性別、生年月日、住所、電話番号、国籍、職業、勤務先名称、勤務先住所、勤務先電話番号及び年収等の入居申込書兼保証委託申込書（以下｢申込書｣といい、申込書に相当する書式を含みます。）、委託契約書兼保証契約書に記載された属性情報（変更後の情報を含みます）。</p>
                    <p className='indent2'>②委託契約に関する情報（賃貸物件の名称・所在地、賃料、口座情報、契約の種類、契約日、保証開始日、保証額等を含みます。）</p>
                    <p className='indent2'>③委託契約に関する賃料支払状況等の取引情報。</p>
                    <p className='indent2'>④運転免許証、パスポート及び在留カード等に記載された本人確認のための情報。</p>
                    <p className='indent2'>⑤個人の肖像又は音声を磁気的又は光学的記録媒体等にて記録された映像又は音声情報。</p>
                    <p className='indent2'>⑥裁判所等公共機関、官報、マスメディア、電話帳又は住宅地図等において公にされている情報。</p>

                    <h3 className='h3_style'>第 2 条（法人情報）</h3>
                    <p>法人情報とは、以下の法人に関する情報等をいい、当該情報に含まれる法人名・代表者名・所在地・電話番号その他の記述等により特定の法人を識別することができるもの等をいいます。また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、それにより特定の法人を識別することができるもの等も法人情報に含まれます。</p>
                    <p className='indent2'>①法人名、代表者名、所在地、電話番号、FAX 番号、設立年月日、資本金、年商、従業員等、事業内容等の、申込書、委託契約兼保証契約書に記載された属性情報（変更後の情報を含みます）。</p>
                    <p className='indent2'>②法人に係る前条第 2 号及び第 3 号に定める情報。</p>
                    <p className='indent2'>③登記事項証明書等に記載された法人確認のための情報。</p>
                    <p className='indent2'>④法人に係る前条第 6 号に定める情報。</p>

                    <h3 className='h3_style'>第 3 条（関連する個人情報）</h3>
                    <p>当社は、緊急連絡先及び同居人等の申込者の関係者に関する個人情報についても本条項に従って取り扱います。</p>

                    <h3 className='h3_style'>第 4 条（個人情報の利用目的）</h3>
                    <p>当社が申込者から取得した個人情報の利用目的は以下のとおりです。本条項に別段の定めがある場合のほか、利用目的を超えて個人情報を利用することはありません。</p>
                    <p className='indent2'>①委託契約及び保証契約の締結可否の判断のため。</p>
                    <p className='indent2'>②委託契約及び保証契約の締結及び履行のため。</p>
                    <p className='indent2'>③委託契約に基づく求償権の行使のため。</p>
                    <p className='indent2'>④当社のサービスの紹介のため。</p>
                    <p className='indent2'>⑤当社のサービスの品質向上のため。</p>
                    <p className='indent2'>⑥委託契約もしくは保証契約の付帯商品提供のため。</p>
                    <p className='indent2'>⑦ご意見、ご要望又はご相談について、確認、回答又はその他の対応を行うため。</p>
                    <p className='indent2'>⑧賃貸人及び管理会社からの委託に基づく収納代行事務を行うため。</p>
                    <p className='indent2'>⑨賃貸借契約の履行及び管理並びに契約終了後の債権債務の精算に協力するため。</p>

                    <h3 className='h3_style'>第 5 条（個人情報の第三者への提供）</h3>
                    <p className='indent1'>⑴当社は、以下に該当する場合を除くほか、あらかじめ申込者本人の同意を得ず個人情報を第三者に提供することはありません。</p>
                    <p className='indent2'>①法令に基づく場合。</p>
                    <p className='indent2'>②人の生命、身体又は財産の保護のために必要がある場合であって、申込者本人の同意を得ることが困難であるとき。</p>
                    <p className='indent2'>③公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、申込者本人の同意を得ることが困難であるとき。</p>
                    <p className='indent2'>④国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、申込者本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。</p>
                    <p className='indent1'>⑵申込者は、当社が申込者の個人情報を以下の第三者に対し提供することに同意します。</p>
                    <p className='indent2'>①第４条記載の利用目的の達成のために、連帯保証人予定者、賃借人、連帯保証人、賃貸物件の所有者、賃貸人、管理会社、仲介会社、調査会社、緊急連絡先若しくは同居人等の申込者の関係者、委託契約もしくは保証契約の付帯商品の提供会社に対し提供すること。</p>
                    <p className='indent2'>②当社が申込者に対して有する債権を譲渡又は担保に供する場合、譲渡先又は担保権者に対し取引に必要な項目を電送等により提供すること。</p>
                    <p className='indent2'>③その他申込者が第三者に不利益を及ぼすと当社が判断した場合に当該第三者に対し提供すること。</p>

                    <h3 className='h3_style'>第 6 条（第三者の範囲）</h3>
                    <p className='indent1'>以下の場合、個人情報の提供を受ける者は、第三者に該当しないものとします。</p>
                    <p className='indent2'>①当社が利用目的の達成に必要な範囲内において個人情報の取り扱いの一部又は全部を委託する場合（なお、委託先における個人情報の取り扱いについては当社が責任を負います）。</p>
                    <p className='indent2'>②合併その他の事由による事業の承継に伴って個人情報が提供される場合。</p>

                    <h3 className='h3_style'>第 7 条（家賃債務保証情報取扱機関への登録･利用等）</h3>
                    <p className='indent1'>⑴申込者は、当社が個人情報を当社の加盟する以下の家賃債務保証情報取扱機関（以下｢加盟家賃債務保証情報取扱機関｣といいます。）に提供することに同意します。</p>
                    <p className='indent2'>■加盟家賃債務保証情報取扱機関</p>
                    <p className='indent2'>名　　称： 一般社団法人 全国賃貸保証業協会（略称 LICC）</p>
                    <p className='indent2'>住　　所： 〒105-0004　東京都港区新橋 5 丁目 22 番 6 号　ル・グラシエル BLDG2 四階 A</p>
                    <p className='indent2'>電話番号： 0570-086-110</p>
                    <p className='indent2'>URL： http://jpg.or.jp/</p>
                    <p className='indent1'>⑵申込者は、当社が申込者等との委託契約締結可否の判断及び委託契約又は保証契約の履行･求償権の行使のために、加盟家賃債務保証情報取扱機関に照会し、申込者に関する個人情報が登録されている場合には、当社が当該情報を利用することに同意します。</p>
                    <p className='indent1'>⑶申込者は、以下の表に定める個人情報が加盟家賃債務保証情報取扱機関に以下の表に定める期間登録され、加盟家賃債務保証情報取扱機関の会員により申込者との契約締結可否の判断及び契約の履行・求償権の行使のために利用されることに同意します。</p>
                    <TableContainer sx={{ marginTop: '5px', marginBottom: '5px' }}>
                        <Table size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ 'th': {backgroundColor: 'white', border: '1px solid'}}}>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '47%' }}>登録情報</TableCell>
                                    <TableCell sx={{ width: '47%' }}>登録期間</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ 'th, td': {backgroundColor: 'white', border: '1px solid'}}}>
                                    <TableCell component="th" scope="row">1</TableCell>
                                    <TableCell sx={{ padding: '5px' }}>氏名、生年月日、住所、電話番号等の本人を特定するための情報</TableCell>
                                    <TableCell sx={{ padding: '5px' }} rowSpan={2}>下記の3又は4のいずれかの登録情報が登録されている期間</TableCell>
                                </TableRow>
                                <TableRow sx={{ 'th, td': {backgroundColor: 'white', border: '1px solid'}}}>
                                    <TableCell component="th" scope="row">2</TableCell>
                                    <TableCell sx={{ padding: '5px' }}>賃貸物件の名称、住所等賃貸物件を特定するための情報</TableCell>
                                </TableRow>
                                <TableRow sx={{ 'th, td': {backgroundColor: 'white', border: '1px solid'}}}>
                                    <TableCell component="th" scope="row">3</TableCell>
                                    <TableCell sx={{ padding: '5px' }}>委託契約又は保証契約の申込をした事実</TableCell>
                                    <TableCell sx={{ padding: '5px' }}>当社が加盟家賃債務保証情報取扱機関に照会した日から6か月間</TableCell>
                                </TableRow>
                                <TableRow sx={{ 'th, td': {backgroundColor: 'white', border: '1px solid'}}}>
                                    <TableCell component="th" scope="row">4</TableCell>
                                    <TableCell sx={{ padding: '5px' }}>当社の賃貸人に対する支払い状況、求償金支払請求訴訟及び建物明渡請求訴訟に関する情報</TableCell>
                                    <TableCell sx={{ padding: '5px' }}>契約期間中及び契約終了後債務が消滅してから5年間</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <p className='indent1'>⑷申込者は、賃貸人が賃借人等に対して建物明渡請求訴訟を提起した場合にこれにかかる情報を、賃貸人が当社に対し、当社が加盟家賃債務保証情報取扱機関に登録する目的で提供することに同意します。</p>
                    <p className='indent1'>⑸原則として申込者等本人に限り、加盟家賃債務保証情報取扱機関に登録される個人情報に係る開示請求又は当該個人情報に誤りがある場合の訂正、削除等の申立を、加盟家賃債務保証情報取扱機関が定める手続き及び方法によって行うことができます。</p>

                    <h3 className='h3_style'>第 8 条（信用情報機関への登録・利用等）</h3>
                    <p className='indent1'>⑴申込者は、当社が第 1 条、第 2 条に該当する個人情報及び法人情報を当社の加盟する以下の信用情報機関（以下「加盟先機関」といいます。）に提供することに同意します。</p>
                    <p className='indent2'>■加盟先機関</p>
                    <p className='indent3'>名　　称：株式会社　日本信用情報機構 ( 略称 JICC)</p>
                    <p className='indent3'>電話番号：0570-055-955</p>
                    <p className='indent3'>URL：https://www.jicc.co.jp</p>
                    <p className='indent1'>⑵当社が加盟先機関及び加盟先機関と提 携する以下の信用情報機関（以下「提携先機関」といいます。）に対して、申込者について照会をかけた際、加盟先機関及び提携先機関に申込者の第 1 条、第 2 条に該当する個人情報及び法人情報、申込者に対する金融機関からの貸付け情報（以下、単に「貸付け情報」といいます。）その他の情報（加盟先機関及び提携先機関の他の加盟会員と申込者との間で締結されている金銭消費貸借契約等に係る入金日、入金予定日、残高金額、完済日、延滞・延滞解消、債権回収、債務整理、保証履行、強制解約、破産申立、債権譲渡等の情報を含みます。）が登録されている場合、当社は、これらの情報の提供を受け、提供を受けたこれらの情報を、第 4 条の利用目的の定めに関わらず、申込者と当社との間で委託契約を締結することの可否を審査する前提となる申込者の返済又は支払能力を調査・判断する目的のみに利用します。</p>
                    <p className='indent2'>■提携先機関</p>
                    <p className='indent2'>・名　　称：全国銀行個人信用情報センター</p>
                    <p className='indent3'>電話番号：03-3214-5020</p>
                    <p className='indent3'>URL：https://www.zenginkyo.or.jp/pcic/</p>
                    <p className='indent2'>・名　　称：株式会社　シー・アイ・シー ( 略称CIC)</p>
                    <p className='indent3'>電話番号：0120-810-414</p>
                    <p className='indent3'>URL：https//www.cic.co.jp/</p>
                    <p className='indent5'>⑶①第１項で当社が提供する個人情報及び法人情報のうち、保証額についての情報は賃貸借申込物件の賃料等 1 カ月分に相当する額とします。また、当社が前項の照会をかけた場合の申込者の申込日及び申込商品種別等の情報（以下「申込情報」といいます。）は、加盟先機関に登録され、この登録期間は、当社が加盟先機関に照会した日から 6 カ月以内です。</p>
                    <p className='indent2'>②第１項で当社が提供する個人情報及び法人情報が、加盟先機関に登録される期間は以下の通りです。</p>
                    <p className='indent3'>ア 申込者を特定するための情報（申込者が個人の場合：氏名、性別、生年月日、住所、電話番号、勤務先名称、勤務先電話番号等。申込者が法人の場合：法人名、代表者名、所在地、電話番号、設立年月日等）契約内容（第 1 条２号の情報のうち、契約の種類、契約日、保証額、賃貸物件の名称・所在地 等）・返済状況（第 1 条３号の情報のうち、入金日、入金予定日、完済日等）・取引事実（第 1 条３号の情報のうち、保証履行額、保証履行日等）・債権譲渡の事実に関する情報のいずれかが登録されている期間</p>
                    <p className='indent3'>イ 契約内容・返済状況・取引事実に関する情報</p>
                    <p className='indent4'>委託契約継続中及び終了後の 5 年以内</p>
                    <p className='indent3'>ウ 債権譲渡の事実に関する情報</p>
                    <p className='indent4'>当該事実の発生日から 1 年以内</p>
                    <p className='indent2'>③加盟先機関は、当社が第 1 項で提供した個人情報及び法人情報並びに第 1 号の申込情報を、加盟先機関に登録している他の加盟会員及び提携先機関に登録している他の加盟会員に提供します。これら加盟会員は、当該個人情報及び法人情報並びに申込情報を、申込者の返済又は支払能力を調査する目的のみに利用します。</p>
                    <p className='indent2'>④申込者は、加盟先機関に登録されている個人情報及び法人情報に係る開示請求または当該個人情報・法人情報及び貸付け情報に誤りがある場合の訂正・削除等の申立を、加盟先機関が定める手続き及び方法によって行うことができます。</p>

                    <h3 className='h3_style'>第 9 条（個人情報の当社への提供）</h3>
                    <p>申込者は、連帯保証人予定者、賃借人、連帯保証人、賃貸人、管理会社、仲介会社又は緊急連絡先及び同居人等の申込者の関係者が、申込者の個人情報を、第 4 条記載の利用目的のために当社に対し提供することに同意します。</p>

                    <h3 className='h3_style'>第 10 条（個人情報の開示･訂正等･利用停止等）</h3>
                    <p className='indent1'>⑴当社は、当社所定の方法により、申込者等本人から、当該申込者本人が識別される個人情報の開示を求められたときは、申込者等本人に対し、遅滞なく、当該保有個人情報を開示します。ただし、開示することにより以下の各号のいずれかに該当する場合は、当社の判断により個人情報の全部又は一部を開示することはありません。</p>
                    <p className='indent2'>①申込者本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合。</p>
                    <p className='indent2'>②当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合。</p>
                    <p className='indent2'>③法令に違反することとなる場合。</p>
                    <p className='indent1'>⑵当社は、当社が保有する個人情報の内容が事実でないことが判明した場合、利用目的の達成に必要な範囲内において、速やかに当該情報を最新の情報へ訂正、追加又は削除（以下｢訂正等｣といいます。）します。</p>
                    <p className='indent1'>⑶当社は、申込者本人から当該本人が識別される個人情報の利用の停止、消去又は第三者への提供の停止（以下「利用停止等」といいます。）の請求を受けた場合は、これに応じます。また、措置を講じた後は、遅滞なくその旨を本人に通知します。ただし、以下の各号のいずれかに該当する場合は、利用停止等は行いません。</p>
                    <p className='indent2'>①申込者本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合。</p>
                    <p className='indent2'>②当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合。</p>
                    <p className='indent2'>③法令に違反することとなる場合。</p>

                    <h3 className='h3_style'>第 11 条（個人情報の正確性）</h3>
                    <p>当社は、利用目的の達成に必要な範囲内において、個人情報を正確かつ最新の内容に保つよう努めます。ただし、委託契約又は保証契約の申込時又は締結時においてご提供いただいた個人情報が正確かつ最新であることについては、申込者が責任を負うものとします。</p>

                    <h3 className='h3_style'>第 12 条（必要情報の提出）</h3>
                    <p>申込者は、委託契約の申込、締結又は履行に必要な情報を提出することに同意します。</p>
                    
                    <h3 className='h3_style'>第 13 条（本条項不同意の場合の措置）</h3>
                    <p>申込者が、委託契約及び保証契約において必要な記載事項（申込書、委託契約書及び保証契約書表面で記載すべき事項）の記載を希望されない場合、及び本条項の全部又は一部を承認できない場合には、当社は委託契約及び保証契約の締結をお断りする場合があります。ただし、第 4 条④に同意しない場合は、これを理由に当社が委託契約及び保証契約を拒否することはありません。</p>
                    
                    <h3 className='h3_style'>第 14 条（審査結果）</h3>
                    <p>当社は、委託契約及び保証契約申込についての審査結果を賃貸人、管理会社又は仲介会社へ通知します。なお審査結果は審査時点のものであり、契約時点で申込者に著しい信用状況の変動や、申込内容の変更等がある場合には契約できない場合があります。又、当社による審査により、委託契約及び保証契約が受諾されない結果となった場合であっても、審査内容及び審査の理由は開示しません。また、当社は、法令に定められた訂正等・利用停止等の場合を除き、提供された個人情報及び法人情報を含む書面についてはいかなる場合にも返却及び削除しません。</p>
                    
                    <h3 className='h3_style'>第 15 条（個人情報の管理）</h3>
                    <p className='indent1'>⑴当社は、その管理下にある個人情報の紛失、誤用及び改変を防止するために、適切なセキュリティ対策の実施に努めます。</p>
                    <p className='indent1'>⑵当社は、保有する個人情報について権限を持つ利用者のみがアクセスできる安全な環境下に保管するよう努めます。</p>
                    
                    <h3 className='h3_style'>第 16 条（個人情報及び法人情報取り扱い業務の外部委託）</h3>
                    <p>当社は、個人情報及び法人情報を取り扱う業務の一部又は全部を外部委託することがあります。</p>
                    
                    <h3 className='h3_style'>第 17 条（統計データの利用）</h3>
                    <p>当社は、提供を受けた個人情報をもとに、個人を特定できない形式に加工した統計データを作成することがあります。当社は、当該データにつき何らの制限なく利用することができるものとします。</p>
                    
                    <h3 className='h3_style'>第 18 条（本条項の改定）</h3>
                    <p>当社は、法令等の定めがある場合を除き、本条項を随時変更することができるものとします。</p>
                    
                    <h3 className='h3_style'>第 19 条（個人情報保護管理者）</h3>
                    <p>全保連株式会社 個人情報保護管理者 コーポレート本部長</p>
                    
                    <h3 className='h3_style'>第 20 条（問合せ窓口）</h3>
                    <p>個人情報に関する苦情、利用目的の通知、開示、訂正等、利用停止等又はその他のご質問、ご相談若しくはお問合せにつきましては当社ホームページ (https://www.zenhoren.jp）を参照いただくか、以下の問合せ窓口までご連絡ください。なお、手続に際しては、当社所定の手数料を要します。</p>
                    <p className='indent2'>住　　所：東京都新宿区西新宿 1-24-1</p>
                    <p className='indent2'>担当部署：全保連株式会社　リスク・コンプライアンス統括部</p>
                    <p className='indent2'>電話番号：03-6327-5843</p>
                    <p className='indent2'>受付時間：土･日･祝日･当社休業日を除く 9：00 ～ 18：00</p>

                    <h3 className='h3_style'>第 21 条（適用除外）</h3>
                    <p>申込者が法人の場合、第 7 条は適用外とします。</p>
                    <h3 className='h3_style'>第 22 条（特記事項）</h3>
                    <p>当社の契約締結業務の都合上、申込者が、当社との間で委託契約（申込者が連帯保証人予定者の場合には、当社との間で委託契約に係る連帯保証契約）を締結するに際して、当社に対し、本条項とは別の個人情報の取り扱いに関する同意書（以下「別同意書」といいます。）を提出することとなる場合において、本条項と別同意書の規定内容が異なる場合には、申込者は、本条項の規定が優先的に適用されることについて同意します。</p>
                </div>
                <div className='consent_btn'>
                    {this.state.comp ? <Alert severity="info">承諾しました。</Alert> : <Button variant="contained" onClick={this.handleClick}>承諾します</Button>}
                </div>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {

        // rent_limited_public_pages_cd: state.PublicDocument.rent_limited_public_pages_cd,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_rent_limited_public_docs_list(state) {
        //     dispatch(PublicDocument.get_rent_limited_public_docs_list(state))
        // },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDocumentComponent);
