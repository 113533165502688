import React, { useState } from 'react';



function ApplyConfirmComponent(props) {

  const onClick = () => {
    props.handlePageChange(1);
    props.handleSetItems(props.inputDatas)
  }

  const getRelationship = (value) => {
    switch(value){
      case 1:
        return "本人"
      case 2: 
        return "父"
      case 3: 
        return "母"
      case 4: 
        return "子"
      case 5: 
        return "妻"
      case 6: 
        return "夫"
      case 7: 
        return "祖父"
      case 8: 
        return "祖母"
      case 9: 
        return "義父"
      case 10: 
        return "義母"
      case 11: 
        return "兄"
      case 12: 
        return "姉"
      case 13: 
        return "弟"
      case 14: 
        return "妹"
      case 20: 
        return "叔父"
      case 21: 
        return "叔母"
      case 22: 
        return "婚約者"
      default:
        return ""
    }
  }


  return (
    <div style={{paddingTop: 50}}>
      <h6>【駐車場】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        <tbody>
            <tr>
                <th>駐車場</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["number_of_cars"] ? <div>{props.inputDatas["number_of_cars"]}台</div> : "不要"}
                </td>
            </tr>
            {props.inputDatas["number_of_cars"] >= 1 ?
            <tr>
                <th>メーカー名/車種</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["car_name1"] ? <div>{props.inputDatas["car_name1"]}</div> : ""}
                </td>
            </tr>:""}
            {props.inputDatas["number_of_cars"] >= 1 ?
            <tr>
                <th>車色</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["car_color1"] ? <div>{props.inputDatas["car_color1"]}</div> : ""}
                </td>
            </tr>:""}
            {props.inputDatas["number_of_cars"] >= 1 ?
            <tr>
                <th>車両番号</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["car_no1"] ? <div>{props.inputDatas["car_no1"]}</div> : ""}
                </td>
            </tr>:""}
            {props.inputDatas["number_of_cars"] >= 2 ?
            <tr>
                <th>メーカー名/車種</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["car_name2"] ? <div>{props.inputDatas["car_name2"]}</div> : ""}
                </td>
            </tr>:""}
            {props.inputDatas["number_of_cars"] >= 2 ?
            <tr>
                <th>車色</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["car_color2"] ? <div>{props.inputDatas["car_color2"]}</div> : ""}
                </td>
            </tr>:""}
            {props.inputDatas["number_of_cars"] >= 2 ?
            <tr>
                <th>車両番号</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["car_no2"] ? <div>{props.inputDatas["car_no2"]}</div> : ""}
                </td>
            </tr>:""}

            {props.inputDatas["number_of_cars"] >= 3 ?
            <tr>
                <th>メーカー名/車種</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["car_name3"] ? <div>{props.inputDatas["car_name3"]}</div> : ""}
                </td>
            </tr>:""}
            {props.inputDatas["number_of_cars"] >= 3 ?
            <tr>
                <th>車色</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["car_color3"] ? <div>{props.inputDatas["car_color3"]}</div> : ""}
                </td>
            </tr>:""}
            {props.inputDatas["number_of_cars"] >= 3 ?
            <tr>
                <th>車両番号</th>
                <td style={{backgroundColor:"white"}}>
                  {props.inputDatas["car_no3"] ? <div>{props.inputDatas["car_no3"]}</div> : ""}
                </td>
            </tr>:""}

        </tbody>
      </table>

      <h6>【賃借人】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        <tbody>
            <tr>
                <th>氏名</th>
                <td>
                  {props.inputDatas["name11"]} {props.inputDatas["name12"]}
                </td>
            </tr>
            <tr>
                <th>フリガナ</th>
                <td>
                  {props.inputDatas["kana11"]} {props.inputDatas["kana12"]}
                </td>
            </tr>
            <tr>
                <th>性別</th>
                <td>
                  {props.inputDatas["sex"] == "1" ? "男性" : props.inputDatas["sex"] == "0" ? "女性" : ""}
                </td>
            </tr>
            <tr>
                <th>生年月日</th>
                <td>
                  {props.inputDatas["birthday_year"]}年{props.inputDatas["birthday_month"]}月{props.inputDatas["birthday_day"]}日
                </td>
            </tr>
            <tr>
                <th>〒</th>
                <td>
                  {props.inputDatas["zip"]}
                </td>
            </tr>
            <tr>
                <th>住所</th>
                <td>
                  {props.inputDatas["address1"]}
                </td>
            </tr>
            <tr>
                <th>TEL/携帯電話</th>
                <td>
                  {props.inputDatas["tel1"]}
                </td>
            </tr>
            <tr>
                <th>メールアドレス</th>
                <td>
                  {props.inputDatas["email"]}
                </td>
            </tr>
            <tr>
                <th>勤務先名</th>
                <td>
                  {props.inputDatas["office_name"]}
                </td>
            </tr>
            <tr>
                <th>勤務先TEL</th>
                <td>
                  {props.inputDatas["office_tel"]}
                </td>
            </tr>
            <tr>
                <th>勤務先〒</th>
                <td>
                  {props.inputDatas["office_zip"]}
                </td>
            </tr>
            <tr>
                <th>勤務先住所</th>
                <td>
                  {props.inputDatas["office_address"]}
                </td>
            </tr>
            <tr>
                <th>業種</th>
                <td>
                {props.inputDatas["job_category"] == 1 ? "農業"
                : props.inputDatas["job_category"] == 2 ? "林業"
                : props.inputDatas["job_category"] == 3 ? "漁業"
                : props.inputDatas["job_category"] == 4 ? "鉱業"
                : props.inputDatas["job_category"] == 5 ? "建設業"
                : props.inputDatas["job_category"] == 6 ? "製造業"
                : props.inputDatas["job_category"] == 7 ? "電気・ガス・熱供給・水道業"
                : props.inputDatas["job_category"] == 8 ? "情報通信業"
                : props.inputDatas["job_category"] == 9 ? "運輸業"
                : props.inputDatas["job_category"] == 10 ? "卸売業・小売業"
                : props.inputDatas["job_category"] == 11 ? "金融業・保険業"
                : props.inputDatas["job_category"] == 12 ? "不動産業"
                : props.inputDatas["job_category"] == 13 ? "学術研究"
                : props.inputDatas["job_category"] == 14 ? "宿泊業，飲食サービス業"
                : props.inputDatas["job_category"] == 15 ? "生活関連サービス業，娯楽業"
                : props.inputDatas["job_category"] == 16 ? "教育，学習支援業"
                : props.inputDatas["job_category"] == 17 ? "医療，福祉"
                : props.inputDatas["job_category"] == 18 ? "その他"
                : ""}
                </td>
            </tr>
            <tr>
                <th>雇用形態</th>
                <td>
                {props.inputDatas["job_class"] == 2 ? "公務員"
                : props.inputDatas["job_class"] == 1 ? "会社員"
                : props.inputDatas["job_class"] == 10 ? "会社経営者"
                : props.inputDatas["job_class"] == 6 ? "契約社員"
                : props.inputDatas["job_class"] == 11 ? "派遣社員"
                : props.inputDatas["job_class"] == 3 ? "自営業"
                : props.inputDatas["job_class"] == 12 ? "個人事業勤務"
                : props.inputDatas["job_class"] == 4 ? "アルバイト"
                : props.inputDatas["job_class"] == 5 ? "パート"
                : props.inputDatas["job_class"] == 13 ? "学生"
                : props.inputDatas["job_class"] == 7 ? "年金"
                : props.inputDatas["job_class"] == 8 ? "生活保護受給"
                : props.inputDatas["job_class"] == 9 ? "無職"
                : props.inputDatas["job_class"] == 14 ? "その他"
                : ""}
                </td>
            </tr>
            <tr>
                <th>住居</th>
                <td>
                {props.inputDatas["my_home"] == 1 ? "持家"
                : props.inputDatas["my_home"] == 2 ? "借家"
                : props.inputDatas["my_home"] == 3 ? "親と同居"
                : ""}
                </td>
            </tr>
            <tr>
                <th>引越し理由</th>
                <td>
                {props.inputDatas["reason"] == 8 ? "結婚"
                : props.inputDatas["reason"] == 7 ? "転勤"
                : props.inputDatas["reason"] == 6 ? "入社"
                : props.inputDatas["reason"] == 4 ? "入学"
                : props.inputDatas["reason"] == 2 ? "独立"
                : props.inputDatas["reason"] == 11 ? "家賃を抑えたい"
                : props.inputDatas["reason"] == 12 ? "手狭になった"
                : props.inputDatas["reason"] == 3 ? "その他"
                : ""}
                </td>
            </tr>
            <tr>
                <th>勤続年数</th>
                <td>
                  {props.inputDatas["work_years"] ? props.inputDatas["work_years"]+"年" : ""}
                </td>
            </tr>
            <tr>
                <th>年収</th>
                <td>
                  {props.inputDatas["income"] ? props.inputDatas["income"]+"万円" : ""}
                </td>
            </tr>
            <tr>
                <th>入居人数</th>
                <td>
                  {props.inputDatas["number_of_renters"]}人
                </td>
            </tr>
        </tbody>
      </table>

      <h6>【緊急連絡先】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        <tbody>
            <tr>
                <th>氏名</th>
                <td>
                  {props.inputDatas["e_name11"]} {props.inputDatas["e_name12"]}
                </td>
            </tr>
            <tr>
                <th>フリガナ</th>
                <td>
                  {props.inputDatas["e_kana11"]} {props.inputDatas["e_kana12"]}
                </td>
            </tr>
            <tr>
                <th>生年月日</th>
                <td>
                  {props.inputDatas["e_birthday_year"]}年{props.inputDatas["e_birthday_month"]}月{props.inputDatas["e_birthday_day"]}日
                </td>
            </tr>
            <tr>
                <th>性別</th>
                <td>
                  {props.inputDatas["e_sex"] == "1" ? "男性" : props.inputDatas["e_sex"] == "0" ? "女性" : ""}
                </td>
            </tr>
            <tr>
                <th>〒</th>
                <td>
                  {props.inputDatas["e_zip"]}
                </td>
            </tr>
            <tr>
                <th>住所</th>
                <td>
                  {props.inputDatas["e_address"]}
                </td>
            </tr>
            <tr>
                <th>TEL/携帯電話</th>
                <td>
                  {props.inputDatas["e_tel"]}
                </td>
            </tr>
            <tr>
                <th>契約者との続柄</th>
                <td>
                  {getRelationship(props.inputDatas["e_relationship"])}
                </td>
            </tr>
            <tr>
                <th>住居</th>
                <td>
                {props.inputDatas["e_myhome"] == 1 ? "持家"
                : props.inputDatas["e_myhome"] == 2 ? "借家"
                : props.inputDatas["e_myhome"] == 3 ? "親と同居"
                : ""}
                </td>
            </tr>
            <tr>
                <th>勤続年数</th>
                <td>
                  {props.inputDatas["e_work_years"] ? props.inputDatas["e_work_years"]+"年" : ""}
                </td>
            </tr>
            <tr>
                <th>年収</th>
                <td>
                  {props.inputDatas["e_income"] ? props.inputDatas["e_income"]+"万円" : ""}
                </td>
            </tr>
            <tr>
                <th>勤務先名</th>
                <td>
                  {props.inputDatas["e_office"]}
                </td>
            </tr>
            <tr>
                <th>勤務先〒</th>
                <td>
                  {props.inputDatas["e_office_zip"]}
                </td>
            </tr>
            <tr>
                <th>勤務先住所</th>
                <td>
                  {props.inputDatas["e_office_address"]}
                </td>
            </tr>
            <tr>
                <th>勤務先TEL</th>
                <td>
                  {props.inputDatas["e_office_tel"]}
                </td>
            </tr>
            <tr>
                <th>業種</th>
                <td>
                {props.inputDatas["e_job_category"] == 1 ? "農業"
                : props.inputDatas["e_job_category"] == 2 ? "林業"
                : props.inputDatas["e_job_category"] == 3 ? "漁業"
                : props.inputDatas["e_job_category"] == 4 ? "鉱業"
                : props.inputDatas["e_job_category"] == 5 ? "建設業"
                : props.inputDatas["e_job_category"] == 6 ? "製造業"
                : props.inputDatas["e_job_category"] == 7 ? "電気・ガス・熱供給・水道業"
                : props.inputDatas["e_job_category"] == 8 ? "情報通信業"
                : props.inputDatas["e_job_category"] == 9 ? "運輸業"
                : props.inputDatas["e_job_category"] == 10 ? "卸売業・小売業"
                : props.inputDatas["e_job_category"] == 11 ? "金融業・保険業"
                : props.inputDatas["e_job_category"] == 12 ? "不動産業"
                : props.inputDatas["e_job_category"] == 13 ? "学術研究"
                : props.inputDatas["e_job_category"] == 14 ? "宿泊業，飲食サービス業"
                : props.inputDatas["e_job_category"] == 15 ? "生活関連サービス業，娯楽業"
                : props.inputDatas["e_job_category"] == 16 ? "教育，学習支援業"
                : props.inputDatas["e_job_category"] == 17 ? "医療，福祉"
                : props.inputDatas["e_job_category"] == 18 ? "その他"
                : ""}
                </td>
            </tr>
            <tr>
                <th>雇用形態</th>
                <td>
                {props.inputDatas["e_job_class"] == 2 ? "公務員"
                : props.inputDatas["e_job_class"] == 1 ? "会社員"
                : props.inputDatas["e_job_class"] == 10 ? "会社経営者"
                : props.inputDatas["e_job_class"] == 6 ? "契約社員"
                : props.inputDatas["e_job_class"] == 11 ? "派遣社員"
                : props.inputDatas["e_job_class"] == 3 ? "自営業"
                : props.inputDatas["e_job_class"] == 12 ? "個人事業勤務"
                : props.inputDatas["e_job_class"] == 4 ? "アルバイト"
                : props.inputDatas["e_job_class"] == 5 ? "パート"
                : props.inputDatas["e_job_class"] == 13 ? "学生"
                : props.inputDatas["e_job_class"] == 7 ? "年金"
                : props.inputDatas["e_job_class"] == 8 ? "生活保護受給"
                : props.inputDatas["e_job_class"] == 9 ? "無職"
                : props.inputDatas["e_job_class"] == 14 ? "その他"
                : ""}
                </td>
            </tr>
        </tbody>
      </table>

      {props.inputDatas["number_of_renters"] >= 1 ?
      <div>
      <h6>【入居者①】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        <tbody>
            <tr>
                <th>氏名</th>
                <td>
                  {props.inputDatas["h_name11"]} {props.inputDatas["h_name12"]}
                </td>
            </tr>
            <tr>
                <th>フリガナ</th>
                <td>
                  {props.inputDatas["h_kana11"]} {props.inputDatas["h_kana12"]}
                </td>
            </tr>
            <tr>
                <th>生年月日</th>
                <td>
                  {props.inputDatas["h_birthday_year1"]}年{props.inputDatas["h_birthday_month1"]}月{props.inputDatas["h_birthday_day1"]}日
                </td>
            </tr>
            <tr>
                <th>性別</th>
                <td>
                  {props.inputDatas["h_sex1"] == "1" ? "男性" : props.inputDatas["h_sex1"] == "0" ? "女性" : ""}
                </td>
            </tr>
            <tr>
                <th>続柄</th>
                <td>
                  {getRelationship(props.inputDatas["h_relationship1"])}
                </td>
            </tr>
            <tr>
                <th>TEL/携帯電話</th>
                <td>
                  {props.inputDatas["h_tel1"]}
                </td>
            </tr>
            <tr>
                <th>勤務先名/学校名</th>
                <td>
                  {props.inputDatas["h_work1"]}
                </td>
            </tr>
        </tbody>
      </table>
      </div>:""}
      {props.inputDatas["number_of_renters"] >= 2 ?
      <div>
      <h6>【入居者②】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        <tbody>
            <tr>
                <th>氏名</th>
                <td>
                  {props.inputDatas["h_name21"]} {props.inputDatas["h_name22"]}
                </td>
            </tr>
            <tr>
                <th>フリガナ</th>
                <td>
                  {props.inputDatas["h_kana21"]} {props.inputDatas["h_kana22"]}
                </td>
            </tr>
            <tr>
                <th>生年月日</th>
                <td>
                  {props.inputDatas["h_birthday_year2"]}年{props.inputDatas["h_birthday_month2"]}月{props.inputDatas["h_birthday_day2"]}日
                </td>
            </tr>
            <tr>
                <th>性別</th>
                <td>
                  {props.inputDatas["h_sex2"] == "1" ? "男性" : props.inputDatas["h_sex2"] == "0" ? "女性" : ""}
                </td>
            </tr>
            <tr>
                <th>続柄</th>
                <td>
                  {getRelationship(props.inputDatas["h_relationship2"])}
                </td>
            </tr>
            <tr>
                <th>TEL/携帯電話</th>
                <td>
                  {props.inputDatas["h_tel2"]}
                </td>
            </tr>
            <tr>
                <th>勤務先名/学校名</th>
                <td>
                  {props.inputDatas["h_work2"]}
                </td>
            </tr>
        </tbody>
      </table>
      </div>:""}
      {props.inputDatas["number_of_renters"] >= 3 ?
      <div>
      <h6>【入居者③】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        <tbody>
            <tr>
                <th>氏名</th>
                <td>
                  {props.inputDatas["h_name31"]} {props.inputDatas["h_name32"]}
                </td>
            </tr>
            <tr>
                <th>フリガナ</th>
                <td>
                  {props.inputDatas["h_kana31"]} {props.inputDatas["h_kana32"]}
                </td>
            </tr>
            <tr>
                <th>生年月日</th>
                <td>
                  {props.inputDatas["h_birthday_year3"]}年{props.inputDatas["h_birthday_month3"]}月{props.inputDatas["h_birthday_day3"]}日
                </td>
            </tr>
            <tr>
                <th>性別</th>
                <td>
                  {props.inputDatas["h_sex3"] == "1" ? "男性" : props.inputDatas["h_sex3"] == "0" ? "女性" : ""}
                </td>
            </tr>
            <tr>
                <th>続柄</th>
                <td>
                  {getRelationship(props.inputDatas["h_relationship3"])}
                </td>
            </tr>
            <tr>
                <th>TEL/携帯電話</th>
                <td>
                  {props.inputDatas["h_tel3"]}
                </td>
            </tr>
            <tr>
                <th>勤務先名/学校名</th>
                <td>
                  {props.inputDatas["h_work3"]}
                </td>
            </tr>
        </tbody>
      </table>
      </div>:""}
      {props.inputDatas["number_of_renters"] >= 4 ?
      <div>
      <h6>【入居者④】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        <tbody>
            <tr>
                <th>氏名</th>
                <td>
                  {props.inputDatas["h_name41"]} {props.inputDatas["h_name42"]}
                </td>
            </tr>
            <tr>
                <th>フリガナ</th>
                <td>
                  {props.inputDatas["h_kana41"]} {props.inputDatas["h_kana42"]}
                </td>
            </tr>
            <tr>
                <th>生年月日</th>
                <td>
                  {props.inputDatas["h_birthday_year4"]}年{props.inputDatas["h_birthday_month4"]}月{props.inputDatas["h_birthday_day4"]}日
                </td>
            </tr>
            <tr>
                <th>性別</th>
                <td>
                  {props.inputDatas["h_sex4"] == "1" ? "男性" : props.inputDatas["h_sex4"] == "0" ? "女性" : ""}
                </td>
            </tr>
            <tr>
                <th>続柄</th>
                <td>
                  {getRelationship(props.inputDatas["h_relationship4"])}
                </td>
            </tr>
            <tr>
                <th>TEL/携帯電話</th>
                <td>
                  {props.inputDatas["h_tel4"]}
                </td>
            </tr>
            <tr>
                <th>勤務先名/学校名</th>
                <td>
                  {props.inputDatas["h_work4"]}
                </td>
            </tr>
        </tbody>
      </table>
      </div>:""}
      {props.inputDatas["number_of_renters"] >= 5 ?
      <div>
      <h6>【入居者⑤】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        <tbody>
            <tr>
                <th>氏名</th>
                <td>
                  {props.inputDatas["h_name51"]} {props.inputDatas["h_name52"]}
                </td>
            </tr>
            <tr>
                <th>フリガナ</th>
                <td>
                  {props.inputDatas["h_kana51"]} {props.inputDatas["h_kana52"]}
                </td>
            </tr>
            <tr>
                <th>生年月日</th>
                <td>
                  {props.inputDatas["h_birthday_year5"]}年{props.inputDatas["h_birthday_month5"]}月{props.inputDatas["h_birthday_day5"]}日
                </td>
            </tr>
            <tr>
                <th>性別</th>
                <td>
                  {props.inputDatas["h_sex5"] == "1" ? "男性" : props.inputDatas["h_sex5"] == "0" ? "女性" : ""}
                </td>
            </tr>
            <tr>
                <th>続柄</th>
                <td>
                  {getRelationship(props.inputDatas["h_relationship5"])}
                </td>
            </tr>
            <tr>
                <th>TEL/携帯電話</th>
                <td>
                  {props.inputDatas["h_tel5"]}
                </td>
            </tr>
            <tr>
                <th>勤務先名/学校名</th>
                <td>
                  {props.inputDatas["h_work5"]}
                </td>
            </tr>
        </tbody>
      </table>
      </div>:""}
      {props.inputDatas["number_of_renters"] >= 6 ?
      <div>
      <h6>【入居者⑥】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        <tbody>
            <tr>
                <th>氏名</th>
                <td>
                  {props.inputDatas["h_name61"]} {props.inputDatas["h_name62"]}
                </td>
            </tr>
            <tr>
                <th>フリガナ</th>
                <td>
                  {props.inputDatas["h_kana61"]} {props.inputDatas["h_kana62"]}
                </td>
            </tr>
            <tr>
                <th>生年月日</th>
                <td>
                  {props.inputDatas["h_birthday_year6"]}年{props.inputDatas["h_birthday_month6"]}月{props.inputDatas["h_birthday_day6"]}日
                </td>
            </tr>
            <tr>
                <th>性別</th>
                <td>
                  {props.inputDatas["h_sex6"] == "1" ? "男性" : props.inputDatas["h_sex6"] == "0" ? "女性" : ""}
                </td>
            </tr>
            <tr>
                <th>続柄</th>
                <td>
                  {getRelationship(props.inputDatas["h_relationship6"])}
                </td>
            </tr>
            <tr>
                <th>TEL/携帯電話</th>
                <td>
                  {props.inputDatas["h_tel6"]}
                </td>
            </tr>
            <tr>
                <th>勤務先名/学校名</th>
                <td>
                  {props.inputDatas["h_work6"]}
                </td>
            </tr>
        </tbody>
      </table>
      </div>:""}

      
      <h6>【ペット】</h6>
      <table style={{marginBottom: 20, textAlignLast: 'left'}}>
        <colgroup width="40%" />
        <colgroup width="60%" />
        {props.inputDatas["pets"] == 1 && props.inputDatas["dog"] && props.inputDatas["cat"] ?
        <tbody>
            <tr>
                <th>ペット【犬、猫】</th>
                <td>
                  あり
                </td>
            </tr>
            <tr>
                <th>犬飼育匹数</th>
                <td>
                  {props.inputDatas["dog_count"] == "" ? "" : props.inputDatas["dog_count"]+"匹"}
                </td>
            </tr>
            <tr>
                <th>犬備考(種類、大きさ)</th>
                <td>
                  {props.inputDatas["dog_remarks"]}
                </td>
            </tr>
            <tr>
                <th>猫飼育匹数</th>
                <td>
                  {props.inputDatas["cat_count"] == "" ? "" : props.inputDatas["cat_count"]+"匹"}
                </td>
            </tr>
            <tr>
                <th>猫備考(種類、大きさ)</th>
                <td>
                  {props.inputDatas["cat_remarks"]}
                </td>
            </tr>
        </tbody>
        : props.inputDatas["pets"] == 1 && props.inputDatas["dog"] ?
        <tbody>
            <tr>
                <th>ペット【犬】</th>
                <td>
                  あり
                </td>
            </tr>
            <tr>
                <th>飼育匹数</th>
                <td>
                  {props.inputDatas["dog_count"] == "" ? "" : props.inputDatas["dog_count"]+"匹"}
                </td>
            </tr>
            <tr>
                <th>備考(種類、大きさ)</th>
                <td>
                  {props.inputDatas["dog_remarks"]}
                </td>
            </tr>
        </tbody>
        : props.inputDatas["pets"] == 1 && props.inputDatas["cat"] ?
        <tbody>
            <tr>
                <th>ペット【猫】</th>
                <td>
                  あり
                </td>
            </tr>
            <tr>
                <th>飼育匹数</th>
                <td>
                  {props.inputDatas["cat_count"] == "" ? "" : props.inputDatas["cat_count"]+"匹"}
                </td>
            </tr>
            <tr>
                <th>備考(種類、大きさ)</th>
                <td>
                  {props.inputDatas["cat_remarks"]}
                </td>
            </tr>
        </tbody>
        : props.inputDatas["pets"] == 1 ?
        <tbody>
            <tr>
                <th>ペット</th>
                <td>
                  あり
                </td>
            </tr>
        </tbody>
        : props.inputDatas["pets"] == 0 ?
        <tbody>
            <tr>
                <th>ペット</th>
                <td>
                  なし
                </td>
            </tr>
        </tbody>
        : ""
        }
      </table>

      <div className="row pb-5" style={{justifyContent: 'center'}}>
        <button type='button' className="border border-dark" style={{padding:20, paddingLeft: 50, paddingRight: 50}} onClick={() => onClick()}>
          戻る
        </button>
      </div>
    </div>
  );
};

export default ApplyConfirmComponent;
