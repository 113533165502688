const authentication = require('react-azure-adb2c').default;





export const list = (values: any) => {

    // const url = `https://localhost:44341/api/mypagerooms/`+values.cd+'/'+values.no;
    // const url = process.env.REACT_APP_API_OPENROOM_URL + `/api/mypagerooms/` + values.cd + '/' + values.no;

    const url = process.env.REACT_APP_API_OPENROOM_EX_URL+`/v1/mypage/rooms/list/` + values.cd + '/' + values.no
    // const url = `http://localhost:5000/v1/mypage/rooms/list/` + values.cd + '/' + values.no

    // const url = `https://openrooms.azurewebsites.net/v1/mypage/rooms/list/` + values.cd + '/' + values.no
    // const url = `http://localhost:5000/v1/mypage/rooms/list/` + values.cd + '/' + values.no
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const houjin = (values: any) => {

    // const url = `https://localhost:44341/api/mypagerooms/`+values.cd+'/'+values.no;
    // const url = process.env.REACT_APP_API_OPENROOM_URL + `/api/mypagerooms/` + values.cd + '/' + values.no;

    const url = process.env.REACT_APP_API_OPENROOM_EX_URL+`/v1/mypage/rooms/houjin/` + values.cd + '/' + values.no
    // const url = `http://localhost:5000/v1/mypage/rooms/houjin/` + values.cd + '/' + values.no

    // const url = `https://openrooms.azurewebsites.net/v1/mypage/rooms/list/` + values.cd + '/' + values.no
    // const url = `http://localhost:5000/v1/mypage/rooms/list/` + values.cd + '/' + values.no
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const detail = (values: any) => {


    // const url = process.env.REACT_APP_API_OPENROOM_URL + `/api/SearchRooms/` + values.tatemono_id + '/' + values.room_id;
    const url = process.env.REACT_APP_API_OPENROOM_EX_URL+`/v1/mypage/rooms/detail/` + values.cd + '/' + values.no + '/' + values.tatemono_id + '/' + values.room_id
    // const url = `https://openrooms.azurewebsites.net/v1/mypage/rooms/detail/` + values.cd + '/' + values.no + '/' + values.tatemono_id + '/' + values.room_id


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};