import React, { Component } from 'react';

import { connect } from 'react-redux';

// import * as Reserve from './Saga/Reserve';

import "react-datepicker/dist/react-datepicker.css";


// import { Field, reduxForm } from 'redux-form';

import './Css/DateComponent.css';
// let hiddenFlg;

const WeekChars = { Sun: "(日)", Mon: "(月)", Tue: "(火)", Wed: "(水)", Thu: "(木)", Fri: "(金)", Sat: "(土)", holi: "(祝)" };


class DateComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // gasu: this.props.responsedetail.gasu,
            // denki: this.props.responsedetail.denki,
            // ih: this.props.responsedetail.ih,
            // gasuka: this.props.responsedetail.gasuka,
            // denkika: this.props.responsedetail.denkika,
            // ihka: this.props.responsedetail.ihka,
            // eakon: this.props.responsedetail.eakon,
        };
    }

    render() {

        //土曜日or日曜日or祝日
        const wd = this.props.day === "Sat" ? "sat" : (this.props.day === "Sun" || this.props.day === "holi") ? "sun" : ""
        return (
            <div>
                {/* {this.props.day} */}
                <div className={'dateCell_' + wd}>
                    {this.props.date.getMonth() + 1}/{this.props.date.getDate()}<br></br>{this.props.day}
                </div>
            </div>
        );
    }
}



//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        // schedule: state.Reserve.schedule,
        // setsubi_master: state.roomsReducer.setsubi_master,

        // chomei_list: state.search.chomei_list,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {


    };
}


export default connect(mapStateToProps, mapDispatchToProps)(DateComponent);