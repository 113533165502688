import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GetAppIcon from '@material-ui/icons/GetApp';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import * as PublicDocument from './Saga/PublicDocument';
import './Css/PublicDocument.css';
var moment = require('moment-timezone');

class DownloadCompComponent extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            selectedIndex: 0
        };
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.setSelectedIndex = this.setSelectedIndex.bind(this);
    }

    handleListItemClick = (event, index) => {
        this.setSelectedIndex(index);
    }

    setSelectedIndex(i) {
        this.setState.selectedIndex = i
    }
    byte(size) {
        // alert(size)
        const kb = 1024
        const mb = Math.pow(kb, 2)
        const gb = Math.pow(kb, 3)
        const tb = Math.pow(kb, 4)


        if (size >= tb) return Math.floor((size / tb) * Math.pow(10, 1)) / Math.pow(10, 1) + 'TB'
        if (size >= gb) return Math.floor((size / gb) * Math.pow(10, 1)) / Math.pow(10, 1) + 'GB'
        if (size >= mb) return Math.floor((size / mb) * Math.pow(10, 1)) / Math.pow(10, 1) + 'MB'
        if (size >= kb) return Math.floor((size / kb) * Math.pow(10, 1)) / Math.pow(10, 1) + 'KB'
        return size + 'B'

    }
    render() {

        return (
            <div className="container my-5 py-5" style={{ textAlign: "-webkit-center", padding:0 }}>
                <p className="title_bold">株式会社グローバルセンターより</p>
                
                {/* <p className="title_bold">ファイル{this.props.rent_limited_public_docs_list ? this.props.rent_limited_public_docs_list.length : 0}件</p> */}
                {this.props.rent_limited_public_docs_list && this.props.rent_limited_public_docs_list.length > 0 ? <p className="title_bold">ファイル{this.props.rent_limited_public_docs_list.length}件</p> : <p className="title_bold">ダウンロードできるファイルはありません。</p>}

                {this.props.rent_limited_public_docs_list && this.props.rent_limited_public_docs_list.length > 0 ? <span>
                <p>{moment(this.props.rent_limited_public_pages.limited).format('YYYY年M月DD日')}までダウンロードできます。</p>
                <hr />
                <p className='remarks_style' style={{textAlign:"left"}} dangerouslySetInnerHTML={{__html:this.props.rent_limited_public_pages.remarks.replace(/\r?\n/g, '<br />')}}></p>
                {/* <p>下記をクリックしてください。</p> */}
                <div className="frame_style">
                    <List component="nav" aria-label="download">
                        {this.props.rent_limited_public_docs_list ? this.props.rent_limited_public_docs_list.map(function (value) {


                            return <ListItem
                                key={value.code}
                                button
                                // selected={this.selectedIndex === 0}
                                onClick={() => this.props.download_rent_limited_public_docs({ fcd: value.code, name: value.name, })}
                            >
                                <ListItemIcon><GetAppIcon color="primary" /></ListItemIcon>
                                <ListItemText primary={value.size > 0 ? value.name + " / " + this.byte(value.size) : value.name} secondary={value.remarks} tertiary={value.size > 0 ? this.byte(value.size) : ""} />
                            </ListItem>

                        }, this) : ""}


                    </List>
                </div>
                </span>:""}

            </div >
        );
    }
}


const mapStateToProps = state => {
    return {
        // users: state.masterReducer.users_select,
        rent_limited_public_docs_list: state.PublicDocument.rent_limited_public_docs_list,
        rent_limited_public_pages: state.PublicDocument.rent_limited_public_pages,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        download_rent_limited_public_docs(state) {
            dispatch(PublicDocument.download_rent_limited_public_docs(state))
        },

    };
}

DownloadCompComponent = connect(
    mapStateToProps, mapDispatchToProps
)(DownloadCompComponent);

// DownloadCompComponent = reduxForm({
//     form: 'tenantform', // a unique identifier for this form
// })(DownloadCompComponent)

export default DownloadCompComponent