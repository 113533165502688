const authentication = require('react-azure-adb2c').default;





export const send = (values: any) => {


        const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/mypage/reserve/` + values.cd;
        // const url = `http://localhost:5000/v1/mypage/reserve/` + values.cd;

        // alert(url)
        const token = authentication.getAccessToken();
        // alert(JSON.stringify(values))
        return fetch(url, {
                method: 'put',
                body: JSON.stringify(values),

                headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
                .then(payload => ({ payload }))
                .catch(error => ({ error }));

};


export const coming = (values: any) => {

        
        const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/mypage/reserve/coming/` + values.cd;
        // const url = `http://localhost:5000/v1/mypage/reserve/coming/` + values.cd;

        // alert(url)
        const token = authentication.getAccessToken();
        // alert(JSON.stringify(values))
        return fetch(url, {
                method: 'put',
                body: JSON.stringify(values),

                headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
                .then(payload => ({ payload }))
                .catch(error => ({ error }));

};

export const updateSend = (values: any) => {


        const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/mypage/reserve/update/` + values.cd;
        // const url = `http://localhost:5000/v1/mypage/reserve/update/` + values.cd;

        // alert(url)
        const token = authentication.getAccessToken();
        // alert(JSON.stringify(values))
        return fetch(url, {
                method: 'put',
                body: JSON.stringify(values),

                headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
                .then(payload => ({ payload }))
                .catch(error => ({ error }));

};




export const cancel = (values: any) => {


        const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/mypage/reserve/cancel/` + values.cd;
        // const url = `http://localhost:5000/v1/mypage/reserve/cancel/` + values.cd;

        // alert(url)
        const token = authentication.getAccessToken();
        // alert(JSON.stringify(values))
        return fetch(url, {
                method: 'put',
                body: JSON.stringify(values),

                headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
                .then(payload => ({ payload }))
                .catch(error => ({ error }));

};












