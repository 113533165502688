import React, { Component } from 'react';

import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';

import "react-datepicker/dist/react-datepicker.css";
//import { actionCreators } from '../../Store/WeatherForecasts';

import { reduxForm } from 'redux-form';
// import { Button, Modal } from 'reactstrap';
import './Css/Table.css';
import './Css/DetailExplanation.css';
import { Card, CardContent } from '@material-ui/core';
// import { tsThisType } from '@babel/types';
// import * as AutoKana from 'vanilla-autokana';

const distance = (lat1, lng1, lat2, lng2) => {
    lat1 *= Math.PI / 180;
    lng1 *= Math.PI / 180;
    lat2 *= Math.PI / 180;
    lng2 *= Math.PI / 180;
    return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}

class ExplanationDispComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.dateComparison = this.dateComparison.bind(this);
    }

    dateComparison() {
        if (this.props.search_rooms_detail.nyuukyo_kanou_year) {
            if (isNaN(this.props.search_rooms_detail.nyuukyo_kanou_day)) {      //『上旬』『中旬』『下旬』の場合
                const today = new Date(Date.now());
                // console.log("today", today);
                if (this.props.search_rooms_detail.nyuukyo_kanou_day == "上旬") {
                    const nyuukyoKanou = new Date(this.props.search_rooms_detail.nyuukyo_kanou_year, this.props.search_rooms_detail.nyuukyo_kanou_month - 1, 10);
                    // console.log("上旬nyuukyoKanou", nyuukyoKanou);
                    if (today > nyuukyoKanou) {
                        return "即入居";
                    } else {
                        return this.props.search_rooms_detail.nyuukyo_kanou_year + "年" + this.props.search_rooms_detail.nyuukyo_kanou_month + "月" + this.props.search_rooms_detail.nyuukyo_kanou_day;
                    }
                } else if (this.props.search_rooms_detail.nyuukyo_kanou_day == "中旬") {
                    const nyuukyoKanou = new Date(this.props.search_rooms_detail.nyuukyo_kanou_year, this.props.search_rooms_detail.nyuukyo_kanou_month - 1, 20);
                    // console.log("中旬nyuukyoKanou", nyuukyoKanou);
                    if (today > nyuukyoKanou) {
                        return "即入居";
                    } else {
                        return this.props.search_rooms_detail.nyuukyo_kanou_year + "年" + this.props.search_rooms_detail.nyuukyo_kanou_month + "月" + this.props.search_rooms_detail.nyuukyo_kanou_day;
                    }
                } else if (this.props.search_rooms_detail.nyuukyo_kanou_day == "下旬") {
                    const nyuukyoKanou = new Date(this.props.search_rooms_detail.nyuukyo_kanou_year, this.props.search_rooms_detail.nyuukyo_kanou_month, 0);  //末日取得
                    // console.log("下旬nyuukyoKanou", nyuukyoKanou);
                    if (today > nyuukyoKanou) {
                        return "即入居";
                    } else {
                        return this.props.search_rooms_detail.nyuukyo_kanou_year + "年" + this.props.search_rooms_detail.nyuukyo_kanou_month + "月" + this.props.search_rooms_detail.nyuukyo_kanou_day;
                    }
                }
                return this.props.search_rooms_detail.nyuukyo_kanou_year + "年" + this.props.search_rooms_detail.nyuukyo_kanou_month + "月" + this.props.search_rooms_detail.nyuukyo_kanou_day;
            } else {
                const today = new Date(Date.now());
                const nyuukyoKanou = new Date(this.props.search_rooms_detail.nyuukyo_kanou_year, this.props.search_rooms_detail.nyuukyo_kanou_month - 1, this.props.search_rooms_detail.nyuukyo_kanou_day);
                // console.log("今日", today);
                // console.log("nyuukyoKanou", nyuukyoKanou);
                if (today > nyuukyoKanou) {
                    return "即入居";
                } else {
                    return this.props.search_rooms_detail.nyuukyo_kanou_year + "年" + this.props.search_rooms_detail.nyuukyo_kanou_month + "月" + this.props.search_rooms_detail.nyuukyo_kanou_day + "日";
                }
            }
        }
        else if(this.props.search_rooms_detail.kanri_kubun_name === "業物"){
            return "即入居";
        }
        else {
            return "相談可";
        }
    }

    

    render() {

    

        const isMobile = window.innerWidth <= 768;

        return (
            <Card className="mb-3">
                <CardContent className="recommended_style">
                    {!isMobile ?
                        <table>
                            <colgroup width='20%' />
                            <colgroup width='30%' />
                            <tbody>
                                <tr>
                                    <th>種別</th>
                                    <td>
                                        {this.props.search_rooms_detail.tatemono_shubetsu_name}
                                    </td>
                                    <th>築年</th>
                                    <td>
                                        {this.props.search_rooms_detail.shunkou_year}年{this.props.search_rooms_detail.shunkou_month}月{this.props.search_rooms_detail.shunkou_day}日
                                    </td>
                                </tr>

                                <tr>
                                    <th>所在階/階層</th>
                                    <td>
                                        {this.props.search_rooms_detail.kai}階/{this.props.search_rooms_detail.kaidate_chijou}階建て
                                    </td>
                                    <th>エレベーター</th>
                                    <td>
                                        {this.props.search_rooms_detail.have_elevator && this.props.search_rooms_detail.have_elevator === 1 ? "あり" : "なし"}
                                    </td>
                                </tr>

                                <tr>
                                    <th>構造</th>
                                    <td>
                                        {this.props.search_rooms_detail.kouzou_name}
                                    </td>
                                    <th>部屋番号</th>
                                    <td>
                                        {this.props.search_rooms_detail.room_name}
                                    </td>
                                </tr>

                                <tr>
                                    <th>向き</th>
                                    <td>
                                        {this.props.search_rooms_detail.room_muki_name}向き
                                    </td>
                                    <th>駐車料金</th>
                                    <td>
                                        {this.props.search_rooms_detail.p_aki === "管理外" ? (this.props.search_rooms_detail.p_ryou === "" ? " - " : this.props.search_rooms_detail.p_ryou)
                                        : this.props.search_rooms_detail.p_aki === "空き無し" ? "満車" 
                                        : this.props.search_rooms_detail.p_aki === "空きあり" ? (this.props.search_rooms_detail.p_ryou === "" ? " - " : this.props.search_rooms_detail.p_ryou)
                                        :this.props.search_rooms_detail.p_aki=== "無し" ? "無し":""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>更新時</th>
                                    <td>
                                        {this.props.search_rooms_detail.koushin}
                                    </td>
                                    <th>入居可能日</th>
                                    <td>
                                        {this.dateComparison()}
                                        {/* {this.props.search_rooms_detail.nyuukyo_kanou_year ? this.props.search_rooms_detail.nyuukyo_kanou_year + "年" + this.props.search_rooms_detail.nyuukyo_kanou_month + "月" + (isNaN(this.props.search_rooms_detail.nyuukyo_kanou_day)?this.props.search_rooms_detail.nyuukyo_kanou_day:this.props.search_rooms_detail.nyuukyo_kanou_day + "日") : " - "} */}
                                        {/* {this.props.search_rooms_detail.nyuukyoKanouYear === "" ? " - " : this.props.search_rooms_detail.nyuukyoKanouYear + "年"}{this.props.search_rooms_detail.nyuukyoKanouMonth === "" || "00" ? " - " : this.props.search_rooms_detail.nyuukyoKanouMonth + "月"}{this.props.search_rooms_detail.nyuukyoKanouDay === "" ? "" : this.props.search_rooms_detail.nyuukyoKanouDay} */}
                                    </td>
                                </tr>
                                <tr>
                                    <th>間取り</th>
                                    <td>
                                        {this.props.search_rooms_detail.madori_name} {this.props.search_rooms_detail.madori_shousai_name ? "(" + this.props.search_rooms_detail.madori_shousai_name + ")" : ""}
                                    </td>
                                    <th>広さ</th>
                                    <td>
                                        {this.props.search_rooms_detail.senyuu_menseki}㎡
                                    </td>
                                </tr>
                                <tr>
                                    <th>交通</th>
                                    <td>
                                        {this.props.search_rooms_detail.koutsuu1}　{this.props.search_rooms_detail.koutsuu1_min}分<br />
                                        {this.props.search_rooms_detail.koutsuu2}　{this.props.search_rooms_detail.koutsuu2_min}分
                                    </td>
                                    <th>所在地</th>
                                    <td>
                                        {this.props.search_rooms_detail.todoufuken}{this.props.search_rooms_detail.shikuchoson}{this.props.search_rooms_detail.choiki}{this.props.search_rooms_detail.other_address}
                                    </td>
                                </tr>
                                {this.props.no === "0" ? "" :
                                this.props.search_rooms_detail.rent_introduction_point_headers ? this.props.search_rooms_detail.rent_introduction_point_headers.map((pointheader, index) => {
                                    if (pointheader.rent_introduction_point_details[0] && pointheader.type === 1 && Object.keys(pointheader).length > 0) {
                                        return <tr key={index}>
                                            <th>{pointheader.contents}まで</th>
                                            <td colSpan="3">

                                                {Math.ceil(distance(pointheader.rent_introduction_point_details[0].lat, pointheader.rent_introduction_point_details[0].lng, this.props.search_rooms_detail.ido, this.props.search_rooms_detail.keido) * 10) / 10}
                                                km
                                            </td>
                                        </tr>
                                    } else {
                                        return ""
                                    }
                                }, this) : ""
                                
                                }


                                {/* <tr>
                                <th>駐車場</th>
                                <td>
                                    **********
                                </td>
                            </tr> */}

                                {/* <tr>
                                <th>保証金</th>
                                <td>
                                    **********
                                </td>
                            </tr> */}

                                {/* <tr>
                                <th>こだわり</th>
                                <td>
                                    {this.props.search_rooms_detail.roomSetsubiName}
                                </td>
                            </tr> */}
                            </tbody>
                        </table>
                        :
                        <table>
                            <colgroup width='30%' />
                            <colgroup width='70%' />
                            <tbody>
                                <tr>
                                    <th>種別</th>
                                    <td>
                                        {this.props.search_rooms_detail.tatemono_shubetsu_name}
                                    </td>
                                </tr>
                                <tr>
                                    <th>築年</th>
                                    <td>
                                        {this.props.search_rooms_detail.shunkou_year}年{this.props.search_rooms_detail.shunkou_month}月{this.props.search_rooms_detail.shunkou_day}日
                                    </td>
                                </tr>

                                <tr>
                                    <th>所在階/階層</th>
                                    <td>
                                        {this.props.search_rooms_detail.kai}階/{this.props.search_rooms_detail.kaidate_chijou}階建て
                                    </td>
                                </tr>
                                <tr>
                                    <th>エレベーター</th>
                                    <td>
                                        {this.props.search_rooms_detail.have_elevator && this.props.search_rooms_detail.have_elevator == 1 ? "あり" : "なし"}
                                    </td>
                                </tr>

                                <tr>
                                    <th>構造</th>
                                    <td>
                                        {this.props.search_rooms_detail.kouzou_name}
                                    </td>
                                </tr>
                                <tr>
                                    <th>部屋番号</th>
                                    <td>
                                        {this.props.search_rooms_detail.room_name}
                                    </td>
                                </tr>

                                <tr>
                                    <th>向き</th>
                                    <td>
                                        {this.props.search_rooms_detail.room_muki_name}向き
                                    </td>
                                </tr>
                                <tr>
                                    <th>駐車料金</th>
                                    <td>
                                        {this.props.search_rooms_detail.p_aki === "管理外" ? (this.props.search_rooms_detail.p_ryou === "" ? " - " : this.props.search_rooms_detail.p_ryou)
                                        : this.props.search_rooms_detail.p_aki === "空き無し" ? "満車" 
                                        : this.props.search_rooms_detail.p_aki === "空きあり" ? (this.props.search_rooms_detail.p_ryou === "" ? " - " : this.props.search_rooms_detail.p_ryou)
                                        :this.props.search_rooms_detail.p_aki=== "無し" ? "無し":""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>更新時</th>
                                    <td>
                                        {this.props.search_rooms_detail.koushin}
                                    </td>
                                </tr>
                                <tr>
                                    <th>入居可能日</th>
                                    <td>
                                        {this.dateComparison()}
                                        {/* {this.props.search_rooms_detail.nyuukyo_kanou_year ? this.props.search_rooms_detail.nyuukyo_kanou_year + "年" + this.props.search_rooms_detail.nyuukyo_kanou_month + "月" + (isNaN(this.props.search_rooms_detail.nyuukyo_kanou_day)?this.props.search_rooms_detail.nyuukyo_kanou_day:this.props.search_rooms_detail.nyuukyo_kanou_day + "日") : " - "} */}
                                        {/* {this.props.search_rooms_detail.nyuukyoKanouYear === "" ? " - " : this.props.search_rooms_detail.nyuukyoKanouYear + "年"}{this.props.search_rooms_detail.nyuukyoKanouMonth === "" || "00" ? " - " : this.props.search_rooms_detail.nyuukyoKanouMonth + "月"}{this.props.search_rooms_detail.nyuukyoKanouDay === "" ? "" : this.props.search_rooms_detail.nyuukyoKanouDay} */}
                                    </td>
                                </tr>
                                <tr>
                                    <th>間取り</th>
                                    <td>
                                        {this.props.search_rooms_detail.madori_name} {this.props.search_rooms_detail.madori_shousai_name ? "(" + this.props.search_rooms_detail.madori_shousai_name + ")" : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>広さ</th>
                                    <td>
                                        {this.props.search_rooms_detail.senyuu_menseki}㎡
                                    </td>
                                </tr>
                                <tr>
                                    <th>交通</th>
                                    <td>
                                        {this.props.search_rooms_detail.koutsuu1}　{this.props.search_rooms_detail.koutsuu1_min}分<br />
                                        {this.props.search_rooms_detail.koutsuu2}　{this.props.search_rooms_detail.koutsuu2_min}分
                                    </td>
                                </tr>
                                <tr>
                                    <th>所在地</th>
                                    <td>
                                        {this.props.search_rooms_detail.todoufuken}{this.props.search_rooms_detail.shikuchoson}{this.props.search_rooms_detail.choiki}{this.props.search_rooms_detail.other_address}
                                    </td>
                                </tr>
                                {this.props.no === "0" ? "" :
                                this.props.search_rooms_detail.rent_introduction_point_headers ? this.props.search_rooms_detail.rent_introduction_point_headers.map((pointheader, index) => {
                                    if (pointheader.type === 1 && Object.keys(pointheader).length > 0) {
                                        return <tr key={index}>
                                            <th>{pointheader.contents}まで</th>
                                            <td>
                                                {Math.ceil(distance(pointheader.rent_introduction_point_details[0].lat, pointheader.rent_introduction_point_details[0].lng, this.props.search_rooms_detail.ido, this.props.search_rooms_detail.keido) * 10) / 10}
                                                km
                                            </td>
                                        </tr>
                                    } else {
                                        return ""
                                    }
                                }, this) : ""}

                                {/* <tr>
                            <th>駐車場</th>
                            <td>
                                **********
                            </td>
                        </tr> */}

                                {/* <tr>
                            <th>保証金</th>
                            <td>
                                **********
                            </td>
                        </tr> */}

                                {/* <tr>
                            <th>こだわり</th>
                            <td>
                                {this.props.search_rooms_detail.roomSetsubiName}
                            </td>
                        </tr> */}
                            </tbody>
                        </table>
                    }
                </CardContent>
            </Card>
        );
    }
}


//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

ExplanationDispComponent = reduxForm({
    form: 'detailform', // a unique identifier for this form
    //validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(ExplanationDispComponent)

export default connect(mapStateToProps, mapDispatchToProps)(ExplanationDispComponent);