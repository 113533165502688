import React, { Component }  from 'react';
import { connect } from 'react-redux';
import './Css/RentNaviComponent.css';

class RentNaviComponent extends Component {
   

    render() {
       
        return (

            <div id="proposing-items-navi">
                <div className="panel">
                    {/* <ul class="nav nav-tabs nav-justified">
                        <li class="active">
                            <a href="#">
                                <i class="fa fa-home"></i>
                                一覧
                            </a>
                        </li>
                        <li>
                            <a href="">
                                <i class="fas fa-map-marker-alt"></i>
                                地図
                            </a>
                        </li>
                        <li>
                            <a href="">
                                <i class="fa fa-star"></i>
                                お気に入り
                            </a>
                        </li>
                    </ul> */}
                </div>
            </div>

        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        cd: state.masterReducer.cd,
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
//        customeredit1change(state) {
//            dispatch(customeredit1change(state))
//        },
//customeredit2change(state) {
//        dispatch(customeredit2change(state))
//    },
//        responseeditchange(state) {
//            dispatch(responseeditchange(state))
//        },
//        customerdetailData1(state) {
//            dispatch(customerdetailData1(state))
//        }, responsedetailData(state) {
//            dispatch(responsedetailData(state))
//        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RentNaviComponent);
