import React, { useState } from 'react';
import ApplyFormComponent from './ApplyFormComponent';
import ApplyConfirm from './ApplyConfirmComponent';
import RentHeaderComponent from '../Layout/RentHeaderComponent';



function ApplyComponent () {

    // 入力値を確認画面へ渡す
    const [inputDatas, setInputDatas] = useState({});
    const handleInputDatas = (value) => {
        setInputDatas(value);
    }

    // 確認画面から入力画面へ戻る
    const [items, setItems] = useState({});
    const handleSetItems = (value) => {
        setItems(value);
    }

    // 入力画面と確認画面の切り替え
    const [pageChange, setPageChange] = useState(1);
    const [returnFlg, setReturnFlg] = useState(false);    // 最初の入力画面と確認画面から戻った入力画面判断
    const handlePageChange = (value) => {
        setPageChange(value);
        setReturnFlg(true);    // 1度でもページ遷移したら、確認画面のitems取得可能
        // if(value) {
        //     setReturnFlg(true);
        // }
    }


    return (
        <div style={{ backgroundColor: "whitesmoke" }}>
            <RentHeaderComponent root="newcard" />

            
        <div className="container">
            <div className="display_style">
                <div className="confirm_h1" style={{backgroundColor:"white"}}>お申込みフォーム</div>

                    <table style={{marginBottom:20, textAlignLast: 'left'}}>
                        <colgroup width="20%" />
                        <colgroup width="30%" />
                        <colgroup width="20%" />
                        <colgroup width="30%" />
                        <tbody>
                            <tr>
                                <th>物件名</th>
                                <td style={{backgroundColor:"white"}}>
                                    {/* <div>{this.props.rent_contracts.building_name}</div> */}
                                </td>
                                <th>部屋名</th>
                                <td style={{backgroundColor:"white"}}>
                                    {/* <div className="fn-middle">{this.props.rent_contracts.room_name}</div> */}
                                </td>
                            </tr>
                            <tr>
                                <th>契約予定日</th>
                                <td style={{backgroundColor:"white"}} colSpan={3}>
                                    {/* <div className="fn-middle">{this.props.rent_contracts.contract_date}</div> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{marginBottom:20, textAlignLast: 'left'}}>
                        <colgroup width="40%" />
                        <colgroup width="60%" />
                        <tbody>
                            <tr>
                                <th colSpan={2} style={{textAlignLast: 'center'}}>条件</th>
                            </tr>
                            <tr>
                                <th>賃料</th>
                                <td style={{backgroundColor:"white"}}>
                                    <div className="fn-middle">{}</div>
                                </td>
                            </tr>
                            <tr>
                                <th>共益費</th>
                                <td style={{backgroundColor:"white"}}>
                                    <div className="fn-middle">{}</div>
                                </td>
                            </tr>
                            <tr>
                                <th>水道料</th>
                                <td style={{backgroundColor:"white"}}>
                                    <div className="fn-middle">{}</div>
                                </td>
                            </tr>
                            <tr>
                                <th>駐車料</th>
                                <td style={{backgroundColor:"white"}}>
                                    <div className="fn-middle">{}</div>
                                </td>
                            </tr>
                            <tr>
                                <th>敷金</th>
                                <td style={{backgroundColor:"white"}}>
                                    <div className="fn-middle">{}</div>
                                </td>
                            </tr>
                            <tr>
                                <th>礼金</th>
                                <td style={{backgroundColor:"white"}}>
                                    <div className="fn-middle">{}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>



                    <div className="conditions_style_application">
                        <div className="container">
                            {pageChange == 1 ? (returnFlg ? <ApplyFormComponent handlePageChange={handlePageChange} handleInputDatas={handleInputDatas} items={items}/> : <ApplyFormComponent handlePageChange={handlePageChange} handleInputDatas={handleInputDatas}/>) : pageChange == 2 ? <ApplyConfirm handlePageChange={handlePageChange} handleSetItems={handleSetItems} inputDatas={inputDatas} /> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}




export default ApplyComponent;
