import React, { Component } from 'react';
import { connect } from 'react-redux';
import LineFormComponent from './LineFormComponent';
import LineTextComponent from './LineTextComponent';
import * as Master from '../../Store/Master';
import * as Line from './Saga/Line';
import Layout from '../Layout/LayoutComponent';

class LineComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comp: false
        };

        this.props.change_line_comp(false)


        this.props.change_cd({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        this.props.get_line_code(this.props.match.params.cd)

        // this.props.get_mypage_request(this.props.match.params.cd)
        this.handlesubmit = this.handlesubmit.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handlesubmit(state) {
        // console.log(state)
        // alert()
        this.setState({ comp: true })
    }

    render() {

        return (
            <Layout>
                <div style={{ backgroundColor: "#f5f5f5" }}>
                    <div className="container">
                        <LineFormComponent />
                        {this.props.line_code === "comp" ? "" : <LineTextComponent />}
                        {/* {this.props.line_comp===false?<LineFormComponent onSubmit={this.handlesubmit}/>:<LineCompComponent/>} */}
                    </div>
                </div>
            </Layout>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,

        line_comp: state.Line.line_comp,
        line_code: state.Line.line_code,
        line_section_id: state.Line.line_section_id,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_cd(state) {
            dispatch(Master.change_cd(state))
        },
        // get_mypage_request(state) {
        //     dispatch(Line.get_mypage_request(state))
        // },
        change_line_comp(state) {
            dispatch(Line.change_line_comp(state))
        },
        get_line_code(state) {
            dispatch(Line.get_line_code(state))
        },
        //        customeredit1change(state) {
        //            dispatch(customeredit1change(state))
        //        },
        //customeredit2change(state) {
        //        dispatch(customeredit2change(state))
        //    },
        //        responseeditchange(state) {
        //            dispatch(responseeditchange(state))
        //        },
        //        customerdetailData1(state) {
        //            dispatch(customerdetailData1(state))
        //        }, responsedetailData(state) {
        //            dispatch(responsedetailData(state))
        //        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LineComponent);
