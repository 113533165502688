import React, { Component } from 'react';

import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { reduxForm } from 'redux-form';
import * as Rent from '../Rent/Saga/Rent';
import './Css/Contact.css';
// import MapDispComponent from '../Map/MapDispComponent';
import { Card, CardContent } from '@material-ui/core';

import * as Detail from './Saga/Detail';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { jaJP } from '@mui/x-date-pickers';

import { ThemeProvider, createTheme } from '@mui/material';
import DateRangeIcon from '@material-ui/icons/DateRange';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

import moment from "moment";



const theme = createTheme(
    {},
    jaJP, // x-date-pickers translations
);
const Today = new Date();



class ContactComponent extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            tatemono:{
                "tatemono_id": props.search_rooms_detail.tatemono_id,
                "room_id": props.search_rooms_detail.room_id,
                "tatemono_name": props.search_rooms_detail.tatemono_name,
                "room_name": props.search_rooms_detail.room_name,
                "zip": props.search_rooms_detail.zip1 + "-" + props.search_rooms_detail.zip2,
                "address": props.search_rooms_detail.todoufuken + props.search_rooms_detail.shikuchoson + props.search_rooms_detail.choiki + props.search_rooms_detail.other_address,
                "chinryou": props.search_rooms_detail.chinryou,
                "madori_name": props.search_rooms_detail.madori_name,
            },
            sort:"",
            mail:"",
            time:"",
            selectedDate:'',
            shitsumon:"",
            sortError: true,
            mailError: true,
        };
        if (this.props.mypage_local_store.length === 0) {
            this.props.get_mypage_local_spots(1)
            this.props.get_mypage_local_spots(2)
        }
        this.onChange = this.onChange.bind(this);
        this.onChangeMail = this.onChangeMail.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        // this.handleDateChange = this.handleDateChange.bind(this);
        this.onChangeShitsumon = this.onChangeShitsumon.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }
    onChange(e) {
        const inputValue = e.target.value;
        console.log(inputValue)
        if(inputValue === ""){
            this.setState({ sortError: true});
        }else {
            this.setState({ sortError: false});
        }
        this.setState({ sort: inputValue});
    }
    onChangeMail(e){
        const inputEmail = e.target.value;

        // メールの形式バリデーションを行う例
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValidEmail = emailRegex.test(inputEmail);

        if (!isValidEmail) {
            this.setState({ mailError: true}); // エラーがある場合にエラーステートを設定
        } else {
            this.setState({ mailError: false});  // エラーがない場合にエラーステートをクリア
        }

        this.setState({ mail: e.target.value});
    }
    onChangeTime(e) {
        this.setState({ time: e.target.value});
        console.log(this.state.time)
    }
    handleDateChange = (date) => {
        // DatePickerで日付が選択されたときに呼び出されるコールバック関数
        console.log(date.$d)
        let dates = moment(date.$d).format( "YYYY年MM月DD日" )
        this.setState({ selectedDate: dates });
        
    }
    

    onChangeShitsumon(e) {
        this.setState({ shitsumon: e.target.value});
    }

    handleFormSubmit(event){
        var contacts = {}

        contacts.sort = this.state.sort;
        contacts.mail = this.state.mail;
        contacts.selectedDate = this.state.selectedDate;
        contacts.time = this.state.time;
        contacts.shitsumon = this.state.shitsumon;

        var list = { cd: this.props.cd, no: this.props.no, contacts:contacts, tatemono:this.state.tatemono }
        
        console.log(list)

        this.props.bukken_reserve(list)
    }


   
    render() {

        return (
            <Card id='Contact' style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                {!this.props.bukken_submit?<CardContent className='inner'>
                    <h3 style={{fontFamily:"Noto Sans JP,sans-serif"}}>お問合せ情報入力</h3>
                    <div className='form_box'>
                        <Box style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                            <span className='title' style={{fontFamily:"Noto Sans JP,sans-serif"}}>問合せ内容</span>
                            <span className='required' style={{fontFamily:"Noto Sans JP,sans-serif"}}>必須</span>
                            <Box className="selectbox">
                                <FormControl fullWidth>
                                    <Select
                                    id="demo-simple-select"
                                    name="Sort"
                                    value={this.state.sort}
                                    onChange={this.onChange}
                                    error={this.state.sortError} // エラーステートに基づいてエラー状態を設定
                                    helpertext={this.state.sortError ? '選択してください。' : ''} // エラーメッセージを表示
                                    >
                                    <MenuItem value={"最新の空室状況を知りたい"}>最新の空室状況を知りたい</MenuItem>
                                    <MenuItem value={"実際に見学したい"}>実際に見学したい</MenuItem>
                                    <MenuItem value={"その他"}>その他</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box className="box">
                            <span className='title' style={{fontFamily:"Noto Sans JP,sans-serif"}}>メールアドレス</span>
                            <span className='required' style={{fontFamily:"Noto Sans JP,sans-serif"}}>必須</span>
                            <Box className="mailbox">
                                <TextField id="outlined-basic" fullWidth
                                variant="outlined" value={this.state.mail} onChange={this.onChangeMail}
                                error={this.state.mailError} // エラーステートに基づいてエラー状態を設定
                                helpertext={this.state.mailError ? '有効なメールアドレスを入力してください' : ''} // エラーメッセージを表示
                                />
                            </Box>
                        </Box>

                        <Box className="box">
                            <span className='title' style={{fontFamily:"Noto Sans JP,sans-serif"}}>物件に関する質問</span>
                            <textarea className='shitsumon' style={{fontFamily:"Noto Sans JP,sans-serif"}}
                            value={this.state.shitsumon}
                            onChange={this.onChangeShitsumon}
                            />
                        </Box>
                        <Box className="box btnbox">

                            {this.props.bukken_reserve_loading ? <LoadingButton
                                loading
                                loadingPosition="start"
                                variant="outlined"
                                style={{ marginLeft: 10 }}
                                className='LoadingButton'
                            >
                                送信中
                            </LoadingButton> :
                            <Button className='soushin' disabled={this.state.sortError || this.state.mailError} 
                            onClick={this.handleFormSubmit} >
                                送 信
                            </Button>
                            }
                            

                            <Button id='RaitenBtn' variant="contained" size="large" style={{backgroundColor: "#4ac0f0"}}  startIcon={<DateRangeIcon />}
                            value={3} onClick={()=>this.props.history.push("/Reserve/"+this.props.cd+"/"+this.props.no+'?tid='+ this.props.search_rooms_detail.tatemono_id + '&rid='+ this.props.search_rooms_detail.room_id)}
                            >
                                続けて来店予約
                            </Button>
                        </Box>
                    </div>
                </CardContent>
                :
                <CardContent className='inner' style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                    <div class="h4 col-12 text-center py-3">
                     お問い合わせありがとうございます。
                    </div>
                </CardContent>
                }
            </Card>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        mypage_local_store: state.Rent.mypage_local_store,
        bukken_reserve_loading: state.Detail.bukken_reserve_loading,
        bukken_submit: state.Detail.bukken_submit,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_mypage_local_spots(state) {
            dispatch(Rent.get_mypage_local_spots(state))
        },
        bukken_reserve(state) {
            dispatch(Detail.bukken_reserve(state))
        },
        change_bukken_reserve(state) {
            dispatch(Detail.change_bukken_reserve(state))
        },
        // change_bukken_reserve_loading(state) {
        //     dispatch(Detail.change_bukken_reserve_loading(state))
        // },
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

ContactComponent = reduxForm({
    form: 'detailform', // a unique identifier for this form
})(ContactComponent)

export default connect(mapStateToProps, mapDispatchToProps)(ContactComponent);