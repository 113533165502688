/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import * as Line from './Saga/Line';
import "react-datepicker/dist/react-datepicker.css";
import { reduxForm } from 'redux-form';
import './Css/Credit.css';
import Layout from '../Layout/LayoutComponent';
import Alert from '@material-ui/lab/Alert';
import RentHeaderComponent from '../Layout/RentHeaderComponent';

class CreditCompComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="app" style={{ height: "100%" }}>
                <RentHeaderComponent />
                <div style={{ minHeight: "100%", backgroundColor: "whitesmoke" }}>
                    <div className="display_style">
                        <div className="container">
                            <div className="frame" style={{ height: 300 }}>
                                <Alert severity="success" style={{ margin: 30, fontSize: 20, height: 200 }}><div>お手続きありがとうございます。</div><div>登録が完了しました</div></Alert>
                            </div>

                            {/* <div className="frame">
                            <div className="text_style code_color">お取引が完了しました</div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        // mypage_request: state.Coming.mypage_request,
        line_code: state.Line.line_code,
        line_limit: state.Line.line_limit,
        line_section_id: state.Line.line_section_id,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mypage_request(state) {
        //     dispatch(Line.change_mypage_request(state))
        // },
        // update_mypage_request(state) {
        //     dispatch(Line.update_mypage_request(state))
        // },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}


// export default ComingFormComponent_all = connect(
//     mapStateToProps, mapDispatchToProps
// )(ComingFormComponent_all);
CreditCompComponent = connect(
    mapStateToProps, mapDispatchToProps
)(CreditCompComponent);

CreditCompComponent = reduxForm({
    form: 'tenantform', // a unique identifier for this form
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(CreditCompComponent)

export default CreditCompComponent