import React, { Component } from 'react';

import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { reduxForm } from 'redux-form';
import * as Rent from '../Rent/Saga/Rent';
import './Css/Osusume.css';
// import MapDispComponent from '../Map/MapDispComponent';
import { Card, CardContent } from '@material-ui/core';


class OsusumeComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            staff:{}
        };
        if (this.props.mypage_local_store.length === 0) {
            this.props.get_mypage_local_spots(1)
            this.props.get_mypage_local_spots(2)
        }
    }
    render() {
        return (
            <Card className="" style={{border: "3px solid #EBA855"}}>
                <CardContent style={{paddingRight:"0",paddingLeft:"0"}}>
                    <div id='DetailComment'>
                        <div className="recommended_title">
                            <div className='staffImg'>
                                {this.props.staff.staff_name && this.props.staff.staff.length > 0 && this.props.staff.staff[0].image_ext? 
                                <img src={"https://img.global-center.co.jp/v1/staff/introduction/" + this.props.staff.staff[0].id + "/140/105/" + this.props.staff.staff[0].image_ext}  alt='スタッフ写真'/>
                                :<img src={`https://www.homestation.jp/manage/master/images/index/`+this.props.staff.staff_name[0].id}  alt='スタッフ写真'/>
                                }
                            </div>
                            <div className='title'>
                                <img className='recommend' src={`${process.env.PUBLIC_URL}/img/recommend.png`} alt=''/>
                                <span className='text' style={{fontFamily:"Noto Sans JP,sans-serif"}}>この部屋のココがオススメ！</span>
                            </div>
                        </div>
                        
                        {this.props.search_rooms_detail.remarks?
                        <div className="recommended_text" style={{fontFamily:"Noto Sans JP,sans-serif"}} dangerouslySetInnerHTML={{ __html: this.props.search_rooms_detail.remarks.replace(/\r?\n/g, '<br />') }} />
                        :
                        <div className="recommended_text" style={{fontFamily:"Noto Sans JP,sans-serif"}} dangerouslySetInnerHTML={{ __html: this.props.search_rooms_detail.pr_comment_web.replace(/\r?\n/g, '<br />') }} />
                        }
                    </div> 
                </CardContent>
            </Card>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        mypage_local_store: state.Rent.mypage_local_store,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_mypage_local_spots(state) {
            dispatch(Rent.get_mypage_local_spots(state))
        },
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

OsusumeComponent = reduxForm({
    form: 'detailform', // a unique identifier for this form
})(OsusumeComponent)

export default connect(mapStateToProps, mapDispatchToProps)(OsusumeComponent);