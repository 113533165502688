import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router'
import logger from 'redux-logger';
// import rootSaga from './sagaes';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'


import * as Master from './Store/Master';

import * as Detail from './Components/Detail/Saga/Detail';
import * as Rent from './Components/Rent/Saga/Rent';
import * as Coming from './Components/Coming/Saga/Coming';

import * as Reserve from './Components/Reserve/Saga/Reserve';
import * as Card from './Components/Card/Saga/Card';
import * as Line from './Components/Line/Saga/Line';
import * as Download from './Components/Download/Saga/Download';
import * as PublicDocument from './Components/PublicDocument/Saga/PublicDocument';
import * as Credit from './Components/Credit/Saga/Credit';
import * as Transfer from './Components/Transfer/Saga/Transfer';

import { all } from 'redux-saga/effects';



export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const reducers = {

    masterReducer: Master.masterReducer,

    Detail: Detail.detailReducer,

    Rent: Rent.rentReducer,
    Reserve: Reserve.reserveReducer,
    Coming: Coming.comingReducer,
    Card: Card.cardReducer,
    Line: Line.lineReducer,
    Download: Download.downloadReducer,
    PublicDocument: PublicDocument.publicdocumentReducer,
    Credit: Credit.creditReducer,
    Transfer:Transfer.transferReducer,

};
const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers // rest of your reducers
});





export function* rootSaga() {
    yield all([
        ...Master.masterSaga,

        ...Detail.detailSaga,
        ...Rent.rentSaga,
        ...Reserve.reserveSaga,
        ...Coming.comingSaga,
        ...Card.cardSaga,
        ...Line.lineSaga,
        ...Download.downloadSaga,
        ...PublicDocument.publicdocumentSaga,
        ...Credit.creditSaga,
        ...Transfer.transferSaga
    ]);
}




export default function configureStore(preloadedState) {
    const store = createStore(
        rootReducer, // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunk,
                // logger,
                sagaMiddleware,
                // ... other middlewares ...
            ),
        ),
    )
    sagaMiddleware.run(rootSaga)

    return store
}
