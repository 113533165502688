import React, { Component }  from 'react';
import { connect } from 'react-redux';
import './Css/RentShopComponent.css';
import Link from '@mui/material/Link';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

class RentStaffComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // map_link:""
            open:false,
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);

        // if(this.props.tenpo[0].Tenpo_No === 17){
        //     this.map_link = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d489.84393729475624!2d134.05478061662552!3d34.31829329681602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3553ebabeba735f1%3A0x1897fbf2e56826c4!2zKOagqinjgrDjg63jg7zjg5Djg6vjgrvjg7Pjgr_jg7wg5pys56S-44K144Oz44OV44Op44Ov44O85bqX!5e0!3m2!1sja!2sjp!4v1693813899062!5m2!1sja!2sjp"            
        // }
    }

    handleClickOpen(){
        this.setState({open: true});
    };
    
    handleClose(){
        this.setState({open: false});
    };
    
    eigyouCall() {
        if(this.props.tenpo.length || Object.keys(this.props.tenpo).indexOf("tenpo")!== -1 ){
            //現在の日付と曜日取得
            const now = new Date();
            const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
            const currentDayIndex = now.getDay();
            const currentDayOfWeek = daysOfWeek[currentDayIndex];//その日の曜日を取得

            const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.props.tenpo[0].start_h, this.props.tenpo[0].start_m);
            const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.props.tenpo[0].end_h, this.props.tenpo[0].end_m);

            // const youbi =this.props.tenpo[0].Teikyuubi.charAt(0) //Teikyuubiの一文字目を取得
            const youbi = this.props.tenpo[0].Tenpo_No !== 10 ? this.props.tenpo[0].Teikyuubi.charAt(0): "木"

            // 定休日でなく、かつ指定の時間帯内にいる場合に電話がかけられる
            // console.log( youbi!==currentDayOfWeek && now >= startTime && now <= endTime,"now")
            // return youbi!==currentDayOfWeek && now >= startTime && now <= endTime;
            if(youbi !== currentDayOfWeek && now >= startTime && now <= endTime){
                return true;
            }else{
                return false;
            }
        }
    }

    render() {
              
        this.eigyouCall();


        return (

           <div id='shopbox'> 
                {this.props.tenpo && this.props.tenpo.length > 0?
                <div>
                <h3>店舗情報</h3>
                <div className='shopbox'>
                    <div className='imgbox'>
                    <img src={"https://img.global-center.co.jp/v1/shop/"+this.props.tenpo[0].Tenpo_No+"/540/960"} alt='店舗'/>
                    <img className='staff' src={"https://img.global-center.co.jp/v1/staff/"+this.props.tenpo[0].Tenpo_No+"/560/940"} alt='スタッフ写真'/>
                    </div>
                    <div className='detail'>
                        <p className='tenpo_name'>{this.props.tenpo[0].Tenpo_Name}</p>
                        <div className='tenpo'>
                            <dl>
                                <dt>所 在 地</dt>
                                <dd>〒{this.props.tenpo[0].Zip_1}-{this.props.tenpo[0].Zip_2}　{this.props.tenpo[0].Shikuchoson+this.props.tenpo[0].Choiki+this.props.tenpo[0].Other_Address}</dd>
                            </dl>
                            <dl>
                                <dt>営業時間</dt>
                                <dd>{this.props.tenpo[0].Eigyou_Jikan}</dd>
                            </dl>
                            <dl>
                                <dt>定 休 日</dt>
                                <dd>{this.props.tenpo[0].Teikyuubi}</dd>
                            </dl>
                            <dl>
                                <dt>T　E　L</dt>
                                {window.innerWidth >= 768?<dd><Link href={'tel:'+ this.props.tenpo[0].Tel}>{this.props.tenpo[0].Tel}</Link></dd>
                                :<dd>
                                    <div onClick={this.handleClickOpen} style={{color: "#1976d2",textDecoration: 'underline'}}>
                                        {this.props.tenpo[0].Tel}
                                    </div>
                                </dd>}
                            </dl>
                            <dl>
                                <dt>F　A　X</dt>
                                <dd>{this.props.tenpo[0].Fax}</dd>
                            </dl>
                            <dl>
                                <dt>駐 車 場</dt>
                                <dd>{this.props.tenpo[0].parking_remarks}</dd>
                            </dl>
                            <dl>
                                <dt>免許番号</dt>
                                <dd>宅地建物取引業{this.props.tenpo[0].Menkyo_Bangou}</dd>
                            </dl>
                            <dl>
                                <dt>所属団体</dt>
                                <dd>
                                    (公社)香川県宅地建物取引業企業協会会員<br/>
                                    四国地区不動産公正取引協議会加盟
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div className='shopmap'>
                    <iframe
                        src={'https://maps.google.com/maps?output=embed&q=' + this.props.tenpo[0].lat +',' +this.props.tenpo[0].lng +'&t=m&hl=ja&z=18'}
                        width="100%" height="100%" style={{border:0}} title='map' allowFullScreen="" frameBorder="0" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>                
                </div>
                {/* <div className='shopmap'>
                    <iframe className='for-large' src={this.map_link} width="100%" height="400" style={{border:0 }}title='map' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    <iframe className='for-small' src={this.map_link} width="100%" height="200" style={{border:0 }}title='map' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div> */}

            <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    id='popupTel'
                    style={{fontFamily:"Noto Sans JP,sans-serif"}}
                >
                    <DialogTitle style={{fontWeight:"bold",fontFamily:"Noto Sans JP,sans-serif"}}>
                    {"電話でお問い合わせ"}
                    </DialogTitle>
                    <DialogContent>
                        {this.props.tenpo.length || Object.keys(this.props.tenpo).indexOf("staff")!== -1 ?
                        <DialogContentText style={{fontFamily:"Noto Sans JP,sans-serif",color:"#000"}}>
                            <p className='shopName'>グローバルセンター{this.props.tenpo[0].Tenpo_Name}</p>
                            <dl className={!this.eigyouCall()?'infos gai':"infos"}>
                                <dt>営業時間</dt>
                                <dd>{this.props.tenpo[0].Eigyou_Jikan}</dd>
                            </dl>
                            {!this.eigyouCall()?<p className='eigyougai'>※現在営業時間外です。</p>:""}
                            <dl className={!this.eigyouCall()?'infos gai':"infos"}>
                                <dt>定休日</dt>
                                <dd>{this.props.tenpo[0].Teikyuubi}</dd>
                            </dl>
                            <div className='popupBtns'>
                                {this.eigyouCall()?<a className='pb_Tel' href={"tel:"+ this.props.tenpo[0].Tel}>
                                    <div style={{ backgroundColor:"#4AC0F0"}}>
                                        <LocalPhoneIcon style={{marginBottom: 0,fontSize:"35px"}}/>
                                        <span className='text'>電話でお問い合わせ</span>
                                    </div>
                                </a>
                                :
                                <div className='pb_Tel'>
                                    <div style={{ backgroundColor:"#A0A0A0" }}>
                                        <LocalPhoneIcon style={{marginBottom: 0,fontSize:"35px"}}/>
                                        <span className='text'>電話でお問い合わせ</span>
                                    </div>
                                </div>
                                }
                                <div className='pb_Cancel' onClick={this.handleClose}>
                                    キャンセル
                                </div>
                            </div>
                        </DialogContentText>
                        :""}
                    </DialogContent>
                </Dialog>
           </div> :""}

        </div>

        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        cd: state.masterReducer.cd,
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
//        customeredit1change(state) {
//            dispatch(customeredit1change(state))
//        },
//customeredit2change(state) {
//        dispatch(customeredit2change(state))
//    },
//        responseeditchange(state) {
//            dispatch(responseeditchange(state))
//        },
//        customerdetailData1(state) {
//            dispatch(customerdetailData1(state))
//        }, responsedetailData(state) {
//            dispatch(responsedetailData(state))
//        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RentStaffComponent);
