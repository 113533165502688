import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import './Css/RentBukkenListComponent.css';

// import { orange } from '@material-ui/core/colors';
// import color from '@material-ui/core/colors/amber';
// import { tsThisType } from '@babel/types';

const distance = (lat1, lng1, lat2, lng2) => {
    lat1 *= Math.PI / 180;
    lng1 *= Math.PI / 180;
    lat2 *= Math.PI / 180;
    lng2 *= Math.PI / 180;
    return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}


class RentBukkenListComponent extends Component {

    constructor(props) {
        super(props);
        // console.log(props.rooms,"rooms")
        this.state = {
            device: 'pc',
        };

        this.dateComparison = this.dateComparison.bind(this);
    }

    

    componentDidMount() {
        const ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
            this.setState({ device: 'sp' });
        } else if (ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0) {
            // iOS12 まで
            this.setState({ device: 'tab' });
        } else if (ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document) {
            // iOS13 以降
            this.setState({ device: 'tab' });
        } else {
            this.setState({ device: 'pc' });
        }
    }

    open_window(url) {

        if(this.state.device === 'pc'){
            window.open(url, '_blank', 'noopener')
        }else{
            this.props.history.push(url)
        }

    }

    dateComparison() {
        if(this.props.rooms.nyuukyo_kanou_year) {
            if(isNaN(this.props.rooms.nyuukyo_kanou_day)) {      //『上旬』『中旬』『下旬』の場合
                const today = new Date(Date.now());
                // console.log("today", today);
                if(this.props.rooms.nyuukyo_kanou_day == "上旬") {
                    const nyuukyoKanou = new Date(this.props.rooms.nyuukyo_kanou_year, this.props.rooms.nyuukyo_kanou_month-1, 10);
                    // console.log("上旬nyuukyoKanou", nyuukyoKanou);
                    if(today > nyuukyoKanou) {
                        return "即入居";
                    } else {
                        return this.props.rooms.nyuukyo_kanou_year + "/" + this.props.rooms.nyuukyo_kanou_month + "/" + this.props.rooms.nyuukyo_kanou_day;
                    }
                } else if(this.props.rooms.nyuukyo_kanou_day == "中旬") {
                    const nyuukyoKanou = new Date(this.props.rooms.nyuukyo_kanou_year, this.props.rooms.nyuukyo_kanou_month-1, 20);
                    // console.log("中旬nyuukyoKanou", nyuukyoKanou);
                    if (today > nyuukyoKanou) {
                        return "即入居";
                    } else {
                        return this.props.rooms.nyuukyo_kanou_year + "/" + this.props.rooms.nyuukyo_kanou_month + "/" + this.props.rooms.nyuukyo_kanou_day;
                    }
                } else if(this.props.rooms.nyuukyo_kanou_day == "下旬") {
                    const nyuukyoKanou = new Date(this.props.rooms.nyuukyo_kanou_year, this.props.rooms.nyuukyo_kanou_month, 0);  //末日取得
                    // console.log("下旬nyuukyoKanou", nyuukyoKanou);
                    if (today > nyuukyoKanou) {
                        return "即入居";
                    } else {
                        return this.props.rooms.nyuukyo_kanou_year + "/" + this.props.rooms.nyuukyo_kanou_month + "/" + this.props.rooms.nyuukyo_kanou_day;
                    }
                }
                return this.props.rooms.nyuukyo_kanou_year + "/" + this.props.rooms.nyuukyo_kanou_month + "/" + this.props.rooms.nyuukyo_kanou_day;
            } else {
                const today = new Date(Date.now());
                const nyuukyoKanou = new Date(this.props.rooms.nyuukyo_kanou_year, this.props.rooms.nyuukyo_kanou_month-1, this.props.rooms.nyuukyo_kanou_day);
                // console.log("今日", today);
                // console.log("nyuukyoKanou", nyuukyoKanou);
                if(today > nyuukyoKanou) {
                    return "即入居";
                } else {
                    return this.props.rooms.nyuukyo_kanou_year + "/" + this.props.rooms.nyuukyo_kanou_month + "/" + this.props.rooms.nyuukyo_kanou_day;
                }
            }
        }else if(this.props.rooms.kanri_kubun_name === "業物"){
            return "即入居";
        }
        else {
            return "相談";
        }
    }

    render() {
        const gaikan_url = "https://img.global-center.co.jp/v2/tatemono/" + this.props.rooms.tatemono_id + "/1/1/520/780/0";
        const madori_img = "https://img.global-center.co.jp/v2/rooms/" + this.props.rooms.tatemono_id + "/" + this.props.rooms.room_id + "/8/1/520/780/0";
        const seiyaku_zumi = this.props.rooms.kuushitsu_joukyou_no === 1 || this.props.search_rooms.rent_introduction_headers.disp_applied === 1 ? "no" : "seiyakuZumiStyle";
        // console.log(this.props.rooms.nyuukyo_kanou_year + "/" + this.props.rooms.nyuukyo_kanou_month + "/" + this.props.rooms.nyuukyo_kanou_day,this.props.rooms.tatemono_name  ,"ココ")
        // console.log(this.props.rooms.kanri_kubun_name,this.props.rooms.tatemono_name  ,"ココ")

        return (<tr className={seiyaku_zumi}>
            <td>
                <span className="maru" style={{ backgroundColor: this.props.rooms.is_bookmark ? "#ff8c00" : "black" }}>{this.props.rooms.numbering}</span>
            </td>
            {/* 画像 */}
            <td>
                <img src={gaikan_url} alt="外観" max-width="80px" height="60px"></img>
            </td>
            {/* 間取り */}
            <td><img src={madori_img} alt="間取り画像" max-width="80px" height="40px"></img><br></br>{this.props.rooms.madori_name}</td>
            {/* 物件名・部屋番号 */}
            <td style={{ maxWidth: 180 }}>
                {this.props.rooms.tatemono_name} {this.props.rooms.room_name}
                <br></br>
                {this.props.rooms.kuushitsu_joukyou_no === 1 || this.props.search_rooms.rent_introduction_headers.disp_applied === 1 ?
                    <button className="btn btn-primary" style={{width:"100%", paddingTop: 2.5, paddingBottom: 2.5, border: 0, height: 25,backgroundColor: "#4ac0f0" }} onClick={() => this.open_window("/Detail/" + this.props.cd + "/" + this.props.no + "/" + this.props.rooms.tatemono_id + "/" + this.props.rooms.room_id)}>
                        <span style={{fontSize: 12, fontWeight: "bold", verticalAlign: "text-top" }}>
                            <i className="fa fa-chevron-right"></i>　詳細を見る
                            </span>
                    </button>
                    :
                    <div style={{ display: "inline-block" }}>
                        <span className="label label-seiyakuZumi" onClick={() => this.open_window("/Detail/" + this.props.cd + "/" + this.props.no + "/" + this.props.rooms.tatemono_id + "/" + this.props.rooms.room_id)}>
                            <i className="fas fa-key"></i> 成約済み
                            </span>
                    </div>
                }

            </td>
            {/* 家賃(カンマ区切りで表示)*/}
            <td>{this.props.rooms.chinryou ? this.props.rooms.chinryou.toLocaleString() : 0}円</td>
            {/* 共益費(カンマ区切りで表示)・0円の場合は『込み』表示 */}
            <td>{this.props.rooms.kyouekihi ? this.props.rooms.kyouekihi.toLocaleString() + "円" : "込み"}</td>
            {/* 駐車場(元データがカンマ区切りで入っているのでそのまま表示)*/}
            <td>
                {this.props.rooms.p_aki === "管理外" ? (this.props.rooms.p_ryou === "" ? " - " : this.props.rooms.p_ryou)
                : this.props.rooms.p_aki === "空き無し" ? "満車" 
                : this.props.rooms.p_aki === "空きあり" ? (this.props.rooms.p_ryou === "" ? " - " : this.props.rooms.p_ryou)
                :this.props.rooms.p_aki=== "無し" ? "無し":""}
            </td>
            {/* 敷金・礼金(カンマ区切りで表示).toLocaleString() */}
            <td>
                {this.props.rooms.shikikin_mon >= 1 ? this.props.rooms.shikikin_mon + "ヶ月 / " : this.props.rooms.shikikin > 0 ? this.props.rooms.shikikin.toLocaleString() + "円/ " : "- / "}
                {this.props.rooms.reikin_mon >= 1 ? this.props.rooms.reikin_mon + "ヶ月" : this.props.rooms.reikin > 0 ? this.props.rooms.reikin.toLocaleString() + "円 " : "-"}
            </td>
            {/* 定額クリーニング */}
            <td>{this.props.rooms.teigaku_cleaning?this.props.rooms.teigaku_cleaning:"-"}</td>     
            {/* 法人契約の場合 */}
            {this.props.houjin===1?<td>{this.props.rooms.houjin_keiyaku?this.props.rooms.houjin_keiyaku:"-"}</td>:""}                 
            {/* 専有面積 */}
            <td>{this.props.rooms.senyuu_menseki}㎡</td>
            {/* 築年 */}
            <td>{this.props.rooms.shunkou_year}年{this.props.rooms.shunkou_month}月</td>
            {/* 入居時期 */}
            <td>{this.dateComparison()}</td>
            {/* <td>{this.props.rooms.nyuukyo_kanou_year ? this.props.rooms.nyuukyo_kanou_year + "/" + this.props.rooms.nyuukyo_kanou_month + "/" + this.props.rooms.nyuukyo_kanou_day : " - "}</td> */}
            {/* 交通 */}
            <td>{this.props.rooms.koutsuu1}<br/>{this.props.rooms.koutsuu1_min}分</td>
            {/* 目的地 */}
            {/* {this.props.search_rooms.rent_introduction_point_headers ? this.props.search_rooms.rent_introduction_point_headers.map((pointheader, index) => {
                if (pointheader.type === 1 && Object.keys(pointheader).length > 0) {
                    if(pointheader.rent_introduction_point_details.length > 0) {
                        if(pointheader.rent_introduction_point_details[0].distance_is_no_disp == 0){
                    return <tr key={index}>
                        <td>
                            {pointheader.contents}まで<br/>
                            <span className='kyori_num'>
                            {Math.ceil(distance(pointheader.rent_introduction_point_details[0].lat, pointheader.rent_introduction_point_details[0].lng, this.props.rooms.ido, this.props.rooms.keido) * 10) / 10}
                            </span>km
                        </td>
                    </tr>
                }}
                } else {
                    return ""
                }
                //全表示
            }, this) : ""} */}

            {this.props.search_rooms.rent_introduction_point_headers ? this.props.search_rooms.rent_introduction_point_headers.map((pointheader, index) => {
                if (pointheader.type === 1 && Object.keys(pointheader).length > 0) {
                    return  <td key={index}>
                        {pointheader.contents}まで<br/>
                        <span className='kyori_num'>
                        {Math.ceil(distance(pointheader.rent_introduction_point_details[0].lat, pointheader.rent_introduction_point_details[0].lng, this.props.rooms.ido, this.props.rooms.keido) * 10) / 10}
                        </span>km
                    </td>
                } else {
                    return ""
                }
                //全表示
            }, this) : ""}
            
            
            {/* <td>お勤めエリアまで<br/><span className='kyori_num'>1.1</span>km</td> */}

        </tr>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        search_rooms: state.Rent.search_rooms,

    }
}
function mapDispatchToProps(dispatch) {
    return {

    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RentBukkenListComponent));
