import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import RentStaffComponent from './RentStaffComponent';
import RentNaviComponent from './RentNaviComponent';
import RentBukkenListComponent from './RentBukkenListComponent';
import RentBukkenBox from './RentBukkenBox';
import RentShopComponent from './RentShopComponent';
import './Css/RentComponent.css';
import RentMapDispComponent from './RentMapDispComponent';
import RentLoadingComponent from './RentLoadingComponent';
import Layout from '../Layout/LayoutComponent';
import * as Rent from './Saga/Rent';
import * as Master from '../../Store/Master';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import CommentIcon from '@material-ui/icons/Comment';
// import TextureIcon from '@material-ui/icons/Texture';
// import { makeStyles } from '@material-ui/core/styles';
// import BottomNavigation from '@material-ui/core/BottomNavigation';
// import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import StoreIcon from '@material-ui/icons/Store';
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Chip from '@material-ui/core/Chip';
import StarIcon from '@material-ui/icons/Star';
import Alert from '@material-ui/lab/Alert';
import FaceIcon from '@material-ui/icons/Face';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


// swiper bundle styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'

import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Autoplay, Mousewheel, Keyboard } from "swiper";
import SwiperCore, { Navigation } from 'swiper';
import { Loop } from '@material-ui/icons';
import { relative } from 'path';



// Navigationモジュールを有効化
SwiperCore.use([Navigation]);


// const useStyles = makeStyles({
//     root: {
//     //   width: 500,
//         textAlign: "right"
//     },
// });


// const BankIcon = () => {
//     return (
//         <SvgIcon
//             className="icon_bank"
//             component="span"
//             viewBox=""
//         >
//         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><g><g><circle style={{fill: "#bf4645"}} cx="12.5" cy="12.5" r="12.5"/><g><rect style={{fill: "none",stroke: "#fff",strokeLinecap: "round",strokeLinejoin: "round",strokeWidth: "1.07px"}} x="2.86" y="7.37" width="19.29" height="9.79"/><g><circle style={{strokeMiterlimit: "10",strokeWidth: ".67px",fill: "none",stroke: "#fff"}} cx="12.5" cy="12.27" r="3.68"/><path style={{fill: "#fff"}} d="m11.09,13.42c-.18,0-.27-.08-.27-.23s.09-.23.27-.23h1v-.33l-.11-.17h-.89c-.18,0-.27-.08-.27-.23s.09-.23.27-.23h.63l-1.04-1.71s-.05-.14-.05-.21c0-.17.18-.33.43-.33.1,0,.24.08.29.17l1.17,2.11,1.17-2.11c.05-.08.16-.16.27-.16.21,0,.41.17.41.34,0,.07-.03.15-.05.19l-1.09,1.72h.68c.18,0,.27.08.27.23s-.09.23-.27.23h-.96l-.1.16v.35h1.06c.18,0,.27.08.27.23s-.09.23-.27.23h-1.06v.95c0,.26-.14.41-.37.41s-.39-.15-.39-.41v-.95h-1Z"/></g></g></g></g></svg>        
//         </SvgIcon>
//     );
// };



class RentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "store",
            parking: false,
            conveni: false,
            store: false,
            bank: false,
            youchien: false,
            school: false,
            ds: false,
            hospital: false,
            sort:"",
            spot: 0,
            list_switch: true,
            refresh: false,
            offset: 0,
            parPage: 10,
            nowPage: 1,
            favorite: 1,    //off:1
            // list_switch: this.props.search_rooms.rentIntroductionHeaders.dispList === 0 ? true : false
        };
        this.handleChange = this.handleChange.bind(this);
        this.spotDisp = this.spotDisp.bind(this);
        this.spotFavorite = this.spotFavorite.bind(this);
        this.onChange = this.onChange.bind(this)
        this.displist = this.displist.bind(this);
        this.handleClickPagination = this.handleClickPagination.bind(this);

        this.props.change_cd({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        this.props.get_search_rooms({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        this.props.get_staff({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        if (this.props.mypage_local_store.length === 0) {
            this.props.get_mypage_local_spots(1)
            this.props.get_mypage_local_spots(2)
        }
        this.props.get_mypage_local_shuhen()
        this.props.get_houjin({ cd: this.props.match.params.cd, no: this.props.match.params.no })
    }

    


    // handleChange = (event, value) => {
    //     this.setState({ value });
    // };

    handleChange(e) {
        this.setState({ list_switch: e.target.checked });
    }

    componentDidMount() {


        window.scrollTo(0, 0)
    }
    spotDisp(value) {
        
        if (value === 1) {//parking
            this.setState({ spot: this.state.parking === true ? 0 : 1 });
            this.setState({ parking: !this.state.parking });

        }else if (value === 2) {//コンビニ
            this.setState({ spot: this.state.conveni === true ? 0 : 2 });
            this.setState({ conveni: !this.state.conveni });
        }else if (value === 3) {//スーパー
            this.setState({ spot: this.state.store === true ? 0 : 3 });
            this.setState({ store: !this.state.store });

        }else if (value === 4) {//銀行
            this.setState({ spot: this.state.bank === true ? 0 : 4 });
            this.setState({ bank: !this.state.bank });
        }else if (value === 5) {//幼稚園
            this.setState({ spot: this.state.youchien === true ? 0 : 5 });
            this.setState({ youchien: !this.state.youchien });
        }else if (value === 6) {//小中学校
            this.setState({ spot: this.state.school === true ? 0 : 6 });
            this.setState({ school: !this.state.school });
        }else if (value === 7) {//ドラッグストア
            this.setState({ spot: this.state.ds === true ? 0 : 7 });
            this.setState({ ds: !this.state.ds });
        }else if (value === 8) {//病院
            this.setState({ spot: this.state.hospital === true ? 0 : 8 });
            this.setState({ hospital: !this.state.hospital });
        }
        // console.log(value)
    }

    
      
    onChange(e) {
        this.setState({ sort: e.target.value});

        //並びかえ
        if( e.target.value === 1){//家賃安い順
            this.props.search_rooms.mypage_rooms.sort(function(a, b) {
                return (a.chinryou < b.chinryou) ? -1 : 1;  //オブジェクトの昇順ソート
            });

        }else if( e.target.value === 2){//家賃高い順
            this.props.search_rooms.mypage_rooms.sort(function(a, b) {
                return (a.chinryou > b.chinryou) ? -1 : 1;  //オブジェクトの降順ソート
            });
        }else if( e.target.value === 3){//築年数順
            this.props.search_rooms.mypage_rooms.sort((a, b) => {
                const dateStringA = `${a.shunkou_year}-${a.shunkou_month.toString().padStart(2, '0')}`;
                const dateStringB = `${b.shunkou_year}-${b.shunkou_month.toString().padStart(2, '0')}`;
                return (dateStringA < dateStringB) ? 1 : -1;
            });
        }
    }

    displist() {
        var plms = document.getElementById("plms");
        plms.classList.toggle("active");

        let search_rooms = this.props.search_rooms

        search_rooms.rent_introduction_headers.disp_list = search_rooms.rent_introduction_headers.disp_list === 0 ? 1 : 0
        this.props.get_search_rooms_success(search_rooms)
        this.setState({ refresh: !this.state.refresh })

    }

    spotFavorite() {
        this.props.change_favorite_list_flg(!this.props.favorite_list_flg);

        this.props.change_favorite_list()
        this.state.favorite === 0 ?
            this.setState({ favorite: 1 })
            :
            this.setState({ favorite: 0 })
    }

    handleClickPagination(e, value) {
        //value: ページ番号
        this.setState({
            nowPage: value,
            offset: (value - 1) * this.state.parPage,
        })
    }
    
    
    
    

    render() {
        // const { value } = this.state;


        const isMobile = window.innerWidth <= 768;

        console.log(this.props.houjin.type,"Rent")
        return (

            
            <Layout>
                {!this.props.search_rooms.rent_introduction_headers ?
                    <div className="main_background" style={{ marginTop: "50px" }}>
                        <RentLoadingComponent />
                    </div>
                    :
                    <div>
                        <div className="rent_layout">
                            <div className="container">
                                <div className="content">
                                    {this.props.search_rooms.rent_introduction_headers.comment || (this.props.staff && this.props.staff.tenpo[0].Tenpo_No !== 26)?
                                    <RentStaffComponent comment={this.props.search_rooms.rent_introduction_headers.comment} staff={this.props.staff}/>
                                    :""}
                                    
                                    <RentNaviComponent />
                                    {/* 上部の空欄pt-3 */}
                                    <div className="pt-3 RentMap" style={{position:'relative'}}>
                                        {this.props.search_rooms.mypage_rooms ? 
                                        <RentMapDispComponent 
                                            parking={this.state.parking}
                                            conveni={this.state.conveni}
                                            store={this.state.store}
                                            bank={this.state.bank}
                                            youchien={this.state.youchien}
                                            school={this.state.school}
                                            ds={this.state.ds}
                                            hospital={this.state.hospital}
                                            shuhen={this.props.shuhen}
                                        /> : ""}
                                        {/* <BottomNavigation
                                            value={this.props.favorite_list_flg}
                                            style={{ display: "block", float: "right", textAlign: "left", paddingTop: "0" }}
                                            showLabels
                                        >
                                            <BottomNavigationAction value={true} onClick={this.spotFavorite} label="お気に入り" icon={<StarIcon />} />
                                        </BottomNavigation> */}
                                        <Button id='FabBtn' value={true} onClick={this.spotFavorite}>
                                            <StarIcon fontSize='large' style={{color:this.state.favorite === 0?"#ff8c00":"#d3d3d3",}}/>
                                            <span style={{fontSize:'14px'}}>お気に入り<br/>のみ表示</span>
                                        </Button>

                                        {window.innerWidth <= 768?
                                            <Swiper
                                                id='MapSwiper'
                                                slidesPerView={3}
                                                spaceBetween={5}
                                                navigation={{ // ナビゲーションオプションを設定
                                                    nextEl: '.swiper-button-next', // 次に進むボタンのクラス名
                                                    prevEl: '.swiper-button-prev', // 前に戻るボタンのクラス名
                                                }}
                                                // modules={[Navigation]}
                                                className="mySwiper"
                                            >
                                                <SwiperSlide>
                                                    <Button id='ParkingiBtn' className='mapbtns'
                                                    value={this.state.parking ? 1 : 0}
                                                    onClick={() => this.spotDisp(1)} >
                                                        <Box>
                                                            {this.state.parking?<img src={`${process.env.PUBLIC_URL}/img/map/parking_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/parking_off.png`} alt=''/>}
                                                        </Box>
                                                    </Button>
                                                </SwiperSlide>

                                                <SwiperSlide>
                                                    <Button id='ConbiniBtn' className='mapbtns'
                                                    value={this.state.conveni ? 2 : 0}
                                                    onClick={() => this.spotDisp(2)} >
                                                        {this.state.conveni?<img src={`${process.env.PUBLIC_URL}/img/map/conbini_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/conbini_off.png`} alt=''/>}
                                                    </Button>
                                                </SwiperSlide>

                                                <SwiperSlide>
                                                    <Button id='SuperBtn' className='mapbtns'
                                                    value={this.state.store ? 3 : 0}
                                                    onClick={() => this.spotDisp(3)} >
                                                        {this.state.store?<img src={`${process.env.PUBLIC_URL}/img/map/super_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/super_off.png`} alt=''/>}
                                                    </Button>
                                                </SwiperSlide>

                                                <SwiperSlide>
                                                    <Button id='BankBtn' className='mapbtns'
                                                    value={this.state.bank ? 4 : 0}
                                                    onClick={() => this.spotDisp(4)}>
                                                        {this.state.bank?<img src={`${process.env.PUBLIC_URL}/img/map/bank_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/bank_off.png`} alt=''/>}
                                                    </Button>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <Button id='YouchienBtn' className='mapbtns'
                                                    value={this.state.youchien ? 5 : 0}
                                                    onClick={() => this.spotDisp(5)} >
                                                        {this.state.youchien?<img src={`${process.env.PUBLIC_URL}/img/map/youchien_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/youchien_off.png`} alt=''/>}
                                                    </Button>
                                                </SwiperSlide>

                                                <SwiperSlide>
                                                    <Button id='SchoolBtn' className='mapbtns'
                                                    value={this.state.school ? 6 : 0}
                                                    onClick={() => this.spotDisp(6)} >
                                                        {this.state.school?<img src={`${process.env.PUBLIC_URL}/img/map/school_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/school_off.png`} alt=''/>}
                                                    </Button>
                                                </SwiperSlide>

                                                <SwiperSlide>
                                                    <Button id='DSBtn' className='mapbtns'
                                                    value={this.state.ds ? 7 : 0}
                                                    onClick={() => this.spotDisp(7)} >
                                                        {this.state.ds?<img src={`${process.env.PUBLIC_URL}/img/map/ds_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/ds_off.png`} alt=''/>}
                                                    </Button>
                                                </SwiperSlide>

                                                <SwiperSlide>
                                                    <Button id='HospitalBtn' className='mapbtns'
                                                    value={this.state.hospital ? 8 : 0}
                                                    onClick={() => this.spotDisp(8)} >
                                                        {this.state.hospital?<img src={`${process.env.PUBLIC_URL}/img/map/hospital_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/hospital_off.png`} alt=''/>}
                                                    </Button>
                                                </SwiperSlide>
                                                <div className="swiper-button-next"><img src={`${process.env.PUBLIC_URL}/img/icon/icon_next.png`} alt=''/></div> 
                                                <div className="swiper-button-prev"><img src={`${process.env.PUBLIC_URL}/img/icon/icon_prev.png`} alt=''/></div>
                                            </Swiper>
                                            :
                                            <div id='MapBtns'>
                                                <Button id='ParkingiBtn' className='mapbtns'
                                                value={this.state.parking ? 1 : 0}
                                                onClick={() => this.spotDisp(1)} >
                                                    <Box>
                                                        {this.state.parking?<img src={`${process.env.PUBLIC_URL}/img/map/parking_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/parking_off.png`} alt=''/>}
                                                    </Box>
                                                </Button>

                                                <Button id='ConbiniBtn' className='mapbtns'
                                                value={this.state.conveni ? 2 : 0}
                                                onClick={() => this.spotDisp(2)} >
                                                    {this.state.conveni?<img src={`${process.env.PUBLIC_URL}/img/map/conbini_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/conbini_off.png`} alt=''/>}
                                                </Button>

                                                <Button id='SuperBtn' className='mapbtns'
                                                value={this.state.store ? 3 : 0}
                                                onClick={() => this.spotDisp(3)} >
                                                    {this.state.store?<img src={`${process.env.PUBLIC_URL}/img/map/super_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/super_off.png`} alt=''/>}
                                                </Button>

                                                <Button id='BankBtn' className='mapbtns'
                                                value={this.state.bank ? 4 : 0}
                                                onClick={() => this.spotDisp(4)}>
                                                    {this.state.bank?<img src={`${process.env.PUBLIC_URL}/img/map/bank_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/bank_off.png`} alt=''/>}
                                                </Button>

                                                <Button id='YouchienBtn' className='mapbtns'
                                                value={this.state.youchien ? 5 : 0}
                                                onClick={() => this.spotDisp(5)} >
                                                    {this.state.youchien?<img src={`${process.env.PUBLIC_URL}/img/map/youchien_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/youchien_off.png`} alt=''/>}
                                                </Button>

                                                <Button id='SchoolBtn' className='mapbtns'
                                                value={this.state.school ? 6 : 0}
                                                onClick={() => this.spotDisp(6)} >
                                                    {this.state.school?<img src={`${process.env.PUBLIC_URL}/img/map/school_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/school_off.png`} alt=''/>}
                                                </Button>

                                                <Button id='DSBtn' className='mapbtns'
                                                value={this.state.ds ? 7 : 0}
                                                onClick={() => this.spotDisp(7)} >
                                                    {this.state.ds?<img src={`${process.env.PUBLIC_URL}/img/map/ds_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/ds_off.png`} alt=''/>}
                                                </Button>

                                                <Button id='HospitalBtn' className='mapbtns'
                                                value={this.state.hospital ? 8 : 0}
                                                onClick={() => this.spotDisp(8)} >
                                                    {this.state.hospital?<img src={`${process.env.PUBLIC_URL}/img/map/hospital_on.png`} alt=''/>:<img src={`${process.env.PUBLIC_URL}/img/map/hospital_off.png`} alt=''/>}
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                    
                                    <div className="row">
                                        
                                        <p className='shuhen'>※周辺環境は最新でない場合があります。</p>

                                        {/* <div style={{ width: "100%", height: "55px", marginLeft: "10px", marginRight: "10px", background: "#FFF" }}>
                                            <BottomNavigation
                                                value={this.props.search_rooms.rent_introduction_headers ? this.props.search_rooms.rent_introduction_headers.disp_list : 1}
                                                onChange={this.displist}
                                                style={{ display: "block", float: "right", textAlign: "left", paddingTop: "0" }}
                                                showLabels
                                            >
                                                <BottomNavigationAction value={0} label="リスト表示" icon={<ListAltIcon />} />
                                            </BottomNavigation>

                                            <BottomNavigation
                                                // value={this.state.spot}
                                                value={this.state.conveni ? 2 : 0}
                                                onChange={this.spotDisp}
                                                style={{ display: "block", float: "right", textAlign: "right", paddingTop: "0" }}
                                                showLabels
                                            >
                                                <BottomNavigationAction value={2} onClick={this.spotDisp} label="コンビニ" icon={<LocalConvenienceStoreIcon  />} />
                                            </BottomNavigation>

                                            <BottomNavigation
                                                // value={this.state.spot}
                                                value={this.state.store ? 1 : 0}
                                                onChange={this.spotDisp}
                                                style={{ display: "block", float: "right", textAlign: "right", paddingTop: "0" }}
                                                showLabels
                                            >
                                                <BottomNavigationAction value={1} onClick={this.spotDisp} label="スーパー" icon={<StoreIcon  />} />
                                            </BottomNavigation>

                                            <BottomNavigation
                                                value={this.props.favorite_list_flg}
                                                style={{ display: "block", float: "right", textAlign: "left", paddingTop: "0" }}
                                                showLabels
                                            >
                                                <BottomNavigationAction value={true} onClick={this.spotFavorite} label="お気に入り" icon={<StarIcon />} />
                                            </BottomNavigation>
                                        </div> */}
                                        
                                        <FormControl id='SortBox'>
                                            <span style={{fontFamily:"Noto Sans JP,sans-serif"}}>表示の並び替え　</span>
                                            <Select
                                                name="Sort"
                                                id="Sort"
                                                value={this.state.sort}
                                                onChange={this.onChange}
                                                label="表示の並び替え"
                                            >
                                                <MenuItem value={1}>{"家賃安い"}</MenuItem>
                                                <MenuItem value={2}>{"家賃高い"}</MenuItem>
                                                <MenuItem value={3}>{"築年数"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        

                                        {/* <BottomNavigation
                                            value={this.props.search_rooms.rent_introduction_headers ? this.props.search_rooms.rent_introduction_headers.disp_list : 1}
                                            onChange={this.displist}
                                            style={{ display: "block", textAlign: "left", paddingTop: "0" }}
                                            showLabels
                                        >
                                            <BottomNavigationAction value={0} label="リスト表示" icon={<ListAltIcon />} />
                                        </BottomNavigation> */}

                                        {window.innerWidth >= 768?
                                        <Button id='DispList'                                           
                                           value={this.props.search_rooms.rent_introduction_headers ? this.props.search_rooms.rent_introduction_headers.disp_list : 1}
                                           onClick={() => this.displist()} startIcon={<ListAltIcon size="large"/>}>
                                           <span className='text'>リストで表示する</span>
                                           <span id='plms' className='active'></span>
                                       </Button>
                                        :
                                        ""
                                        }

                                        {this.props.search_rooms.rent_introduction_headers && window.innerWidth >= 768 ? this.props.search_rooms.rent_introduction_headers.disp_list === 0 ? <div className="table_style" style={{ marginLeft: "10px", marginRight: "10px", }}>
                                            <table style={{ backgroundColor: "#FFF", fontSize: "14px",fontFamily:"Noto Sans JP"}}>
                                                <thead>
                                                    <tr className="shadow-sm">
                                                        <th>No.</th>
                                                        <th></th>
                                                        <th>間取り</th>
                                                        <th>物件名</th>
                                                        <th>家賃</th>
                                                        <th>共益費</th>
                                                        <th>駐車場</th>
                                                        <th>敷金/礼金</th>
                                                        <th>定額クリーニング</th>
                                                        {this.props.houjin.type === 1?<th>法人契約の場合</th>:""}
                                                        <th>専有面積</th>
                                                        <th>築年</th>
                                                        <th>入居時期</th>
                                                        <th>最寄り駅</th>
                                                        {this.props.search_rooms.rent_introduction_point_headers.length > 0 ?
                                                        this.props.search_rooms.rent_introduction_point_headers.map((point, index) => point.type === 1 ?
                                                        <th>目的地</th> : "")
                                                        :""}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.search_rooms.mypage_rooms ? this.props.search_rooms.mypage_rooms.slice(this.state.offset, this.state.offset + this.state.parPage).map((rooms, index) => rooms ?
                                                        <RentBukkenListComponent key={index} rooms={rooms} 
                                                        cd={this.props.match.params.cd} 
                                                        no={this.props.match.params.no} 
                                                        houjin={this.props.houjin.type}
                                                        dispApplied={this.props.search_rooms.rent_introduction_headers ? 
                                                        this.props.search_rooms.rent_introduction_headers.disp_applied : 1} />
                                                    : "") : ""}
                                                </tbody>
                                            </table>
                                        </div> : "" : ""}

                                        <div className="frame_style" style={{ width: "100%", margin: "10px", padding: "5px" }}>
                                            <div style={{ float: "left" }}>
                                                <Pagination
                                                    count={this.props.search_rooms.mypage_rooms.length % this.state.parPage === 0 ?
                                                        this.props.search_rooms.mypage_rooms.length / this.state.parPage
                                                        :
                                                        Math.ceil(this.props.search_rooms.mypage_rooms.length / this.state.parPage)
                                                    }
                                                    shape="rounded"
                                                    limit={this.state.parPage}
                                                    offset={this.state.offset}
                                                    variant="outlined"
                                                    total={this.props.search_rooms.mypage_rooms.length}
                                                    onChange={this.handleClickPagination}
                                                    page={this.state.nowPage}
                                                />
                                            </div>
                                            <div style={{ float: "right", marginTop: "5px" }}>
                                                <Chip label={"物件数：" + this.props.search_rooms.mypage_rooms.length} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="staff_message">
                            <strong>
                                担当者からのコメント
                            </strong>
                            <p>
                                現在の条件では該当物件が無かった為、本日は条件を緩和してお送りさせていただいております。少し条件を変えることでご提案出来る物件が増える可能性がございますので、その際はご連絡いただければ幸いです。
                            </p>
                        </div> */}
                                        {/* {this.props.search_rooms.mypageRooms?this.props.search_rooms.mypageRooms.map((rooms,index)=>rooms ? */}
                                        {this.props.search_rooms.mypage_rooms ? this.props.search_rooms.mypage_rooms.slice(this.state.offset, this.state.offset + this.state.parPage).map((rooms, index) => rooms ?
                                            <div key={index} className="col-lg-6" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                <RentBukkenBox rooms={rooms} houjin={this.props.houjin.type} dispApplied={this.props.search_rooms.rent_introduction_headers.disp_applied} />
                                            </div> : "") : ""
                                        }
                                    </div>

                                </div>
                                <div className="frame_style" style={{ width: "100%", marginTop: "10px", padding: "5px 0" }}>
                                    <Pagination
                                        count={this.props.search_rooms.mypage_rooms.length % this.state.parPage === 0 ?
                                            this.props.search_rooms.mypage_rooms.length / this.state.parPage
                                            :
                                            Math.ceil(this.props.search_rooms.mypage_rooms.length / this.state.parPage)
                                        }
                                        shape="rounded"
                                        variant="outlined"
                                        limit={this.state.parPage}
                                        offset={this.state.offset}
                                        total={this.props.search_rooms.mypage_rooms.length}
                                        onChange={this.handleClickPagination}
                                        page={this.state.nowPage}
                                    />
                                </div>
                                
                                {this.props.staff.tenpo && this.props.staff.tenpo[0].Tenpo_No !== 26 ?<RentShopComponent tenpo={this.props.staff.tenpo}/>:""}
                                
                            </div>
                        </div>
                    </div>}
            </Layout>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        shuhen: state.Rent.shuhen,
        search_rooms: state.Rent.search_rooms,
        mypage_local_store: state.Rent.mypage_local_store,
        favorite_list_flg: state.Rent.favorite_list_flg,
        staff:state.Rent.staff,
        houjin: state.Rent.houjin,

        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_mypage_local_shuhen(state) {
            dispatch(Rent.get_mypage_local_shuhen(state))
        },
        get_houjin(state) {
            dispatch(Rent.get_houjin(state))
        },
        get_staff(state){
            dispatch(Rent.get_staff(state))
        },
        get_search_rooms(state) {
            dispatch(Rent.get_search_rooms(state))
        },
        change_cd(state) {
            dispatch(Master.change_cd(state))
        },
        get_mypage_local_spots(state) {
            dispatch(Rent.get_mypage_local_spots(state))
        },
        get_search_rooms_success(state) {
            dispatch(Rent.get_search_rooms_success(state))
        },
        change_favorite_list(state) {
            dispatch(Rent.change_favorite_list(state))
        },
        change_favorite_list_flg(state) {
            dispatch(Rent.change_favorite_list_flg(state))
        },

        
        
        //        customeredit1change(state) {
        //            dispatch(customeredit1change(state))
        //        },
        //customeredit2change(state) {
        //        dispatch(customeredit2change(state))
        //    },
        //        responseeditchange(state) {
        //            dispatch(responseeditchange(state))
        //        },
        //        customerdetailData1(state) {
        //            dispatch(customerdetailData1(state))
        //        }, responsedetailData(state) {
        //            dispatch(responsedetailData(state))
        //        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RentComponent);
