const authentication = require('react-azure-adb2c').default;








export const list = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/file/headers/download/` + values;

    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/file/headers/download/` + values;




    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



// export const update = (values: any) => {


//     const url = process.env.REACT_APP_API_OPENROOM_URL + `/api/MypageRequest/` + values.responseCode;


//     // alert(url)

//     const token = authentication.getAccessToken();

//     return fetch(url, {
//         method: 'put',
//         body: JSON.stringify(values),

//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//     }).then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// };