import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as MypageDetailApi from '../Api/MypageDetailApi';
import * as rent_introduction_details_api from '../Api/rent_introduction_details_api';
import * as DaichoDownloadApi from '../Api/DaichoDownloadApi';
import moment from 'moment';

export interface MapSearchState {
    staff: any;
    search_rooms_detail: any;
    download_flg: boolean;
    bukken_reserve_loading: boolean,
    bukken_submit: boolean,
}

const initialState: MapSearchState = {
    staff: [],
    search_rooms_detail: [],
    download_flg: false,
    bukken_reserve_loading: false,
    bukken_submit: false,
}



///action 
const actionCreator = actionCreatorFactory();


export const get_detail_shuhen = actionCreator("GET_DETAIL_SHUHEN");
export const change_detail_shuhen = actionCreator("CHANGE_DETAIL_SHUHEN");


export const bukken_reserve = actionCreator("BUKKEN_RSERVE");
export const change_bukken_reserve = actionCreator("CHANGE_RSERVE");
export const change_bukken_reserve_loading = actionCreator<boolean>('CHANGE_BUKKEN_RESERVE_LOADING');
export const change_bukken_submit = actionCreator<boolean>('CHANGE_BUKKEN_SUBMIT');


export const get_staff = actionCreator('GET_STAFF');
export const change_staff = actionCreator('CHANGE_STAFF');

export const get_search_rooms_detail = actionCreator('GET_SEARCH_ROOMS_DETAIL');
export const get_search_rooms_detail_success = actionCreator<any>("GET_SEARCH_ROOMS_DETAIL_SUCCESS");
// export const get_setsubi_master_failure = actionCreator("GET_SETSUBI_MASTER_FAILURE");
export const change_favorite_detail = actionCreator<any>("CHANGE_FAVORITE_DETAIL");
export const daicho_download_docs = actionCreator<any>("DAICHO_DOWNLOAD_DOCS");
export const change_download_flg = actionCreator<boolean>("CHANGE_DOWNLOAD_FLG");

export const detailReducer = reducerWithInitialState(initialState)
    .case(change_detail_shuhen, (state, payload) => ({ ...state, shuhen: payload }))

    .case(get_search_rooms_detail_success, (state, payload) => ({ ...state, search_rooms_detail: payload }))
    .case(change_download_flg, (state, payload) => ({ ...state, download_flg: payload }))
    .case(change_staff, (state, payload) => ({ ...state, staff: payload }))


    .case(bukken_reserve, (state, payload) => ({ ...state, bukken_reserve: payload }))
    .case(change_bukken_reserve_loading, (state, payload) => ({ ...state, bukken_reserve_loading: payload }))
    .case(change_bukken_submit, (state, payload) => ({ ...state, bukken_submit: payload }))

// .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))

// .case(get_response_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

// .case(change_showRight, (state, payload) => ({ ...state, showRight: payload }))
// .case(change_showFlag, (state, payload) => ({ ...state, showFlag: payload }))
// .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))

// .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }));








// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const detailSaga = [takeEvery('GET_SEARCH_ROOMS_DETAIL', handle_get_search_rooms_detail),
takeEvery('GET_DETAIL_SHUHEN', handle_get_detail_shuhen),
takeEvery('CHANGE_FAVORITE_DETAIL', handle_change_favorite_detail),
takeEvery('DAICHO_DOWNLOAD_DOCS', handle_daicho_download_docs),
takeEvery('GET_STAFF', handle_get_staff),

takeEvery('BUKKEN_RSERVE', handle_bukken_reserve),

    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;


function* handle_get_detail_shuhen(action: any) {

    const { payload, error } = yield call(MypageDetailApi.Shuhen, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        // console.log(payload,"paylood")
        yield put(change_detail_shuhen(payload));


    } else {
        console.log(error, "失敗")
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}




function* handle_bukken_reserve(action: any): any {

    // const state = yield select();
    // console.log(state.Detail.reserve,"reserve")
    yield put(change_bukken_submit(false));
    yield put(change_bukken_reserve_loading(true));
    const { payload, error } = yield call(MypageDetailApi.send, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log(payload)
        yield put(change_bukken_submit(true));
        yield put(change_bukken_reserve_loading(false));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_get_search_rooms_detail(action: any): any {
    yield put(get_search_rooms_detail_success({}));
    const { payload, error } = yield call(MypageDetailApi.detail, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し

        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_search_rooms_detail_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_change_favorite_detail(action: any): any {
    const state = yield select();


    const { payload, error } = yield call(rent_introduction_details_api.updatebookmark, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert("dd")
        // alert(JSON.stringify(payload))
        // console.log(payload)
        let search_rooms_detail = state.Detail.search_rooms_detail
        yield put(get_search_rooms_detail_success([]));
        // alert()
        search_rooms_detail.is_bookmark = payload.is_bookmark

        yield put(get_search_rooms_detail_success(search_rooms_detail));


        // yield put(get_search_rooms_success(search_rooms));


    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_daicho_download_docs(action: any): any {

    // yield put(change_line_files_loading(true));

    const state = yield select();
    // console.log(state)
    yield put(change_download_flg(true));

    // alert(action.payload.extension)

    const { payload, error } = yield call(DaichoDownloadApi.download, action.payload);

    // サーバーからのレスポンスデータによる分岐処理

    if (payload && !error) {

        const a = document.createElement('a');

        // ダウンロードされるファイル名

        a.download = "daicho.pdf";

        a.href = URL.createObjectURL(payload);

        // ダウンロード開始

        a.click();

        yield put(change_download_flg(false));
        //   console.log(state)

    } else {

        //    // 失敗時アクション呼び出し

        //    yield put(loginFailure(payload));

    }

}

function* handle_get_staff(action: any): any {
    const { payload, error } = yield call(rent_introduction_details_api.staff, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し
        // yield put(change_all_list(payload));


        // const state = yield select();

        yield put(change_staff(payload));

        //yield put(get_setsubi_master_success(setsubis));



    } else {
        //    // 失敗時アクション呼び出し
        console.log("失敗")
        //    yield put(loginFailure(payload));
    }
}

