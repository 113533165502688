import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Master from '../../Store/Master';
import * as Transfer from './Saga/Transfer';
import Layout from '../Layout/LayoutComponent';
import './Css/Transfer.css';
import visa from './Img/visa.jpg';
import jcb from './Img/jcb.jpg';
import master from './Img/mastercard.png';
import american from './Img/american.jpg';
import diners from './Img/dinersclub.jpg';
import RentHeaderComponent from '../Layout/RentHeaderComponent';

var moment = require("moment");
function convertHalfWidthToFullWidth(str) {
    str = str.replace(/[!-\/:-@\[-`\{-~]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
    });
    str = str.replace(/ /g,"　");
    // 半角文字の時の処理を記述
    return str.replace(/[A-Za-z0-9]/g, function(match) {
        return String.fromCharCode(match.charCodeAt(0) + 0xFEE0);
    });
}
function hiraganaToKatakana(str) {
    return str.replace(/[\u3041-\u3096]/g, function(match) {
      var char = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(char);
    });
  }
function addZeroAndFormat(number) {
    var numStr = String(number)
    var zerosToAdd = 12 - numStr.length
    var formattedStr = '0'.repeat(zerosToAdd) + numStr
    return formattedStr;
}
function convertFullWidthKatakanaToHalfWidth(str) {
    var kanaMap = {
        "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ",
        "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
        "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
        "バ": "ﾊﾞ", "ビ": "ﾋﾞ", "ブ": "ﾌﾞ", "ベ": "ﾍﾞ", "ボ": "ﾎﾞ",
        "パ": "ﾊﾟ", "ピ": "ﾋﾟ", "プ": "ﾌﾟ", "ペ": "ﾍﾟ", "ポ": "ﾎﾟ",
        "ヴ": "ｳﾞ", "ヷ": "ﾜﾞ", "ヺ": "ｦﾞ",
        "ア": "ｱ", "イ": "ｲ", "ウ": "ｳ", "エ": "ｴ", "オ": "ｵ",
        "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ",
        "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
        "タ": "ﾀ", "チ": "ﾁ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ",
        "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
        "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ",
        "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
        "ヤ": "ﾔ", "ユ": "ﾕ", "ヨ": "ﾖ",
        "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
        "ワ": "ﾜ", "ヲ": "ｦ", "ン": "ﾝ",
        "ァ": "ｧ", "ィ": "ｨ", "ゥ": "ｩ", "ェ": "ｪ", "ォ": "ｫ",
        "ッ": "ｯ", "ャ": "ｬ", "ュ": "ｭ", "ョ": "ｮ",
        "。": "｡", "、": "､", "ー": "ｰ", "「": "｢", "」": "｣", "・": "･"
    }
    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str
            .replace(reg, function (match) {
                return kanaMap[match];
            })
            .replace(/゛/g, 'ﾞ')
            .replace(/゜/g, 'ﾟ');
}

function removeSpaces(str) {
    str = str.replace("　","");
    return str.replace(/[ 　]/g, ' '); // 半角スペースと全角スペースを正規表現で検索し、空文字に置き換える
}
function zenremoveSpaces(str) {
    str = str.replace("　","");
    return str.replace(/[ 　]/g, '　'); // 半角スペースと全角スペースを正規表現で検索し、空文字に置き換える
}
function convertZenkakuAlphabet(str) {
    return str.replace(/[A-Za-z0-9]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
    });
}
class TransferConfirmComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // comp: false
        };
        // this.props.change_line_comp(false)
        // this.props.change_cd({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        // this.props.get_line_code(this.props.match.params.cd)
        this.handlesubmit = this.handlesubmit.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    
    handlesubmit(state) {
    
        // const url = 'http://localhost:5000/v1/accounts/getId'
        const url = 'https://living-api.global-center.co.jp/v1/accounts/getId'
        
        fetch(url, {
            method: 'post',
            body:JSON.stringify({rent_contract_id:this.props.transfer_detail.id,site:2}),
            headers: { 'Content-type': 'application/json; charset=UTF-8' },
            }).then(res => res.json())
            .then(payload => {
                console.log(payload)
                console.log(payload.id)
                var id = addZeroAndFormat(payload.id)
                var ele= document.getElementsByName('shop_res_link')
                ele[0].value = 'https://living-api.global-center.co.jp/v1/accounts/success/'+payload.id+'/2'
                var ele1 = document.getElementsByName('bill_no')
                console.log(ele[0].value)
                ele1[0].value = id 
                
                var formId = document.forms['transferForm'].elements['bill_no'].value
                if(id.length >= 12 && formId.length >= 12){
                    var sendForm = document.getElementById('sendForm');
                    sendForm.click();
                }
            })
            .catch(error => ({ error }));
    }

    render() {
        return (
            <div className="app" style={{ height: "100%" }}>
                <RentHeaderComponent />
                <div style={{ minHeight: "100%", backgroundColor: "whitesmoke" }}>
                    <div style={{ backgroundColor: "#f5f5f5" }}>
                        <div className="display_style">
                            <div className="container">
                            {this.props.transfer_detail.contract_form == 1? 
                                <div className="frame">
                                    <div class="confirm_h1">契約内容確認</div>
                                    <table>
                                        <colgroup width="20%" />
                                        <colgroup width="30%" />
                                        <colgroup width="20%" />
                                        <colgroup width="30%" />
                                        <tbody>
                                            <tr>
                                                <th>物件名</th>
                                                <td colSpan="3">
                                                    <div>{this.props.transfer_detail.building_name}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>部屋名</th>
                                                <td colSpan="3">
                                                    <div class="fn-middle">{this.props.transfer_detail.room_name}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>契約日</th>
                                                <td colSpan="3">
                                                    <span>{moment(this.props.transfer_detail.contract_date).format('YYYY年MM月DD日')}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{ marginTop: 5 }}>
                                        <colgroup width="40%" />
                                        <colgroup width="60%" />
                                        <tbody>
                                            <tr>
                                                <th colSpan="2" style={{ textAlign: "center" }}>条件</th>
                                            </tr>
                                            {this.props.transfer_detail.monies ? this.props.transfer_detail.monies.map(function (value) {

                                                return (
                                                    value.billing == 1 ? 
                                                    <tr>
                                                        <th>{value.name}</th>
                                                        <td><span>{value.amount > 0 ? (value.amount + value.tax_amount).toLocaleString() + "円" : value.rent_m_etc_id > 0 ? value.etc_name : "-"}</span></td>
                                                    </tr>
                                                    :"")
                                            }) : ""}


                                        </tbody>
                                    </table>
                                    <div style={{ fontSize: "15px", marginTop: "5px" }}>下記の「口座登録へ進む」ボタンから口座情報の入力をお願いします。</div>
                                </div>
                            :
                            
                            <div className="frame">
                                <div class="confirm_h1">契約内容確認</div>
                                <table>
                                    <colgroup width="20%" />
                                    <colgroup width="30%" />
                                    <colgroup width="20%" />
                                    <colgroup width="30%" />
                                    <tbody>
                                        <tr>
                                            <th>物件名</th>
                                            <td colSpan="3">
                                                <div>{this.props.transfer_detail.building_name}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>部屋名</th>
                                            <td colSpan="3">
                                                <div class="fn-middle">{this.props.transfer_detail.room_name}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>契約日</th>
                                            <td colSpan="3">
                                                <span>{moment(this.props.transfer_detail.contract_date).format('YYYY年MM月DD日')}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ marginTop: 5 }}>
                                    <colgroup width="40%" />
                                    <colgroup width="60%" />
                                    <tbody>
                                        <tr>
                                            <th colSpan="2" style={{ textAlign: "center" }}>条件</th>
                                        </tr>
                                        {this.props.transfer_detail.monies ? this.props.transfer_detail.monies.map(function (value) {
                                            return <tr>
                                                <th>{value.name}</th>
                                                <td><span>{value.amount > 0 ? (value.amount + value.tax_amount).toLocaleString() + "円" : value.rent_m_etc_id > 0 ? value.etc_name : "-"}</span></td>
                                            </tr>
                                        }) : ""}


                                    </tbody>
                                </table>
                                <div style={{ fontSize: "15px", marginTop: "5px" }}>下記の「口座登録へ進む」ボタンから口座情報の入力をお願いします。</div>
                            </div>
                            }
                            </div>
                             
                            <div className="container" style={{ marginTop: 80, marginBottom: 50, textAlign: "center" }}>
                                <form method="post" action="https://www.paymentstation.jp/customer/sf/at/kokkzmoshikomi/begin.do" accept-charset="Shift_JIS" name="transferForm">
                                    <input type="hidden" name="version" value="130"/>
                                    <input type="hidden" name="shop_cd" value="3315086"/>
                                    <input type="hidden" name="syuno_co_cd" value="20635"/>
                                    <input type="hidden" name="shop_pwd" value="VxNYEC7hb6"/>
                                    <input type="hidden" name="koushin_kbn" value="1"/>
                                    <input type="hidden" name="bill_no" value={addZeroAndFormat(this.props.transfer_detail.id)}/>
                                    <input type="hidden" name="bill_name" value={zenremoveSpaces(convertZenkakuAlphabet(hiraganaToKatakana(this.props.transfer_detail.contract_form == 0 ? this.props.transfer_detail.name1 : this.props.transfer_detail.name2)))}/>
                                    <input type="hidden" name="bill_kana" value={removeSpaces(convertFullWidthKatakanaToHalfWidth(convertZenkakuAlphabet(hiraganaToKatakana(this.props.transfer_detail.contract_form == 0 ? this.props.transfer_detail.kana1 : this.props.transfer_detail.kana2))))}/>
                                    <input type="hidden" name="bill_method" value="01"/>
                                    <input type="hidden" name="kessai_id" value="0101"/>
                                    <input type="hidden" name="shop_msg_1" value={convertHalfWidthToFullWidth(this.props.transfer_detail.building_name) + convertHalfWidthToFullWidth(this.props.transfer_detail.room_name) + 'の賃料の引落口座をご入力ください。'}/>
                                    <input type="hidden" name="shop_link" value='https://rooms.global-center.co.jp/transfer/complete'/>
                                    <input type="hidden" name="shop_res_link" value={'https://living-api.global-center.co.jp/v1/accounts/success/'+this.props.transfer_detail.id + '/2' }/>
                                    <input type="hidden" name="shop_error_link" value='https://rooms.global-center.co.jp/transfer/cancel'/>
                                    <input type="hidden" name="shop_name" value='株式会社グローバルセンター'/>
                                    <input type="hidden" name="shop_phon_hyoji_kbn" value='0'/>
                                    <input type="hidden" name="shop_mail_hyoji_kbn" value='0'/>
                                    <button type="submit" className="btn_style" id="sendForm" style={{display:'none'}}><span style={{ verticalAlign: "super" }}>口座登録へ進む</span></button>
                                </form>
                                {/* <button className="btn_style" onClick={this.lineopen}><span style={{ verticalAlign: "super" }}>カード決済へ進む</span></button> */}
                            </div>
                            <div className="container" style={{ marginTop: 80, marginBottom: 50, textAlign: "center" }}>
                                <button onClick={this.handlesubmit} className="btn_style"><span style={{ verticalAlign: "super" }}>口座登録へ進む</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,

        transfer_detail: state.Transfer.transfer_detail,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_cd(state) {
        //     dispatch(Master.change_cd(state))
        // },
        // // get_mypage_request(state) {
        // //     dispatch(Line.get_mypage_request(state))
        // // },
        // change_line_comp(state) {
        //     dispatch(Line.change_line_comp(state))
        // },
        // get_line_code(state) {
        //     dispatch(Line.get_line_code(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferConfirmComponent);
