/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import * as Line from './Saga/Line';
import "react-datepicker/dist/react-datepicker.css";
import { reduxForm } from 'redux-form';
import './Css/Line.css';
import qrCode from './Img/qr-image.png';
import qr1 from './Img/qr1.png';
import qr2 from './Img/qr2.png';
import qr3 from './Img/qr3.png';
import qr4 from './Img/qr4.png';
import qr6 from './Img/qr6.png';
import qr17 from './Img/qr17.png';
import qr47 from './Img/qr47.png';
import qr10 from './Img/qr10.png';
import addFriend from './Img/image4.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


var moment = require("moment");

// export class RenderField extends React.Component {
//     render() {
//         const { className, type, style, input, disabled, meta: { touched, error } } = this.props;
//         return (
//             <div>
//                 {type === 'textarea' ?
//                     <textarea className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
//                     : type === 'select' ?
//                         <select className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
//                         :
//                         <input className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
//                 }
//                 {touched && error && <div className="text-danger">{error}</div>}
//             </div>
//         );
//     }
// };


class LineFormComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
        this.lineopen = this.lineopen.bind(this)
    }

    lineopen() {
        // alert(this.props.line_section_id)
        if (this.props.line_section_id === 1) {
            window.open('https://line.me/R/ti/p/@772czlir')
        } else if (this.props.line_section_id === 2) {
            window.open('https://line.me/R/ti/p/@221axikr')
        } else if (this.props.line_section_id === 3) {
            window.open('https://line.me/R/ti/p/@723cihaw')
        } else if (this.props.line_section_id === 4) {
            window.open('https://line.me/R/ti/p/@383gtpzg')
        } else if (this.props.line_section_id === 6) {
            window.open('https://line.me/R/ti/p/@133rokhc')
        } else if (this.props.line_section_id === 17) {
            window.open('https://line.me/R/ti/p/@653lvwms')
        } else if (this.props.line_section_id === 47) {
            window.open('https://line.me/R/ti/p/@707zmmom')
        } else if (this.props.line_section_id === 10) {
            window.open('https://line.me/R/ti/p/@014lkgye')
        } else {
            window.open('https://line.me/R/ti/p/%40bmz6622z')
        }
    }

    render() {
        return (

            <div className="display_style">
                <div className="container">
                    <div className="frame">
                        <div className="row mt-3" style={{ margin: "auto" }}>
                            {this.props.line_code === "comp" ?
                                <div className="col-xs-12 text_center">LINE登録済みです。<br></br>下記リンクよりLINEトークを開いてお問い合わせください。</div>
                                :
                                <div className="col-xs-12 text_center">LINEでお部屋探しを始めよう</div>
                            }
                        </div>
                        <div className="row pt-2">
                            <div className="col-xs-12 text_center">
                                {this.props.line_code === "comp" ?
                                    <div className="bottom_style"><button className="btn_style1 mt-0" onClick={this.lineopen}><i class="fab fa-line fa-2x"></i><span style={{ verticalAlign: "super", fontSize: "13px", letterSpacing: 0 }}>グローバルセンター<br></br>LINE公式アカウントを開く</span></button></div>
                                    :
                                    <div className="bottom_style"><button className="btn_style1" onClick={this.lineopen}><i class="fab fa-line fa-2x"></i><span style={{ verticalAlign: "super" }}>友達追加</span></button></div>
                                }
                                <div className="bottom_style qrCode_none">
                                    {this.props.line_section_id === 1 ? <img alt="QRコード" src={qr1} width="60px" />
                                        : this.props.line_section_id === 2 ? <img alt="QRコード" src={qr2} width="60px" />
                                            : this.props.line_section_id === 3 ? <img alt="QRコード" src={qr3} width="60px" />
                                                : this.props.line_section_id === 4 ? <img alt="QRコード" src={qr4} width="60px" />
                                                    : this.props.line_section_id === 6 ? <img alt="QRコード" src={qr6} width="60px" />
                                                        : this.props.line_section_id === 17 ? <img alt="QRコード" src={qr17} width="60px" />
                                                            : this.props.line_section_id === 47 ? <img alt="QRコード" src={qr47} width="60px" />
                                                                : this.props.line_section_id === 10 ? <img alt="QRコード" src={qr10} width="60px" />
                                                                    : <img alt="QRコード" src={qrCode} width="60px" />
                                    }
                                    <br></br><span className="text_style4">友達追加QRコード</span></div>
                                {/* <div className="bottom_style qrCode_none"><img alt="QRコード" src={qrCode} width="60px" /><br></br><span className="text_style4">友達追加QRコード</span></div> */}
                            </div>
                        </div>
                        <div className="row pt-3">
                            {this.props.line_code === "comp" ? ""
                                :
                                <div className="col-xs-12 text_style1">LINEで友達追加後、5分以内に「認証コード」の数字をLINEトークから送信し認証して下さい。</div>
                            }
                        </div>
                        {this.props.line_limit ? <div className="code_color pt-2">
                            <div className="text_style2">認証コード</div>
                            <div className="text_style3">{this.props.line_code}</div>
                            <div className="col-xs-12 text_style2">{moment(this.props.line_limit).format("YYYY年MM月DD日 HH:mm")} まで有効</div>
                        </div> : ""}
                    </div>
                    {this.props.line_code === "comp" ? ""
                        :
                        <div className="pt-3">
                            <div className="text_style4">・認証完了後にLINE上でやりとりを行うことが出来ます。</div>
                            <div className="text_style4">・認証コードを忘れた場合や有効期限が切れた場合は、お手数ですが最初からやり直してください。</div>
                        </div>
                    }
                </div>
                <div className="container line-info">
                    <div className={this.props.line_code === "comp" ? "row mt-5" : "row mt-3"}>
                        <div className="col-xs-12 text_center2">LINE友達登録でお部屋探しをもっと便利に</div>
                    </div>
                    <div className="row line_middle pt-3 pb-4">
                        <div className="col-md-5 col-5"><img alt="LINEで物件配信" src={addFriend} /></div>
                        <div className="col-md-5 col-7 pl-0">
                            <ul className="pl-0">
                                <li>
                                    <div className="text_center3"><CheckCircleIcon /></div>
                                    <div className="text_style5">新着物件をいち早くキャッチ！</div>
                                </li>
                                <li>
                                    <div className="text_center3"><CheckCircleIcon /></div>
                                    <div className="text_style5">ご質問・お問い合わせ・来店予約がLINEトーク上からお手軽に！</div>
                                </li>
                                <li>
                                    <div className="text_center3"><CheckCircleIcon /></div>
                                    <div className="text_style5">通知offや配信停止(ブロック)はいつでも可能です。</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        // mypage_request: state.Coming.mypage_request,
        line_code: state.Line.line_code,
        line_limit: state.Line.line_limit,
        line_section_id: state.Line.line_section_id,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mypage_request(state) {
        //     dispatch(Line.change_mypage_request(state))
        // },
        // update_mypage_request(state) {
        //     dispatch(Line.update_mypage_request(state))
        // },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}


// export default ComingFormComponent_all = connect(
//     mapStateToProps, mapDispatchToProps
// )(ComingFormComponent_all);
LineFormComponent = connect(
    mapStateToProps, mapDispatchToProps
)(LineFormComponent);

LineFormComponent = reduxForm({
    form: 'tenantform', // a unique identifier for this form
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(LineFormComponent)

export default LineFormComponent