/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useState } from 'react';

import { connect } from 'react-redux';
import * as Card from './Saga/Card';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import "react-datepicker/dist/react-datepicker.css";
import Select from '@material-ui/core/Select';
import { reduxForm } from 'redux-form';
import './Css/Card.css';
import { useKana } from 'react-use-kana';
import Alert from '@material-ui/lab/Alert';



const Name = ({ props, handleChange }) => {
    // const { kana, setKanaSource } = useKana();
    const [count, setCount] = useState(0);
    const {
        kana: kana1,
        setKanaSource: setKanaSource1,
    } = useKana();

    const {
        kana: kana2,
        setKanaSource: setKanaSource2,
    } = useKana();
    function change_name(e, props) {


        let card_details = props.card_details



        if (e.target.name === "name1") {
            setKanaSource1(e.target.value)

            card_details.name1 = e.target.value;
            card_details.kana1 = kana1;
        } else if (e.target.name === "name2") {
            setKanaSource2(e.target.value)
            card_details.name2 = e.target.value;
            card_details.kana2 = kana2;
        } else {
            card_details[e.target.name] = e.target.value;

        }

        handleChange()

        props.change_card_details(card_details)
        setCount(count + 1)
    }

    return (
        <div >
            <div className="row">
                <div className="col-md-3">氏名
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-3 col-5 form-group">
                            <TextField
                                id="name1"
                                name="name1"
                                label="姓（漢字）"
                                margin="dense"
                                variant="outlined"
                                autoComplete='off'
                                value={props.card_details.name1}
                                error={!props.card_details.name1}
                                onChange={(e) => change_name(e, props)}
                                required={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </div>
                        <div className="col-md-3 col-5 form-group">
                            <TextField
                                id="name2"
                                name="name2"
                                label="名（漢字）"
                                margin="dense"
                                variant="outlined"
                                autoComplete='off'
                                value={props.card_details.name2}
                                error={!props.card_details.name2}
                                onChange={(e) => change_name(e, props)}
                                required={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>



            <div className="row">
                <div className="col-md-3">フリガナ
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-3 col-5 form-group">
                            <TextField
                                id="kana1"
                                name="kana1"
                                label="姓（カナ）"
                                margin="dense"
                                variant="outlined"
                                autoComplete='off'
                                value={props.card_details.kana1}
                                error={!props.card_details.kana1}
                                onChange={(e) => change_name(e, props)}
                                required={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </div>
                        <div className="col-md-3 col-5 form-group">
                            <TextField
                                id="kana2"
                                name="kana2"
                                label="名（カナ）"
                                margin="dense"
                                variant="outlined"
                                autoComplete='off'
                                value={props.card_details.kana2}
                                error={!props.card_details.kana2}
                                onChange={(e) => change_name(e, props)}
                                required={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};
// let autokana1;

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (!values.name1) {
        errors.name1 = "姓を入力してください。";
    }
    if (!values.name2) {
        errors.name2 = "名を入力してください。";
    }
    if (!values.kana1) {
        errors.kana1 = "フリガナ(姓)を入力してください。";
    }
    if (!values.kana2) {
        errors.kana2 = "フリガナ(名)を入力してください。";
    }
    if (!values.address1) {
        errors.address1 = "住所を入力してください。";
    }
    if (!values.tel2) {
        errors.tel2 = "携帯番号を入力してください。";
    }

    if (values.post) {
        if (!values.zip1.match(/^\d{3}$/)) {
            errors.zip1 = "郵便番号の形式が正しくありません。";
        }
    }
    if (values.post) {
        if (!values.zip2.match(/^\d{4}$/)) {
            errors.zip2 = "郵便番号の形式が正しくありません。";
        }
    }

    if (values.tel1) {
        if (!values.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel1.match(/^\d{7,13}$/)) {
            errors.tel1 = "電話番号の形式が正しくありません。";
        }
    }
    if (values.tel2) {
        if (!values.tel2.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel2.match(/^\d{7,13}$/)) {
            errors.tel2 = "携帯番号の形式が正しくありません。";
        }
    }
    if (values.email) {
        if (!values.email.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            errors.email = "メールアドレスの形式が正しくありません。";
        }
    }

    return errors;
};

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, onKeyDown, style,meta: { touched, error } }) => (
//     <div>
//         <input {...input} type={type} id={id} onKeyDown={onKeyDown} placeholder={placeholder} style={style} className={(touched && error) || ((input.name==="name1"||input.name==="name2"||input.name==="kana1"||input.name==="kana2"||input.name==="address1"||input.name==="tel2") && !touched) ? 'alert-danger ' + className : className}/>
//         {touched && error && <div className="text-danger">{error}</div>}
//     </div>
// );



export class RenderField extends React.Component {
    render() {
        const { className, type, style, input, disabled, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};




class CardFormComponent extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.year = [{ value: "", label: "選択してください" }];
        this.month = [{ value: "", label: "選択してください" }];
        this.day = [{ value: "", label: "選択してください" }];

        var hiduke = new Date();
        // 昭和
        for (let i = 1960; i <= 1988; i++) {
            let showa = i - 1925
            let yearset = []
            yearset.value = i
            yearset.label = i + "年/昭和" + showa + "年"
            this.year.push(yearset);
        }
        // 平成
        for (let i = 1989; i <= 2018; i++) {
            let heisei = i - 1988
            let yearset = []
            yearset.value = i
            yearset.label = i + "年/平成" + heisei + "年"
            this.year.push(yearset);
        }
        // 令和
        for (let i = 2019; i <= hiduke.getFullYear(); i++) {
            let heisei = i - 2018
            let yearset = []
            yearset.value = i
            yearset.label = i + "年/令和" + heisei + "年"
            this.year.push(yearset);
        }
        for (let i = 1; i <= 12; i++) {
            let monthset = []
            monthset.value = i
            monthset.label = i + "月"
            this.month.push(monthset);
        }
        for (let i = 1; i <= 31; i++) {
            let dayset = []
            dayset.value = i
            dayset.label = i + "日"
            this.day.push(dayset);
        }

        //お引越し理由
        this.moveReasonData = [
            { value: "", label: "選択してください" },
            { value: "8", label: "結婚" },
            { value: "7", label: "転勤" },
            { value: "6", label: "入社" },
            { value: "4", label: "入学" },
            { value: "2", label: "独立" },
            { value: "11", label: "家賃を抑えたい" },
            { value: "12", label: "手狭になった" },
            { value: "14", label: "離婚・別居" },
            { value: "15", label: "騒音" },
            { value: "3", label: "その他" },
        ];
        //家賃
        this.rentData = [
            {value:"20000", label:"2万"},
            {value:"25000", label:"2.5万"},
            {value:"30000", label:"3万"},
            {value:"35000", label:"3.5万"},
            {value:"40000", label:"4万"},
            {value:"45000", label:"4.5万"},
            {value:"50000", label:"5万"},
            {value:"55000", label:"5.5万"},
            {value:"60000", label:"6万"},
            {value:"65000", label:"6.5万"},
            {value:"70000", label:"7万"},
            {value:"75000", label:"7.5万"},
            {value:"80000", label:"8万"},
            {value:"85000", label:"8.5万"},
            {value:"90000", label:"9万"},
            {value:"95000", label:"9.5万"},
            {value:"100000", label:"10万"},
            {value:"110000", label:"11万"},
            {value:"120000", label:"12万"},
            {value:"130000", label:"13万"},
            {value:"140000", label:"14万"},
            {value:"150000", label:"15万"},
            {value:"200000", label:"20万"},
            {value:"300000", label:"30万"},
            {value:"500000", label:"50万"},
            {value:"1000000", label:"100万"},
        ];
        //入居時期
        this.movingTimeData = [
            {value:"", label:"選択してください"},
            {value:"1", label:"今月中"},
            {value:"2", label:"来月中"},
            {value:"3", label:"2ヶ月後"},
            {value:"4", label:"3ヶ月後"},
            {value:"5", label:"4ヶ月後"},
            {value:"6", label:"5ヶ月後"},
            {value:"7", label:"半年後"},
            {value:"8", label:"1年以内"},
        ];

        this.state = {
            customerinfo: this.props.forecasts,
            startDate: new Date(),
            checkedA: true,
            checkedB: true,
            checkedF: true,
            radio: {},
            setDog: false,
            setCat: false,
            change: false,
            error: { name1: true, name2: true, kana1: true, kana2: true, tel1: true, rentMReasonId: true, sonota1: true, sonota2: true, mypageRequestNumberOfPeople1: true, },
            error_kikkake: true,
            checkedList: [],
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.handleChangeRdio = this.handleChangeRdio.bind(this);
        this.onChangeSelectBox = this.onChangeSelectBox.bind(this);

        this.checkKikkake = this.checkKikkake.bind(this);
        // this.selectRiyuu = this.selectRiyuu.bind(this);
        this.checkTel = this.checkTel.bind(this);

        this.onChangeWeb = this.onChangeWeb.bind(this);
        this.onChangeMagazine = this.onChangeMagazine.bind(this);
        this.onChangeDog = this.onChangeDog.bind(this);
        this.onChangeCat = this.onChangeCat.bind(this);
        this.onChangePartnerShip = this.onChangePartnerShip.bind(this);

        this.on_change_privacy_policy = this.on_change_privacy_policy.bind(this);




        // this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる
    }

    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e)
        
        let card_details = this.props.card_details

        if (e.target.name == "mypage_request_areas" || e.target.name == "mypage_request_plans" || e.target.name == "mypage_response_requests") {

            if (card_details[e.target.name] && card_details[e.target.name].split(",").includes(e.target.value)) {

                //削除
                card_details[e.target.name] = card_details[e.target.name].split(",").filter(item => parseInt(item) != parseInt(e.target.value)).join(',')
            } else {

                let ary = card_details[e.target.name] ? card_details[e.target.name].split(",") : []

                ary.push(parseInt(e.target.value))

                ary = ary.filter(Boolean)

                card_details[e.target.name] = ary.join(',')
            }
        }else if(e.target.name == "mypage_student_independent"){
            if(e.target.value == "1"){
                card_details.mypage_request_rent_m_school_id = null
                card_details.mypage_request_gakunen = null
            }else{
                card_details.office_name = null
                card_details.office_add = null
            }
            card_details[e.target.name] = e.target.value
        }else if(e.target.name == "post"){
            card_details[e.target.name] = e.target.value.replace(/[^0-9]/g, '')
        }else{
            card_details[e.target.name] = e.target.value
        }
        

        this.props.change_card_details(card_details)
        this.setState({ change: !this.state.change });

    }

    onChangeWeb(e) {

        let card_details = this.props.card_details

        let request_media = card_details.request_media ? card_details.request_media.split(',') : []
        if (!e.target.checked) {
            request_media = request_media.filter(a => a !== '2' && a !== '1' && a !== '14' && a !== '16' && a !== '17' && a !== '21' && a !== '15' && a !== '36')
        }


        card_details[e.target.name] = e.target.checked


        card_details.request_media = request_media.join(',')
        this.props.change_card_details(card_details)
        this.setState({ change: !this.state.change });

    }

    onChangeMagazine(e) {

        let card_details = this.props.card_details

        let request_media = card_details.request_media ? card_details.request_media.split(',') : []
        if (!e.target.checked) {
            request_media = request_media.filter(a => a !== '28' && a !== '5' && a !== '29' && a !== '6')
        }
        card_details[e.target.name] = e.target.checked


        card_details.request_media = request_media.join(',')
        this.props.change_card_details(card_details)
        this.setState({ change: !this.state.change });

    }


    onChangeDog(e) {

        let card_details = this.props.card_details

        card_details[e.target.name] = e.target.checked
        if (!card_details.mypage_dog) {
            card_details.mypage_dog_count = 0
            card_details.mypage_dog_remarks = ''
        }

        this.props.change_card_details(card_details)
        this.setState({ change: !this.state.change });

    }

    onChangeCat(e) {

        let card_details = this.props.card_details

        card_details[e.target.name] = e.target.checked
        if (!card_details.mypage_cat) {
            card_details.mypage_cat_count = 0
            card_details.mypage_cat_remarks = ''
        }

        this.props.change_card_details(card_details)
        this.setState({ change: !this.state.change });

    }

    componentDidMount() {
        // console.log(this.props.comingdetail)
        //   this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる

        // This method is called when the component is first added to the document
        //this.ensureDataFetched();

    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }

    on_change_privacy_policy() {

        let card_details = this.props.card_details
        card_details.privacy_policy = card_details.privacy_policy ? !card_details.privacy_policy : true



        this.props.change_card_details(card_details)
        this.setState({ change: !this.state.change });
    }

    handleChange = event => {

        this.setState({ change: !this.state.change });
    };
    // handleChangeRdio = e => {
    //     alert(e.target.value)
    //     let card_details = this.props.card_details

    //     card_details[e.target.name] = e.target.value
    //     this.props.change_card_details(card_details)
    //     this.setState({ change: !this.state.change });
    // };

    onChangeSelectBox(e) {
        console.log(e.target.name)
        this.setState({ [e.target.name]: e.target.value });
    }

    checkKikkake(e) {
        let card_details = this.props.card_details
        let request_media = card_details.request_media ? card_details.request_media.split(',') : []

        if (e.target.checked === true) {
            request_media.push(e.target.value)
            if (e.target.name === "kikkake_sonota") {
                card_details.kikkake_sonota = true
            }
            if (e.target.name === "introduction") {
                card_details.introduction = true
            }
        } else {
            request_media = request_media.filter(a => a !== e.target.value)
            if (e.target.name === "kikkake_sonota") {
                card_details.kikkake_sonota = false
            }
            if (e.target.name === "introduction") {
                card_details.introduction = false
            }
        }
        // this.setState({ checkedList })
        if (request_media.length === 0) {
            this.setState({ error_kikkake: true })
        } else {
            this.setState({ error_kikkake: false })
        }

        card_details.request_media = request_media.join(',')
        this.props.change_card_details(card_details)
    }


    onChangePartnerShip(e) {
        let card_details = this.props.card_details
        card_details.partnership = e.target.checked
        this.props.change_card_details(card_details)
        this.setState({ [e.target.name]: e.target.value });
    }



    checkTel(e) {
        let error = this.state.error
        let val = this.zenkakuToHankaku(e.target.value).replace(/[^0-9]/g, '')
        if (val.length >= 12) {
            val = val.slice(0, 11)
        }
        if (!val.match(/^\d{10,11}$/)) {
            error[e.target.name] = true
            this.setState(error)
        } else {
            error[e.target.name] = false
            this.setState(error)
        }
        e.target.value = val
    }

    zenkakuToHankaku(str) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
    }

    render() {

        return (

            // <form onSubmit={handleSubmit}>
            <div className="display_style">
                <div className="title_style">ご来店カード</div>
                <div className="conditions_style">
                    <div className="container">
                        <div className="row msg_font pt-2 pl-3">
                            本日はご来店頂き、誠にありがとうございます。<br></br>お客様のご希望にかなう物件を紹介いたしますので、出来るだけ詳しくご記入ください。
            </div>
                    </div>
                    <div className="container">
                        <div className="row pt-2">
                            <h6>【お客様情報】</h6>
                        </div>
                        <Name props={this.props} handleChange={this.handleChange} />
                        <div className="row">
                            <div className="col-md-3">性別
                </div>
                            <div className="form-group col-md-9">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="sex" name="sex" value={this.props.card_details.sex} onChange={this.onChange} row>
                                        <FormControlLabel value="1" control={<Radio color="primary" />} checked={this.props.card_details.sex === "1"} label="男性" labelPlacement="end" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} checked={this.props.card_details.sex === "2"} label="女性" labelPlacement="end" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">生年月日</div>
                            <div className="form-group col-md-9">
                                <div className="row">
                                    <div className="col-md-3 col-9 pr-0">
                                        <FormControl variant="outlined" margin="dense" style={{ width: "100%" }}>
                                            <InputLabel>年</InputLabel>
                                            <Select
                                                name="birthday_year"
                                                id="birthday_year"
                                                value={this.props.card_details.birthdayYear}
                                                onChange={this.onChange}
                                                label="年"
                                            >
                                                {this.year.map(d =>
                                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {/* <span style={{verticalAlign:"bottom"}}>年</span> */}
                                    </div>
                                    <div className="col-md-2 col-4 pr-0">
                                        <FormControl variant="outlined" margin="dense" style={{ width: "100%" }}>
                                            <InputLabel>月</InputLabel>
                                            <Select
                                                name="birthday_month"
                                                id="birthday_month"
                                                value={this.props.card_details.birthday_month}
                                                onChange={this.onChange}
                                                label="月"
                                            >
                                                {this.month.map(d =>
                                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {/* <span style={{verticalAlign:"bottom"}}>月</span> */}
                                    </div>
                                    <div className="col-md-2 col-4 pr-0">
                                        <FormControl variant="outlined" margin="dense" style={{ width: "100%" }}>
                                            <InputLabel>日</InputLabel>
                                            <Select
                                                name="birthday_day"
                                                id="birthday_day"
                                                value={this.props.card_details.birthday_day}
                                                onChange={this.onChange}
                                                label="日"
                                            >
                                                {this.day.map(d =>
                                                    <MenuItem value={d.value}>{d.label}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {/* <span style={{verticalAlign:"bottom"}}>日</span> */}
                                        {/* <Field name="birthdayDay"
                            className="form-control"
                            component="select"
                            style={{fontSize:13}}
                        >
                            {this.day.map((value) => <option value={value}>{value}</option>)}
                        </Field> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">〒
                </div>
                            <div className="col-md-9 form-group">
                                <div className="row">
                                    <div className="col-sm-2 col-4 pr-0">
                                        <TextField
                                            id="post"
                                            name="post"
                                            type="text"
                                            label="郵便番号"
                                            onChange={this.onChange}
                                            margin="dense"
                                            variant="outlined"
                                            autoComplete='off'
                                            style={{ width: "100%" }}
                                            helperText="-(ﾊｲﾌﾝ)入力不要"
                                            value={this.props.card_details.post}
                                        />
                                        {/* <Field
                                name="zip1"
                                type="text"
                                className="form-control"
                                component={renderField}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') e.preventDefault();
                                    }}
                            /> */}
                                        {/* </div><span style={{ marginTop: "13px" }}>-</span>
                                    <div className="col-sm-2 col-4 pl-0">
                                        <TextField
                                            id="zip2"
                                            name="zip2"
                                            label="郵便番号"
                                            onChange={this.onChange}
                                            margin="dense"
                                            variant="outlined"
                                            autoComplete='off'
                                            style={{ width: "100%" }}
                                        /> */}
                                        {/* <Field
                                name="zip2"
                                type="text"
                                className="form-control"
                                component={renderField}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') e.preventDefault();
                                    }}
                            /> */}
                                    </div>
                                    <div className="col-3 pl-0 pr-0">
                                        <button style={{ marginLeft: "10px" }} className="btn btn-sm btn-success mt-3" type="button" onClick={this.props.get_zip_address}>住所検索</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">住所
                </div>
                            <div className="col-md-9 form-group">
                                <div className="row pb-2">
                                    <div className="col-md-4 col-11 pr-0">
                                        <TextField
                                            id="add1"
                                            name="add1"
                                            onChange={this.onChange}
                                            label="都道府県"
                                            margin="dense"
                                            value={this.props.card_details.add1}
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            required={true}
                                            error={!this.props.card_details.add1}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 col-11 pr-0">
                                        <TextField
                                            id="add2"
                                            name="add2"
                                            onChange={this.onChange}
                                            label="市区"
                                            margin="dense"
                                            value={this.props.card_details.add2}
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            required={true}
                                            error={!this.props.card_details.add2}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-10">
                                        <TextField
                                            id="add3"
                                            name="add3"
                                            label="町村"
                                            onChange={this.onChange}
                                            value={this.props.card_details.add3}
                                            margin="dense"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {/* <Field
                                name="address2"
                                component="input"
                                type="text"
                                placeholder="アパート・マンション名など"
                                className="form-control"
                                onKeyDown={e => {
                                    if (e.key === 'Enter') e.preventDefault();
                                  }}
                            /> */}
                                    </div>
                                    <div className="col-md-10">
                                        <TextField
                                            id="add4"
                                            name="add4"
                                            label="番地　アパート・マンション名など"
                                            onChange={this.onChange}
                                            value={this.props.card_details.add4}
                                            margin="dense"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">TEL（携帯）</div>
                            <div className="col-md-9 form-group">
                                <div className="row">
                                    <div className="col-md-10">
                                        <TextField
                                            id="tel1"
                                            name="tel1"
                                            label="TEL"
                                            margin="dense"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            error={!this.props.card_details.tel1}
                                            value={this.props.card_details.tel1}
                                            onChange={this.onChange}
                                            required={true}
                                            placeholder="例）09012345678"
                                            helperText="半角数字のみで入力してください。 -(ﾊｲﾌﾝ)入力不要"
                                        />
                                        {/* <Field
                                name="tel1"
                                component={renderField}
                                type="text"
                                placeholder=""
                                className="form-control"
                            /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">メールアドレス</div>
                            <div className="col-md-9 form-group">
                                <div className="row">
                                    <div className="col-md-10">
                                        <TextField
                                            id="mail1"
                                            name="mail1"
                                            label="メールアドレス"
                                            margin="dense"
                                            variant="outlined"
                                            value={this.props.card_details.mail1}
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            onChange={this.onChange}
                                        />
                                        {/* <Field
                                name="email"
                                type="text"
                                placeholder=""
                                className="form-control"
                                component={renderField}
                            /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-3">職業</div>
                            <div className="form-group col-md-9">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="mypage_student_independent" name="mypage_student_independent" value={this.props.card_details.mypage_student_independent} onChange={this.onChange} row>
                                        <FormControlLabel value="1" control={<Radio color="primary" />} checked={this.props.card_details.mypage_student_independent === "1"} label="社会人" labelPlacement="end" />
                                        <FormControlLabel value="2" control={<Radio color="primary" />} checked={this.props.card_details.mypage_student_independent === "2"} label="学生" labelPlacement="end" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>

                        {this.props.card_details.mypage_student_independent === "1" ? <div className="row">
                            <div className="col-md-3">勤務先</div>
                            <div className="col-md-9 form-group">
                                <div className="row">
                                    <div className="col-md-8">
                                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} >
                                            <InputLabel>職業</InputLabel>
                                            <Select
                                                name="mypage_rent_m_job_id"
                                                id="mypage_rent_m_job_id"
                                                value={this.props.card_details.mypage_rent_m_job_id}
                                                onChange={this.onChange}
                                                label=""
                                            >
                                                {this.props.rent_m_jobs.map(d =>
                                                    <MenuItem value={d.id}>{d.name}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-10">
                                        <TextField
                                            id="office_name"
                                            name="office_name"
                                            label="名称"
                                            margin="dense"
                                            variant="outlined"
                                            value={this.props.card_details.office_name}
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="col-md-10">
                                        <TextField
                                            id="office_add"
                                            name="office_add"
                                            label="住所"
                                            margin="dense"
                                            variant="outlined"
                                            value={this.props.card_details.office_add}
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        </div>:""}
                        {this.props.card_details.mypage_student_independent === "2" ? <div className="row">
                            <div className="col-md-3">学校</div>
                            <div className="col-md-9 form-group">
                                <div className="row">
                                    <div className="col-md-8">
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label="mypage_student_examinee" name="mypage_student_examinee" value={this.props.card_details.mypage_student_examinee} onChange={this.onChange} row>
                                                <FormControlLabel value="1" control={<Radio color="primary" />} checked={this.props.card_details.mypage_student_examinee === "1"} label="在学中" labelPlacement="end" />
                                                <FormControlLabel value="2" control={<Radio color="primary" />} checked={this.props.card_details.mypage_student_examinee === "2"} label="受験生" labelPlacement="end" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {this.props.card_details.mypage_student_examinee === "1" ? <div className="col-md-5">
                                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} >
                                            <InputLabel>学校名</InputLabel>
                                            <Select
                                                name="mypage_request_rent_m_school_id"
                                                id="mypage_request_rent_m_school_id"
                                                value={this.props.card_details.mypage_request_rent_m_school_id}
                                                onChange={this.onChange}
                                                label="学校名"
                                            >
                                                {this.props.rent_m_schools.map(d =>
                                                    <MenuItem value={d.id}>{d.name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>:""}
                                    {this.props.card_details.mypage_student_examinee === "1" ? <div className="col-md-4">
                                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} >
                                            <InputLabel>学年</InputLabel>
                                            <Select
                                                name="mypage_request_gakunen"
                                                id="mypage_request_gakunen"
                                                value={this.props.card_details.mypage_request_gakunen}
                                                onChange={this.onChange}
                                                label="学年"
                                            >
                                                <MenuItem value={0}>-</MenuItem>
                                                <MenuItem value={1}>新入学</MenuItem>
                                                <MenuItem value={2}>1回生</MenuItem>
                                                <MenuItem value={3}>2回生</MenuItem>
                                                <MenuItem value={4}>3回生</MenuItem>
                                                <MenuItem value={5}>4回生</MenuItem>
                                                <MenuItem value={6}>院生</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>:""}
                                    {this.props.card_details.mypage_student_examinee === "2" ? <div className="col-md-9">
                                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} >
                                            <InputLabel>出身校</InputLabel>
                                            <Select
                                                name="mypage_request_rent_m_highschool_id"
                                                id="mypage_request_rent_m_highschool_id"
                                                value={this.props.card_details.mypage_request_rent_m_highschool_id}
                                                onChange={this.onChange}
                                                label="出身校"
                                            >
                                                <MenuItem value={0}>-</MenuItem>
                                                {/* <MenuItem value={1}>テスト１</MenuItem>
                                                <MenuItem value={2}>テスト２</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </div>:""}

                                </div>
                            </div>
                        </div>:""}


                        <div class="row">
                            <div class="col-md-3">地域</div>
                            <div className="col-md-9 form-group">
                                <div className="row">
                                    <div className="col-md-8">
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label="area" name="mypage_request_areas_request" value={this.props.card_details.mypage_request_areas_request} onChange={this.onChange} row>
                                                <FormControlLabel value="1" control={<Radio color="primary" />} checked={this.props.card_details.mypage_request_areas_request === "1"} label="希望あり" labelPlacement="end" />
                                                <FormControlLabel value="2" control={<Radio color="primary" />} checked={this.props.card_details.mypage_request_areas_request === "2"} label="希望なし" labelPlacement="end" />
                                                <FormControlLabel value="3" control={<Radio color="primary" />} checked={this.props.card_details.mypage_request_areas_request === "3"} label="相談したい" labelPlacement="end" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {this.props.card_details.mypage_request_areas_request === "1" ? <div class="col-md-9 form-group">

                                        <FormControlLabel control={<Checkbox name="mypage_request_areas" value="1" checked={this.props.card_details.mypage_request_areas ? this.props.card_details.mypage_request_areas.split(',').includes("1") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="高松市中心部"/>
                                        <FormControlLabel control={<Checkbox name="mypage_request_areas" value="2" checked={this.props.card_details.mypage_request_areas ? this.props.card_details.mypage_request_areas.split(',').includes("2") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="レインボー・サンフラワー周辺"/>
                                        <FormControlLabel control={<Checkbox name="mypage_request_areas" value="3" checked={this.props.card_details.mypage_request_areas ? this.props.card_details.mypage_request_areas.split(',').includes("3") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="高松市南部"/>
                                        <FormControlLabel control={<Checkbox name="mypage_request_areas" value="4" checked={this.props.card_details.mypage_request_areas ? this.props.card_details.mypage_request_areas.split(',').includes("4") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="高松市東部"/>
                                        <FormControlLabel control={<Checkbox name="mypage_request_areas" value="5" checked={this.props.card_details.mypage_request_areas ? this.props.card_details.mypage_request_areas.split(',').includes("5") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="高松市西部"/>
                                        {/* <FormControlLabel control={<Checkbox name="mypage_request_areas" value="6" checked={this.props.card_details.mypage_request_areas ? this.props.card_details.mypage_request_areas.split(',').includes("6") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="香川町　三木町"/>
                                        <FormControlLabel control={<Checkbox name="mypage_request_areas" value="7" checked={this.props.card_details.mypage_request_areas ? this.props.card_details.mypage_request_areas.split(',').includes("7") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="庵治町・牟礼町"/>
                                        <FormControlLabel control={<Checkbox name="mypage_request_areas" value="8" checked={this.props.card_details.mypage_request_areas ? this.props.card_details.mypage_request_areas.split(',').includes("8") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="高松市全域"/> */}
                                        
                                        {/* <div class="row">
                                            <div class="col-md-12">その他、具体的なエリア
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-10">
                                                <TextField
                                                    id="mypage_area_remarks"
                                                    name="mypage_area_remarks"
                                                    margin="dense"
                                                    value={this.props.card_details.mypage_area_remarks}                                            
                                                    onChange={this.onChange}
                                                    variant="outlined"
                                                    style={{width:"100%"}}
                                                />
                                            </div>
                                        </div> */}
                                    </div>:""}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">家賃</div>
                            <div class="form-group col-md-9">
                                <div class="row">
                                    <div class="col-md-6">
                                        <FormControl variant="outlined" margin="dense">
                                            <Select
                                                /************/
                                                name="mypage_request_from_cost"
                                                id="mypage_request_from_cost"
                                                style={{fontSize:13, width:150}}
                                                value={parseInt(this.props.card_details.mypage_request_from_cost)}
                                                onChange={this.onChange}
                                            >
                                            <option selected="selected" value="0">下限なし</option>
                                            {this.rentData.map(d => 
                                                <MenuItem value={d.value}>{d.label}</MenuItem>
                                            )}
                                            </Select>
                                        </FormControl><div style={{display:"inline-block", marginTop:"15px", paddingLeft:"5px", paddingRight:"5px"}}> ～ </div>
                                        <FormControl variant="outlined" margin="dense">
                                            <Select
                                                /************/
                                                name="mypage_request_to_cost"
                                                id="mypage_request_to_cost"
                                                style={{fontSize:13, width:150}}
                                                value={parseInt(this.props.card_details.mypage_request_to_cost)}
                                                onChange={this.onChange}
                                            >
                                            <option selected="selected" value="0">上限なし</option>
                                            {this.rentData.map(d => 
                                                <MenuItem value={d.value}>{d.label}</MenuItem>
                                            )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className={{fontSize:13}}>現在の家賃
                                    </div>
                                    <div class="col-2">
                                        <TextField
                                            /***********/
                                            id="mypage_rent_current"
                                            name="mypage_rent_current"
                                            type="number"
                                            value={this.props.card_details.mypage_rent_current}
                                            // className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end" style={{width:"80px"}}>万円</InputAdornment>,
                                            }}
                                            margin="dense"
                                            onChange={this.onChange}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">間取り</div>
                            <div class="form-group col-md-9">
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="1" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("1") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="ワンルーム" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="2" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("2") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="1K" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="3" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("3") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="1DK" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="5" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("5") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="1LDK" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="8" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("8") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="2K" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="9" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("9") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" />} label="2DK" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="11" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("11") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary"/>} label="2LDK"/>
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="14" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("14") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="3K" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="15" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("15") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="3DK" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="17" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("17") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="3LDK" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="20" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("20") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="4K" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="21" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("21") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="4DK" />
                                <FormControlLabel control={<Checkbox name="mypage_request_plans" value="23" checked={this.props.card_details.mypage_request_plans ? this.props.card_details.mypage_request_plans.split(',').includes("23") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="4LDK以上" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">希望設備</div>
                            <div class="form-group col-md-9">
                                
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="448" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("448") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="家具家電" />
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="104" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("104") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="バイク" />
                                
                                {
                                    this.props.card_details.mypage_response_requests && this.props.card_details.mypage_response_requests.split(',').includes("104") ? <FormControl variant="outlined" margin="dense">
                                    <InputLabel>バイク</InputLabel>
                                    <Select
                                        name="mypage_request_bike"
                                        id="mypage_request_bike"
                                        // style={{fontSize:13, width:150}}
                                        value={parseInt(this.props.card_details.mypage_request_bike)}
                                        onChange={this.onChange}
                                        label="バイク"
                                    >
                                        <MenuItem value={0}>{"-"}</MenuItem>
                                        <MenuItem value={1}>{"原付"}</MenuItem>
                                        <MenuItem value={2}>{"小型"}</MenuItem>
                                        <MenuItem value={3}>{"中型"}</MenuItem>
                                        <MenuItem value={4}>{"大型"}</MenuItem>
                                    </Select>
                                </FormControl>
                                    :""
                                }
                                {/* <FormControlLabel control={<Checkbox name="mypage_response_requests" value="20" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("20") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="バストイレ別" />
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="37" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("37") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="オートロック" />
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="58" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("58") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="エアコン" />
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="1" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("1") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="温水洗浄暖房便座" />
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="3" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("3") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="追焚き" />
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="91" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("91") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}}  color="primary" /> } label="対面キッチン" />
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="77" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("77") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="独立洗面台" />
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="153" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("153") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="2F以上" />
                                <FormControlLabel control={<Checkbox name="mypage_response_requests" value="16" checked={this.props.card_details.mypage_response_requests ? this.props.card_details.mypage_response_requests.split(',').includes("16") : false} onChange={this.onChange} style={{transform: "scale(1.0)"}} color="primary" /> } label="インターネット使い放題" /> */}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row pt-2">
                            <h6>【ご来店のきっかけ】</h6>
                        </div>
                        <div className="row">
                            <div className="col-md-3">何を見てご来店頂きましたか</div>
                            <div className="col-md-9">
                                <FormControl name="request_media" component="fieldset" error={this.state.error_kikkake} required={true}>
                                    <FormHelperText style={{ fontSize: "16px" }}>1つ以上選択してください。</FormHelperText>
                                    <FormGroup aria-label="mainMedia" name="mainMedia" row>
                                        <div className="col-sm-12"><FormControlLabel control={<Checkbox name="web" color="primary" checked={this.props.card_details.web} onChange={this.onChangeWeb} />} label="インターネット" /></div>
                                        <div className="col-sm-12" style={{ display: this.props.card_details.web ? '' : 'none' }}>
                                            <div className="row border_style">
                                                {this.props.rent_m_medea.filter(a=>a.web == 1).map(d =>
                                                    <div className="col-sm-3"><FormControlLabel value={d.id} control={<Checkbox color="secondary" onChange={this.checkKikkake} checked={(',' + this.props.card_details.request_media + ',').indexOf(','+d.id+',') !== -1 ? true : false} />} label={d.name} /></div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-12"><FormControlLabel control={<Checkbox name="magazine" color="primary" checked={this.props.card_details.magazine ? true : false} onChange={this.onChangeMagazine} />} label="雑誌" /></div>
                                        <div className="col-sm-12" style={{ display: this.props.card_details.magazine ? '' : 'none' }}>
                                            <div className="row border_style">
                                                {this.props.rent_m_medea.filter(a=>a.web == 0).map(d =>
                                                    <div className="col-sm-3"><FormControlLabel value={d.id} control={<Checkbox color="secondary" onChange={this.checkKikkake} checked={(',' + this.props.card_details.request_media + ',').indexOf(','+d.id+',') !== -1 ? true : false} />} label={d.name} /></div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4"><FormControlLabel value="23" control={<Checkbox color="primary" onChange={this.checkKikkake} checked={(',' + this.props.card_details.request_media + ',').indexOf(',23,') !== -1 ? true : false} />} label="ラジオ" /></div>
                                        <div className="col-sm-4"><FormControlLabel value="22" control={<Checkbox color="primary" onChange={this.checkKikkake} checked={(',' + this.props.card_details.request_media + ',').indexOf(',22,') !== -1 ? true : false} />} label="ＴＶＣＭ" /></div>
                                        <div className="col-sm-4"><FormControlLabel value="11" control={<Checkbox color="primary" onChange={this.checkKikkake} checked={(',' + this.props.card_details.request_media + ',').indexOf(',11,') !== -1 ? true : false} />} label="通りがかり" /></div>
                                        <div className="col-sm-12"><FormControlLabel value="8" control={<Checkbox color="primary" onChange={this.checkKikkake} checked={(',' + this.props.card_details.request_media + ',').indexOf(',8,') !== -1 ? true : false} />} label="以前に当社を利用したことがある" /></div>
                                        <div className="col-sm-3">
                                            <FormControlLabel value="9" control={<Checkbox name="introduction" onChange={this.checkKikkake} color="primary" />} label="紹介" />
                                        </div>
                                        <div className="col-sm-9">
                                            <RadioGroup aria-label="mypage_introducer" name="mypage_introducer" value={this.props.card_details.mypage_introducer} onChange={this.onChange} style={{ display: this.props.card_details.introduction ? '' : 'none' }} row>
                                                <div className="col-sm-3 pl-0"><FormControlLabel value="1" control={<Radio color="primary" />} label="社内" className="pd_l" /></div>
                                                <div className="col-sm-3 pl-0"><FormControlLabel value="2" control={<Radio color="primary" />} label="ご友人" className="pd_l" /></div>
                                                <div className="col-sm-3 pl-0"><FormControlLabel value="3" control={<Radio color="primary" />} label="ご家族" className="pd_l" /></div>
                                                <div className="col-sm-3 pl-0"><FormControlLabel value="4" control={<Radio color="primary" />} label="不動産会社" className="pd_l" /></div>
                                            </RadioGroup>
                                        </div>
                                        <div className="col-sm-3"><FormControlLabel value="4" control={<Checkbox name="kikkake_sonota" onChange={this.checkKikkake} color="primary" checked={(',' + this.props.card_details.request_media + ',').indexOf(',4,') !== -1 ? true : false} />} label="その他" /></div>
                                        <div className="col-sm-7" style={{ display: this.props.card_details.kikkake_sonota ? '' : 'none' }}>
                                            <TextField
                                                id="sonota1"
                                                name="sonota1"
                                                label="ご来店きっかけ　その他"
                                                margin="dense"
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                                autoComplete='off'
                                                value={this.props.card_details.sonota1}
                                                error={this.props.card_details.sonota1}
                                                onChange={this.onChange}
                                                required={true}
                                            />
                                        </div>
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row pt-2">
                            <h6>【業務提携】</h6>
                        </div>
                        <div className="row">
                            <div className="col-md-3">業務提携サービス</div>
                            <div className="col-md-9 form-group">
                                <div className="col-sm-3">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="partnership"
                                                value={true}
                                                checked={this.props.card_details.partnership ? true : false}
                                                onChange={this.onChangePartnerShip}
                                                style={{ transform: "scale(1.0)" }}
                                                color="primary"
                                            />
                                        }
                                        label="あり"
                                    />
                                </div>
                                <div className="col-sm-7">
                                    {this.props.card_details.partnership ?<TextField
                                        id="partnership_remarks"
                                        name="partnership_remarks"
                                        label="勤務先名"
                                        margin="dense"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        autoComplete='off'
                                        value={this.props.card_details.partnership_remarks}
                                        error={(this.props.card_details.partnership && (this.props.card_details.partnership_remarks == "" || !this.props.card_details.partnership_remarks)) ? true: false}
                                        onChange={this.onChange}
                                        required={true}
                                        helperText="提携サービスをご利用の場合は勤務先を必ずご入力ください。"
                                    />:<div></div>}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="container">
                        <div className="row pt-2">
                            <h6>【お引越し理由】</h6>
                        </div>
                        <div className="row">
                            <div className="col-md-3">お引越し理由
                </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} error={!this.props.card_details.rent_m_reason_id} required={true}>
                                            <InputLabel>お引越し理由</InputLabel>
                                            <Select
                                                name="rent_m_reason_id"
                                                id="rent_m_reason_id"
                                                value={this.props.card_details.rent_m_reason_id}
                                                onChange={this.onChange}
                                                label="お引越し理由"
                                            >
                                                {this.props.rent_m_reasons.map(d =>
                                                    <MenuItem value={d.id}>{d.name}</MenuItem>
                                                )}
                                                
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="col-sm-5" style={{ display: parseInt(this.props.card_details.rent_m_reason_id) === 3 ? '' : 'none' }}>
                                        <TextField
                                            id="reason_remarks"
                                            name="reason_remarks"
                                            label="お引越し理由　その他"
                                            margin="dense"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            value={this.props.card_details.reason_remarks}
                                            error={!this.props.card_details.reason_remarks}
                                            onChange={this.onChange}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container pb-4">
                        <div className="row pt-2">
                            <h6>【入居予定】</h6>
                        </div>
                        <div class="row">
                            <div class="col-md-3">入居時期</div>
                            <div class="col-md-9 form-group">
                                <div class="row">
                                    <div className="col-sm-3">
                                        <FormControl variant="outlined" margin="dense">
                                            <Select
                                                name="mypage_request_moving_time"
                                                id="mypage_request_moving_time"
                                                style={{fontSize:13, width:200}}
                                                value={parseInt(this.props.card_details.mypage_request_moving_time)}
                                                onChange={this.onChange}
                                            >
                                            {this.movingTimeData.map(d => 
                                                <MenuItem value={d.value}>{d.label}</MenuItem>
                                            )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">入居人数</div>
                            <div className="col-md-9 form-group">
                                <div className="row">
                                    <div className="col-md-3 col-5">
                                        <TextField
                                            id="mypage_request_number_of_people_adult"
                                            name="mypage_request_number_of_people_adult"
                                            type="number"
                                            label="入居人数　大人"
                                            value={this.props.card_details.mypage_request_number_of_people_adult}
                                            onChange={this.onChange}
                                            // className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end" style={{ width: "30px" }}>人</InputAdornment>,
                                            }}
                                            margin="dense"
                                            variant="outlined"
                                            autoComplete='off'
                                            error={this.state.error.mypage_request_number_of_people_adult}
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-md-3 col-5">
                                        <TextField
                                            id="mypage_request_number_of_people_child"
                                            name="mypage_request_number_of_people_child"
                                            type="number"
                                            label="入居人数　子供"
                                            value={this.props.card_details.mypage_request_number_of_people_child}
                                            onChange={this.onChange}
                                            // className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end" style={{ width: "30px" }}>人</InputAdornment>,
                                            }}
                                            margin="dense"
                                            variant="outlined"
                                            autoComplete='off'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">駐車場</div>
                            <div className="col-md-9 form-group">
                                <div className="row">
                                    <div className="col-md-3 col-5">
                                        <TextField
                                            id="mypage_request_number_of_standardcar"
                                            name="mypage_request_number_of_standardcar"
                                            type="number"
                                            label="普通自動車"
                                            value={this.props.card_details.mypage_request_number_of_standardcar}
                                            onChange={this.onChange}
                                            // className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end" style={{ width: "30px" }}>台</InputAdornment>,
                                            }}
                                            margin="dense"
                                            variant="outlined"
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div className="col-md-3 col-5">
                                        <TextField
                                            id="mypage_request_number_of_lightcar"
                                            name="mypage_request_number_of_lightcar"
                                            type="number"
                                            label="軽自動車"
                                            value={this.props.card_details.mypage_request_number_of_lightcar}
                                            onChange={this.onChange}
                                            // className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end" style={{ width: "30px" }}>台</InputAdornment>,
                                            }}
                                            margin="dense"
                                            variant="outlined"
                                            autoComplete='off'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">犬飼育</div>
                            <div className="col-md-9 form-group">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="mypage_dog"
                                            value={true}
                                            checked={this.props.card_details.mypage_dog ? true : false}
                                            onChange={this.onChangeDog}
                                            style={{ transform: "scale(1.0)" }}
                                            color="primary"
                                        />
                                    }
                                    label="犬"
                                />
                            </div>
                        </div>
                        <div style={{ display: (this.props.card_details.mypage_dog === true) ? '' : 'none' }}>
                            <div className="row">
                                <div className="col-md-3">犬飼育匹数</div>
                                <div className="col-md-9 form-group">
                                    <div className="row">
                                        <div className="col-md-3 col-5">
                                            <TextField
                                                id="mypage_dog_count"
                                                label="犬飼育匹数"
                                                name="mypage_dog_count"
                                                type="number"
                                                value={this.props.card_details.mypage_dog_count}
                                                onChange={this.onChange}
                                                // className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end" style={{ width: "30px" }}>匹</InputAdornment>,
                                                }}
                                                margin="dense"
                                                variant="outlined"
                                                autoComplete='off'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">犬備考(種類、大きさ)</div>
                                <div className="col-md-9 form-group">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <TextField
                                                id="mypage_dog_remarks"
                                                label="犬備考(種類、大きさ)"
                                                name="mypage_dog_remarks"
                                                margin="dense"
                                                variant="outlined"
                                                value={this.props.card_details.mypage_dog_remarks}
                                                onChange={this.onChange}
                                                style={{ width: "100%" }}
                                                autoComplete='off'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">猫飼育</div>
                            <div className="col-md-9 form-group">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="mypage_cat"
                                            value={true}
                                            checked={this.props.card_details.mypage_cat ? true : false}
                                            onChange={this.onChangeCat}
                                            style={{ transform: "scale(1.0)" }}
                                            color="primary"
                                        />
                                    }
                                    label="猫"
                                />
                            </div>
                        </div>
                        <div style={{ display: (this.props.card_details.mypage_cat === true) ? '' : 'none' }}>
                            <div className="row">
                                <div className="col-md-3">猫飼育匹数</div>
                                <div className="col-md-9 form-group">
                                    <div className="row">
                                        <div className="col-md-3 col-5">
                                            <TextField
                                                id="mypage_cat_count"
                                                label="猫飼育匹数"
                                                name="mypage_cat_count"
                                                value={this.props.card_details.mypage_cat_count}
                                                onChange={this.onChange}
                                                type="number"
                                                // className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end" style={{ width: "30px" }}>匹</InputAdornment>,
                                                }}
                                                margin="dense"
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">猫備考(種類、大きさ)</div>
                                <div className="col-md-9 form-group">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <TextField
                                                id="mypage_cat_remarks"
                                                label="猫備考(種類、大きさ)"
                                                name="mypage_cat_remarks"
                                                value={this.props.card_details.mypage_cat_remarks}
                                                onChange={this.onChange}
                                                margin="dense"
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">備考</div>
                            <div className="col-md-9 form-group">
                                <div className="row">
                                    <div className="col-md-10">
                                        <TextField
                                            id="remarks"
                                            name="remarks"
                                            label="備考"
                                            margin="dense"
                                            variant="outlined"
                                            value={this.props.card_details.remarks}
                                            style={{ width: "100%" }}
                                            autoComplete='off'
                                            onChange={this.onChange}
                                            multiline
                                            rows={4}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6 className="privacyPolicy_title">プライバシーポリシー</h6>
                        <div className="privacyPolicy_text">
                            <p>当社は、お取引に伴い、お客様の個人情報をいただいております。この書面はこのたびのお取引に伴い、入手する個人情報の保護とお取り扱いにつきまして、個人情報保護法の規定に従いご説明するものです。</p>
                            <br></br>
                            <p>１．個人情報の定義</p>
                            <p>当社は個人情報を以下の通り定義いたします。</p>
                            <p>【個人情報】</p>
                            <p>生存する個人に関する情報であって、氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものも含む）又は以下①若しくは②の個人識別符号が含まれるもの</p>
                            <p>① 特定の個人の身体の一部の特徴を電子計算機のために変換した符号</p>
                            <p>② 対象者ごとに異なるものとなるように役務の利用、商品の購入又は書類に付される符号</p>
                            <p>【要配慮個人情報】</p>
                            <p>人種、信条、社会的身分、病歴、前科、犯罪被害の事実等、その取扱いによっては差別や偏見を生じるおそれがあるため、特に慎重な取扱いが求められる記述等を含む個人情報</p>
                            <br></br>
                            <p>２. 個人情報に対する当社の基本姿勢</p>
                            <p>当社は、個人情報保護法の趣旨を尊重し、これを担保するために「個人情報保護方針」「個人情報保護規定」を定め、実行してまいります。</p>
                            <br></br>
                            <p>３．お客様個人情報の利用目的</p>
                            <p>当社におけるお客様の個人情報の利用目的は以下の通りです。</p>
                            <p>① 不動産の管理、仲介、賃貸、売買、建築請負等の取引に関する契約の履行、損害保険、情報・サービスの提供のため</p>
                            <p>② 物件のご紹介、お申込の結果等の連絡、信用情報機関への信用照会、賃貸借契約・連帯保証契約・管理委託契約・サブリース原契約媒介契約の締結、履行、及び契約管理、契約後の管理・アフターサービス等の実施のため。</p>
                            <p>③ 当社は、当社の他の不動産及びサービスの紹介並びにお客様にとって有用と思われる当社提携先の商品・サービス等の紹介 またアンケート等の発送のため</p>
                            <p>（成約に至らなかった後を含む）</p>
                            <br></br>
                            <p>４．お客様個人情報の第三者への提供</p>
                            <p>お客様の個人情報は、お客さまとの契約の目的を達成するために以下の者に提供する場合があります。</p>
                            <p>① 取引先の金融機関等</p>
                            <p>② 建物の補修に伴う関係業者等</p>
                            <p>③ 対象不動産についての建物管理並びに家賃管理、契約管理などの必要がある場合における管理業者</p>
                            <p>④ 賃貸経営事業に必要となる当社関連会社</p>
                            <p>⑤ 各種申請業務に伴う関係行政機関</p>
                            <p>⑥ その他、利用目的の達成のために必要となるその他の第三者</p>
                            <br></br>
                            <p>５．要配慮個人情報について</p>
                            <p>① 要配慮個人情報は本人の同意を得て取得致します。</p>
                            <p>② 要配慮個人情報については、オプトアウト手続きによる第三者提供を行いません。</p>
                            <br></br>
                            <p>６．匿名加工情報の提供について</p>
                            <p>当社は、当社が保有する個人情報を、個人を識別できない形に加工した上 (匿名加工情報あるいは統計情報) で、第三者への提供を行うことがあります。</p>
                            <br></br>
                            <p>７．個人情報の取得及び第三者提供の例外</p>
                            <p>以下の場合は、お客様の同意なくして個人情報（要配慮個人情報含む）の取得及び第三者提供を行う事があります。</p>
                            <p>① 法令に基づく場合</p>
                            <p>（例：警察、裁判所、税務署等からの照会）</p>
                            <p>② 人の生命・身体・財産の保護に必要且つ本人の同意取得が困難</p>
                            <p>（例：災害時の被災者情報の家族・自治体等への提供）</p>
                            <p>③ 公衆衛生・児童の健全育成に必要且つ、本人の同意取得が困難</p>
                            <p>（例：児童生徒の不登校や、児童虐待のおそれのある情報を関係機関で共有）</p>
                            <p>④ 国の機関等の法令の定める事務への協力</p>
                            <p>（例：国や地方公共団体の統計調査等への回答）</p>
                            <p>⑤ 委託、事業承継、共同利用等</p>
                            <br></br>
                            <p>８．お客様個人情報の保護対策</p>
                            <p>① 当社の従業者に対して個人情報保護のための教育を定期的に行い、お客様の個人情報を厳重に管理致します。</p>
                            <p>② 当社の下請け業者・取引先に対して、個人情報保護防止の啓蒙及び指導を実施致します。</p>
                            <p>③ 当社が保有する個人情報については当社「個人情報保護規定」に従い、必要なセキュリティ対策を講じます。</p>
                            <br></br>
                            <p>９．クッキー情報の利用について</p>
                            <p>① 当サイトでは、ヤフー株式会社をはじめとする第三者から配信される広告が掲載される場合があり、これに関連して、当該第三者が、当サイトを訪問したユーザーのクッキー情報等を取得し、利用している場合があります。</p>
                            <p>② 当該第三者によって取得されたクッキー情報等は、当該第三者のプライバシーポリシーに従って取り扱われます。</p>
                            <p>③ ユーザーは、当該第三者のウェブサイト内に設けられたオプトアウトページにアクセスして、当該第三者によるクッキー情報等の広告配信への利用を停止することができます。</p>
                            <p>④ クッキーとは、ウェブページを利用したときに、ブラウザーとサーバーとの間で送受信した利用履歴や入力内容などを、お客様のコンピューターにファイルとして保存しておく仕組みです。</p>
                            <p>⑤ 次回、同じページにアクセスすると、クッキーの情報を使って、ページの運営者はお客様ごとに表示を変えたりすることができます。お客様がブラウザーの設定でクッキーの送受信を許可している場合、ウェブサイトは、ユーザーのブラウザーからクッキーを取得できます。なお、お客様のブラウザーは、プライバシー保護のため、そのウェブサイトのサーバーが送受信したクッキーのみを送信します。お客様は、クッキーの送受信に関する設定を「すべてのクッキーを許可する」、「すべてのクッキーを拒否する」、「クッキーを受信したらユーザーに通知する」などから選択できます。</p>
                            <p>⑥ 設定方法は、にブラウザーより異なります。クッキーに関する設定方法は、お使いのブラウザーの「ヘルプ」メニューでご確認ください。</p>
                            <p>⑦ すべてのクッキーを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。</p>
                        </div>
                        <div className="privacyPolicy_agree">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="privacy_policy"
                                        value={this.props.card_details.privacy_policy}
                                        onChange={this.on_change_privacy_policy}
                                        style={{ transform: "scale(1.0)" }}
                                        color="primary"
                                    />
                                }
                                label="プライバシーポリシーに同意する"
                            />
                        </div>
                    </div>

                    {this.props.card_details.privacy_policy && 
                    this.props.card_details.name1 && 
                    this.props.card_details.name2 && 
                    this.props.card_details.kana1 && 
                    this.props.card_details.kana2 && 
                    this.props.card_details.add1 && 
                    this.props.card_details.add2 && 
                    this.props.card_details.rent_m_reason_id && 
                    this.props.card_details.tel1 && 
                    (!this.props.card_details.partnership || this.props.card_details.partnership == false || (this.props.card_details.partnership == true && this.props.card_details.partnership_remarks != "" && this.props.card_details.partnership_remarks)) &&
                    this.props.card_details.request_media ? <div id="footer-submit_button" className="pt-3 pb-3">
                        <button className="form-group btn btn-primary mx-auto d-block" onClick={this.props.update_card_details}>登録する</button>
                    </div> : <div id="footer-submit_button" className="pt-3 pb-5">
                        <Alert severity="error">未入力の項目があります。</Alert>
                    </div>}

                    {/* <button type="submit" className="form-group btn btn-primary mx-auto d-block" onClick={this.handleFormSubmit}>この内容で確認画面へ進む</button> */}
                </div>
            </div>
            // </form>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        card_details: state.Card.card_details,
        rent_m_medea: state.Card.rent_m_medea,
        rent_m_reasons: state.Card.rent_m_reasons,
        rent_m_schools: state.Card.rent_m_schools,
        rent_m_jobs: state.Card.rent_m_jobs,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_card_details(state) {
            dispatch(Card.change_card_details(state))
        },
        get_zip_address(state) {
            dispatch(Card.get_zip_address(state))
        },
        update_card_details(state) {
            dispatch(Card.update_card_details(state))
        },


        // update_mypage_request(state) {
        //     dispatch(Card.update_mypage_request(state))
        // },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}


// export default ComingFormComponent_all = connect(
//     mapStateToProps, mapDispatchToProps
// )(ComingFormComponent_all);
CardFormComponent = connect(
    mapStateToProps, mapDispatchToProps
)(CardFormComponent);

CardFormComponent = reduxForm({
    form: 'tenantform', // a unique identifier for this form
    validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(CardFormComponent)

export default CardFormComponent