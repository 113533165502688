import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Master from '../../Store/Master';
import * as Card from './Saga/Card';
import Layout from '../Layout/LayoutComponent';
import './Css/Card.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RentHeaderComponent from '../Layout/RentHeaderComponent';
import ApplicationLogin from './ApplicationLogin';
import ApplicationComponent from './ApplicationComponent';
import ApplicationCompComponent from './ApplicationCompComponent';

// import CreditConfirmComponent from './CreditConfirmComponent';



class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // comp: false
        };
        this.props.change_rent_contracts_renter_cd(this.props.match.params.way)
        // this.props.change_credit_cd(this.props.match.params.cd)
        // this.props.change_line_comp(false)
        // this.props.change_cd({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        // this.props.get_line_code(this.props.match.params.cd)
        // // this.handlesubmit = this.handlesubmit.bind(this)
        // this.loginClick = this.loginClick.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    loginClick() {

    }
    // handlesubmit(state) {
    //     // console.log(state)
    //     // alert()
    //     this.setState({ comp: true })
    // }

    render() {

        return (
            <div className="app" style={{ height: "100%" }}>
                <RentHeaderComponent />
                { this.props.rent_contracts.contract_code && this.props.rent_contracts.contract_code !== ""  ? this.props.application_comp == false ? <ApplicationComponent /> :<ApplicationCompComponent /> : <ApplicationLogin />}

            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {

        rent_contracts: state.Card.rent_contracts,
        application_comp: state.Card.application_comp,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_contracts_renter_cd(state) {
            dispatch(Card.change_rent_contracts_renter_cd(state))
        },
        // check_rent_contracts_renter_sign_in(state) {
        //     dispatch(Card.check_rent_contracts_renter_sign_in(state))
        // },
        // change_credit_cd(state) {
        //     dispatch(Credit.change_credit_cd(state))
        // },
        // // get_mypage_request(state) {
        // //     dispatch(Line.get_mypage_request(state))
        // // },
        // change_line_comp(state) {
        //     dispatch(Line.change_line_comp(state))
        // },
        // get_line_code(state) {
        //     dispatch(Line.get_line_code(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
