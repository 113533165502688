/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useState } from 'react';

import { connect } from 'react-redux';
import * as Card from './Saga/Card';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import "react-datepicker/dist/react-datepicker.css";
import Select from '@material-ui/core/Select';
import { reduxForm } from 'redux-form';
import './Css/Card.css';
import { useKana } from 'react-use-kana';
import Alert from '@material-ui/lab/Alert';



const Name = ({ props, handleChange }) => {
    // const { kana, setKanaSource } = useKana();
    const [count, setCount] = useState(0);
    const {
        kana: kana1,
        setKanaSource: setKanaSource1,
    } = useKana();

    const {
        kana: kana2,
        setKanaSource: setKanaSource2,
    } = useKana();

    function change_name(e, props) {


        let rent_contracts_renter = props.rent_contracts_renter

        if (e.target.name === "name1_1") {
            setKanaSource1(e.target.value)
            // card_details.name1 = e.target.value;
            // card_details.kana1 = kana1;
            rent_contracts_renter[props.renter_key].name1_1 = e.target.value
            rent_contracts_renter[props.renter_key].kana1_1 = kana1
        } else if (e.target.name === "name1_2") {
            setKanaSource2(e.target.value)
            // card_details.name2 = e.target.value;
            // card_details.kana2 = kana2;
            rent_contracts_renter[props.renter_key].name1_2 = e.target.value
            rent_contracts_renter[props.renter_key].kana1_2 = kana2
        } else {
            rent_contracts_renter[props.renter_key][e.target.name] = e.target.value;

        }

        handleChange()

        props.change_rent_contracts_renter(rent_contracts_renter)
        props.get_rent_contracts_renter_form_check()
        setCount(count + 1)

        // let application_form = this.props.application_form
        // for (let list of application_form) {
        //     list[0].people_num

        // }
        
        // console.log(rent_contracts_renter)

    }

    return (
        <div >
            <div className="row">
                <div className="col-md-3">氏名
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-4 col-5 form-group">
                            <TextField
                                id="name1_1"
                                name="name1_1"
                                label="姓（漢字）"
                                margin="dense"
                                variant="outlined"
                                autoComplete='off'
                                value={props.renter.name1_1}
                                error={!props.renter.name1_1}
                                onChange={(e) => change_name(e, props)}
                                required={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </div>
                        <div className="col-md-4 col-5 form-group">
                            <TextField
                                id="name1_2"
                                name="name1_2"
                                label="名（漢字）"
                                margin="dense"
                                variant="outlined"
                                autoComplete='off'
                                value={props.renter.name1_2}
                                error={!props.renter.name1_2}
                                onChange={(e) => change_name(e, props)}
                                required={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>



            <div className="row">
                <div className="col-md-3">フリガナ
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-4 col-5 form-group">
                            <TextField
                                id="kana1_1"
                                name="kana1_1"
                                label="姓（カナ）"
                                margin="dense"
                                variant="outlined"
                                autoComplete='off'
                                value={props.renter.kana1_1}
                                error={!props.renter.kana1_1}
                                onChange={(e) => change_name(e, props)}
                                required={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </div>
                        <div className="col-md-4 col-5 form-group">
                            <TextField
                                id="kana1_2"
                                name="kana1_2"
                                label="名（カナ）"
                                margin="dense"
                                variant="outlined"
                                autoComplete='off'
                                value={props.renter.kana1_2}
                                error={!props.renter.kana1_2}
                                onChange={(e) => change_name(e, props)}
                                required={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};
// let autokana1;

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (!values.name1) {
        errors.name1 = "姓を入力してください。";
    }
    if (!values.name2) {
        errors.name2 = "名を入力してください。";
    }
    if (!values.kana1) {
        errors.kana1 = "フリガナ(姓)を入力してください。";
    }
    if (!values.kana2) {
        errors.kana2 = "フリガナ(名)を入力してください。";
    }
    // if (!values.address1) {
    //     errors.address1 = "住所を入力してください。";
    // }
    if (!values.tel2) {
        errors.tel2 = "携帯番号を入力してください。";
    }

    // if (values.post) {
    //     if (!values.zip1.match(/^\d{3}$/)) {
    //         errors.zip1 = "郵便番号の形式が正しくありません。";
    //     }
    // }
    // if (values.post) {
    //     if (!values.zip2.match(/^\d{4}$/)) {
    //         errors.zip2 = "郵便番号の形式が正しくありません。";
    //     }
    // }

    if (values.tel1) {
        if (!values.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel1.match(/^\d{7,13}$/)) {
            errors.tel1 = "電話番号の形式が正しくありません。";
        }
    }
    // if (values.email) {
    //     if (!values.email.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
    //         errors.email = "メールアドレスの形式が正しくありません。";
    //     }
    // }

    return errors;
};

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, onKeyDown, style,meta: { touched, error } }) => (
//     <div>
//         <input {...input} type={type} id={id} onKeyDown={onKeyDown} placeholder={placeholder} style={style} className={(touched && error) || ((input.name==="name1"||input.name==="name2"||input.name==="kana1"||input.name==="kana2"||input.name==="address1"||input.name==="tel2") && !touched) ? 'alert-danger ' + className : className}/>
//         {touched && error && <div className="text-danger">{error}</div>}
//     </div>
// );



export class RenderField extends React.Component {
    render() {
        const { className, type, style, input, disabled, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};




class ApplicationFormComponent extends Component {

    constructor(props) {
        super(props);
        // console.log(props);

        this.year = [{ value: "", label: "選択してください" }];
        this.month = [{ value: "", label: "選択してください" }];
        this.day = [{ value: "", label: "選択してください" }];

        var hiduke = new Date();
        // 昭和
        for (let i = 1960; i <= 1988; i++) {
            let showa = i - 1925
            let yearset = []
            yearset.value = i
            yearset.label = i + "年/昭和" + showa + "年"
            this.year.push(yearset);
        }
        // 平成
        for (let i = 1989; i <= 2018; i++) {
            let heisei = i - 1988
            let yearset = []
            yearset.value = i
            yearset.label = i + "年/平成" + heisei + "年"
            this.year.push(yearset);
        }
        // 令和
        for (let i = 2019; i <= hiduke.getFullYear(); i++) {
            let heisei = i - 2018
            let yearset = []
            yearset.value = i
            yearset.label = i + "年/令和" + heisei + "年"
            this.year.push(yearset);
        }
        for (let i = 1; i <= 12; i++) {
            let monthset = []
            monthset.value = i
            monthset.label = i + "月"
            this.month.push(monthset);
        }
        for (let i = 1; i <= 31; i++) {
            let dayset = []
            dayset.value = i
            dayset.label = i + "日"
            this.day.push(dayset);
        }
        
        //続柄
        this.relationship = [
            { value: null, label: "選択してください" },
            { value: 1, label: "本人" },
            { value: 2, label: "父" },
            { value: 3, label: "母" },
            { value: 4, label: "子" },
            { value: 5, label: "妻" },
            { value: 6, label: "夫" },
            { value: 7, label: "祖父" },
            { value: 8, label: "祖母" },
            { value: 9, label: "義父" },
            { value: 10, label: "義母" },
            { value: 11, label: "兄" },
            { value: 12, label: "姉" },
            { value: 13, label: "弟" },
            { value: 14, label: "妹" },
            { value: 20, label: "叔父" },
            { value: 21, label: "叔母" },
            { value: 22, label: "婚約者" },
            { value: 23, label: "その他" },
        ]
        // //お引越し理由
        // this.moveReasonData = [
        //     { value: "", label: "選択してください" },
        //     { value: "8", label: "結婚" },
        //     { value: "7", label: "転勤" },
        //     { value: "6", label: "入社" },
        //     { value: "4", label: "入学" },
        //     { value: "2", label: "独立" },
        //     { value: "11", label: "家賃を抑えたい" },
        //     { value: "12", label: "手狭になった" },
        //     { value: "3", label: "その他" },
        // ]

        this.state = {
            customerinfo: this.props.forecasts,
            startDate: new Date(),
            radio: {},
            setDog: false,
            setCat: false,
            change: false,
            error: { name1: true, name2: true, kana1: true, kana2: true, tel1: true },
            error_kikkake: true,
            checkedList: [],
        };
        // this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        // this.onChangeWeb = this.onChangeWeb.bind(this);
        // this.onChangeMagazine = this.onChangeMagazine.bind(this);
        // this.onChangeDog = this.onChangeDog.bind(this);
        // this.onChangeCat = this.onChangeCat.bind(this);
        // this.on_change_privacy_policy = this.on_change_privacy_policy.bind(this);

        // this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる
    }

    // handleFormSubmit(event) {
    //     event.preventDefault();
    //     console.log(event)
    //     // form値取得
    //     console.log(this.props.forecasts);
    //     // form値取得
    //     //const params = {
    //     //    name1: event.target.name1.value,
    //     //    kana1: event.target.kana1.value,
    //     //};

    //     //alert(JSON.stringify(params, null, '  '));
    // }

    onChange(e) {
        // console.log(e)

        let rent_contracts_renter = this.props.rent_contracts_renter

        rent_contracts_renter[this.props.renter_key][e.target.name] = e.target.value
 
        
        

        this.props.change_rent_contracts_renter(rent_contracts_renter)

        this.props.get_rent_contracts_renter_form_check()
        this.setState({ change: !this.state.change });

    }


    componentDidMount() {
        // console.log(this.props.comingdetail)
        //   this.props.initialize(this.props.comingdetail);//フォームに初期値を反映させる

        // This method is called when the component is first added to the document
        //this.ensureDataFetched();

    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }

    // on_change_privacy_policy() {

    //     let card_details = this.props.card_details
    //     card_details.privacy_policy = card_details.privacy_policy ? !card_details.privacy_policy : true



    //     this.props.change_card_details(card_details)
    //     this.setState({ change: !this.state.change });
    // }

    handleChange = event => {
        this.setState({ change: !this.state.change });
    };


    render() {
        return (
            <div>
                <Name props={this.props} handleChange={this.handleChange} />
                <div className="row">
                    <div className="col-md-3">生年月日</div>
                    <div className="form-group col-md-9">
                        <div className="row">
                            <div className="col-md-4 col-9 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }}>
                                    <InputLabel>年</InputLabel>
                                    <Select
                                        name="birthday_year"
                                        id="birthday_year"
                                        value={this.props.renter.birthday_year}
                                        onChange={this.onChange}
                                        label="年"
                                    >
                                        {this.year.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }}>
                                    <InputLabel>月</InputLabel>
                                    <Select
                                        name="birthday_month"
                                        id="birthday_month"
                                        value={this.props.renter.birthday_month}
                                        onChange={this.onChange}
                                        label="月"
                                    >
                                        {this.month.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                                {/* <span style={{verticalAlign:"bottom"}}>月</span> */}
                            </div>
                            <div className="col-md-2 col-4 pr-0">
                                <FormControl variant="outlined" margin="dense" style={{ width: "100%" }}>
                                    <InputLabel>日</InputLabel>
                                    <Select
                                        name="birthday_day"
                                        id="birthday_day"
                                        value={this.props.renter.birthday_day}
                                        onChange={this.onChange}
                                        label="日"
                                    >
                                        {this.day.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">性別</div>
                    <div className="form-group col-md-9">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="sex" value={String(this.props.renter.sex)} onChange={this.onChange} row>
                                <FormControlLabel control={<Radio color="primary" value={"1"} />} label="男性" labelPlacement="end" />
                                <FormControlLabel control={<Radio color="primary" value={"0"} />} label="女性" labelPlacement="end" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">続柄</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-sm-5">
                                <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} error={!this.props.renter.rent_m_relation_id} required={true}>
                                    <InputLabel>続柄</InputLabel>
                                    <Select
                                        name="rent_m_relation_id"
                                        id="rent_m_relation_id"
                                        value={parseInt(this.props.renter.rent_m_relation_id)}
                                        onChange={this.onChange}
                                        label="続柄"
                                    >
                                        {this.relationship.map(d =>
                                            <MenuItem value={d.value}>{d.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="col-sm-5" style={{ display: parseInt(this.props.renter.rent_m_relation_id) === 23 ? '' : 'none' }}>
                                <TextField
                                    id="affiliation"
                                    name="affiliation"
                                    label="続柄　その他"
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    autoComplete='off'
                                    value={this.props.renter.affiliation}
                                    error={!this.props.renter.affiliation}
                                    onChange={this.onChange}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.renter_key == 0 ? <div className="row">
                    <div className="col-md-3">現住所</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="post"
                                    name="post"
                                    label={"郵便番号"}
                                    margin="dense"
                                    variant="outlined"
                                    value={this.props.renter.post}
                                    style={{ width: "50%" }}
                                    autoComplete='off'
                                    onChange={this.onChange}
                                    placeholder="例）7610000"
                                />
                                <button style={{ marginLeft: "10px" }} className="btn btn-sm btn-success mt-3" type="button" onClick={this.props.get_zip_address_renter}>住所検索</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="add1"
                                    name="add1"
                                    label={"現住所"}
                                    margin="dense"
                                    variant="outlined"
                                    value={this.props.renter.add1}
                                    style={{ width: "100%" }}
                                    autoComplete='off'
                                    onChange={this.onChange}
                                    helperText={this.props.renter_key == 0 ? "お引越し前のご住所をご入力ください。" : ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>:""}
                <div className="row">
                    <div className="col-md-3">TEL（携帯）</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="tel1"
                                    name="tel1"
                                    label="TEL"
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    autoComplete='off'
                                    error={!this.props.renter.tel1}
                                    value={this.props.renter.tel1}
                                    onChange={this.onChange}
                                    required={true}
                                    placeholder="例）090-1234-5678"
                                    helperText="半角数字のみで入力してください。 -(ﾊｲﾌﾝ)入力必要"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">勤務先・学校名</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="office_name"
                                    name="office_name"
                                    label={"勤務先・学校名"}
                                    margin="dense"
                                    variant="outlined"
                                    value={this.props.renter.office_name}
                                    style={{ width: "100%" }}
                                    autoComplete='off'
                                    onChange={this.onChange}
                                    helperText={this.props.renter_key == 0 ? "お引越し後の勤務先・学校名をご入力ください。" : ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.renter_key == 0 ? <div className="row">
                    <div className="col-md-3">勤務先TEL</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-md-10">
                                <TextField
                                    id="office_tel"
                                    name="office_tel"
                                    label="勤務先TEL"
                                    margin="dense"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    autoComplete='off'
                                    // error={!this.props.renter.tel1}
                                    value={this.props.renter.office_tel}
                                    onChange={this.onChange}
                                    // required={true}
                                    placeholder="例）090-1234-5678"
                                    helperText="半角数字のみで入力してください。 -(ﾊｲﾌﾝ)入力必要"
                                />
                            </div>
                        </div>
                    </div>
                </div>:""}
                {this.props.renter_key == 0 ? <div className="row">
                    <div className="col-md-3">業種</div>
                    <div className="col-md-9 form-group">
                        <div className="row">
                            <div className="col-sm-5">
                                <FormControl variant="outlined" margin="dense" style={{ width: "90%" }} >
                                    <InputLabel>業種</InputLabel>
                                    <Select
                                        name="rent_m_job_category_id"
                                        id="rent_m_job_category_id"
                                        value={parseInt(this.props.renter.rent_m_job_category_id)}
                                        onChange={this.onChange}
                                        label="業種"
                                    >
                                        {this.props.rent_m_job_categorys.map(d =>
                                            <MenuItem value={d.id}>{d.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>:""}


                {/* {this.props.renter.name1_1 != "" && 
                this.props.renter.name1_2 != "" && 
                this.props.renter.kana1_1 != "" && 
                this.props.renter.kana1_2 != "" && 
                this.props.renter.rent_m_relation_id != "" && 
                this.props.renter.tel1 != "" ? <div id="footer-submit_button" className="pt-3 pb-3">
                    <button className="form-group btn btn-primary mx-auto d-block" onClick={this.props.update_card_details}>{this.props.renter_key}登録する</button>
                </div> : <div id="footer-submit_button" className="pt-3 pb-3">
                    <Alert severity="error">未入力の項目があります。</Alert>
                </div>} */}
            </div>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        // card_details: state.Card.card_details,
        rent_contracts_renter: state.Card.rent_contracts_renter,
        rent_m_job_categorys: state.Card.rent_m_job_categorys,
        // rent_contracts_renter_form_check: state.Card.rent_contracts_renter_form_check,

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_card_details(state) {
        //     dispatch(Card.change_card_details(state))
        // },
        get_zip_address_renter(state) {
            dispatch(Card.get_zip_address_renter(state))
        },
        // update_card_details(state) {
        //     dispatch(Card.update_card_details(state))
        // },
        change_rent_contracts_renter(state) {
            dispatch(Card.change_rent_contracts_renter(state))
        },
        change_rent_contracts_renter_form_check(state) {
            dispatch(Card.change_rent_contracts_renter_form_check(state))
        },
        get_rent_contracts_renter_form_check(state) {
            dispatch(Card.get_rent_contracts_renter_form_check(state))
        },

        // update_mypage_request(state) {
        //     dispatch(Card.update_mypage_request(state))
        // },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}


// export default ComingFormComponent_all = connect(
//     mapStateToProps, mapDispatchToProps
// )(ComingFormComponent_all);
ApplicationFormComponent = connect(
    mapStateToProps, mapDispatchToProps
)(ApplicationFormComponent);

ApplicationFormComponent = reduxForm({
    form: 'tenantform', // a unique identifier for this form
    validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(ApplicationFormComponent)

export default ApplicationFormComponent