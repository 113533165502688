const authentication = require('react-azure-adb2c').default;








export const detail = (values: any) => {


    // const url = process.env.REACT_APP_API_OPENROOM_URL+`/api/MypageRequest/`+values;
    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/responses/joukendetail/`+values;
    // const url = `http://localhost:5000/v1/rent/responses/joukendetail/`+values;



        const token = authentication.getAccessToken();

            return  fetch(url, {
            method: 'get',
            // body: JSON.stringify(search),

            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
        .then(payload => ({ payload }))
            .catch(error => ({ error }));

};



export const update = (values: any) => {

    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/responses/joukensettei/`+ values.cd
    // const url = `http://localhost:5000/v1/rent/responses/joukensettei/`+ values.cd

    // const url = process.env.REACT_APP_API_OPENROOM_URL+`/api/MypageRequest/`+values.responseCode;


// alert(url)

        const token = authentication.getAccessToken();

            return  fetch(url, {
            method: 'put',
            body: JSON.stringify(values.request),

            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
        .then(payload => ({ payload }))
            .catch(error => ({ error }));

};