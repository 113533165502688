import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';


class RentLoading extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

      return (
        <div>
            {/* ローディング */}
            <div style={{width:"100%"}}><LinearProgress /></div>
        </div>
    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
    }
}
function mapDispatchToProps(dispatch) {
    return {
        firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        },
        loadTodos(state) {
        //    dispatch(loadTodos(state))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RentLoading);