import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import './Css/Transfer.css';
import Layout from '../Layout/LayoutComponent';
import Alert from '@material-ui/lab/Alert';
import RentHeaderComponent from '../Layout/RentHeaderComponent';

class TransferCancelComponent extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
        };

    }

    render() {
        return (
            <div className="app" style={{ height: "100%" }}>
                <RentHeaderComponent />
                <div style={{ minHeight: "100%", backgroundColor: "whitesmoke" }}>
                    <div className="display_style">
                        <div className="container">
                            <div className="frame" style={{ height: 300 }}>
                                <Alert severity="error" style={{ margin: 30, fontSize: 20, height: 200 }}>お取引がキャンセルされました<br/>エラーが発生した場合、店舗までご連絡ください。</Alert>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        line_code: state.Line.line_code,
        line_limit: state.Line.line_limit,
        line_section_id: state.Line.line_section_id,
    }
}

TransferCancelComponent = connect(
    mapStateToProps
)(TransferCancelComponent);

TransferCancelComponent = reduxForm({
})(TransferCancelComponent)

export default TransferCancelComponent