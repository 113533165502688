import React, { Component } from 'react';
import { connect } from 'react-redux';


class NoPageComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }; 
    }


    render() {
       
    return (
        <div className="height_style">
            <h5>このページはありません。</h5>
        </div> 
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {


    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoPageComponent);
