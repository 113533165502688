import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
// import * as MypageRequestApi from '../Api/MypageRequestApi';
import * as transfer_api from '../Api/transfer_api';

export interface TransferState {
    // line_detail: any;
    // transfer_comp: boolean;
    // transfer_code: any;
    // transfer_section_id: any;
    // transfer_limit: any;
    transfer_detail: any;
    transfer_pass: string;
    transfer_cd: string;
    // search_rooms_detail:any;
}
const initialState: TransferState = {

    // transfer_section_id: 0,
    // transfer_comp: false,
    // transfer_code: null,
    // transfer_limit: null,
    transfer_detail: [],
    transfer_pass: "",
    transfer_cd: ""
    // search_rooms_detail: [],
}



///action 
const actionCreator = actionCreatorFactory();

export const transfer_login = actionCreator('TRANSFER_LOGIN');
export const change_transfer_detail = actionCreator<any>('CHANGE_TRANSFER_DETAIL');
export const change_transfer_pass = actionCreator<string>('CHANGE_TRANSFER_PASS');
export const change_transfer_cd = actionCreator<string>('CHANGE_TRANSFER_CD');

// export const change_line_detail = actionCreator<any>("CHANGE_LINE_DETAIL");
// export const update_mypage_request = actionCreator<any>('UPDATE_MYPAGE_REQUEST');
// export const change_line_comp = actionCreator<any>("CHANGE_LINE_COMP");

// export const get_line_code = actionCreator<any>("GET_LINE_CODE");
// export const change_line_code = actionCreator<any>("CHANGE_LINE_CODE");
// export const change_line_section_id = actionCreator<any>("CHANGE_LINE_SECTION_ID");

// export const change_line_limit = actionCreator<any>("CHANGE_LINE_LIMIT");
// export const get_search_rooms_detail= actionCreator('GET_SEARCH_ROOMS_DETAIL');
// export const get_search_rooms_detail_success = actionCreator("GET_SEARCH_ROOMS_DETAIL_SUCCESS");


export const transferReducer = reducerWithInitialState(initialState)
    .case(change_transfer_detail, (state, payload) => ({ ...state, transfer_detail: payload }))
    .case(change_transfer_pass, (state, payload) => ({ ...state, transfer_pass: payload }))
    .case(change_transfer_cd, (state, payload) => ({ ...state, transfer_cd: payload }))
// .case(change_line_comp, (state, payload) => ({ ...state, line_comp: payload }))

// .case(change_line_code, (state, payload) => ({ ...state, line_code: payload }))
// .case(change_line_limit, (state, payload) => ({ ...state, line_limit: payload }))
// .case(get_search_rooms_detail_success, (state, payload) => ({ ...state, search_rooms_detal: payload }))
// .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))

// .case(get_response_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

// .case(change_showRight, (state, payload) => ({ ...state, showRight: payload }))
// .case(change_showFlag, (state, payload) => ({ ...state, showFlag: payload }))
// .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))

// .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }));








// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const transferSaga = [
    takeEvery('TRANSFER_LOGIN', handle_transfer_login),
    // takeEvery('UPDATE_MYPAGE_REQUEST', handle_update_mypage_request),
    // takeEvery('GET_LINE_CODE', handle_get_line_code),

    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_transfer_login(action: any) {
    const state = yield select();



    const { payload, error } = yield call(transfer_api.login, { cd: state.Transfer.transfer_cd, pass: state.Transfer.transfer_pass });
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        yield put(change_transfer_detail(payload));

        // yield put(get_search_rooms_success(payload));


    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


// function* handle_update_mypage_request(action: any) {
//     const state = yield select();

//     const { payload, error } = yield call(MypageRequestApi.update, state.Line.mypage_request);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         console.log(payload)

//         // const setsubis = payload.map((item:any, i:number) => ({
//         //     value: item.setsubiNo,
//         //     label: item.setsubiName,
//         //   }))

//         // 成功時アクション呼び出し


//         yield put(change_line_comp(true));
//         //    yield put(get_search_rooms_detail_success(payload));

//     } else {
//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }


// function* handle_get_line_code(action: any) {
//     const state = yield select();

//     const { payload, error } = yield call(line_auth_api.auth, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         console.log(payload)
//         // const setsubis = payload.map((item:any, i:number) => ({
//         //     value: item.setsubiNo,
//         //     label: item.setsubiName,
//         //   }))

//         // 成功時アクション呼び出し


//         yield put(change_line_code(payload.code));
//         yield put(change_line_section_id(payload.section_id));
//         yield put(change_line_limit(payload.limited_at));
//         //    yield put(get_search_rooms_detail_success(payload));

//     } else {
//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }
