const authentication = require('react-azure-adb2c').default;


export const detail = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/contracts/detail`;
    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/contracts/detail`;

    // alert(url)

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update = (value: any) => {
    // alert( JSON.stringify(value))

    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/contracts/update/`+ value.rent_contracts.contract_code;
    // const url = `http://localhost:5000/v1/rent/contracts/update/`+ value.rent_contracts.contract_code;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};