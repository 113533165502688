import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as Reserve from './Saga/Reserve';

import "react-datepicker/dist/react-datepicker.css";
// import DateComponent from './DateComponent';
import * as Master from '../../Store/Master';
import './Css/Table.css';
// import TimeStateComponent from './TimeStateComponent';

// import { Field, reduxForm } from 'redux-form';
import './Css/Reserve.css';
import Layout from '../Layout/LayoutReserveComponent';
import ReserveRequest from './ReserveRequestComponent';
import Confirmation from './ConfirmationComponent';
import Completion from './CompletionComponent';
import Loading from './LoadingComponent';
import SubmitError from './SubmitErrorComponent';
import ReserveContent from './ReserveContentComponent';
import NoPage from './NoPageComponent';
import ReserveVisit from './ReserveVisitComponent';
// import ReserverInfo from './ReserverInfoComponent';
import ReserverChange from './ReserveContentChangeComponent';



import Button from '@material-ui/core/Button';

import moment from "moment";
import { Row } from 'reactstrap';

class Reserved extends Component {

    constructor(props) {
        super(props);
        this.state = {
            flg: 8,
            tel1_flg: 1,
            raitenChage:null,
            change_num:null,
            tid:"",
            rid:"",
        };
        this.props.change_cd({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        this.changeFlg = this.changeFlg.bind(this);
        this.checktel = this.checktel.bind(this);
        // this.props.get_raiten()
        this.props.get_shop_tel()
        // this.changeRaiten = this.changeRaiten.bind(this);
    }



    componentDidMount() {
        // ページ読み込み時の処理をここに書く
        console.log(this.props.reserve_flg,"reserve_flg")
        this.props.change_reserve_flg(1)

        this.props.get_raiten()

        //リンクの最後のほうにあるParamsを取るとこ？
        const { location } = this.props;
        const queryParams = new URLSearchParams(location.search);
        const tid = queryParams.get('tid');
        const rid = queryParams.get('rid');

        this.setState({tid:tid})
        this.setState({rid:rid})

        if(tid && rid){
            this.props.get_rooms_detail({ tid: tid, rid: rid });
        }
    }

    
    

    componentDidUpdate() {

    }

    ensureDataFetched() {

    }

    // changeFlg() {
    //     this.setState({flg:2})
    // }

    changeFlg(num) {
        this.setState({ flg: num })
        if (num === 4) {
            this.props.send_reserve()
        } else if (num === 6) {
            this.props.send_reserve()
        }
        if (num === 2) {

            if (!this.props.reserve.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !this.props.reserve.tel1.match(/^\d{7,13}$/)) {
                // alert("電話番号の形式が正しくありません。");
                this.setState({ flg: 1 })
                this.setState({ tel1_flg: 2 })
            } else {
                this.setState({ tel1_flg: 1 })
            }



        }
    }
    checktel(e) {
        //    alert(e.target.value);


        if (!e.target.value.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !e.target.value.match(/^\d{7,13}$/)) {
            this.setState({ tel1_flg: 2 })
        } else {
            this.setState({ tel1_flg: 1 })
        }

    }

    formatDate(date){
        moment.locale("ja", {
            weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
            weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
        });
        return moment(date).utc().format('YYYY年MM月DD日(ddd) HH時')
    }

    changeRaiten(num,event){
        console.log(event,"aaa")
        this.setState({change_num: num })
        this.setState({raitenChage: event })
        // this.setState({flg: 6 })
    }

    render() {
        // console.log(this.props.raiten.length,"length")
        return (
            <Layout>
                <div className="container">
                    <div className="reserve_style">
                        {/* {console.log("****************"+this.state.flg)} */}

                        {/* 来店予約の時間出す所 */}
                        {this.props.raiten && this.props.raiten.length > 0 && !this.state.change_num ? 
                        <div className="container" style={{fontFamily:"Noto Sans JP,sans-serif"}}>
                            <div style={{ borderBottom: "2px solid #ff9429", fontWeight: "bold", marginBottom: "5px", paddingTop: "20px", paddingBottom: "5px",fontFamily:"Noto Sans JP,sans-serif" }}>
                                <h3 className='Titles'>来店予約</h3>
                            
                                <div style={{fontSize:"15px",paddingTop:"10px"}}>
                                    {this.props.raiten.map((d,index) =>
                                    <div className={(this.props.raiten.length - 1 === index) ? "RaitenS last" :"RaitenS"}>
                                        <div style={{marginBottom:0}}>
                                            {this.formatDate(d.start_at)}に来店予定です。<br/>
                                            {d.tatemono_name?<span>物件名：{d.tatemono_name}　{d.room_name}<br/></span>:""}
                                            ご予約人数：{d.reservations_people}人　
                                            {d.reservations_remarks ? <span>店舗へのご要望・連絡事項：{d.reservations_remarks}</span>:""}
                                        </div>

                                        <div style={{marginLeft:"auto"}}>
                                            <Button style={{background:'#51bcbc',color:'#fff'}} variant="outlined" onClick={() =>this.changeRaiten(1,d)}>変更する</Button>
                                            <Button style={{marginLeft:'10px',background:'#E9573F',color:'#fff'}} variant="outlined" onClick={() =>this.changeRaiten(2,d)}>キャンセル</Button>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                
                            </div>
                            <div style={{marginTop:"20px", marginLeft:"20px", marginRight:"20px"}}>
                                <h6 style={{marginTop:"10px", fontWeight:"normal",fontFamily:"Noto Sans JP,sans-serif"}}>時間に遅れそうな際、道が分からず迷われた際、お気軽にお電話ください。</h6>
                                {this.props.shop_tel?<div className="confirm_btn_style" style={{marginTop:20}}>
                                    <a style={{background:"rgb(74, 192, 240)",fontFamily:"Noto Sans JP,sans-serif"}} className="btn btn-primary btn-shadow" href={"tel:"+this.props.shop_tel[0].tel}><i class="fas fa-phone"></i> 電話する</a>
                                </div>:""}
                            </div>
                        </div>
                        :""}
                        
                        {this.state.raitenChage?
                        <ReserverChange num={this.state.change_num} list={this.state.raitenChage}/>
                        :""} 
                        

                        {/* {this.state.flg===1 ? <ReserveRequest/> : 2 ? <Confirmation InfoMethod={(i)=>this.changeFlg(i)}/> : 3 ? <Completion/> : ""} */}
                        {this.state.flg === 1 ? <ReserveRequest tel1_flg={this.state.tel1_flg} checktel={this.checktel} /> : ""}
                        {this.state.flg === 2 ? <Confirmation InfoMethod={(i) => this.changeFlg(i)} /> : ""}
                        {this.state.flg === 3 ? <Loading /> : ""}
                        {this.state.flg === 4 ? <Completion /> : ""}
                        {this.state.flg === 5 ? <SubmitError /> : ""}
                        {this.state.flg === 6 ? <ReserveContent /> : ""}
                        {this.state.flg === 7 ? <NoPage /> : ""}
                        {/* Object.keys(this.props.raiten).length */}
                        {this.state.flg === 8 && this.props.raiten && this.props.raiten.length < 1 && !this.state.tid ? <ReserveVisit /> : ""}
                        {this.state.flg === 8 && this.props.raiten && this.props.raiten.length < 1 && this.state.tid ? <ReserveVisit tatemono={this.props.tatemono} tid={this.state.tid}/>:""}
                        {/* {this.state.tid ? <ReserveVisit tatemono={this.props.tatemono} tid={this.state.tid}/>:""} */}
                        {/* {this.state.flg === 8 ? <ReserveVisit/> : ""} */}
                        {this.state.flg === 9 ? <NoPage /> : ""}
                        {this.state.flg === 1 ?
                            <div >
                                <button className="form-group btn btn-primary mx-auto d-block" style={{ marginTop: 10, marginBottom: 50 }} onClick={() => this.changeFlg(2)}>予約する</button>
                                {/* <button className="form-group btn btn-primary mx-auto d-block" style={{marginTop:10}} onClick={()=>this.changeFlg(8)}>来店予約</button> */}
                            </div>
                            : ""}
                    </div>
                </div>
            </Layout>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        shop_tel: state.Reserve.shop_tel,
        raiten: state.Reserve.raiten,
        reserve: state.Reserve.reserve,
        tatemono: state.Reserve.tatemono,
        reserve_flg: state.Reserve.reserve_flg,
        // setsubi_master: state.roomsReducer.setsubi_maskter,

        // chomei_list: state.search.chomei_list,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_raiten(state) {
            dispatch(Reserve.get_raiten(state))
        },
        get_shop_tel(state) {
            dispatch(Reserve.get_shop_tel(state))
        },
        change_cd(state) {
            dispatch(Master.change_cd(state))
        },
        send_reserve(state) {
            dispatch(Reserve.send_reserve(state))
        },
        change_reserve(state) {
            dispatch(Reserve.change_reserve(state))
        },
        get_rooms_detail(state) {
            dispatch(Reserve.get_rooms_detail(state))
        },
        change_reserve_flg(state) {
            dispatch(Reserve.change_reserve_flg(state))
        },

    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Reserved);