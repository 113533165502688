import React from 'react';
//import { Container } from 'reactstrap';
// import Header from './HeaderComponent';
// import LeftSide from './LeftSideComponent';

import { connect } from 'react-redux';
import './Css/icomoon/style.css';
import './Css/drawer.min.css';
// import '../../Css/Site.css';
import './Css/fontawesome/css/all.min.css';



// import LimitComponent from "./LimitComponent";
// import {Modal } from 'reactstrap';
import RentFooterComponent from "./RentFooterComponent";

import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RentHeaderComponent from './RentHeaderComponent';

interface Props {

  // user_details: any,
  // shops:any,
  // // mail_new:number,
  // limitmodal:boolean,

}

interface State {

  
  // state types
}


class LayoutComponent extends React.Component<Props, State >  {
  constructor(props: Readonly<Props>) {
    super(props)
    this.state = {

      
    }
  }

  notify = () => toast("Wow so easy !");
  
  render() {
  return (
    <div className="app" style={{height:"100%"}}> 
        {/* <Header user_details={this.props.user_details}　shops={this.props.shops}/>
        <LeftSide /> */}
        <RentHeaderComponent style={{fontFamily:"Noto Sans JP"}}/>
     
        <div style={{minHeight:"100%", backgroundColor:"whitesmoke",fontFamily:"Noto Sans JP"}}>
        {this.props.children}
        </div>
        {/* <RentFooterComponent /> */}
    </div>
    )
  }
  };
//コンテナ
const mapStateToProps = (state:any) => {
  return {
   
      // user_details: state.masterReducer.user_details,
      // shops: state.masterReducer.shops,
      // mail_new: state.mailReducer.mail_new,
      // limitmodal:state.commonReducer.limitmodal,
  }
}
function mapDispatchToProps(dispatch:any) {
  return {    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);