import React, { Component } from 'react';
import { connect } from 'react-redux';
import PublicDocumentComp from './PublicDocumentCompComponent';
import * as Master from '../../Store/Master';
import * as PublicDocument from './Saga/PublicDocument';
import RentHeaderComponent from '../Layout/RentHeaderComponent';
class PublicDocumentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comp: false
        };

        // this.props.change_download_comp(false)


        // this.props.change_cd({cd:this.props.match.params.cd,no:this.props.match.params.no})


        this.props.get_rent_limited_public_docs_list(this.props.rent_limited_public_pages_cd)
        this.handlesubmit = this.handlesubmit.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handlesubmit(state) {
        // console.log(state)
        // alert()
        this.setState({ comp: true })
    }

    render() {

        return (
            <div style={{ backgroundColor: "whitesmoke" }}>
                <RentHeaderComponent />
                <div className="container">
                    <PublicDocumentComp />
                </div>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {

        // download_comp: state.Download.download_comp,
        rent_limited_public_pages_cd: state.PublicDocument.rent_limited_public_pages_cd,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_cd(state) {
        //     dispatch(Master.change_cd(state))
        // },
        get_rent_limited_public_docs_list(state) {
            dispatch(PublicDocument.get_rent_limited_public_docs_list(state))
        },
        // change_download_comp(state) {
        //     dispatch(PublicDocument.change_download_comp(state))
        // },

        //        customeredit1change(state) {
        //            dispatch(customeredit1change(state))
        //        },
        //customeredit2change(state) {
        //        dispatch(customeredit2change(state))
        //    },
        //        responseeditchange(state) {
        //            dispatch(responseeditchange(state))
        //        },
        //        customerdetailData1(state) {
        //            dispatch(customerdetailData1(state))
        //        }, responsedetailData(state) {
        //            dispatch(responsedetailData(state))
        //        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicDocumentComponent);
