
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';



import { call, takeEvery, select, put } from 'redux-saga/effects';
import * as MypageSendMailApi from '../Api/MypageSendMailApi';
import * as ReserveApi from '../Api/ReserveApi';

import * as rent_customer_contacts_api from '../Api/rent_customer_contacts_api';
import moment from 'moment';
export interface SearchState {
    get_chomei: any;
    chomei_list: any;
    reserve: any;
    schedule: any;
    reserve_date: any;
    reserve_flg: number;
    reserve_send_loading: boolean;
    cancel_loading: boolean;
    tatemono: any;
    raiten_date: any;
}
const dt: any = new Date();
dt.setDate(dt.getDate() + 1)
const initialState: SearchState = {
    get_chomei: [],
    chomei_list: [],
    reserve: {
        reservationsPeople: "1",
        reservationsTime: "1",
        date: dt,
        tel1: "",
        requestRemarks: "",
        cd: "",
        no: ""
    },
    schedule: [],
    reserve_date: null,
    tatemono:[],
    cancel_loading:false,
    reserve_flg: 1,
    reserve_send_loading: false,
    raiten_date:[],
    
}



///action 
const actionCreator = actionCreatorFactory();

export const get_chomei = actionCreator('GET_CHOMEI');
export const get_chomei_success = actionCreator("GET_CHOMEI_SUCCESS");
export const get_chomei_failure = actionCreator("GET_CHOMEI_FAILURE");

export const get_rooms_detail = actionCreator('GET_ROOMS_DETAIL');
export const change_rooms_detail = actionCreator('CHANGE_ROOMS_DETAIL');


export const change_reserve = actionCreator("CHANGE_RSERVE");


export const send_reserve = actionCreator("SEND_RSERVE");
export const get_reserve = actionCreator<any>('GET_RSERVE');

export const send_reserve_coming = actionCreator("SEND_RSERVE_COMING");



export const update_send_reserve = actionCreator("UPDATE_SEND_RSERVE");

export const cancel_send_reserve = actionCreator("CANCEL_SEND_RSERVE");

export const get_schedule = actionCreator<any>('GET_SCHEDULE');
export const change_schedule = actionCreator<any>('CHANGE_SCHEDULE');

export const get_shop_tel = actionCreator<any>('GET_SHOP_TEL');
export const change_shop_tel = actionCreator<any>('CHANGE_SHOP_TEL');


export const get_raiten = actionCreator<any>('GET_RAITEN');
export const change_raiten = actionCreator<any>('CHANGE_RAITEN');

export const change_reserve_date = actionCreator<any>('CHANGE_RESERVE_DATE');

export const change_reserve_flg = actionCreator<number>('CHANGE_RESERVE_FLG');

export const change_reserve_send_loading = actionCreator<boolean>('CHANGE_RESERVE_SEND_LOADING');

export const change_cancel_loading = actionCreator<boolean>('CHANGE_CANCEL_LOADING');

export const change_raiten_date = actionCreator<number>('CHANGE_RAITEN_DATE');



// export  get_t_shutokus = actionCreator('GET_T_SHUTOKUS');
// export const get_t_shutokus_success = actionCreator("GET_T_SHUTOKUS_SUCCESS");
// export const get_t_shutokus_failure = actionCreator("GET_T_SHUTOKUS_FAILURE");

// export const delete_rent_customer_contacts = actionCreator<any>('DELETE_RENT_CUSTOMER_CONTACTS');

// export const update_rent_customer_contacts = actionCreator<any>('UPDATE_RENT_CUSTOMER_CONTACTS');
// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const reserveReducer = reducerWithInitialState(initialState)
    .case(change_rooms_detail, (state, payload) => ({ ...state, tatemono: payload }))


    .case(get_chomei_success, (state, payload) => ({ ...state, chomei_list: payload }))
    .case(change_reserve, (state, payload) => ({ ...state, reserve: payload }))
    .case(change_schedule, (state, payload) => ({ ...state, schedule: payload }))

    .case(change_shop_tel, (state, payload) => ({ ...state, shop_tel: payload }))

    .case(change_raiten, (state, payload) => ({ ...state, raiten: payload }))

    .case(change_reserve_date, (state, payload) => ({ ...state, reserve_date: payload }))
    .case(change_reserve_flg, (state, payload) => ({ ...state, reserve_flg: payload }))
    .case(change_reserve_send_loading, (state, payload) => ({ ...state, reserve_send_loading: payload }))
    
    .case(change_cancel_loading, (state, payload) => ({ ...state, cancel_loading: payload }))

    .case(change_raiten_date, (state, payload) => ({ ...state, raiten_date: payload }))
    



// .case(get_t_shutokus_success, (state, payload) => ({ ...state, t_shutokus: payload }));

//.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
// .case(add_rent_customer_contacts, (state, payload) => ({ ...state, users: payload }));
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const reserveSaga = [
    takeEvery('SEND_RSERVE', handle_send_reserve),
    takeEvery('SEND_RSERVE_COMING', handle_send_reserve_coming),
    
    takeEvery('UPDATE_SEND_RSERVE', handle_update_send_reserve),

    takeEvery('CANCEL_SEND_RSERVE', handle_cancel_send_reserve),
    takeEvery('GET_SCHEDULE', handle_get_schedule),
    takeEvery('GET_RSERVE', handle_get_reserve),

    takeEvery('GET_RAITEN', handle_get_raiten),

    takeEvery('GET_SHOP_TEL', handle_get_shop_tel),

    takeEvery('GET_ROOMS_DETAIL', handle_get_rooms_detail),
    
    


    // takeEvery('GET_T_SHUTOKUS', handle_get_t_shutokus),
    // takeEvery('UPDATE_RENT_CUSTOMER_CONTACTS', handle_update_rent_customer_contacts_Request),
    // takeEvery('DELETE_RENT_CUSTOMER_CONTACTS', handle_delete_rent_customer_contacts_Request),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_rooms_detail(action: any): any {
    const { payload, error } = yield call(ReserveApi.Tatemono, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload,"payload")
        yield put(change_rooms_detail(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_get_shop_tel(action: any): any {
    const state = yield select();

    const { payload, error } = yield call(ReserveApi.shop, state.masterReducer.cd.cd);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)

        yield put(change_shop_tel(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_get_raiten(action: any): any {
    const state = yield select();
    let reserve = state.Reserve.reserve
    reserve.cd = state.masterReducer.cd.cd
    reserve.no = state.masterReducer.cd.no

    const { payload, error } = yield call(ReserveApi.raiten, reserve);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        yield put(change_raiten(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_send_reserve(action: any): any {
    const state = yield select();


    yield put(change_reserve_send_loading(true));
    let reserve = state.Reserve.reserve
    let reserve_date = state.Reserve.reserve_date
    // alert(reserve_date.time)
    reserve.cd = state.masterReducer.cd.cd
    reserve.no = state.masterReducer.cd.no
    if (reserve_date.time === 1) {
        reserve.reservationsTime = 1
    } else if (reserve_date.time === 2) {
        reserve.reservationsTime = 7
    } else if (reserve_date.time === 3) {
        reserve.reservationsTime = 11
    }
    reserve.date = moment(reserve_date.date).format('YYYY/MM/DD')


    const { payload, error } = yield call(MypageSendMailApi.send, reserve);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_raiten_date(payload));
       
        yield put(change_reserve_flg(6));
        yield put(change_reserve_send_loading(false));
        // yield put(get_chomei_success(payload));
        yield call(rent_customer_contacts_api.autodelete, reserve.cd);

        //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_send_reserve_coming(action: any): any {
    const state = yield select();


    yield put(change_reserve_send_loading(true));
    let reserve = state.Reserve.reserve
    let reserve_date = state.Reserve.reserve_date
    // alert(reserve_date.time)
    reserve.cd = state.masterReducer.cd.cd
    reserve.no = state.masterReducer.cd.no
    reserve.tatemono = action.payload[0]
    if (reserve_date.time === 1) {
        reserve.reservationsTime = 1
    } else if (reserve_date.time === 2) {
        reserve.reservationsTime = 7
    } else if (reserve_date.time === 3) {
        reserve.reservationsTime = 11
    }
    reserve.date = moment(reserve_date.date).format('YYYY/MM/DD')


    const { payload, error } = yield call(MypageSendMailApi.coming, reserve);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_raiten_date(payload));
        yield put(change_reserve_flg(6));
        yield put(change_reserve_send_loading(false));
        // yield put(get_chomei_success(payload));
        yield call(rent_customer_contacts_api.autodelete, reserve.cd);

        //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_update_send_reserve(action: any): any {
    console.log(action.payload,"来店のID")
    const state = yield select();

    yield put(change_reserve_send_loading(true));
    let reserve = state.Reserve.reserve
    let reserve_date = state.Reserve.reserve_date

    reserve.raiten_id = action.payload.id //元々のID
    reserve.raiten_list = action.payload
    reserve.cd = state.masterReducer.cd.cd
    reserve.no = state.masterReducer.cd.no
    if (reserve_date.time === 1) {
        reserve.reservationsTime = 1
    } else if (reserve_date.time === 2) {
        reserve.reservationsTime = 7
    } else if (reserve_date.time === 3) {
        reserve.reservationsTime = 11
    }
    reserve.date = moment(reserve_date.date).format('YYYY/MM/DD')

    console.log(reserve,"reserve")

    const { payload, error } = yield call(MypageSendMailApi.updateSend, reserve);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_raiten_date(payload));
        console.log(payload)
        yield put(change_reserve_flg(6));
        yield put(change_reserve_send_loading(false));
        yield call(rent_customer_contacts_api.autodelete, reserve.cd);


    } else {
        // 失敗時アクション呼び出し
    }
}


function* handle_cancel_send_reserve(action: any): any {
    const state = yield select();
    yield put(change_cancel_loading(true));
    let reserve = state.Reserve.reserve
    reserve.cancel_id = action.payload.id //元々のID
    reserve.cancel = action.payload.cancelReason
    reserve.start_at = action.payload.start_at
    reserve.cd = state.masterReducer.cd.cd
    reserve.no = state.masterReducer.cd.no
    moment.locale("ja", {
        weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
        weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
    });
    reserve.date = moment(action.payload.start_at).utc().format('YYYY年MM月DD日(ddd) HH時')

    console.log(reserve,"reserve")

    const { payload, error } = yield call(MypageSendMailApi.cancel, reserve);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        yield put(change_reserve_flg(6));
        yield put(change_cancel_loading(false));
        yield call(rent_customer_contacts_api.autodelete, reserve.cd);


    } else {
        // 失敗時アクション呼び出し
    }
}


function* handle_get_schedule(action: any): any {
    const state = yield select();


    const { payload, error } = yield call(ReserveApi.cal, { date: action.payload ? action.payload : moment().format("YYYY/MM/DD"), cd: state.masterReducer.cd.cd });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        yield put(change_schedule(payload));
        // alert(payload)

        //   yield put(get_rent_customer_contacts_calendar_success(calendar));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_get_reserve(action: any): any {

    const state = yield select();


    const { payload, error } = yield call(ReserveApi.cal, { date: action.payload ? action.payload : moment().format("YYYY/MM/DD"), cd: state.masterReducer.cd.cd });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        yield put(change_schedule(payload));
        // alert(payload)

        //   yield put(get_rent_customer_contacts_calendar_success(calendar));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}