import React, { Component }  from 'react';
import { connect } from 'react-redux';
// import ComingHeaderComponent from './ComingHeaderComponent';
import ComingFormComponent from './ComingFormComponent';
import ComingCompComponent from './ComingCompComponent';
import * as Master from '../../Store/Master';
import * as Coming from './Saga/Coming';
import Layout from '../Layout/LayoutReserveComponent';
class ComingComponent extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            comp:false
    };

    this.props.change_coming_comp(false)
  

    this.props.change_cd({cd:this.props.match.params.cd,no:this.props.match.params.no})


    this.props.get_mypage_request(this.props.match.params.cd)
    this.handlesubmit = this.handlesubmit.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handlesubmit(state){
        // console.log(state)
        // alert()
        this.setState({comp:true})
    }

    render() {
       
        return (
            <Layout>
            <div className="container Comming">
                {/* <ComingHeaderComponent/> */}
                
             {this.props.coming_comp===false?<ComingFormComponent onSubmit={this.handlesubmit}/>:<ComingCompComponent/>}
            </div>
            </Layout>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
  
        coming_comp: state.Coming.coming_comp,


    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_cd(state) {
            dispatch(Master.change_cd(state))
        },
        get_mypage_request(state) {
            dispatch(Coming.get_mypage_request(state))
        },
        change_coming_comp(state) {
            dispatch(Coming.change_coming_comp(state))
        },
        
//        customeredit1change(state) {
//            dispatch(customeredit1change(state))
//        },
//customeredit2change(state) {
//        dispatch(customeredit2change(state))
//    },
//        responseeditchange(state) {
//            dispatch(responseeditchange(state))
//        },
//        customerdetailData1(state) {
//            dispatch(customerdetailData1(state))
//        }, responsedetailData(state) {
//            dispatch(responsedetailData(state))
//        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComingComponent);
