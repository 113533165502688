import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Css/Reserve.css';
import { withRouter } from "react-router";

class SubmitErrorComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }; 
    }


    render() {
       
    return (
        <div className="height_style">
            <h5>メールの送信に失敗しました。</h5>
            <h6 style={{fontWeight:"normal"}}>*************までお問い合わせください。</h6>
            <button className="btn btn-secondary btn-shadow" onClick={()=>this.props.history.push("/Rent/"+this.props.cd.cd+"/"+this.props.cd.no)}> お部屋一覧</button>
        </div>

                            

    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        cd: state.masterReducer.cd,
    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubmitErrorComponent));
