import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Master from '../../Store/Master';
import * as Credit from './Saga/Credit';
import Layout from '../Layout/LayoutComponent';
import './Css/Credit.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RentHeaderComponent from '../Layout/RentHeaderComponent';
import CreditConfirmLoginComponent from './CreditConfirmLoginComponent';
import CreditConfirmComponent from './CreditConfirmComponent';



class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // comp: false
        };

        this.props.change_credit_cd(this.props.match.params.cd)
        // this.props.change_line_comp(false)
        // this.props.change_cd({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        // this.props.get_line_code(this.props.match.params.cd)
        // // this.handlesubmit = this.handlesubmit.bind(this)
        // this.loginClick = this.loginClick.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    loginClick() {

    }
    // handlesubmit(state) {
    //     // console.log(state)
    //     // alert()
    //     this.setState({ comp: true })
    // }

    render() {

        return (
            <div className="app" style={{ height: "100%" }}>
                <RentHeaderComponent />
                {  this.props.credit_detail.id > 0 ? <CreditConfirmComponent /> : <CreditConfirmLoginComponent />}

            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {

        //customerid: ownProps.match.params.id,
        credit_detail: state.Credit.credit_detail,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,

        // line_comp: state.Line.line_comp,
        // line_code: state.Line.line_code,
        // line_section_id: state.Line.line_section_id,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_credit_cd(state) {
            dispatch(Credit.change_credit_cd(state))
        },

        // // get_mypage_request(state) {
        // //     dispatch(Line.get_mypage_request(state))
        // // },
        // change_line_comp(state) {
        //     dispatch(Line.change_line_comp(state))
        // },
        // get_line_code(state) {
        //     dispatch(Line.get_line_code(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
