import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import './Css/Reserve.css';

const reservationsTime = [
    {value:"1", label:"10時00分"},
    {value:"2", label:"10時30分"},
    {value:"3", label:"11時00分"},
    {value:"4", label:"11時30分"},
    {value:"5", label:"12時00分"},
    {value:"6", label:"12時30分"},
    {value:"7", label:"13時00分"},
    {value:"8", label:"13時30分"},
    {value:"9", label:"14時00分"},
    {value:"10", label:"14時30分"},
    {value:"11", label:"15時00分"},
    {value:"12", label:"15時30分"},
    {value:"13", label:"16時00分"},
    {value:"14", label:"16時30分"},
    {value:"15", label:"17時00分"},
    {value:"16", label:"17時30分"},
    {value:"17", label:"18時00分"},
];

class ReserveContentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelReason: "",
            error_flg: 0
        };
        this.checkText = this.checkText.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    // onClickToConfirm = () => {
    // };  
    formatdata(date){
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
     
    return (year + '年' + month + '月' + day + '日')
    }

    handleChange(e){
        this.setState({cancelReason: e.target.value});
        // console.log(e.target)
    }

    checkText(){
        this.state.cancelReason === "" ? 
            this.setState({error_flg:1})
        :
            this.setState({error_flg:2})
    }

    render() {
       
    return (
        <div className="reserve_height">
            <div className="mt-3 mb-5">
                <div className="pl-3 pr-3">
                <h3>ご予約内容</h3>
                <table className="table_style">
                    <tbody>
                        <tr>
                            <th className="yoyaku_title"><i class="fas fa-clock mr-2"></i>ご希望日時</th>
                            <td>
                                {this.props.reserve_date.date ? this.props.reserve_date.date.getMonth()+1 + "/" + this.props.reserve_date.date.getDate() : ""}
                                {this.props.reserve_date.flg && (this.props.reserve_date.flg === "Sun") ? "(日)" : (this.props.reserve_date.flg === "Mon") ? "(月)" : (this.props.reserve_date.flg === "Tue") ? "(火)" : (this.props.reserve_date.flg === "Wed") ? "(水)" : (this.props.reserve_date.flg === "Thu") ? "(木)" : (this.props.reserve_date.flg === "Fri") ? "(金)" : (this.props.reserve_date.flg === "Sat") ? "(土)" : (this.props.reserve_date.flg === "Holi") ? "()" : ""}
                                {this.props.reserve_date.time && (this.props.reserve_date.time === 1) ? "　10時" : (this.props.reserve_date.time === 2) ? "　13時" : (this.props.reserve_date.time === 3) ? "　15時" : ""}
                            </td>
                        </tr>
                        <tr>
                            <th className="yoyaku_title"><i class="fas fa-phone mr-2"></i>電話番号</th>
                            <td>{this.props.reserve.tel1}</td>
                        </tr>
                        <tr>
                            <th className="yoyaku_title"><i class="fas fa-users mr-1"></i>ご予約人数</th>
                            <td>{this.props.reserve.reservationsPeople}人</td>
                        </tr>
                        <tr>
                            <th className="yoyaku_title"><i class="fas fa-edit mr-1"></i>店舗へのご要望・連絡事項</th>
                            <td>{this.props.reserve.requestRemarks}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div style={{marginTop:"20px", marginLeft:"20px", marginRight:"20px", borderTop:"dashed 1px"}}>
                    <h6 style={{marginTop:"10px", fontWeight:"normal"}}>時間に遅れそうな際、道が分からず迷われた際、お気軽にお電話ください。</h6>
                    <div className="confirm_btn_style" style={{marginTop:0}}>
                        <button className="btn btn-primary btn-shadow"><i class="fas fa-phone"></i> 電話する</button>
                    </div>
                </div>
            </div>
            <div className="mt-3 mb-3">
                <div className="pl-3 pr-3">
                <h3>ご予約のキャンセル</h3>
                <div className="row">
                    <div className="col-12 mb-1"><i class="fas fa-edit mr-1"></i>キャンセル理由</div>
                    <div className="col-12 mb-1">時間を変更する際は、来店をキャンセルしてから、再度ご予約ください。</div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <TextField
                            error={this.state.error_flg === 1}
                            id="cancelReason"
                            name="cancelReason"
                            multiline
                            rows="3"
                            defaultValue={this.state.cancelReason}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            helperText={this.state.error_flg === 1 ? "入力してください。" : ""}
                            placeholder="例) 引っ越し時期が延期になったため"
                            style={{width:"100%", backgroundColor:"white", marginTop:0}}
                        />
                    </div>
                </div>
                <div className="confirm_btn_style" style={{marginTop:0}}>
                    <button className="btn btn-primary btn-shadow" onClick={this.checkText}> キャンセル</button>
                </div>
            </div>
        </div>
    </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        reserve: state.Reserve.reserve,
        reserve_date: state.Reserve.reserve_date,
    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        tatemonochange() {
            //dispatch(Responses.responseeditchange())
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveContentComponent);
