import React, { Component } from 'react';
import { connect } from 'react-redux';
import Login from './Login';
import PublicDocumentC from './PublicDocumentComponent';
import * as Master from '../../Store/Master';
import * as PublicDocument from './Saga/PublicDocument';
import RentHeaderComponent from '../Layout/RentHeaderComponent';
class PublicDocumentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comp: false
        };

        // this.props.change_download_comp(false)


        // this.props.change_cd({cd:this.props.match.params.cd,no:this.props.match.params.no})
        // this.props.get_download_file_list(this.props.match.params.fcd)
        
        this.props.change_rent_limited_public_pages_cd(this.props.match.params.fcd)

        this.handlesubmit = this.handlesubmit.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handlesubmit(state) {
        // console.log(state)
        // alert()
        this.setState({ comp: true })
    }

    render() {

        return (
            <div style={{ backgroundColor: "whitesmoke" }}>
                <RentHeaderComponent />
                <div className="container">
                    {/* <DownloadCompComponent /> */}
                    { this.props.rent_limited_public_pages.id > 0 ? <PublicDocumentC /> : <Login />}
                </div>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {

        rent_limited_public_pages: state.PublicDocument.rent_limited_public_pages,
        

    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_limited_public_pages_cd(state) {
            dispatch(PublicDocument.change_rent_limited_public_pages_cd(state))
        },
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicDocumentComponent);
