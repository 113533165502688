import React, { Component } from 'react';

import { connect } from 'react-redux';

import "react-datepicker/dist/react-datepicker.css";
import {  reduxForm } from 'redux-form';
import './Css/Table.css';
// import '../../Css/DetailFacility.css';
import './Css/DetailExplanation.css';
import { Card, CardContent } from '@material-ui/core';

const setsubiGroup = [

    { id: 1, value: "給湯・浴室"  },
    { id: 2,value: "ネット・ＴＶ" },
    { id: 3,value: "キッチン" },
    { id: 4,value: "部屋設備" },
    { id: 5,value: "冷暖房" },
    { id: 6,value: "駐車場" },
    { id: 10,value: "トイレ" },
    { id: 11,value: "洗濯場" },
    { id: 12,value: "セキュリティ" },
    { id: 8,value: "その他" },
    { id: 9,value: "条件" },
    { id: 13,value: "特集" }
];
class FacilityDispComponent extends Component {
   
    constructor(props) {
        super(props);
      
        this.state = {
        };
    }


    render() {
        const isMobile = window.innerWidth <= 768;

        let list = []
        let list_sp = []
        let stsubi=""
        let stsubilist=[]
        let stsubilist_sp=[]
        let title=""


        if(this.props.search_rooms_detail.setsubi){
            for (let setsubikey in setsubiGroup) {
                
                // sp版
                if(isMobile){
                    stsubilist_sp=[]
                    title=<th key={setsubikey} rowSpan={Math.ceil(this.props.search_rooms_detail.setsubi.filter(a=>a.setsubi_group_no===setsubiGroup[setsubikey].id).length/2)}>{setsubiGroup[setsubikey].value}</th>
    

                    for (let step = 0; step < Math.ceil(this.props.search_rooms_detail.setsubi.filter(a=>a.setsubi_group_no===setsubiGroup[setsubikey].id).length/2); step++) {
                    stsubi= this.props.search_rooms_detail.setsubi.filter(a=>a.setsubi_group_no===setsubiGroup[setsubikey].id).slice(step*2,(step*2)+2).map(function(x,key) {
                        return <td key={key}>{x.setsubi_name}</td>
                    })
                    stsubi=step===0?
                    <tr key={step}>{title}{stsubi}{stsubi.length===1?<td></td>:""}</tr>
                    :<tr key={step}>{stsubi}{stsubi.length===1?<td></td>:""}</tr>
                    stsubilist_sp.push(stsubi)
                    }   
                }
                // pc版
                else if(!isMobile){
                    stsubilist=[]
                    title=<th key={setsubikey} rowSpan={Math.ceil(this.props.search_rooms_detail.setsubi.filter(a=>a.setsubi_group_no===setsubiGroup[setsubikey].id).length/4)}>{setsubiGroup[setsubikey].value}</th>
    
                    for (let step = 0; step < Math.ceil(this.props.search_rooms_detail.setsubi.filter(a=>a.setsubi_group_no===setsubiGroup[setsubikey].id).length/4); step++) {
                        stsubi= this.props.search_rooms_detail.setsubi.filter(a=>a.setsubi_group_no===setsubiGroup[setsubikey].id).slice(step*4,(step*4)+4).map(function(x,key) {
                            return <td key={key}>{x.setsubi_name}</td>
                        })

                        stsubi = (
                            step === 0?
                            <tr key={step}>
                                {title}
                                {stsubi}
                                {Array.from({ length: Math.max(0, 4 - (stsubi ? stsubi.length : 0)) }, (_, index) => (
                                    <td key={index}></td>
                                ))}
                            </tr>
                            :<tr key={step}>
                            {stsubi}
                            {Array.from({ length: Math.max(0, 4 - (stsubi ? stsubi.length : 0)) }, (_, index) => (
                                <td key={index}></td>
                            ))}
                        </tr>
                        );
                        stsubilist.push(stsubi)
                    }
                }
                // for (let step = 0; step < Math.ceil(this.props.search_rooms_detail.setsubi.filter(a=>a.setsubi_group_no===setsubiGroup[setsubikey].id).length/2); step++) {
                //     stsubi= this.props.search_rooms_detail.setsubi.filter(a=>a.setsubi_group_no===setsubiGroup[setsubikey].id).slice(step*2,(step*2)+2).map(function(x,key) {
                //         return <td key={key}>{x.setsubi_name}</td>
                //     })
                //     stsubi=step===0?<tr key={step}>{title}{stsubi}{stsubi.length===1?<td ></td>:""}</tr>:<tr key={step}>{stsubi}{stsubi.length===1?<td></td>:""}</tr>
                //     stsubilist.push(stsubi)
                // }


                list.push(stsubilist)
                list_sp.push(stsubilist_sp)
            }
        }
        
        return (
            <Card className="mb-3">
                <CardContent className="recommended_style facility_table">
                    <div className="recommended_point" style={{fontFamily:"Noto Sans JP,sans-serif"}}>設備・こだわり</div>
                    {!isMobile?<table>
                        {/* <colgroup width="28%" />
                        <colgroup width="36%" />
                        <colgroup width="36%" /> */}
                        <colgroup width="20%" />
                        <colgroup width="20%" />
                        <colgroup width="20%" />
                        <colgroup width="20%" />
                        <colgroup width="20%" />
                        <tbody>
                        {list}
                        </tbody>
                    </table>
                    :
                    <table>
                        <colgroup width="28%" />
                        <colgroup width="36%" />
                        <colgroup width="36%" />
                        <tbody>
                        {list_sp}
                        </tbody>
                    </table>
                    }   
                </CardContent>
            </Card>
        );
    }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

FacilityDispComponent = reduxForm({
    form: 'detailform', // a unique identifier for this form
    //validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(FacilityDispComponent)

export default connect(mapStateToProps, mapDispatchToProps)(FacilityDispComponent);