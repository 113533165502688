const authentication = require('react-azure-adb2c').default;





export const updatebookmark = (values: any) => {

    // const url = `https://localhost:44341/api/mypagerooms/`+values.cd+'/'+values.no;
    // const url = process.env.REACT_APP_API_OPENROOM_URL + `/api/mypagerooms/` + values.cd + '/' + values.no;
    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/introduction/details/bookmark/` + values.cd + '/' + values.no + '/' + values.tatemono_id + '/' + values.room_id;
    // const url = `http://localhost:5000/v1/rent/introduction/details/bookmark/` + values.cd + '/' + values.no + '/' + values.tatemono_id + '/' + values.room_id;

    // alert(JSON.stringify(values))
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const detail = (values: any) => {


    const url = process.env.REACT_APP_API_OPENROOM_URL + `/api/SearchRooms/` + values.tatemono_id + '/' + values.room_id;




    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const staff = (values: any) => {

    
    const url = process.env.REACT_APP_API_OPENROOM_EX_URL+`/v1/mypage/rooms/staff/` + values.cd + '/' + values.no
    // const url = `http://localhost:5000/v1/mypage/rooms/staff/` + values.cd + '/' + values.no

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};