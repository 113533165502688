import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Detail from './Saga/Detail';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import { reduxForm } from 'redux-form';
import './Css/Table.css';
import './Css/DetailFloorPlan.css';
import { Card, CardContent } from '@material-ui/core';
import { Row, Col } from 'reactstrap';

import DateRangeIcon from '@material-ui/icons/DateRange';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import SvgIcon from "@material-ui/core/SvgIcon";
import StarIcon from '@material-ui/icons/Star';

const LineIcon = () => {
    return (
        <SvgIcon
            className="icon_line"
            component="span"
            viewBox=""
            style={{fontSize:"38px"}}
        >
            <svg style={{verticalAlign: 'super'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.37 43.23"><g><path style={{fill: "#fff"}} d="m45.37,18.41C45.37,8.26,35.19,0,22.69,0S0,8.26,0,18.41c0,9.1,8.07,16.72,18.97,18.16.74.16,1.74.49,2,1.12.23.57.15,1.47.07,2.05,0,0-.27,1.6-.32,1.94-.1.57-.46,2.24,1.97,1.22,2.42-1.02,13.06-7.69,17.82-13.17h0c3.29-3.61,4.86-7.26,4.86-11.33Zm-30.69,5.43c0,.24-.19.43-.43.43h-6.37c-.24,0-.43-.19-.43-.43h0v-9.9c0-.24.19-.43.43-.43h1.61c.24,0,.43.19.43.43v7.86h4.33c.24,0,.43.19.43.43v1.61Zm3.84,0c0,.24-.19.43-.43.43h-1.61c-.24,0-.43-.19-.43-.43v-9.9c0-.24.19-.43.43-.43h1.61c.24,0,.43.19.43.43v9.9Zm10.95,0c0,.24-.19.43-.43.43h-1.6s-.08,0-.11-.02c0,0,0,0,0,0-.01,0-.02,0-.03,0,0,0,0,0-.01,0h-.02s-.01-.02-.02-.02c0,0,0,0-.01,0,0,0-.02-.01-.03-.02,0,0,0,0,0,0-.04-.03-.08-.06-.11-.11l-4.54-6.12v5.88c0,.24-.19.43-.43.43h-1.61c-.24,0-.43-.19-.43-.43v-9.9c0-.24.19-.43.43-.43h1.6s0,0,.01,0c0,0,.02,0,.02,0,0,0,.02,0,.02,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,0,0,.01,0,.02,0,0,0,.02,0,.03,0,0,0,0,0,.01,0,0,0,.02,0,.03.01,0,0,0,0,.01,0,0,0,.02,0,.02.01,0,0,0,0,.01,0,0,0,.02.01.02.02,0,0,0,0,.01,0,0,0,.02.01.02.02h0s.02.02.03.03c0,0,0,0,0,0,.01.01.02.03.03.04l4.53,6.12v-5.88c0-.24.19-.43.43-.43h1.61c.24,0,.43.19.43.43v9.9Zm8.79-8.29c0,.24-.19.43-.43.43h-4.33v1.67h4.33c.24,0,.43.19.43.43v1.61c0,.24-.19.43-.43.43h-4.33v1.67h4.33c.24,0,.43.19.43.43v1.61c0,.24-.19.43-.43.43h-6.37c-.24,0-.43-.19-.43-.43h0v-9.89h0c0-.25.19-.44.43-.44h6.37c.24,0,.43.19.43.43v1.61Z"/></g></svg>
        </SvgIcon>
    );
};




class FloorPlanComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.setFavorite = this.setFavorite.bind(this);
        this.LineLink = this.LineLink.bind(this);

    }
    
    LineLink(){  
        if(this.props.staff.tenpo[0].Tenpo_No === 17){
            window.location.href = "https://page.line.me/653lvwms?openQrModal=true";
        }
        else if(this.props.staff.tenpo[0].Tenpo_No === 47){
            window.location.href = "https://page.line.me/707zmmom?openQrModal=true";
        }
        else if(this.props.staff.tenpo[0].Tenpo_No === 1){
            window.location.href = "https://page.line.me/772czlir?openQrModal=true";
        }
        else if(this.props.staff.tenpo[0].Tenpo_No === 2){
            window.location.href = "https://page.line.me/221axikr?openQrModal=true";
        }
        else if(this.props.staff.tenpo[0].Tenpo_No === 3){
            window.location.href = "https://page.line.me/723cihaw?openQrModal=true";
        }
        else if(this.props.staff.tenpo[0].Tenpo_No === 4){
            window.location.href = "https://page.line.me/383gtpzg?openQrModal=true";
        }
        else if(this.props.staff.tenpo[0].Tenpo_No === 6){
            window.location.href = "https://page.line.me/133rokhc?openQrModal=true";
        }else{
            window.location.href = "https://page.line.me/653lvwms?openQrModal=true";
        }
    }

    setFavorite() {
        this.props.change_favorite_detail({ is_bookmark: this.props.search_rooms_detail.is_bookmark ? false : true, tatemono_id: this.props.search_rooms_detail.tatemono_id, room_id: this.props.search_rooms_detail.room_id, cd: this.props.cd.cd, no: this.props.cd.no })

    }
    

    render() {
        const floorPlan_num = this.props.search_rooms_detail.t_room_img.length;
        const floorPlan_img = this.props.search_rooms_detail.t_room_img[floorPlan_num - 1]

        const isMobile = window.innerWidth <= 768;


        return (
        <div className='rightBox'>           
            { isMobile ? "":
            <Card className="mb-3">
                <CardContent className="floorPlan_space">
                    <Row gap="1">
                        <Col lg="6" className="item1">
                            <Button onClick={()=> this.LineLink()} className='DetailBtns Line' variant="contained" size="large" style={{backgroundColor: "#06c755"}} startIcon={<LineIcon style={{fontSize:"38px"}}/>}>
                                <p style={{ fontSize: "15px",fontWeight: "bold",marginBottom:"0"}}>LINEで問合せ</p> 
                            </Button>
                        </Col>
                        <Col lg="6" className="item2">
                            <Button className='DetailBtns fav' variant="contained" value={this.props.search_rooms_detail.is_bookmark}
                            onClick={this.setFavorite} size="large" style={{backgroundColor: "#fff"}} >
                                {<StarIcon
                                    style={this.props.search_rooms_detail.is_bookmark ? { color: "ff8c00",fontSize:"38px" } : { color: "#d3d3d3",fontSize:"38px" }}
                                />}
                               <p style={{ fontSize: "15px", color: "#808080", fontWeight: "bold",marginBottom:"0" }}>{this.props.search_rooms_detail.is_bookmark ? "お気に入り" : "お気に入り登録"}</p>
                            </Button>
                        </Col>
                        <Col lg="6" className="item3">
                            <Button className='DetailBtns' variant="contained" size="large" style={{backgroundColor: "#4ac0f0",}}  startIcon={<DateRangeIcon style={{fontSize:"38px"}}/>}
                            onClick={()=>this.props.history.push("/Reserve/"+this.props.cd.cd+"/"+this.props.cd.no+'?tid='+ this.props.search_rooms_detail.tatemono_id + '&rid='+ this.props.search_rooms_detail.room_id)}
                            >
                                <p style={{ fontSize: "15px",fontWeight: "bold",marginBottom:"0"}}>店舗に<br/>来店予約する</p> 
                            </Button>
                        </Col>
                        <Col lg="6" className="item4">
                            <Button className='DetailBtns' variant="contained" size="large" style={{backgroundColor: "#EBA855"}} startIcon={<EmailIcon style={{fontSize:"38px"}}/>}
                            href='#Contact'>
                                <p style={{ fontSize: "15px",fontWeight: "bold",marginBottom:"0"}}>お部屋を<br/>メールお問合せ</p> 
                            </Button>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
            }
            <Card>
                <CardContent className="floorPlan_space madori">
                    <img src={"https://img.global-center.co.jp/v2/rooms/" + this.props.search_rooms_detail.tatemono_id + "/" + this.props.search_rooms_detail.room_id + "/8/1/520/780/0"} alt="間取" />
                </CardContent>
            </Card>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.masterReducer.users_select,
        cd: state.masterReducer.cd,
        download_flg: state.Detail.download_flg,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_favorite_detail(state) {
            dispatch(Detail.change_favorite_detail(state))
        },
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

FloorPlanComponent = connect(
    mapStateToProps, mapDispatchToProps
)(FloorPlanComponent);

FloorPlanComponent = reduxForm({
    form: 'detailform', // a unique identifier for this form
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(FloorPlanComponent)

export default FloorPlanComponent