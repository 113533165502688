const authentication = require('react-azure-adb2c').default;



export const list = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/limited/public/pages/list/` + values;
    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/limited/public/pages/list/` + values;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        // headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
        headers: { 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const detail = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/limited/public/pages/detail`;
    const url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/rent/limited/public/pages/detail`;

    // alert(url)

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};