
import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom'
import { Route } from 'react-router';
// import Layout from './Components/Layout/LayoutComponent';
import Reserve from './Components/Reserve/Index';
import Rent from './Components/Rent/RentComponent';
import ComingComponent from './Components/Coming/ComingComponent';
import NewCard from './Components/Card/CardComponent';
import CardComponent from './Components/Card/Index';

import ApplicationForm from './Components/Card/ApplicationComponent';
import ApplicationIndex from './Components/Card/ApplicationIndex';

import ApplyForm from './Components/Card/ApplyComponent';
// import ApplyConfirm from './Components/Card/ApplyConfirmComponent';

import LineComponent from './Components/Line/LineComponent';
import { withCookies, Cookies, useCookies } from 'react-cookie';
import DetailComponent from './Components/Detail/DetailComponent';
import DownloadComponent from './Components/Download/DownloadComponent';
import PublicDocument from './Components/PublicDocument/Index';
import CreditCompComponent from './Components/Credit/CreditCompComponent';
import CreditCancelComponent from './Components/Credit/CreditCancelComponent';
import Credit from './Components/Credit/Index';
import TransferCompComponent from './Components/Transfer/TransferCompComponent';
import TransferCancelComponent from './Components/Transfer/TransferCancelComponent';
import Transfer from './Components/Transfer/Index';
import ConfirmationDocument from './Components/ConfirmationDocument/Index';

interface Props extends RouteComponentProps<{}> {
  // 他の型定義
  cookies: any
}



const App: React.FC = (props) => {
  const [cookies, setCookie] = useCookies(['globalcenter']);

  if (!cookies.globalcenter) {
    setCookie('globalcenter', "dddd", { path: '/', domain: ".global-center.co.jp", maxAge: 60 * 60 * 24 * 730 });
  }
  return (
    // <Layout>

    //   <Route path='/Rent/:cd/:no/' component={Rent} />
    //  <Route path='/Detail/:cd/:no/:tid/:rid' component={DetailComponent} />

    // <Route path='/Reserve/:cd/:no' component={Reserve} />

    //  <Route path='/Coming/:cd/:no' component={ComingComponent} /> 
    //   </Layout> 
    //  <Layout>
    <div style={{ height: "100%" }}>
      <Route path='/Rent/:cd/:no/' component={Rent} />
      <Route path='/Reserve/:cd/:no' component={Reserve} />
      <Route path='/Detail/:cd/:no/:tid/:rid' component={DetailComponent} />
      <Route path='/Coming/:cd/:no' component={ComingComponent} />
      <Route path='/Card/:cd' component={CardComponent} />
      <Route path='/NewCard/:way/:sectionid' component={NewCard} />
      <Route path='/ComingCard/:responsecode/:way/:sectionid' component={NewCard} />

      <Route path='/Application/:way/:sectionid' component={ApplicationForm} />
      <Route path='/ApplicationIndex/:way/:sectionid' component={ApplicationIndex} />

      <Route path='/Apply/:way/:sectionid' component={ApplyForm} />
      {/* <Route path='/Apply/confirm' component={ApplyConfirm} /> */}

      <Route path='/Line/:cd/:no' component={LineComponent} />
      <Route path='/Download/:fcd' component={DownloadComponent} />
      <Route path='/PublicDocument/:fcd' component={PublicDocument} />
      <Route path='/credit/complete' component={CreditCompComponent} />
      <Route path='/credit/cancel' component={CreditCancelComponent} />
      <Route path='/credit/confirm/:cd' component={Credit} />
      <Route path='/transfer/complete' component={TransferCompComponent} />
      <Route path='/transfer/cancel' component={TransferCancelComponent} />
      <Route path='/transfer/confirm/:cd' component={Transfer} />
      <Route path='/ConfirmationDocument' component={ConfirmationDocument} />
    </div>
    //  </Layout> 

  );
}
export default withCookies(App);

const mapStateToProps = (state: any) => {
  return {
    // users: state.masterReducer.users_select,
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    // change_reserve(state) {
    //    dispatch(Reserve.change_reserve(state))
    // },

  };
}

connect(
  mapStateToProps, mapDispatchToProps
)(App);