import React, { Component } from 'react';
//import Select from 'react-select';
import { connect } from 'react-redux';
// import { Button, Col, Row, Container,
//     Card, CardBody, CardImg, CardTitle, CardImgOverlay,
//     CardText, Alert } from 'reactstrap';
//import { actionCreators } from '../../Store/MailData';

// import DatePicker from "react-datepicker";
//import DatePicker from 'react-ja-date-picker'
//import "react-datepicker/dist/react-datepicker.css";
//import { actionCreators } from '../../Store/WeatherForecasts';

import { reduxForm } from 'redux-form';
// import '../../Css/Table.css';
import './Css/Card.css';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// import * as AutoKana from 'vanilla-autokana';
let autokana1;



// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};
    
    if (!values.articleCd) {
        errors.articleCd = "物件番号を入力してください。";
    }
    if (!values.articleName) {
        errors.articleName = "建物名を入力してください。";
    }
    if (!values.articleKana) {
        errors.articleKana = "建物カナを入力してください。";
    }
    if (values.articleCharger === "0") {
        errors.articleCharger = "担当者を入力してください。";
    }
    if (!values.address1) {
        errors.address5 = "住所を入力してください。";
    }
    if (!values.address2) {
        errors.address5 = "住所を入力してください。";
    }
    if (!values.address3) {
        errors.address5 = "住所を入力してください。";
    }
    if (!values.address4 && !values.address5) {
        errors.address5 = "住所を入力してください。";
    }
    if (values.construction === "0") {
        errors.construction = "物件構造を入力してください。";
    }
    
    return errors;
};


export class RenderField extends React.Component {
    render() {
        const { className, type, style, input, disabled, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};


// const RenderSelectInput = ({input: { onChange, value }, options, name, id}) => (
  
//     <Select 
//         //  {...input}
//          id={id} 
//          name={name} 
//          options={options}
//          value={value}
//          onChange={onChange}
//          onBlurResetsInput={false}
//         //  onBlur={(value) => input.onBlur(value)}
//     />

   
// )



class CardCompComponent extends Component {
   
    constructor(props) {
        super(props);
      console.log(props);

        this.state = {
            customerinfo:this.props.forecasts,
            startDate: new Date()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.props.initialize(this.props.tenantdetail);//フォームに初期値を反映させる
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }
    
    componentDidMount() {
        console.log(this.props.tenantdetail)
          this.props.initialize(this.props.tenantdetail);//フォームに初期値を反映させる
        
    // This method is called when the component is first added to the document
        //this.ensureDataFetched();
       
    }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.ensureDataFetched();
  }

  ensureDataFetched() {
      //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
      //console.log(startDateIndex)

      //this.props.requestWeatherForecasts(0);

    }


    onChangeName(property, event) {

        if (property === "tatemonoKana") {
            this.props.change(property, autokana1.getFurigana())
        } else if (property === "kana2") {
            
        }


    }

    render() {
        // const { handleSubmit } = this.props;
       
      return (
        <div class="container shadow my-5 py-5">
            <div class="row justify-content-center">
                <div class="text-center py-3">
                      <CheckCircleIcon />
                </div>
                <div class="h4 col-12 text-center py-3">
                ありがとうございます。
                </div>
                <div class="h5 col-12 text-center py-3">
                回答が送信されました。
                </div>
            </div>
        </div>

    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        users: state.masterReducer.users_select,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

CardCompComponent = connect(
    mapStateToProps, mapDispatchToProps
)(CardCompComponent);

CardCompComponent = reduxForm({
    form: 'tenantform', // a unique identifier for this form
    validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(CardCompComponent)

export default CardCompComponent