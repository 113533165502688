import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as MypageRequestApi from '../Api/MypageRequestApi';
import * as line_auth_api from '../Api/line_auth_api';

export interface MapSearchState {
    // line_detail: any;
    line_comp: boolean;
    line_code: any;
    line_section_id: any;
    line_limit: any;
    // search_rooms_detail:any;
}
const initialState: MapSearchState = {

    line_section_id: 0,
    line_comp: false,
    line_code: null,
    line_limit: null,
    // search_rooms_detail: [],
}



///action 
const actionCreator = actionCreatorFactory();

// export const get_line_detail = actionCreator('GET_LINE_DETAIL');
// export const change_line_detail = actionCreator<any>("CHANGE_LINE_DETAIL");
// export const update_mypage_request = actionCreator<any>('UPDATE_MYPAGE_REQUEST');
export const change_line_comp = actionCreator<any>("CHANGE_LINE_COMP");

export const get_line_code = actionCreator<any>("GET_LINE_CODE");
export const change_line_code = actionCreator<any>("CHANGE_LINE_CODE");
export const change_line_section_id = actionCreator<any>("CHANGE_LINE_SECTION_ID");

export const change_line_limit = actionCreator<any>("CHANGE_LINE_LIMIT");
// export const get_search_rooms_detail= actionCreator('GET_SEARCH_ROOMS_DETAIL');
// export const get_search_rooms_detail_success = actionCreator("GET_SEARCH_ROOMS_DETAIL_SUCCESS");


export const lineReducer = reducerWithInitialState(initialState)
    .case(change_line_section_id, (state, payload) => ({ ...state, line_section_id: payload }))
    .case(change_line_comp, (state, payload) => ({ ...state, line_comp: payload }))

    .case(change_line_code, (state, payload) => ({ ...state, line_code: payload }))
    .case(change_line_limit, (state, payload) => ({ ...state, line_limit: payload }))
// .case(get_search_rooms_detail_success, (state, payload) => ({ ...state, search_rooms_detal: payload }))
// .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))

// .case(get_response_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

// .case(change_showRight, (state, payload) => ({ ...state, showRight: payload }))
// .case(change_showFlag, (state, payload) => ({ ...state, showFlag: payload }))
// .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))

// .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }));








// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const lineSaga = [
    //     takeEvery('GET_LINE_DETAIL', handle_get_line_detail),
    // takeEvery('UPDATE_MYPAGE_REQUEST', handle_update_mypage_request),
    takeEvery('GET_LINE_CODE', handle_get_line_code),

    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



// function* handle_get_line_detail(action: any) {

//     const { payload, error } = yield call(MypageRequestApi.detail, action.payload);
//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         console.log(payload)

//         // const setsubis = payload.map((item:any, i:number) => ({
//         //     value: item.setsubiNo,
//         //     label: item.setsubiName,
//         //   }))

//         // 成功時アクション呼び出し


//         yield put(change_line_detail(payload));

//         // yield put(get_search_rooms_success(payload));


//     } else {
//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }


// function* handle_update_mypage_request(action: any) {
//     const state = yield select();

//     const { payload, error } = yield call(MypageRequestApi.update, state.Line.mypage_request);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         console.log(payload)

//         // const setsubis = payload.map((item:any, i:number) => ({
//         //     value: item.setsubiNo,
//         //     label: item.setsubiName,
//         //   }))

//         // 成功時アクション呼び出し


//         yield put(change_line_comp(true));
//         //    yield put(get_search_rooms_detail_success(payload));

//     } else {
//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }


function* handle_get_line_code(action: any) {
    const state = yield select();

    const { payload, error } = yield call(line_auth_api.auth, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        yield put(change_line_code(payload.code));
        yield put(change_line_section_id(payload.section_id));
        yield put(change_line_limit(payload.limited_at));
        //    yield put(get_search_rooms_detail_success(payload));

    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}
