import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardFormComponent from './CardFormComponent';
import CardCompComponent from './CardCompComponent';
import * as Master from '../../Store/Master';
import * as Card from './Saga/Card';
import RentHeaderComponent from '../Layout/RentHeaderComponent';
class CardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comp: false
        };

        this.props.change_card_comp(false)

        if (this.props.match) {
            this.props.change_card_section_id(this.props.match.params.sectionid)
            this.props.change_card_way(this.props.match.params.way)//0　来店　1メール　2LINE
            if(this.props.match.params.responsecode){
                this.props.change_card_response_code(this.props.match.params.responsecode)
            }
            
        }

        this.props.get_rent_m_medea();
        this.props.get_rent_m_reasons();
        this.props.get_rent_m_schools();
        this.props.get_rent_m_jobs();


        // this.props.get_card_details(this.props.match.params.cd)
        this.handlesubmit = this.handlesubmit.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handlesubmit(state) {
        // console.log(state)
        // alert()
        this.setState({ comp: true })
    }

    render() {

        return (
            <div style={{ backgroundColor: "whitesmoke" }}>
                <RentHeaderComponent root="newcard" />
                <div className="container">

                    {this.props.card_comp === false ? this.props.rent_m_medea.length > 0 && this.props.rent_m_reasons.length > 0 && this.props.rent_m_schools.length > 0 && this.props.rent_m_jobs.length > 0 ? <CardFormComponent onSubmit={this.handlesubmit} /> : "" : <CardCompComponent />}
                </div>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,

        card_comp: state.Card.card_comp,

        rent_m_medea: state.Card.rent_m_medea,
        rent_m_reasons: state.Card.rent_m_reasons,
        rent_m_schools: state.Card.rent_m_schools,
        rent_m_jobs: state.Card.rent_m_jobs,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_cd(state) {
            dispatch(Master.change_cd(state))
        },

        change_card_comp(state) {
            dispatch(Card.change_card_comp(state))
        },

        change_card_section_id(state) {
            dispatch(Card.change_card_section_id(state))
        },
        change_card_way(state) {
            dispatch(Card.change_card_way(state))
        },
        change_card_response_code(state) {
            dispatch(Card.change_card_response_code(state))
        },

        get_rent_m_medea(state) {
            dispatch(Card.get_rent_m_medea(state))
        },
        get_rent_m_reasons(state) {
            dispatch(Card.get_rent_m_reasons(state))
        },
        get_rent_m_schools(state) {
            dispatch(Card.get_rent_m_schools(state))
        },
        get_rent_m_jobs(state) {
            dispatch(Card.get_rent_m_jobs(state))
        },
        //customeredit2change(state) {
        //        dispatch(customeredit2change(state))
        //    },
        //        responseeditchange(state) {
        //            dispatch(responseeditchange(state))
        //        },
        //        customerdetailData1(state) {
        //            dispatch(customerdetailData1(state))
        //        }, responsedetailData(state) {
        //            dispatch(responsedetailData(state))
        //        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardComponent);
