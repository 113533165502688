import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmationDocumentC from './ConfirmationDocumentComponent';
import RentHeaderComponent from '../Layout/RentHeaderComponent';
import './Css/ConfirmationDocument.css';

class ConfirmationDocumentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        return (
            <div>
                <RentHeaderComponent />
                <div className="confirmation_container">
                    <ConfirmationDocumentC />
                </div>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {

    }
}
function mapDispatchToProps(dispatch) {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDocumentComponent);
