import React, { Component,useEffect } from 'react';
import { connect } from 'react-redux';
import './Css/RentBukkenBox.css';
import { withRouter } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import LocationOn from '@material-ui/icons/LocationOn';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import './Css/DetailImage.css'
import Slider from "react-slick";
import Carousel, { Modal, ModalGateway } from 'react-images';
import { height } from '@mui/system';

const distance=(lat1, lng1, lat2, lng2)=> {
    lat1 *= Math.PI / 180;
    lng1 *= Math.PI / 180;
    lat2 *= Math.PI / 180;
    lng2 *= Math.PI / 180;
    return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
}

class RentBukkenBoxPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            device: 'pc',

            modalIsOpen : false,
            images : [],
            images_index : 0,
            index: 0,
            room_length: 0,
            sameNumberingArr: [],
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.sameNumbering = this.sameNumbering.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }

    componentDidMount() {
        const ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
            this.setState({ device: 'sp' });
        } else if (ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0) {
            // iOS12 まで
            this.setState({ device: 'tab' });
        } else if (ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document) {
            // iOS13 以降
            this.setState({ device: 'tab' });
        } else {
            this.setState({ device: 'pc' });
        }

        // 同じ建物の部屋配列
        const sameTatemono = this.sameNumbering(this.props.search_rooms.mypage_rooms);
        // console.log(this.props.search_rooms.mypage_rooms,this.props.rooms, sameTatemono)
        this.setState({sameNumberingArr: sameTatemono, room_length: sameTatemono.length, index: 0})
    }


    open_window(url) {

        if(this.state.device == 'pc'){
            window.open(url, '_blank', 'noopener')
        }else{
            this.props.history.push(url)
        }

    }

    toggleModal(e, state){


        let img = []
        let index = 0

        // if(key != null){
            let room_img =  this.props.rooms.room_img
            room_img.forEach( function( img_value, key ) {
                img.push({ src: img_value.original })
                if (img_value.original == e.target.src) {
                    index = key
                }
            });
        // }

        this.setState({
            images:img,
            images_index :index,
            modalIsOpen: state
        });

    }

    sameNumbering(arr) {
        const arr2 = arr.filter(rooms => {
            if(rooms.numbering == this.props.rooms.numbering) {
                return rooms;
            }
        })
        return arr2;
    }

    prev() {
        this.setState({index: this.state.index - 1})
    }
    next() {
        this.setState({index: this.state.index + 1})
    }
    render() {
        // const settings = {
        //     dots: true,
        //     infinite: true,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 1
        // };
        // const gaikan_url = "https://www.global-center.co.jp/claso/common/img/building/" + this.props.rooms.tatemonoId + "/1/1";
        // const room_img = this.props.rooms.tRoomImg;
        const madori_img = "https://img.global-center.co.jp/v2/rooms/" + this.props.rooms.tatemono_id + "/" + this.props.rooms.room_id + "/8/1/520/780/0";

        return (
            <div style={{position:'relative',zIndex:"10"}}>
            <div className="BukkenBox">
                <div className="text-right ma-bottom-8">
                </div>
                <div style={{position: "relative", top: "40px", left: "260px"}} onClick={() => this.props.setroomdetail([])}>
                    <CloseIcon
                        fontSize="small"
                    />
                </div>
                <div className="jumbotron pb-1">
                    
            {
            this.state.sameNumberingArr ?
            this.state.sameNumberingArr.map((rooms, index) => rooms ?
            index == this.state.index ? this.props.rooms.numbering != rooms.numbering ? this.componentDidMount() :  
            <div>
                <div className="row">
                    <div className="col-11" style={{ marginTop: "10px" }}>
                        <span className="maru" style={rooms.is_bookmark ? { backgroundColor: "#ff8c00", display: "inline-block", fontSize: "12px" } : { backgroundColor: "black", display: "inline-block", fontSize: "12px" }}>{rooms.numbering}</span>　<span style={{ display: "inline-block", fontSize: "15px" }}>{rooms.tatemono_name} {rooms.room_name}</span>
                    </div>
                    {/* <div class="col-xs-3 favorite_btn">
                        <a class="btn btn-cliant-white btn-shadow btn-block toggle-favorite ma-top-8" href="">
                            <i class="big fa fa-star"></i>
                        </a>
                    </div> */}
                </div>
                {/* <div className="room-image-slide"></div> */}
                <div className="map-contents" style={{ borderTop: "1px solid #ddd", height: 170}}>
                    {/* <img src={gaikan_url}  alt="物件画像" /> */}
                    {rooms.room_img ? <ImageGallery items={rooms.room_img}
                        showPlayButton={false}
                        showThumbnails={false}
                        autoPlay={false}
                        lazyLoad={true}
                        showFullscreenButton={false}
                        onClick={(e) => this.toggleModal(e, true)}
                    /> : ""}
                    {/* {this.props.rooms.room_img ? <Slider className='imgslick' style={{marginTop:20}}>
                            {this.props.rooms.room_img.map((value, key)=>
                                <div style={{height:200, width:200, position:"relative"}}>
                                    <img src={value.original} onClick={()=>this.toggleModal(true, key)} alt="物件画像" />
                                </div>
                            )}  
                        </Slider> : ""} */}
                    <ModalGateway>
                        {this.state.modalIsOpen ? (
                        <Modal onClose={()=>this.setState({modalIsOpen: false})}>
                            <Carousel views={this.state.images} currentIndex={this.state.images_index} />
                        </Modal>
                        ) : null}
                    </ModalGateway>

                    {rooms.kuushitsu_joukyou_no === 1 || this.props.search_rooms.rent_introduction_headers.disp_applied === 1 ? "" : <p style={{position: "relative", bottom: 30}}>成約済み</p>}
                    {/* <Slider {...settings}>
                        {<div class="mt-3">
                            <img src={gaikan_url}  alt="物件画像" />
                        </div>}
                        {room_img.map((value)=>
                            <div>
                                <img src={"https://www.global-center.co.jp/claso/common/img/room/" + value.tatemonoId + "/" + value.roomId + "/" + value.imgShubetsuNo + "/" + value.imgNo} alt="物件画像" />
                            </div>
                        )}                            
                    </Slider> */}
                </div>
                {/* <bukkenTable/> */}
                <table className="table_layout" style={{ fontSize: "12px" }}>
                    <tbody>
                        <tr>
                            <td rowSpan="5">
                                <span className="contain" style={{ width: 87, height: 110, backgroundImage: `url(${madori_img})` }}></span><span style={{display: "block"}}>{rooms.madori_name}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                家賃<br></br>共益費
                            </th>
                            {/* カンマ区切りで表示する */}
                            <td className="color-red pt-0 pb-0">
                                <strong style={{ fontSize: "15px" }}>{rooms.chinryou > 0 ? rooms.chinryou.toLocaleString() : 0}円</strong><br></br>{rooms.kyouekihi ? rooms.kyouekihi.toLocaleString() + "円" : "込み"}
                            </td>
                        </tr>
                        <tr>
                            <th style={{height: 30}}>
                                敷金/礼金
                            </th>
                            {/* カンマ区切りで表示する */}
                            <td style={{ fontSize: "14px" }}>
                                {rooms.shikikin_mon >= 1 ? rooms.shikikin_mon + "ヶ月 / " : rooms.shikikin > 0 ? rooms.shikikin.toLocaleString() + "円 / " : "- / "}
                                {rooms.reikin_mon >= 1 ? rooms.reikin_mon + "ヶ月" : rooms.reikin > 0 ? rooms.reikin.toLocaleString() + "円" : "-"}
                            </td>
                        </tr>
                        <tr>
                            <th style={{height: 30}}>
                                定額クリーニング
                            </th>
                            <td style={{fontSize: "14px"}}>
                                {rooms.teigaku_cleaning?rooms.teigaku_cleaning:"-"}
                            </td>
                        </tr>
                        <tr>
                            <th style={{height: 30}}>
                                専有面積
                            </th>
                            <td style={{fontSize: "14px"}}>
                                {rooms.senyuu_menseki}㎡
                            </td>
                        </tr>
                        <tr>
                            <th style={{height: 30}}>
                                築年
                            </th>
                            <td style={{fontSize: "14px"}}>
                                {rooms.shunkou_year}年{rooms.shunkou_month}月
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <span style={{ fontSize: "15px", fontWeight: "bold" }}>所在地　</span>
                                <span style={{ fontSize: "14px" }}>{rooms.todoufuken} {rooms.shikuchoson}{rooms.choiki}</span>
                                {/* <a href={"https://www.google.com/maps?q=" + this.props.rooms.ido + "," + this.props.rooms.keido}>[地図]</a> */}
                            </td>
                        </tr>


                        {/* {this.props.search_rooms.rentIntroductionPointHeadersViewModel?this.props.search_rooms.rentIntroductionPointHeadersViewModel.map((pointheader,index) => {
                            if(pointheader.type===1 && pointheader.rentIntroductionPointDetails.length>0){
                            return  <tr key={index}>
                                <th>
                                <LocationOn style={{color:"red"}}/> {pointheader.contents}まで
                                </th>
                                <td colSpan="2">
                                <div>
                            {pointheader.rentIntroductionPointDetails.length>0?Math.ceil(distance(pointheader.rentIntroductionPointDetails[0].lat,pointheader.rentIntroductionPointDetails[0].lng,this.props.rooms.ido,this.props.rooms.keido)* 10)/10+"km":""}
                            </div>
                            </td>
                        
                        </tr>
                            }
                    //全表示
                    },this):""} */}
                    </tbody>
                </table>
                
                <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "5px" }}>
                    <div style={{width: 50}}>
                    {this.state.room_length == 1 ? "" :
                    (this.state.index == 0 ?
                        ""
                    : this.state.index == this.state.room_length - 1 ?
                        <IconButton size="small" onClick={()=>this.prev()} style={{padding:0}}><KeyboardDoubleArrowLeftIcon fontSize="inherit" /><span style={{fontSize: "12px"}}>前へ</span></IconButton>
                    :                    
                        <IconButton size="small" onClick={()=>this.prev()} style={{padding:0}}><KeyboardDoubleArrowLeftIcon fontSize="inherit" /><span style={{fontSize: "12px"}}>前へ</span></IconButton>
                    )
                    }
                    </div>
                    <div style={{display: "grid", width: 100}}>
                        <button className="btn btn-primary btn-cliant-blue btn-shadow" style={{ fontSize: "12px", height: "35px",background:"rgb(74, 192, 240)",border:"none" }} onClick={() => this.open_window("/Detail/" + this.props.match.params.cd + "/" + this.props.match.params.no + "/" + rooms.tatemono_id + "/" + rooms.room_id)}>
                            <i className="fa fa-chevron-right"></i> 詳細を見る
                        </button>
                        {this.state.room_length == 1 ? <span style={{paddingBottom: "2px"}}></span>
                        : <span style={{fontSize: "14px", width: "60px", textAlign: "right"}}>{this.state.index + 1}/{this.state.room_length}</span>
                        }
                    </div>
                    <div style={{ display: "flex", flexFlow: "column", width: 50 }}>
                    {/* {this.state.first_flg && this.state.last_flg ? "" */}
                    {this.state.room_length == 1 ? "" :
                    (this.state.index == 0 ?
                        <IconButton size="small" onClick={()=>this.next()} style={{padding:0}}><span style={{fontSize: "12px"}}>次へ</span><KeyboardDoubleArrowRightIcon fontSize="inherit" /></IconButton>
                    : this.state.index == this.state.room_length - 1 ?
                        ""
                    :
                        <IconButton size="small" onClick={()=>this.next()} style={{padding:0}}><span style={{fontSize: "12px"}}>次へ</span><KeyboardDoubleArrowRightIcon fontSize="inherit" /></IconButton>
                    )
                    }
                    </div>
                </div>
            </div>
            : ""
            : "")
            : ""
        }
                </div>
            </div>
        </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        search_rooms: state.Rent.search_rooms,
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
        //        customeredit1change(state) {
        //            dispatch(customeredit1change(state))
        //        },
        //customeredit2change(state) {
        //        dispatch(customeredit2change(state))
        //    },
        //        responseeditchange(state) {
        //            dispatch(responseeditchange(state))
        //        },
        //        customerdetailData1(state) {
        //            dispatch(customerdetailData1(state))
        //        }, responsedetailData(state) {
        //            dispatch(responsedetailData(state))
        //        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RentBukkenBoxPopup));
