import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Master from '../../Store/Master';
import * as Card from './Saga/Card';
import Layout from '../Layout/LayoutComponent';
import './Css/Card.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RentHeaderComponent from '../Layout/RentHeaderComponent';

class ApplicationLoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // comp: false
        };
        this.onChange = this.onChange.bind(this);
        // this.props.change_line_comp(false)
        // this.props.change_cd({ cd: this.props.match.params.cd, no: this.props.match.params.no })
        // this.props.get_line_code(this.props.match.params.cd)
        // // this.handlesubmit = this.handlesubmit.bind(this)
        // this.loginClick = this.loginClick.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }


    onChange(e) {
        this.props.change_rent_contracts_renter_pass(e.target.value)
    }
    // handlesubmit(state) {
    //     // console.log(state)
    //     // alert()
    //     this.setState({ comp: true })
    // }

    render() {

        return (
            <div className="app" style={{ height: "100%" }}>
                <RentHeaderComponent />
                <div style={{ minHeight: "100%", backgroundColor: "whitesmoke" }}>
                    <div style={{ backgroundColor: "#f5f5f5" }}>
                        <div className="display_style">
                            <div className="container">
                                <div className="frame">
                                    <div className="confirm_h1" style={{textAlign:"center"}}><div>株式会社グローバルセンター</div><div>ログイン</div></div>
                                    <div style={{ textAlign: "center" }}>株式会社グローバルセンターより発行されたパスワードを入力してください。</div>
                                    <div className="container" style={{ marginTop: 80, marginBottom: 50, textAlign: "center" }}>
                                        <TextField
                                            id="filled-password-input"
                                            label="パスワード"
                                            type="password"
                                            name={"cre"}
                                            autoComplete="current-password"
                                            variant="filled"
                                            defaultValue=""
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="container" style={{ marginBottom: 50, textAlign: "center" }}>
                                        <Button variant="contained" color="primary" className="login_btn" onClick={this.props.get_rent_contracts_application}>ログイン</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,

        // line_comp: state.Line.line_comp,
        // line_code: state.Line.line_code,
        // line_section_id: state.Line.line_section_id,
        rent_contracts: state.Card.rent_contracts,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_rent_contracts_application(state) {
            dispatch(Card.get_rent_contracts_application(state))
        },
        change_rent_contracts_renter_pass(state) {
            dispatch(Card.change_rent_contracts_renter_pass(state))
        },
        // change_credit_pass(state) {
        //     dispatch(Credit.change_credit_pass(state))
        // },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLoginComponent);
