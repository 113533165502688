import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as SearchRoomsApi from '../Api/SearchRoomsApi';
import * as MypageLocalSpotsApi from '../Api/MypageLocalSpotsApi';
import * as rent_introduction_details_api from '../Api/rent_introduction_details_api';

export interface MapSearchState {
    staff:any;
    search_rooms: any;
    search_rooms_detail: any;
    mypage_local_conveni: any;
    mypage_local_store: any;
    all_list: any;
    favorite_list_flg: boolean;
    shuhen:any,
    houjin:any,
}
const initialState: MapSearchState = {
    staff:[],
    search_rooms: [],

    search_rooms_detail: [],
    mypage_local_conveni: [],
    mypage_local_store: [],
    all_list: [],
    favorite_list_flg: false,
    shuhen:[],
    houjin:[],
}



///action 
const actionCreator = actionCreatorFactory();

export const get_staff = actionCreator('GET_STAFF');
export const change_staff = actionCreator('CHANGE_STAFF');

export const get_search_rooms = actionCreator('GET_SEARCH_ROOMS');
export const get_search_rooms_success = actionCreator<any>("GET_SEARCH_ROOMS_SUCCESS");

export const get_houjin = actionCreator("GET_HOUJIN");
export const change_houjin = actionCreator("CHANGE_HOUJIN");


export const get_mypage_local_spots = actionCreator("GET_MYPAGE_LOCAL_SPOTS");
export const change_mypage_local_store = actionCreator("CHANGE_MYPAGE_LOCAL_STORE");
export const change_mypage_local_conveni = actionCreator("CHANGE_MYPAGE_LOCAL_CONVENI");

export const get_mypage_local_shuhen = actionCreator("GET_MYPAGE_LOCAL_SHUHEN");
export const change_mypage_local_shuhen = actionCreator("CHANGE_MYPAGE_LOCAL_SHUHEN");

export const change_favorite = actionCreator<any>("CHANGE_FAVORITE");

export const change_all_list = actionCreator<any>("CHANGE_ALL_LIST");

export const change_favorite_list_flg = actionCreator<boolean>("CHANGE_FAVORITE_LIST_FLG");

export const change_favorite_list = actionCreator("CHANGE_FAVORITE_LIST");
// export const get_search_rooms_detail= actionCreator('GET_SEARCH_ROOMS_DETAIL');
// export const get_search_rooms_detail_success = actionCreator("GET_SEARCH_ROOMS_DETAIL_SUCCESS");


export const rentReducer = reducerWithInitialState(initialState)
    .case(get_search_rooms_success, (state, payload) => ({ ...state, search_rooms: payload }))
    .case(change_houjin, (state, payload) => ({ ...state, houjin: payload }))
    .case(change_mypage_local_store, (state, payload) => ({ ...state, mypage_local_store: payload }))
    .case(change_mypage_local_conveni, (state, payload) => ({ ...state, mypage_local_conveni: payload }))
    .case(change_all_list, (state, payload) => ({ ...state, all_list: payload }))
    .case(change_favorite_list_flg, (state, payload) => ({ ...state, favorite_list_flg: payload }))
    .case(change_staff, (state, payload) => ({ ...state, staff: payload }))
    .case(change_mypage_local_shuhen, (state, payload) => ({ ...state, shuhen: payload }))

// .case(get_search_rooms_detail_success, (state, payload) => ({ ...state, search_rooms_detal: payload }))
// .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))

// .case(get_response_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

// .case(change_showRight, (state, payload) => ({ ...state, showRight: payload }))
// .case(change_showFlag, (state, payload) => ({ ...state, showFlag: payload }))
// .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))

// .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }));








// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const rentSaga = [takeEvery('GET_SEARCH_ROOMS', handle_get_search_rooms),
takeEvery('GET_HOUJIN', handle_get_houjin),
takeEvery('GET_MYPAGE_LOCAL_SPOTS', handle_get_mypage_local_spots),
takeEvery('GET_MYPAGE_LOCAL_SHUHEN', handle_get_mypage_local_shuhen),
takeEvery('CHANGE_FAVORITE', handle_change_favorite),
takeEvery('CHANGE_FAVORITE_LIST', handle_change_favorite_list),

takeEvery('GET_STAFF', handle_get_staff),

    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;

function* handle_get_mypage_local_shuhen(action: any) {

    const { payload, error } = yield call(MypageLocalSpotsApi.ShuhenList, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し

        // console.log(payload,"paylood")
        yield put(change_mypage_local_shuhen(payload));
        

    } else {
        console.log(error,"マップ周辺失敗")
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_staff(action: any) : any {
    const { payload, error } = yield call(rent_introduction_details_api.staff, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し
        // yield put(change_all_list(payload));


        // const state = yield select();
 
        yield put(change_staff(payload));

        //yield put(get_setsubi_master_success(setsubis));



    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_search_rooms(action: any) : any {

    const { payload, error } = yield call(SearchRoomsApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し
        yield put(change_all_list(payload));


        const state = yield select();
        // if (state.Rent.favorite_list_flg) {
        //     let mypage_rooms = payload.mypage_rooms
        //     payload.mypage_rooms = mypage_rooms.filter((a: any) => a.is_bookmark === true)
        //     yield put(get_search_rooms_success(payload));
        // } else {
        yield put(get_search_rooms_success(payload));
        // }
        yield put(change_favorite_list());

        //yield put(get_setsubi_master_success(setsubis));



    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_get_houjin(action: any) : any {

    const { payload, error } = yield call(SearchRoomsApi.houjin, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // console.log(payload,"法人")
        yield put(change_houjin(payload));

    } else {
        console.log(error,"rent")
    }
}


function* handle_get_mypage_local_spots(action: any) {

    const { payload, error } = yield call(MypageLocalSpotsApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
           console.log(payload,"bbb")


        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        //yield put(get_setsubi_master_success(setsubis));
        if (action.payload === 1) {
            yield put(change_mypage_local_store(payload));
        } else if (action.payload === 2) {
            yield put(change_mypage_local_conveni(payload));
        }

    } else {
        console.log(error,"rent")
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}






function* handle_change_favorite(action: any) : any {
    const state = yield select();


    const { payload, error } = yield call(rent_introduction_details_api.updatebookmark, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert("dd")
        // alert(JSON.stringify(payload))
        // console.log(payload)
        let search_rooms = state.Rent.search_rooms
        let all_list = state.Rent.all_list
        // alert()

        all_list.mypage_rooms = all_list.mypage_rooms.map(function (value: any) {
            if (value.tatemono_id === payload.tatemono_id.trim() && value.room_id === payload.room_id.trim()) {
                value.is_bookmark = payload.is_bookmark


            }
            return value

        })




        yield put(get_search_rooms_success([]));
        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し

        // alert(JSON.stringify(search_rooms))

        // yield put(change_reflash(true));
        // search=search_rooms
        //yield put(get_setsubi_master_success(setsubis));

        if (state.Rent.favorite_list_flg) {

            search_rooms.mypage_rooms = all_list.mypage_rooms.filter((a: any) => a.is_bookmark === true)
            yield put(get_search_rooms_success(search_rooms));
        } else {
            search_rooms.mypage_rooms = all_list.mypage_rooms
            yield put(get_search_rooms_success(search_rooms));
        }


        // yield put(get_search_rooms_success(search_rooms));


    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}
function* handle_change_favorite_list(action: any) : any {
    const state = yield select();


    yield put(get_search_rooms_success([]));
    let search_rooms = state.Rent.search_rooms
    let all_list = state.Rent.all_list
    if (state.Rent.favorite_list_flg) {

        let search_rooms_set: any = []
        search_rooms_set.mypage_rooms = []
        all_list.mypage_rooms.filter((a: any) => a.is_bookmark === true).map(async function (value: any) {
            search_rooms_set.mypage_rooms.push(value)
        })
        search_rooms_set.rent_introduction_headers = search_rooms.rent_introduction_headers
        search_rooms_set.rent_introduction_point_headers = search_rooms.rent_introduction_point_headers

        yield put(get_search_rooms_success(search_rooms_set));
    } else {

        let search_rooms_set: any = []
        search_rooms_set.mypage_rooms = []
        search_rooms_set.mypage_rooms = all_list.mypage_rooms
        search_rooms_set.rent_introduction_headers = search_rooms.rent_introduction_headers
        search_rooms_set.rent_introduction_point_headers = search_rooms.rent_introduction_point_headers
        yield put(get_search_rooms_success(search_rooms_set));
    }



}