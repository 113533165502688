import React from 'react';
// import { compose, withStateHandlers } from "recompose";
import { connect } from 'react-redux';
import styled from 'styled-components'
// import ie from './Img/Map/icon_ie01.png'
import GoogleMapReact from 'google-map-react'
// import GoogleMapReact, { MapOptions, Maps } from 'google-map-react'
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import './Css/Pin.css'
import StoreIcon from '@material-ui/icons/Store';
// import StoreIcon from '@material-ui/icons/StoreMallDirectory';
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
// 参考サイト
// https://stackblitz.com/edit/react-umnzy4
// https://tomchentw.github.io/react-google-maps/



const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,


  }
}

// const onBoundsChange = (center, zoom  , bounds, marginBounds ) => {
//   console.log(bounds)
//   // this.props.onCenterChange(center);
//   // this.props.onZoomChange(zoom);
// }
// const bnCoord = {
//   lat: 44.597923,
//   lng: 0.873799,
// }
const App = (props) => {

  var setParking = []
  var setConveni = []
  var setSuper = []
  var setBankPost = []
  var setNursery = []
  var setSchool = []
  var setDrug = []
  var setHospital = []

  if(props.shuhen){
    const shuhen = props.shuhen;
    setParking =  shuhen.filter(x => x.category === 'parking')
    setConveni = shuhen.filter(x => x.category === 2)
    setSuper = shuhen.filter(x => x.category === 1)
    setBankPost = shuhen.filter(x => x.category === 18 || x.category === 5)
    setNursery = shuhen.filter(x => x.category === 13 || x.category === 14)
    setSchool = shuhen.filter(x => x.category === 19 || x.category === 6)
    setDrug = shuhen.filter(x => x.category === 17)
    setHospital = shuhen.filter(x => x.category === 3 || x.category === 106)
  }

  
  // alert(props.search_rooms_detail.zoom)

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact

        bootstrapURLKeys={{
          key: 'AIzaSyClDVE129NsjckVjDJpMUpqBUuqMTg5YUo'
        }}

        defaultCenter={{
          lat: props.search_rooms_detail.ido,
          lng: props.search_rooms_detail.keido
        }}
        defaultZoom={props.search_rooms_detail.zoom_level ? props.search_rooms_detail.zoom_level : 13}

        options={createMapOptions}
      // draggable={props.mapsearch.draggable}
      // onBoundsChange={onBoundsChange}

      >





        {/* {
          props.convini ? props.mypage_local_conveni ? props.mypage_local_conveni.map((pin) => (

            <Pin

              key={pin.id}
              flg={1}
              style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

              lat={pin.lat}
              lng={pin.lng}
            >

              <div className="wrapper">
                <div className="spot_convenience">
                  <LocalConvenienceStoreIcon fontSize="small" />
                </div>
              </div>

            </Pin>
          )) : "" : ""
        }
        {
          props.store ? props.mypage_local_store ? props.mypage_local_store.map((pin) => (

            <Pin

              key={pin.id}
              flg={1}
              style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

              lat={pin.lat}
              lng={pin.lng}
            >

              <div className="wrapper">
                <div className="spot_supermarket">
                  <StoreIcon fontSize="small" />
                </div>
              </div>

            </Pin>
          )) : "" : ""
        } */}

        <Pin
          style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, zIndex:10, cursor: 'pointer', borderRadius: 30 }}

          lat={props.search_rooms_detail.ido}
          lng={props.search_rooms_detail.keido}

        >
          <div className="wrapper">
            <div className="pin2">
              <HomeWorkIcon />
            </div>
          </div>


        </Pin>



        {/* パーキング */}
        {
          props.parking ? setParking.map((pin) => (
          <Pin
              flg={1}
              style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              lat={pin.lat}
              lng={pin.lng}
            >
              <img width={40}  src={`${process.env.PUBLIC_URL}/img/icon/icon_parking.png`} alt={pin.name}/>
          </Pin>
        )) : ""
        }

        {/* コンビニ */}
        {
          props.conveni ? setConveni.map((pin) => (
          <Pin
              flg={1}
              style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

              lat={pin.lat}
              lng={pin.lng}
            >
              <img width={40}  src={`${process.env.PUBLIC_URL}/img/icon/icon_convini.png`} alt={pin.name}/>
          </Pin>
        )) : ""
        }


        {/* スーパー */}
        {
          props.store ? setSuper.map((pin) => (
          <Pin
              flg={1}
              style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

              lat={pin.lat}
              lng={pin.lng}
            >
              <img width={40}  src={`${process.env.PUBLIC_URL}/img/icon/icon_super.png`} alt={pin.name}/>
          </Pin>
          )) : ""
        }

        

        {/* 銀行 */}
        {
          props.bank ? setBankPost.map((pin) => (
          <Pin
              flg={1}
              style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              lat={pin.lat}
              lng={pin.lng}
            >
              <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_bank.png`} alt={pin.name}/>
          </Pin>
          )) : ""
        }     
        
        
        {/* 幼稚園 */}
        {
          props.youchien ? setNursery.map((pin) => (
          <Pin
              flg={1}
              style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              lat={pin.lat}
              lng={pin.lng}
            >
              <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_youtien.png`} alt={pin.name}/>
          </Pin>
          )) : ""
        }     

        {/*　小中学校 */}
        {
          props.school ? setSchool.map((pin) => (
          <Pin
              flg={1}
              style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              lat={pin.lat}
              lng={pin.lng}
            >
              <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_school.png`} alt={pin.name}/>
          </Pin>
          )) : ""
        }     

        {/*　ドラッグストア */}
        {
          props.ds ? setDrug.map((pin) => (
          <Pin
              flg={1}
              style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              lat={pin.lat}
              lng={pin.lng}
            >
              <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_ds.png`} alt={pin.name}/>
          </Pin>
          )) : ""
        }   

        {/*　病院 */}
        {
          props.hospital ? setHospital.map((pin) => (
          <Pin
              flg={1}
              style={{ position: 'absolute', width: 20, height: 20, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}
              lat={pin.lat}
              lng={pin.lng}
            >
              <img width={40} src={`${process.env.PUBLIC_URL}/img/icon/icon_hospital.png`} alt={pin.name}/>
          </Pin>
          )) : ""
        }  


      </GoogleMapReact></div>)
}


const Pin = styled.div``

//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
  return {
    search_rooms_detail: state.Detail.search_rooms_detail,
    mypage_local_conveni: state.Rent.mypage_local_conveni,
    mypage_local_store: state.Rent.mypage_local_store,
    shuhen: state.Detail.shuhen,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    //firebasedata: state.firebaseReducer.firebasedata,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    //firebaseSet(state) {
    //    dispatch(firebaseSet(state))
    //},
    //loadTodos(state) {
    //    dispatch(loadTodos(state))
    //}
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(App);